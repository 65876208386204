
export class BranchVisitParams {
    constructor(
        public type?: string,
        public stateName?: any[],
        public branchCodes?: string[],
        public fromDate?: any,
        public toDate?: any
    ) { }

}