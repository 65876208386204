import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { IgCoreModule } from 'projects/ig-core/src/lib/ig-core.module';
import { MultiLevelMenuModule } from 'projects/multi-level-menu/src/lib/multi-level-menu.module';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgConfigureModule } from './build-config/configure.module';
import { EntityModule } from './entities/entity.module';
import { HomeModule } from './home/home.module';
import { IgLayoutModule } from './ig-layout/ig-layout.module';
import { MaterialLibModule } from './material-lib.module';
import { UserAccountModule } from './user-account/user-account.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { AutoPopulateTagModule } from './admin/auto-populate-tag/auto-populate-tag.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgConfigureModule.forRoot(),
    FlexLayoutModule.withConfig({ useColumnBasisZero: false }),
    MaterialLibModule,
    ReactiveFormsModule,
    IgCoreModule,
    LayoutModule,
    IgLayoutModule,
    HttpClientModule,
    NgHttpLoaderModule,
    MultiLevelMenuModule,
    HomeModule,
    UserAccountModule,
    AdminModule,
    EntityModule,
    DashboardModule,
    ChartsModule,
    AutoPopulateTagModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
