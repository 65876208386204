import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { AutoPopulateModule } from '../auto-populate-module.model';
import { AutoPopulateTagService } from '../auto-populate-tag.service';
import { Subject } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-create-auto-populate-tag',
  templateUrl: './create-auto-populate-tag.component.html',
  styleUrls: ['./create-auto-populate-tag.component.css']
})
export class CreateAutoPopulateTagComponent implements OnInit, OnDestroy {
  public autoPopulateForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    'code': '',
    'autoPopulateModuleId': '',
    'query': '',
    'primaryKey': '',
    'datasource': '',

  };
  public autoPopulates: AutoPopulateModule[] = [];
  public autoPopulateData: AutoPopulateModule;
  public isQueryValid: boolean = false;
  public editable = true;
  private destroy$ = new Subject<void>();
  public isNew: boolean;
  isViewMode: boolean = false;
  public datasources: any[] = [];  // Add this to your component's properties


  constructor(
    private autoPopulateTagService: AutoPopulateTagService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getAutoPopulateEntityList();
    this.getDatasourceList();

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.isViewMode = this.activatedRoute.snapshot.url.some(segment => segment.path === 'view');
    this.isNew = !id;  // isNew is true if there's no ID (creating a new tag)

    if (id) {
      this.autoPopulateTagService.getTagById(id).subscribe(data => {
        this.autoPopulateData = data;
        this.editable = !!this.autoPopulateData?.id;
        this.autoPopulateForm.patchValue(this.autoPopulateData);

        if (this.isViewMode) {
          this.autoPopulateForm.disable();
        }
      });
    }

    if (!this.isViewMode) {
      this.autoPopulateForm.get('query').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isQueryValid = false;
      });

      this.autoPopulateForm.get('primaryKey').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isQueryValid = false;
      });

      this.autoPopulateForm.get('dataSourceId').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isQueryValid = false;
      });
    }
  }


  ngOnDestroy(): void { }

  buildForm() {
    this.autoPopulateForm = this.formBuilder.group({
      code: ['', Validators.required],
      id: [''],
      version: [''],
      autoPopulateModuleId: ['', Validators.required],
      query: ['', Validators.required],
      primaryKey: ['', Validators.required],
      dataSourceId: ['', Validators.required],
      used: ['']
    });
  }


  getAutoPopulateEntityList(): void {
    this.autoPopulateTagService.getAutoPopulateModules().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(autoPopulates => {
        this.autoPopulates = autoPopulates;
      },
        error => {
          this.snackBar.open('An error occurred while fetching module list.', 'Close', {
            duration: 5000,
          });
        });
  }

  validateDescription(): void {
    const queryControl = this.autoPopulateForm.get('query');
    const primaryKeyControl = this.autoPopulateForm.get('primaryKey');
    const dataSourceValue = this.autoPopulateForm.get('dataSourceId');

    queryControl?.markAsTouched();
    primaryKeyControl?.markAsTouched();
    dataSourceValue?.markAsTouched();
    if (queryControl?.value.trim() === '') {
      queryControl.setErrors({ required: true });
    } else {
      queryControl.setErrors(null);
    }

    if (primaryKeyControl?.value.trim() === '') {
      primaryKeyControl.setErrors({ required: true });
    } else {
      primaryKeyControl.setErrors(null);
    }
    // if (dataSourceValue?.value.trim() === '') {
    //   dataSourceValue.setErrors({ required: true });
    // } else {
    //   dataSourceValue.setErrors(null);
    // }


    this.autoPopulateForm.updateValueAndValidity();
    // if (this.autoPopulateForm.invalid) {
    //   return;
    // }



    const request = {
      query: queryControl?.value,
      primaryKey: primaryKeyControl?.value,
      dataSourceId: dataSourceValue?.value
    };

    this.autoPopulateTagService.getValidateQuery(request)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        response => {
          if (response) {
            this.isQueryValid = true;
            this.snackBar.open('Query Validated Successfully', 'Close', {
              duration: 5000,
            });
          } else {
            queryControl?.setErrors({ invalidQuery: true });
            this.isQueryValid = false;
          }
          this.autoPopulateForm.updateValueAndValidity();
        },
        error => {
          queryControl?.setErrors({ invalidQuery: true });
          this.snackBar.open('Invalid Query', 'Close', {
            duration: 5000,
          });
          this.autoPopulateForm.updateValueAndValidity();
        }
      );
  }

  getDatasourceList(): void {
    this.autoPopulateTagService.getLmsDatasources().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(datasources => {
        this.datasources = datasources;
      },
        error => {
          this.snackBar.open('An error occurred while fetching datasource list.', 'Close', {
            duration: 5000,
          });
        });
  }


  onSubmit(): void {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.autoPopulateForm);
    if (this.autoPopulateForm.valid && this.isQueryValid) {
      this.autoPopulateTagService.postAutopopulates(this.autoPopulateForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          response => this.onSubmitSuccess(response),
          response => this.onSubmitError(response)
        );
    } else {
      this.formErrors = this.igFormService.validateForm(this.autoPopulateForm, this.formErrors, false);
      return;
    }
  }

  private onSubmitSuccess(response: any): void {
    const msg = this.isNew ? 'Created auto-populate tag successfully' : `Updated auto-populate tag successfully`;
    this.snackBar.open(msg, 'Close');
    this.goToPopulateListPage();
  }

  // private onSubmitError(response: any): void {
  //   const errorModel = this.igFormService.handleServerError(this.autoPopulateForm, this.formErrors, response.error);
  //   if (errorModel && errorModel.isValidationError) {
  //     this.formErrors = errorModel.formErrors;
  //   }else {
  //     // Show the backend error in a snackbar
  //     const errorMessage = response.error?.message || 'An error occurred while processing your request.';
  //     this.snackBar.open(errorMessage, 'Close', {
  //       duration: 5000,
  //     });
  //   }
  // }

  private onSubmitError(response: any): void {
    const errorMessage = response.error.title || 'An unexpected error occurred. Please try again.';

    this.snackBar.open(errorMessage, 'Close', {
      duration: 5000,
    });
    // Getting the form control and setting the error
    const codeControl = this.autoPopulateForm.get('code');
    if (codeControl) {
      codeControl.setErrors({ duplicate: true });
    }
  }

  goToPopulateListPage(): void {
    this.router.navigate(['/admin/auto-populate-tag']);

  }
  public onCancel(): void {
    this.router.navigate(['/admin/auto-populate-tag']);
  }
}
