import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validator, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {Router} from '@angular/router';
import {LoginService} from 'projects/ig-core/src/lib/authentication/login.service';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {ForgotPasswordDialogComponent} from './forgot-password-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    username: '',
    password: '',
  };

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private igFormService: IgFormService,
              private loginService: LoginService,
              private router: Router,
              public dialog: MatDialog) {}

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void {
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.compose([Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
        /*// check whether the entered password has a number
        IgPatternValidator.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        IgPatternValidator.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        IgPatternValidator.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        IgPatternValidator.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)*/])],
      rememberMe: [false]
    });

    // on each value change we call the validateForm function
    // We only validate form controls that are dirty, meaning they are touched
    // the result is passed to the formErrors object
    this.loginForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.loginForm, this.formErrors, true);
    });
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    // this.fireClientError();
    // mark all fields as touched
    this.igFormService.markFormGroupTouched(this.loginForm);

    /*right before we submit our form to the server we check if the form is valid
    if not, we pass the form to the validateform function again. Now with check dirty false
    this means we check every form field independent of wether it's touched*/

    if (this.loginForm.valid) {
      // this.fireClientError();
      this.loginService.login(this.loginForm.value)
        .pipe(takeUntil(componentDestroyed(this))).subscribe((response) => this.onSubmitSuccess(response),
        (response) => this.onSubmitError(response));
    } else {
      this.formErrors = this.igFormService.validateForm(this.loginForm, this.formErrors, false);
    }

  }

  private onSubmitSuccess(response) {
    this.snackBar.open('Sign in successful', 'Close', {
      duration: 3000,
    });
    this.router.navigate(['home']);
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(this.loginForm, this.formErrors, response.error);
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    } else if (errorModel && !errorModel.isValidationError) {
      this.formError.hasError = true;
      this.formError.error = errorModel.otherError;
      console.log(this.formError);
      // this.loginForm.reset();
    }
  }

  fireClientError() {
    // it is not defined, ups Error
    // return it.happens;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '500px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(result => {});
  }
}
