import { AuditPlan } from '../audit-plan/audit-plan.model';

export class AuditTransaction {
    constructor(
        public id?: number,
        public version?: number,
        public branchCode?: string,
        public branchName?: string,
        public status?: string,
        public auditNo?: string,
        public actionStatus?: string,
        public comment?: string,
        public remarks?: string,
        public auditPlanDTO?: AuditPlan,
        public reducedScorePercentage?: string,
        public auditFlowHistories?:string,
        public teamName?:string,
        public userComments?: string,
        public scorePercentage?: number,
        public commencementDate?: number,
        public finishedDate?: number,
    ) {}
}
