<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon> donut_small</mat-icon>
        </div>
        <div mat-card-title>
            Activity Configuration
        </div>
        <div class="page-title-action">
            <mat-card-actions class="pull-right">
                <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
                    <mat-icon>add_circle</mat-icon>
                    Create Template
                </button>
                <button mat-button mat-raised-button color="accent" [routerLink]="'mapping-template'">
                    <mat-icon>add_circle</mat-icon>
                    Template Mapping
                </button>
            </mat-card-actions>
        </div>
    </mat-card-header>

    <mat-card-content>

        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="id" matSortDirection="desc" matSortDisableClear
                   [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
                    <td mat-cell *matCellDef="let element" data-label="Name"> {{element.name}}</td>
                </ng-container>

                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="code">Code</th>
                    <td mat-cell *matCellDef="let element" data-label="Code"> {{element.code}}</td>
                </ng-container>

                <ng-container matColumnDef="effectiveDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="effectiveDate">EffectiveDate</th>
                    <td mat-cell *matCellDef="let element" data-label="EffectiveDate"> {{element.effectiveDate}}</td>
                </ng-container>

                <ng-container matColumnDef="businessLine">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="businessLine">Business Line</th>
                    <td mat-cell *matCellDef="let element" data-label="Business Line"> {{element.businessLineDTO.name}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="View" [routerLink]="['view', element.id]">
                            <mat-icon>more</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Edit" [routerLink]="['edit', element.id]" [disabled]="element.effectiveDate && element.userLoginIds?.length > 0 ">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Clone" (click)="clone(element.id)">
                            <mat-icon>file_copy</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                           [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"
                           [pageIndex]="pagingAndSortParams.pageIndex"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

