import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validator, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { IgFormService } from '../../../../projects/ig-core/src/lib/form/form.service';
import { LoginService } from '../../../../projects/ig-core/src/lib/authentication/login.service';
import { FormErrorModel } from '../../../../projects/ig-core/src/lib/form/form-error.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-reset-password-dialouge',
  templateUrl: 'forgot-password-dialog.html'
})
export class ForgotPasswordDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    private igFormService: IgFormService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private snackBar: MatSnackBar
  ) {}

  public resetForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    email: ''
  };
  success = false;
  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  buildForm() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.resetForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.resetForm,
          this.formErrors,
          true
        );
      });
  }
  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.resetForm);
    if (this.resetForm.valid) {
      this.loginService
        .sendMail(this.resetForm.value.email)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          response => this.onSubmitSuccess(response),
          response => this.onSubmitError(response)
        );
    } else {
      this.formErrors = this.igFormService.validateForm(
        this.resetForm,
        this.formErrors,
        false
      );
    }
  }
  onSubmitSuccess(response) {
    this.success = true;
    this.snackBar.open("If user/e-mail is registered, you'll receive the forget password link in your email", 'Close');
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

  onSubmitError(response) {
    console.log(response);
    if (response.error.status === 400 || response.error.status === "BAD_REQUEST") {
      this.snackBar.open(` ${response.error.title} `, 'Close');
    } else {
      this.snackBar.open(
        'There was an error while processing your request',
        'Close');
    }
  }

  ngOnDestroy(): void {}
}
