import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
// import { AuthenticatedUserService } from '/projects/ig-core/src/lib/authentication/authenticated-user-service';
import { AuthorityComponent } from './authority.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MaterialLibModule } from '../../material-lib.module';
// import { RoleCreationComponent } from './role-creation.component';
import { FormsModule } from '@angular/forms';
// import { RoleDeleteCreationComponent } from './role-delete-dialog.component';
// import { RoleViewComponent } from './role-view.component';
@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule
   ],
  declarations: [ AuthorityComponent],
  entryComponents: [AuthorityComponent],
  exports: [RouterModule]
})
export class AuthorityModule {}
