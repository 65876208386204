export class Role {
    id?: number;
    version?: number;
    name?: string;
    code?: string;
    flag?: string;
    authorities?: string;
    createdBy?: string;
    createdDate?: any;
}
