import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { Role } from '../role.model';
import { RoleService } from '../role.service';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';

@Component({
    selector: 'app-role-editor',
    templateUrl: './role-editor.component.html',
    styleUrls: ['../role.component.css']
})
export class RoleEditorComponent implements OnInit, OnDestroy {
    role: Role;
    editable = true;
    isNew: boolean;
    authorityDropdownSettings = {
        singleSelection: false,
        idField: 'authorityName',
        textField: 'authorityName',
        itemsShowLimit: 1,
        allowSearchFilter: true,
      };
    selectedItems:Array<string> = []
    public roleEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        'id': '', 'version': '', 'name': '', 'code': '', 'authorities': '',
    };
    public authoritiesObservable$;
    constructor(private roleService: RoleService,
        private activatedRoute: ActivatedRoute,

        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.role === undefined) {
                    this.role = {};
                } else {
                    this.role = data.role;
                    this.selectedItems = this.role.authorities as any
                }
                this.editable = data.editable;
            });
        this.authoritiesObservable$ = this.roleService.getAuthorities();
    }

    ngOnInit() {
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.roleEditorForm.disable();
        }
    }
    ngOnDestroy(): void {
    }

    buildForm() {
         this.isNew = false;
     if (this.role.id === undefined) {
         this.isNew = true;
     }
        this.roleEditorForm = this.formBuilder.group({

            'id': [this.role.id, []],

            'version': [this.role.version, []],

            'name': [this.role.name, []],

            'code': [this.role.code, []],

            'authorities': [this.role.authorities, []],

            'createdBy' : [this.role.createdBy, []],

            'createdDate' : [this.role.createdDate, []],

        });
        this.roleEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.roleEditorForm, this.formErrors, true);
        });
    }
    onSubmit() {
        this.formError = new FormErrorModel(false, '');

        // mark all fields as touched
        this.igFormService.markFormGroupTouched(this.roleEditorForm);

        if (this.roleEditorForm.valid) {
            this.roleEditorForm.controls["authorities"].setValue(this.selectedItems)
            this.roleService.saveRole(this.roleEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.roleEditorForm, this.formErrors, false);
            return false;
        }

    }

    onSelect(event){
        this.selectedItems.push(event.authorityName)
    }

    onDeSelect(event){
        this.selectedItems.splice(this.selectedItems.indexOf(event.authorityName), 1)
    }

    onDeselectAll(){
        this.selectedItems = []
    }

    onSelectAll(event){
        this.selectedItems = event
    }

    private onSubmitSuccess(response) {
        let msg = '';
        if (this.role.id) {
          msg = `Updated role ${this.role.id} successfully`;
        } else {
          msg = `Created role successfully`;
        }
        this.snackBar.open( msg, 'Close');
        this.goToRoleListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.roleEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    }

    goToRoleListPage() {
        if (this.role.id) {
            this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
    }

}
