<mat-card style="height: 95%; overflow-y: hidden;">
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon>important_devices</mat-icon>
    </div>
    <div mat-card-title>
      Digital Registers Report
    </div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="cihReportForm" novalidate (ngSubmit)="onSubmit()">

      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start">
     
        <mat-form-field  *ngIf="isRoleAdmin" style="margin-right: 3%;margin-top: 4.6px;">
          <mat-select placeholder="Business Line"  formControlName="businessLineId"
           (selectionChange)="changeBusinessLine($event)">
            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
              {{businessLine.name}}
            </mat-option>
          </mat-select>
          <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
        </mat-form-field>
        
        <mat-form-field style="margin-right: 1%;margin-top: 4.6px;">
          <mat-select [(ngModel)]="register" placeholder="Digital Register" formControlName="register"
            (selectionChange)="onSelectRegister($event.value)">
            <mat-option *ngFor="let digitalRegister of digitalRegisterList" [value]="digitalRegister.code">
              {{digitalRegister.name}}
            </mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.register">{{ formErrors.register }}</mat-error>
        </mat-form-field>

        <mat-form-field style="margin-right: 1%;margin-top: 4.6px;">
          <mat-select formControlName="type" name="type" [(ngModel)]="type" placeholder="Report Type"
          (selectionChange)="changeRegister($event)">
            <mat-option *ngFor="let typeReport of typeReportList" [value]="typeReport.code">{{typeReport.name}}
            </mat-option>
          </mat-select>
          <mat-error align="start" >{{ formErrors.type }}</mat-error>
        </mat-form-field>

        <div style="width: 220px;margin-right: 3%;margin-top: 4.6px;">
          <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'" [data]="stateDropdownList"
            [(ngModel)]="selectedStates" [settings]="stateDropdownSettings" (onSelect)="onSelectState($event)"
            (onSelectAll)="onSelectAllState($event)" (onDeSelect)="onItemDeSelect($event)"
            (onDeSelectAll)="onItemDeSelectAll($event)" required>
          </ng-multiselect-dropdown>
          <mat-error align="start" *ngIf="formErrors.stateName && cihReportForm.controls['stateName']?.touched ">{{ formErrors.stateName }}</mat-error>
        </div>

        <div style="width: 220px;margin-right: 3%;margin-top: 4.6px;">
          <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
            [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)">
          </ng-multiselect-dropdown>
          <mat-error align="start" *ngIf="formErrors.name && cihReportForm.controls['name']?.touched ">{{ formErrors.name }}</mat-error>
        </div>
       
        <mat-form-field style="margin-left: 1%;margin-right: 3%;margin-top: 4.6px;">
          <input matInput name="fromDateFilter" formControlName="fromDateFilter" [max]="toDateFilter"
            (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter"
            [matDatepicker]="fromDatePicker" placeholder="FromDate">
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter }}
          </mat-error>
        </mat-form-field>

        <mat-form-field style="margin-right: 3%;margin-top: 4.6px;">
          <input matInput name="toDateFilter" formControlName="toDateFilter" [min]="fromDateFilter"
            (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
            [matDatepicker]="toDatePicker" placeholder="ToDate">
          <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter }}</mat-error>
        </mat-form-field>

        <div *ngIf="isCihStatus">
        <mat-form-field   style="margin-right: 3%;margin-top: 4.6px;">
          <mat-select multiple placeholder="CIH Status" formControlName="cihStatus">
            <mat-option *ngFor="let cihStatustype of cihStatustypes" [value]="cihStatustype.code">{{ cihStatustype.name }}</mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.cihStatus">{{ formErrors.cihStatus }}</mat-error>
          </mat-form-field>
        </div>

        <button type="submit" style="height: 40px;margin-right: 1%;margin-top: 4.6px;" mat-raised-button
          color="primary">
          <mat-icon>cloud_download</mat-icon> Download Report
        </button>

        <button type="button" style="height: 40px;margin-right: 1%;margin-top: 4.6px;" mat-raised-button color="primary"
          (click)="clearFilter()"> Clear
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>