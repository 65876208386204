<mat-card style="height:95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToBranchSetListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="!editable" class="button-icon-text">View Branch Set</mat-card-title>
        <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create Branch Set</mat-card-title>
        <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit Branch Set</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="branchSetEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="start"
                class="two-per-row">

                <input name="id" type="hidden" formControlName="id" />
                <input name="version" type="hidden" formControlName="version" />
                <mat-form-field>
                    <input name="name" matInput type="text" formControlName="name" placeholder="Name" />
                    <mat-error align="start" *ngIf="formErrors.name">
                        {{ formErrors.name }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="code" matInput type="text" formControlName="code" placeholder="Code" />
                    <mat-error align="start" *ngIf="formErrors.code">
                        {{ formErrors.code }}
                    </mat-error>
                </mat-form-field>
                
                <mat-form-field>
                    <mat-select placeholder="Business Line"  formControlName="businessLineId" 
                    (selectionChange)="changeBusinessLine($event)">
                      <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                        {{businessLine.name}} 
                      </mat-option>
                    </mat-select>
                    <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                  </mat-form-field>

                <div fxFlex="44%">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                        [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                        (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)"
                        [disabled]="!editable" >
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.stateName && branchSetEditorForm.controls['stateName']?.touched ">
                        {{ formErrors.stateName }}
                    </mat-error>
                </div>

                <div fxFlex="44%">
                    <ng-multiselect-dropdown formControlName="branchIds" name="branchIds" [placeholder]="'branches'"
                        [data]="dropdownList" [(ngModel)]="selectedBranches" [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelect)="onItemDeSelectBranch($event)" [disabled]="!editable" >
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.branchIds && branchSetEditorForm.controls['branchIds']?.touched ">
                        {{ formErrors.branchIds }}
                    </mat-error>
                </div>

                <!-- <mat-form-field>
                            <mat-select placeholder="Branches" name="branchIds"
                                        formControlName="branchIds" multiple>
                                <mat-option *ngFor="let branch of allBranches$ | async" [value]="branch.id">
                                    {{branch.name}} - {{branch.code}}
                                </mat-option>
                            </mat-select>
                            <mat-error align="start" *ngIf="formErrors.branchIds">
                                {{ formErrors.branchIds }}
                            </mat-error>
                        </mat-form-field> -->

                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="primary">Submit</button>
                    <button mat-raised-button (click)="goToBranchSetListPage()">Cancel</button>
                </mat-card-actions>
            </div>

        </form>
    </mat-card-content>
</mat-card>