import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, take } from 'rxjs/operators';
import { Import, OtsJobStatus, OtsJobType } from './import.model';
import { ReportService } from '../reports/report.service';

export class ImportDataSource extends DataSource<Import> {

    private importSubject = new BehaviorSubject<Import[]>([]);

    public totalCount$ = new BehaviorSubject<number>(0);
    result: any;
    paginator: any;

    constructor(private reportService: ReportService) { super(); }

    connect(collectionViewer: CollectionViewer): Observable<Import[]> {
        return this.importSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.importSubject.complete();
        this.totalCount$.complete();
    }
    loadOtsImport(sortDirection = 'asc', pageIndex = 0, pageSize = 3, jobName: String, jobStatus: String, jobType: String, uploadedDate: Date) {

        this.reportService.importData({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection,
            jobName: jobName,
            jobStatus: jobStatus,
            jobType: jobType,
            uploadedDate: uploadedDate
        }

        ).pipe(
            catchError(() => of([])),
            finalize(() => { })
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.importSubject.next(response['body']);

        });
    }




}