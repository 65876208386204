<mat-card>
    <div fxLayoutAlign="end" style="margin-right: 10px;">
        <mat-button-toggle-group appearance="standard" (change)="onBmtReportTypeChange($event)">
            <mat-button-toggle value="1" [checked]="showActivityesCount"><small>Completed</small>
            </mat-button-toggle>
            <mat-button-toggle value="2"><small>Productivity</small></mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group appearance="standard" style="margin-left: 10px;"
            (change)="onBmtReportChartTypeChange($event)">
            <mat-button-toggle value="1" [checked]="showActivityesCount"><small>Bar Graph</small>
            </mat-button-toggle>
            <mat-button-toggle value="2"><small>Line Graph</small></mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div fxLayoutAlign="end" style="margin: 15px;">
        <small style="color: grey;font-size: 10px;">Data displayed from Sept 1,2020-Sept 30,2020</small>
    </div>

    <br />
    <mat-card-content>
        <div style="display: block" fxLayoutAlign="center" *ngIf="!showProductivityReport">
            <canvas style="height:800; width:60vw" baseChart [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartActivityOptions" [legend]="barChartLegend" [chartType]="barChartType" (chartClick)="chartClicked($event)" >
            </canvas>
        </div>
        <div *ngIf="showProductivityReport" style="display: block" fxLayoutAlign="center">
            <canvas style="height:400; width:60vw "  baseChart [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartActivityOptions" [legend]="barChartLegend" [chartType]="barChartType" >
            </canvas>
        </div>
    </mat-card-content>
</mat-card>
<div *ngIf="show">
    <app-count-data-report></app-count-data-report>
</div>



