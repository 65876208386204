import { Injectable } from '@angular/core';
import { AutoPopulateTagModule } from './auto-populate-tag.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ValidateQueryModel } from './validate-query.model';
import { AutoPopulateModule } from './auto-populate-module.model';

@Injectable({
  providedIn: 'root'
})
export class AutoPopulateTagService {

  constructor(private http: HttpClient) { }

  getAutoPopulateModules() {
    return this.http.get<AutoPopulateTagModule[]>('api/auto-populate-modules');
  }

  private apiUrl = 'api/auto-populate-tags';
  private baseUrl = 'api/auto-populate-modules';


  postAutopopulates(data: AutoPopulateModule): Observable<AutoPopulateModule> {
    if (data.id) {
      return this.http.put<AutoPopulateModule>(this.apiUrl, data);

    } else {
      return this.http.post<AutoPopulateModule>(this.apiUrl, data);

    }
  }
  getTags(): Observable<AutoPopulateTagModule[]> {
    return this.http.get<AutoPopulateTagModule[]>(`${this.apiUrl}`);
  }

  getModules(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`);
  }

  getValidateQuery(request: any): Observable<boolean> {

    const validateQuery = new ValidateQueryModel();

    if (request.query) {
      validateQuery.query = request.query;
    }
    if (request.primaryKey) {
      validateQuery.primaryKey = request.primaryKey;
    }
    if(request.dataSourceId){
      validateQuery.dataSourceId = request.dataSourceId;
    }
    return this.http.post<boolean>(`api/auto-populate-tags/validate-query`, validateQuery);
  }

  getLmsDatasources(): Observable<any[]> {
    return this.http.get<any[]>('api/lms-datasources');
  }
  updateTag(tag: any): Observable<any> {
    return this.http.put(this.apiUrl, tag);
  }

  getTagById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  deleteTag(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getAutoPoulateTags(questionaireId: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('questionnaireId', questionaireId);
    return this.http.get<any[]>('api/auto-populate-tags/questionnaire', { params: params, observe: 'response' });
  }
}
