import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigureService} from 'src/app/build-config/configure.service';
import {retry, tap} from 'rxjs/operators';
import {IgErrorLogService} from '../error-handler/ig-error-log.service';
import {AuthTokenService} from '../authentication/auth-token.service';
import {Router} from '@angular/router';

@Injectable()
export class IgServerErrorInterceptor implements HttpInterceptor {
  constructor(private igErrorLogService: IgErrorLogService,
              private authTokenService: AuthTokenService,
              private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {}, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.router.navigate(['signout']);
        }
        this.igErrorLogService.logError(err);
      }
    }));
  }

}
