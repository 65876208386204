import { Component, OnInit } from '@angular/core';
import { AuthorityService } from './authority.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { AuthorityDataSource } from './authority.data.source';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import {AfterViewInit, Inject, LOCALE_ID, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
// import { RoleDeleteCreationComponent } from './role-delete-dialog.component';
@Component({
  selector: 'app-authority',
  templateUrl: './authority.component.html',
  styleUrls: ['./authority.component.css']
})
export class AuthorityComponent implements OnInit, AfterViewInit, OnDestroy {
  title: string;
 // userSearchParams: UserListComponent;
  pagingAndSortParams: PagingAndSortParams;
  dataSource: AuthorityDataSource;
  listSource: ListSource;

  displayedColumns = ['authorityName'];

  constructor(private roleService: AuthorityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog) { }
    serverUrl: string;

      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;

  ngOnInit() {
    this.dataSource = new AuthorityDataSource(this.roleService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this))).subscribe((listParams) => {
      // Load paging and sort params from url
      this.pagingAndSortParams = listParams.pagingAndSortParam;
      let sortColumn = this.pagingAndSortParams.sortColumn;
      if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
        sortColumn = 'authorityName';
      }
      // Load other, params and queryParams
      this.dataSource.loadAudits(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize);
    });
  }
  ngAfterViewInit(): void {
    // initiate sort and pagination change once the view is initialized
    this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadAuditsPage());
  }

  ngOnDestroy(): void {
  }

  loadAuditsPage() {
    this.router.navigate(['/api/authorities'], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

}
