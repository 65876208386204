<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar style="padding: 10px 0px; text-align: center;">
      <mat-icon> history</mat-icon>
    </div>
    <mat-card-title class="button-icon-text"> Job Import History</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-form-field>
        <input matInput name="jobName" [(ngModel)]="jobName" placeholder="Name">
      </mat-form-field>
      &nbsp;
      <!-- <mat-form-field>
        <mat-select name="jobType" [(ngModel)]="jobType" placeholder="Type">
          <mat-option *ngFor="let jobType of jobTypeList" [value]="jobType">
            {{jobType}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp; -->
      <mat-form-field>
        <mat-select name="jobStatus" [(ngModel)]="jobStatus" placeholder="Status">
          <mat-option *ngFor="let jobStatus of jobStatusList" [value]="jobStatus">
            {{jobStatus}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <button type="button" mat-raised-button color="primary" (click)="search()">
        <mat-icon>search</mat-icon>
      </button>
      &nbsp;
      <button type="button" mat-raised-button color="primary" (click)="clearFilter()">
        Clear
      </button>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table matSort matSortActive="jobName" matSortDirection="asc" matSortDisableClear
        [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="jobName">
          <th mat-header-cell *matHeaderCellDef> Job Name </th>
          <td mat-cell *matCellDef="let element" data-label="Job Name"> {{element.jobName}} </td>
        </ng-container>


        <!--  <ng-container matColumnDef="jobType">
          <th mat-header-cell *matHeaderCellDef>Job Type </th>
          <td mat-cell *matCellDef="let element" data-label="JobType"> {{element.jobType}} </td>
        </ng-container> -->


        <ng-container matColumnDef="uploadedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="uploadedDate"> Uploaded Date</th>
          <td mat-cell *matCellDef="let element" data-label="UploadedDate"> {{element.uploadedDate}} </td>
        </ng-container>

        <ng-container matColumnDef="totalCount">
          <th mat-header-cell *matHeaderCellDef> Total Count </th>
          <td mat-cell *matCellDef="let element" data-label="TotalCount"> {{element.totalCount}} </td>
        </ng-container>

        <ng-container matColumnDef="successCount">
          <th mat-header-cell *matHeaderCellDef>Success Count</th>
          <td mat-cell *matCellDef="let element" data-label="SuccessCount"> {{element.successCount}}</td>
        </ng-container>

        <ng-container matColumnDef="failureCount">
          <th mat-header-cell *matHeaderCellDef> Failure Count</th>
          <td mat-cell *matCellDef="let element" data-label="FailureCount"> {{element.failureCount}} </td>
        </ng-container>


        <ng-container matColumnDef="jobStatus">
          <th mat-header-cell *matHeaderCellDef> Job Status</th>
          <td mat-cell *matCellDef="let element" data-label="JobStatus"> {{element.jobStatus}} </td>
        </ng-container>

        

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">

            <button  mat-icon-button matTooltip="Output File" [disabled]="!element.outputFileId" (click)="getErrorAndOutPutFileId(element)">
              <mat-icon>view_list</mat-icon>
          </button>
          <button  mat-icon-button matTooltip="Output File" [disabled]="!element.errorFileId" (click)="getErrorAndOutPutFileId(element)">
            <mat-icon>warning</mat-icon>
        </button>

          </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
        [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>

    </div>
  </mat-card-content>
</mat-card>