<mat-card>
    <div class="flex-container">
    <mat-card-header>
      <!-- <div mat-card-avatar style="flex-grow: 1">
          <i class="material-icons">
              face
              </i>
      </div> -->
      <mat-card-title style="flex-grow: 1">
        Authority
      </mat-card-title>
      <!-- <button mat-button (click)="roleCreation()">Role !</button> -->
    </mat-card-header>
  </div>
    <mat-card-content>
<table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
   
    <ng-container matColumnDef="authorityName">
      <th mat-header-cell *matHeaderCellDef > Authority </th>
      <td mat-cell *matCellDef="let element"> {{element.authorityName}} </td>
    </ng-container>
  
    <!-- <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef > </th>
        <td mat-cell *matCellDef="let element">  
          <a [routerLink]="['/admin/rolesView', element.id]">
            <span>
            <mat-icon style="color: grey">assignment</mat-icon>
            </span>
          </a>
  
          <a [routerLink]="['/admin/rolesUpdate', element.id]">
            <span >
            <mat-icon>mode_edit</mat-icon>
            </span>
          </a>
  
          <button mat-icon-button (click)="deleteDialog(element)">
            <span >
            <mat-icon>delete</mat-icon>
            </span>
          </button>
  </td>
      </ng-container>
   -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
  [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>

</mat-card-content>
</mat-card>