import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { NameValueDto } from 'projects/ig-core/src/lib/interfaces/name-value-dto';
import { takeUntil } from 'rxjs/operators';
import { ReportService } from './report.service';
import { ActivatedRoute } from '@angular/router';
import { DownloadFormatType } from './download-format';
import { months, years, reportTypes } from '../../constants/data.constants';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';

export interface Report {
    value: string;
    viewValue: string;
}

export interface DownloadFormat {
    value: DownloadFormatType;
    viewValue: string;
}

@Component({
    selector: 'app-audit-report',
    templateUrl: './audit-report.component.html'
})

export class AuditReportComponent implements OnInit, OnDestroy {
    reportTypeList: Report[] = [];
    hasAuditPlans = false;
    reportType: any;
    internalAuditResponse = [];
    downLoadFormatList: DownloadFormat[] = [];
    downloadFormat: DownloadFormatType;
    fromDateFilter: string;
    toDateFilter: string;
    auditMonth = [];
    month: number;
    auditYear: string;
    auditId: number;
    questionnaireVersion: string;
    branches: any;
    selectedBranches: any[];
    branchCode: any;
    allMonths: any[];
    allYears: NameValueDto[];
    isFromToDate: boolean;
    isToDate: boolean;
    isMonthYear: boolean;
    isBranches: boolean;
    isBranch: boolean;
    hasOneMonth: boolean;
    isQuestionnaireVersion: boolean;
    isDownloadOnlyExcel = false;
    fromDate: string;
    toDate: string;
    minDate: Date;
    maxDate: Date;
    referenceCodeList = [];
    isWeightageType: boolean = false;
	weightageType: string;
    singleDropdownSettings = {
        singleSelection: true,
        idField: 'code',
        textField: 'nameCode',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    dropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'nameCode',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        versionField:''
    }
    code: string;
    public auditReportForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        reportTypeList: '',
        branchCodesSelect: '',
        branchCode: '',
        fromDateFilter: '',
        toDateFilter: '',
        auditMonth: '',
        month: '',
        auditYear: '',
        auditId: '',
        questionnaireVersion: '',
        downLoadFormatList: ''
    };
    dropdownList: any;

    states: any[];
    selectedStates: any[];
    isStates: boolean;
    versionNameList: any;
    isYear: boolean;

    loggedInUserRole: any;

    constructor(private igFormService: IgFormService, private formBuilder: FormBuilder,
        private snackBar: MatSnackBar, private reportService: ReportService,
        private activatedRoute: ActivatedRoute,
        private referenceCodeService: ReferenceCodeService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUserRole = data.loggedInUser.body.role;
            });
    }
    ngOnInit() {
        this.loadModuleMastersCodes();
        this.setTypesOfReports();
        this.loadBranches();
        this.loadMonthsAndYears();
        this.buildForm();
        this.loadStates();
        this.isToDate = false;
        this.maxDate = new Date(Date.now());
        this.formError = new FormErrorModel(false, '');
    }

    loadModuleMastersCodes(){
        this.referenceCodeService.getModuleMastersCodes('AUDIT_WEIGHTAGE_TYPE')
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => {
            this.referenceCodeList = response;
          });
        }

    onItemSelect(item: any) {
        if (this.reportType === 'internal-audit-report' || this.reportType === 'answer-scoring-report' || this.reportType === 'parameter-wise-weightage') {
            this.reportService.getAllAuditPlansForBranchCode(item.code).pipe(takeUntil(componentDestroyed(this)))
                .subscribe(
                    (res: HttpResponse<any>) => {
                        this.populateAuditPlans(res.body);
                    });
        }

    }

    clearFilter() {
        this.selectedBranches = undefined;
        this.branchCode = undefined;
        this.fromDateFilter = undefined;
        this.toDateFilter = undefined;
        this.auditMonth = undefined;
        this.auditId = undefined;
        this.month = undefined;
        this.auditYear = undefined;
        this.selectedStates = undefined;
        this.questionnaireVersion = undefined;
        this.auditReportForm.get('weightageType').setValue(undefined);
        this.hasAuditPlans = false;
        if (this.isDownloadOnlyExcel) {
            this.downLoadFormatList = [
                { value: DownloadFormatType.EXCEL, viewValue: 'EXCEL' }
            ];
        } else {
            this.downLoadFormatList = [
                { value: DownloadFormatType.PDF, viewValue: 'PDF' },
                { value: DownloadFormatType.EXCEL, viewValue: 'EXCEL' }
            ];
        }
    }
    // On selection of type of report this method is called
    public onChange(event): void {
        const selectedReportType = event;
        this.isDownloadOnlyExcel = false;
        //this.isWeightageType = false;
        if (selectedReportType === 'branch-deviation') {
            const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
            branchCodesControl.setValidators(Validators.required);
            branchCodesControl.updateValueAndValidity();
            const auditForMonth = this.auditReportForm.get('auditMonth');
            auditForMonth.setValidators(Validators.required);
            auditForMonth.updateValueAndValidity();
            const auditForYear = this.auditReportForm.get('auditYear');
            auditForYear.setValidators(Validators.required);
            auditForYear.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].enable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].enable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranches = true;
            this.isBranch = false;
            this.hasOneMonth = false;
            this.isMonthYear = true;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.isYear = false;
            this.clearFilter();
        } else if (selectedReportType === 'branch-frequency') {
            const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
            branchCodesControl.setValidators(Validators.required);
            branchCodesControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = true;
            this.hasOneMonth = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isMonthYear = false;
            this.isBranch = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.isYear = false;
            this.clearFilter();
        } else if (selectedReportType === 'internal-audit-report' || selectedReportType === 'answer-scoring-report' || selectedReportType === 'parameter-wise-weightage') {
            if(selectedReportType === 'internal-audit-report'){
                    const weightageTypeCodesControl = this.auditReportForm.get('weightageType');
                    weightageTypeCodesControl.setValidators(Validators.required);
                    weightageTypeCodesControl.updateValueAndValidity();
                    this.auditReportForm.controls['weightageType'].enable();
                    this.isWeightageType = true;
                }else{
                    this.auditReportForm.controls['weightageType'].disable();
                    this.isWeightageType = false;
                }
            const branchCodeControl = this.auditReportForm.get('branchCode');
            branchCodeControl.setValidators(Validators.required);
            branchCodeControl.updateValueAndValidity();
            const auditIdControl = this.auditReportForm.get('auditId');
            auditIdControl.setValidators(Validators.required);
            auditIdControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].disable();
            this.auditReportForm.controls['branchCode'].enable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['auditId'].enable();
            this.isBranch = true;
            this.hasOneMonth = false;
            this.isMonthYear = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranches = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            if ( selectedReportType === 'answer-scoring-report' || selectedReportType === 'parameter-wise-weightage') {
            this.isDownloadOnlyExcel = true;
            }
            this.hasAuditPlans = true;
            this.isYear = false;
            this.clearFilter();
        } else if (selectedReportType === 'centre-coverage' || selectedReportType === 'customer-centre-coverage' ) {
            const branchCodeControl = this.auditReportForm.get('branchCode');
            branchCodeControl.setValidators(Validators.required);
            branchCodeControl.updateValueAndValidity();
            this.auditReportForm.controls["branchCodesSelect"].setValidators(Validators.required)
            this.auditReportForm.controls["fromDateFilter"].setValidators(Validators.required)
            this.auditReportForm.controls["toDateFilter"].setValidators(Validators.required)
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].enable();
            this.auditReportForm.controls['toDateFilter'].enable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.isDownloadOnlyExcel = selectedReportType === 'customer-centre-coverage';
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranch = false;
            this.isBranches = true;
            this.hasOneMonth = false;
            this.isFromToDate = true;
            this.isToDate = true;
            this.isMonthYear = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.isYear = false;
            this.clearFilter();
        } else if (selectedReportType === 'branch-parameter-master') {
            const weightageTypeCodesControl = this.auditReportForm.get('weightageType');
            weightageTypeCodesControl.setValidators(Validators.required);
            weightageTypeCodesControl.updateValueAndValidity();
            const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
            branchCodesControl.setValidators(Validators.required);
            branchCodesControl.updateValueAndValidity();
            const auditMonthControl = this.auditReportForm.get('auditMonth');
            auditMonthControl.setValidators(Validators.required);
            auditMonthControl.updateValueAndValidity();
            const auditYearControl = this.auditReportForm.get('auditYear');
            auditYearControl.setValidators(Validators.required);
            auditYearControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].enable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].enable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].enable();
            this.isWeightageType = true;
            this.isBranches = true;
            this.isMonthYear = true;
            this.hasOneMonth = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranch = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.isYear = false;
            this.hasAuditPlans = false;
            this.clearFilter();
        } else if (selectedReportType === 'area-wise-glance' || selectedReportType === 'region-wise-glance') {
            const statesControl = this.auditReportForm.get('selectedStates');
            statesControl.setValidators(Validators.required);
            statesControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].disable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['selectedStates'].enable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = false;
            this.isMonthYear = false;
            this.hasOneMonth = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranch = false;
            this.isStates = true;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.isYear = false;
            this.clearFilter();
        } else if (selectedReportType === 'csat') {
            this.loadQuestionnaire('CSATQ');
            const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
            branchCodesControl.setValidators(Validators.required);
            branchCodesControl.updateValueAndValidity();
            const questionnaireVersionControl = this.auditReportForm.get('questionnaireVersion');
            questionnaireVersionControl.setValidators(Validators.required);
            questionnaireVersionControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['selectedStates'].disable();
            this.auditReportForm.controls['questionnaireVersion'].enable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = true;
            this.hasOneMonth = false;
            this.isFromToDate = false;
            this.isMonthYear = false;
            this.isBranch = false;
            this.isStates = false;
            this.isToDate = false;
            this.isQuestionnaireVersion = true;
            this.isDownloadOnlyExcel = true;
            this.hasAuditPlans = false;
            this.isYear = false;
            this.clearFilter();
        } else if (selectedReportType === 'score-trend') {
            const auditYearControl = this.auditReportForm.get('auditYear');
            auditYearControl.setValidators(Validators.required);
            auditYearControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].disable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].enable();
            this.auditReportForm.controls['selectedStates'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = false;
            this.isYear = true;
            this.isMonthYear = false;
            this.hasOneMonth = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranch = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.clearFilter();
        } else if (selectedReportType === 'branch-grading-master') {
            const auditYearControl = this.auditReportForm.get('auditYear');
            auditYearControl.setValidators(Validators.required);
            auditYearControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].disable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['auditYear'].enable();
            this.auditReportForm.controls['selectedStates'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = false;
            this.isYear = true;
            this.isMonthYear = false;
            this.hasOneMonth = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranch = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.clearFilter();
        } else if (selectedReportType === 'audit-compliance-report') {
            const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
            branchCodesControl.setValidators(Validators.required);
            branchCodesControl.updateValueAndValidity();
            const fromDateControl = this.auditReportForm.get('fromDateFilter');
            fromDateControl.setValidators(Validators.required);
            fromDateControl.updateValueAndValidity();
            const toDateControl = this.auditReportForm.get('toDateFilter');
            toDateControl.setValidators(Validators.required);
            toDateControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].enable();
            this.auditReportForm.controls['toDateFilter'].enable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['selectedStates'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = true;
            this.isMonthYear = false;
            this.hasOneMonth = false;
            this.isFromToDate = true;
            this.isBranch = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.isYear = false;
            this.hasAuditPlans = false;
            this.clearFilter();
        } else if (selectedReportType === 'branch-grading-report') {
            const fromDateControl = this.auditReportForm.get('fromDateFilter');
            fromDateControl.setValidators(Validators.required);
            fromDateControl.updateValueAndValidity();
            const toDateControl = this.auditReportForm.get('toDateFilter');
            toDateControl.setValidators(Validators.required);
            toDateControl.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].disable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].enable();
            this.auditReportForm.controls['toDateFilter'].enable();
            this.auditReportForm.controls['auditMonth'].disable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].disable();
            this.auditReportForm.controls['selectedStates'].disable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isBranches = false;
            this.isMonthYear = false;
            this.hasOneMonth = false;
            this.isFromToDate = true;
            this.isBranch = false;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.isYear = false;
            this.hasAuditPlans = false;
            this.clearFilter();
        } else if (selectedReportType === 'Centre-Count-Update') {
            this.onCvcqReportSelect();
        } else if (selectedReportType === 'previous-compliance-report') {
            const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
            branchCodesControl.setValidators(Validators.required);
            branchCodesControl.updateValueAndValidity();
            const auditForMonth = this.auditReportForm.get('auditMonth');
            auditForMonth.setValidators(Validators.required);
            auditForMonth.updateValueAndValidity();
            const auditForYear = this.auditReportForm.get('auditYear');
            auditForYear.setValidators(Validators.required);
            auditForYear.updateValueAndValidity();
            this.auditReportForm.controls['branchCodesSelect'].enable();
            this.auditReportForm.controls['branchCode'].disable();
            this.auditReportForm.controls['fromDateFilter'].disable();
            this.auditReportForm.controls['toDateFilter'].disable();
            this.auditReportForm.controls['auditMonth'].enable();
            this.auditReportForm.controls['month'].disable();
            this.auditReportForm.controls['auditYear'].enable();
            this.auditReportForm.controls['questionnaireVersion'].disable();
            this.auditReportForm.controls['auditId'].disable();
            this.auditReportForm.controls['weightageType'].disable();
            this.isWeightageType = false;
            this.isFromToDate = false;
            this.isToDate = false;
            this.isBranches = true;
            this.isBranch = false;
            this.hasOneMonth = false;
            this.isMonthYear = true;
            this.isDownloadOnlyExcel = true;
            this.isStates = false;
            this.isQuestionnaireVersion = false;
            this.hasAuditPlans = false;
            this.isYear = false;
            this.clearFilter();
        }
    }

    /**
     * @author J Suhas Bhat
     * This method is called when we wish to show the CVCQ report
     */
    onCvcqReportSelect() {
        this.isDownloadOnlyExcel = true;
        this.loadQuestionnaire('CVCQ');
        const branchCodesControl = this.auditReportForm.get('branchCodesSelect');
        branchCodesControl.setValidators(Validators.required);
        branchCodesControl.updateValueAndValidity();
        const fromDateControl = this.auditReportForm.get('fromDateFilter');
        fromDateControl.setValidators(Validators.required);
        fromDateControl.updateValueAndValidity();
        const toDateControl = this.auditReportForm.get('toDateFilter');
        toDateControl.setValidators(Validators.required);
        toDateControl.updateValueAndValidity();
        const questionnaireVersionControl = this.auditReportForm.get('questionnaireVersion');
        questionnaireVersionControl.setValidators(Validators.required);
        questionnaireVersionControl.updateValueAndValidity();
        this.auditReportForm.controls['branchCodesSelect'].enable();
        this.auditReportForm.controls['branchCode'].disable();
        this.auditReportForm.controls['fromDateFilter'].enable();
        this.auditReportForm.controls['toDateFilter'].enable();
        this.auditReportForm.controls['auditMonth'].disable();
        this.auditReportForm.controls['month'].disable();
        this.auditReportForm.controls['auditYear'].disable();
        this.auditReportForm.controls['selectedStates'].disable();
        this.auditReportForm.controls['questionnaireVersion'].enable();
        this.auditReportForm.controls['auditId'].disable();
        this.isBranches = true;
        this.isMonthYear = false;
        this.hasOneMonth = false;
        this.isFromToDate = true;
        this.isToDate = true;
        this.isBranch = false;
        this.isStates = false;
        this.isQuestionnaireVersion = true;
        this.isYear = false;
        this.hasAuditPlans = false;
        this.clearFilter();
    }

    onFromDateChange(event) {
        if (this.fromDateFilter !== null && this.fromDateFilter !== undefined) {
            this.minDate = new Date(this.fromDateFilter);
            this.isToDate = true;
        }
    }

    loadMonthsAndYears() {
        this.allMonths = months;
        this.allYears = years;
    }

    ngOnDestroy(): void { }
    loadBranches() {
        this.reportService.getAllBranchesForUser().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => { this.onBranchesLoadedSuccess(res.body); });
    }


    loadQuestionnaire(questionnaireType) {
        this.reportService.getAllQuestionnaireVersion(questionnaireType).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => { this.onQuestionnaireLoadedSuccess(res.body); });
    }
    private onBranchesLoadedSuccess(data) {
        this.branches = data;
        this.branches.forEach(value => {
            value.nameCode = `${value.code} - ${value.name}` ;
        });
        this.dropdownList = this.branches;
    }
    private onQuestionnaireLoadedSuccess(data) {
        this.versionNameList = data;
        this.dropdownSettings.versionField = 'questionnaireVersionCode'
        this.dropdownSettings.singleSelection = false
    }
    onSubmit() {
        this.igFormService.markFormGroupTouched(this.auditReportForm);
        if (this.auditReportForm.valid) {
            this.download();
        } else {
            this.formErrors = this.igFormService.validateForm(this.auditReportForm, this.formErrors, false);
            return false;
        }
    }

    buildForm() {
        this.auditReportForm = this.formBuilder.group({
            reportTypeList: [this.reportTypeList, Validators.required],
            branchCodesSelect: [this.selectedBranches],
            branchCode: [this.branchCode],
            fromDateFilter: [this.fromDateFilter],
            toDateFilter: [this.toDateFilter],
            auditMonth: [this.auditMonth],
            month: [this.month],
            auditYear: [this.auditYear],
            selectedStates: [this.selectedStates],
            auditId: [this.auditId],
            questionnaireVersion: [this.questionnaireVersion, Validators.required],
            downLoadFormatList: [this.downLoadFormatList, Validators.required],
            weightageType: [this.weightageType],
        });
        this.auditReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.auditReportForm, this.formErrors, true);
        });
    }

    download() {
        let selectedBranchCodes: any;
        if (this.selectedBranches !== undefined && this.selectedBranches.length > 0) {
            selectedBranchCodes = [];
            this.selectedBranches.forEach(element => {
                selectedBranchCodes.push(element.code);
            });
        }
        const selectedAuditForMonths = [];

        if (this.auditMonth !== undefined && this.auditMonth.length > 0) {
            this.auditMonth.forEach(element => {
                selectedAuditForMonths.push(+element);
            });
        }
        let code = '';
        if (this.branchCode !== undefined) {
            code = this.branchCode[0].code;
        }
        this.reportService.downloadExcel({
            downloadFormat: this.downloadFormat,
            fromDate: this.fromDateFilter,
            toDate: this.toDateFilter,
            reportType: this.reportType,
            branchCodes: selectedBranchCodes,
            branchCode: code,
            auditForMonths: selectedAuditForMonths,
            auditForMonth: this.month,
            auditId: this.auditId,
            auditForYear: this.auditYear,
            questionnaireVersionCode: this.questionnaireVersion,
            states: this.auditReportForm.get('selectedStates').value,
            weightageType:this.auditReportForm.get('weightageType').value,
        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
            JSON.stringify(error)
            if(error.status == 400){
                this.onErrorCategory();
            }else{
                this.onError();
            }
        }, () => {
            console.log('Completed file download.');
        });
    }

    onErrorCategory() {
        if(this.auditReportForm.get('reportTypeList').value === 'internal-audit-report' || 
        this.auditReportForm.get('reportTypeList').value ==='branch-parameter-master'){
            this.snackBar.open(`Category data is mismatch, Report can't be downloaded, Please select correct classiffier Type`, 'Close', {
                duration: 50000,
            });
        }else{
            this.snackBar.open(`Error Downloading Excel`, 'Close', {
                duration: 50000,
            });
        }
        
    }

    onError() {
        this.snackBar.open(`Error Downloading Excel`, 'Close', {
            duration: 3000,
        });
    }

    setReportTypeList(data){
       this.reportTypeList = data;
    }

    private setTypesOfReports() {

        this.reportService.getAllReportTypeForUser().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => { this.setReportTypeList(res.body); });
        this.downLoadFormatList = [
            { value: DownloadFormatType.PDF, viewValue: 'PDF' },
            { value: DownloadFormatType.EXCEL, viewValue: 'EXCEL' }
        ];
    }
    populateAuditPlans(response) {
        if ( response && Array.isArray(response) && response.length !== 0) {
            this.hasAuditPlans = true;
            this.internalAuditResponse = response;
        } else {
            this.hasAuditPlans = false;
        }
        console.log(response);
    }

    private loadStates() {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.states = data.states.body;
            });
    }
}
