import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { QuestionaireCopyComponent } from './questionnaire-copy.component';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {MaterialLibModule} from '../../material-lib.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Route, RouterModule} from '@angular/router';
import {QuestionaireComponent} from './questionaire.component';
import {QuestionaireEditorComponent} from './questionaire-editor.component';
import {QuestionaireDeleteDialogComponent} from './questionaire-delete-dialog.component';
import {QuestionaireResolveService} from './questionaire-resolve.service';
import {NonEditableResolveService} from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import {EditableResolveService} from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import {QuestionModule} from '../question/question.module';
import {QuestionEditorComponent} from '../question/question-editor.component';
import {QuestionResolveService} from '../question/question-resolve.service';
import {QuestionaireReportComponent} from './questionaire-report.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LoggedInUserResolveService } from '../audit/audit-transaction/audit-transaction-resolve.service';

export const questionaireRoute: Route = {
    path: 'questionaire',
    canActivate: [AuthenticatedUserService],
    children: [
        {
            path: '',
            canActivate: [AuthenticatedUserService],
            component: QuestionaireComponent,
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_LIST_QUESTIONNAIRE'] }
        },
        {
            path: 'create',
            canActivate: [AuthenticatedUserService],
            component: QuestionaireEditorComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_QUESTIONNAIRE'] }
        },
        {
            path: 'edit/:id',
            canActivate: [AuthenticatedUserService],
            component: QuestionaireEditorComponent,
            resolve: {
                questionaire: QuestionaireResolveService,
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_QUESTIONNAIRE'] }
        },
        {
            path: 'view/:id',
            canActivate: [AuthenticatedUserService],
            component: QuestionaireEditorComponent,
            resolve: {
                questionaire: QuestionaireResolveService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_QUESTIONNAIRE'] }
        },
        {
            path: ':questionaireId/question/create',
            canActivate: [AuthenticatedUserService],
            component: QuestionEditorComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_QUESTIONNAIRE'] }
        },
        {
            path: ':questionaireId/question/edit/:id',
            canActivate: [AuthenticatedUserService],
            component: QuestionEditorComponent,
            resolve: {
                question: QuestionResolveService,
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_QUESTIONNAIRE'] }
        },
        {
            path: ':questionaireId/question/view/:id',
            canActivate: [AuthenticatedUserService],
            component: QuestionEditorComponent,
            resolve: {
                question: QuestionResolveService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_QUESTIONNAIRE'] }
        },
        {
            path: 'questionaireReport',
            canActivate: [AuthenticatedUserService],
            component: QuestionaireReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService,
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_QUESTIONNAIRE_REPORT'] }
        }]
};
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        QuestionModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [QuestionaireComponent, QuestionaireEditorComponent, QuestionaireDeleteDialogComponent, QuestionaireReportComponent,QuestionaireCopyComponent],
    entryComponents: [QuestionaireDeleteDialogComponent],
    exports: [RouterModule, ReactiveFormsModule, QuestionaireEditorComponent]
})
export class QuestionaireModule { }
