import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { componentDestroyed } from "../../../../../../node_modules/@w11k/ngx-componentdestroyed";
import { takeUntil } from "../../../../../../node_modules/rxjs/operators";
import { ObservationResponseDetail } from "../../../questionaire/observation-response-detail.model";
import { QuestionaireService } from "../../../questionaire/questionaire.service";
import { QuestionnaireResponseDetail } from "../../../questionaire/questionnaire-response-detail.model";
import { ViewRemarkDialogComponent } from "../audit-transaction-detail/view-remark-dialog.component";
import { CheckListDetailService } from "../audit-transaction-resolve.service";
import { AuditTransactionService } from "../audit-transaction-service";
import { FileDeleteDialogComponent } from "../file-delete-dialog.component";
import { ObservationResponseAction } from "../observation-response-action.model";
import { ObservationResponseRemarks } from "../observation-response-remark.model";
import { AddRemarkDialogComponent } from "./add-remark-dialog.component";
import { AuditTransactionDetail } from "./audit-transaction-detail.model";
import { EditRemarksDialogComponent } from "./edit-remark-dialog.component";
import { ScoreRisk } from "./score-risk.model";
import { AuditTransactionOrderCount } from "./audit-transaction-order-count.model";
import { count } from "console";

@Component({
  selector: "app-checklist-detail",
  templateUrl: "./checklist-deatil.component.html",
  styleUrls: ["../audit-transaction.component.css"],
})
export class CheckListDetailComponent implements OnInit, OnDestroy {

  observationResponseAction: ObservationResponseAction;
  auditTransactionDetail: AuditTransactionDetail;
  checkListResponse: any;
  categories: string[] = [];
  currentCategory: string;
  scoreRiskObject: ScoreRisk = new ScoreRisk();
  categoryScoreJson: any;
  categoryScoreMapping: Map<string, AuditTransactionOrderCount> = new Map<string, AuditTransactionOrderCount>();
  currentMarks: number;
  loggedInUser: any;
  errorMessage: string;
  answerJsons: JSON[];
  editScore: Boolean;
  showScore: boolean;
  showFileSave: boolean;
  showDeleteFiles: boolean;
  showRemarkSave: boolean;
  categoryPossibleMarksMapping: String;
  maxMarkForCategory: number;
  hasUpdateMarksError: boolean;
  categoryMaxMarksJson: any;
  isNegativeMark: boolean;
  noMark: boolean;
  categoryWeightagePercentageJson: any;
  weightageForCategory: number;
  observationResponseRemarks: ObservationResponseRemarks;
  checkListForm: FormGroup;
  checkListDetailForm: FormArray;
  observationRemarkDTO: ObservationResponseRemarks;
  isDisabled:boolean=false;
  isDisagree: boolean = false;

  observationResponseActionConfig: any[] = [
    {
      buttonTitle: "Mark as CLOSE",
      action: "CLOSE",
      nextAction: "CLOSE",
      isCommentable: false,
      role: ["HO_ASST", "HA_SUPERVISOR"],
      isRequired: false,
    },
    {
      buttonTitle: "Mark as OPEN",
      action: "OPEN",
      nextAction: "OPEN",
      isCommentable: true,
      role: ["HO_ASST", "HA_SUPERVISOR"],
      isRequired: false,
    },

    {
      buttonTitle: "AGREE",
      action: "agree",
      nextAction: "agree",
      isCommentable: false,
      role: ["AM"],
      isRequired: false,
    },
    {
      buttonTitle: "DISAGREE",
      action: "disagree",
      nextAction: "disagree",
      isCommentable: true,
      role: ["AM"],
      isRequired: true,
    },
    {
      buttonTitle: "RECTIFIED",
      action: "rectified",
      nextAction: "rectified",
      isCommentable: false,
      role: ["BM"],
      isRequired: false,
    },
    {
      buttonTitle: "NOT RECTIFIED",
      action: "not_rectified",
      nextAction: "not_rectified",
      isCommentable: true,
      role: ["BM"],
      isRequired: true,
    },
  ];
  observationResponseActionButtons: any[] = [];
  addRemarkDialogRef: MatDialogRef<AddRemarkDialogComponent>;
  matDialogConfig = new MatDialogConfig();
  viewRemarkDialogRef: MatDialogRef<ViewRemarkDialogComponent>;
  id: number;
  marks: String;
  dataResponse: AuditTransactionDetail;
  checkStatus: string;
  isAuditClosed: boolean;
  file: File;
  editRemarkDialogRef: MatDialogRef<EditRemarksDialogComponent>;
  isAmAndBm: boolean;
  isHoAssistantAndHaSupervisor: boolean;
  isAdmin: boolean;
  remarksList: any = [];
  message: string;

  constructor(
    private checklistDetailService: CheckListDetailService,
    private location: Location,
    private auditTransactionService: AuditTransactionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private questionnaireService: QuestionaireService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.loggedInUser = data.loggedInUser.body;
      });

    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.id = params.id;
      });
    this.filterObservationResponseActionConfig();
    this.checkListForm = this.formBuilder.group({
      checkListDetailForm: this.formBuilder.array([]),
    });
  }

  @ViewChild("fileUploader", { static: false })
  myInputVariable: ElementRef;

  @ViewChild("fileUpload")
  fileUpload: ElementRef;

  @Input()
  files: File[] = [];

  @Input()
  multiple;
  @Input()
  chooseLabel = "Choose";
  @Input()
  uploadLabel = "Upload";
  @Input()
  deleteButtonIcon = "close";

  filterObservationResponseActionConfig() {
    this.observationResponseActionButtons =
      this.observationResponseActionConfig.filter(
        (config) => config.role.indexOf(this.loggedInUser.role) > -1
      );
  }

  ngOnInit() {
    this.isDisabled = true;
    this.editScore = false;
    this.showScore = false;
    this.isAuditClosed = false;
    this.showRemarkSave = false;
    this.showDeleteFiles = false;
    this.showFileSave = false;
    this.isHoAssistantAndHaSupervisor = false;
    this.isAdmin = false;
    this.loadPage(true);
    this.syncAuditTransactions();

    if (this.loggedInUser.authorities.indexOf("ROLE_EDIT_SCORES") >= 0) {
      this.editScore = true;
    }
    if (this.loggedInUser.authorities.indexOf("ROLE_SHOW_SCORES") >= 0) {
      this.showScore = true;
    }
    if (this.loggedInUser.authorities.indexOf("ROLE_SHOW_OBSERVATION_RESPONSE_FILE_SAVE") >= 0) {
      this.showFileSave = true;
    }
    if (this.loggedInUser.authorities.indexOf("ROLE_SHOW_OBSERVATION_RESPONSE_FILE_DELETE") >= 0) {
      this.showDeleteFiles = true;
    }
    if (this.loggedInUser.authorities.indexOf("ROLE_SHOW_OBSERVATION_REMARK_CREATE") >= 0) {
      this.showRemarkSave = true;
    }
    this.hasUpdateMarksError = false;
    this.isNegativeMark = false;
    this.noMark = false;
    if (this.checkStatus === "WF_AUDIT_CLOSED") {
      this.isAuditClosed = true;
      this.editScore = false;
    }
  }

  loadPage(categoryRefresh: boolean) {
    this.checkStatus = this.auditTransactionService.getAuditTransactionStatus();
    // this.auditTransactionDetail =
    //   this.checklistDetailService.getAuditTransactionDetail();
    this.auditTransactionService
      .getAuditTransactionDetails(this.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.dataResponse = data;
        this.auditTransactionDetail = data;
        // this.categoryPossibleMarksMapping = data.marks;
        if (this.dataResponse.categoryScoreMapping) {
          this.categoryScoreJson = JSON.parse(
            this.dataResponse.categoryScoreMapping
          );
        }
        if (this.dataResponse.marks) {
          this.categoryMaxMarksJson = JSON.parse(this.dataResponse.marks);
        }
        if (this.dataResponse.branchTotalWeightagePercentage) {
          this.categoryWeightagePercentageJson = JSON.parse(
            this.dataResponse.branchTotalWeightagePercentage
          );
        }
        this.getDistinctQuestionCategories();
        if(categoryRefresh){
        this.filerQuestionsForCategory(
          this.categoryScoreMapping.keys().next().value
        );
        }
        this.checkListResponse =
        this.dataResponse.observationResponseDetailDTOs.filter(
          (observationResponseDetail) =>
            observationResponseDetail.category === this.currentCategory
        );
        this.initializeForm();
      });
  }

  initializeForm() {
    this.checkListDetailForm = new FormArray([]);

    this.checkListResponse.forEach((checkListResponseItem, index) => {
      const complianceHistorySize = checkListResponseItem.complianceHistory.length;
      const formGroup = new FormGroup({
        id: new FormControl(
          checkListResponseItem.observationResponseRemarksDTO
            ? checkListResponseItem.observationResponseRemarksDTO.id
            : ""
        ),
        version: new FormControl(
          checkListResponseItem.observationResponseRemarksDTO
            ? checkListResponseItem.observationResponseRemarksDTO.version
            : 0
        ),
        description: new FormControl(
          checkListResponseItem.observationResponseRemarksDTO
            ? checkListResponseItem.observationResponseRemarksDTO.remark
            : ""
        ),
        instancesVerified: new FormControl(
          checkListResponseItem.noOfInstancesVerified
        ),
        deviationsFound: new FormControl(
          checkListResponseItem.noOfDeviationsFound
        ),
        answers: new FormControl(checkListResponseItem.answer),
        observationResponseDetailId: new FormControl(
          checkListResponseItem.id
        ),
        questionnaireResponseId: new FormControl(
          checkListResponseItem.questionnaireResponseId
        ),
        lastAction: new FormControl(checkListResponseItem.lastAction),
        complianceHistory: new FormControl(checkListResponseItem.complianceHistory),
        lastModifiedBy: new FormControl(checkListResponseItem.lastModifiedBy),
        // lastModifiedBy: new FormControl(complianceHistorySize>0 ? checkListResponseItem.complianceHistory[complianceHistorySize-1].lastModifiedBy : null) 


      });

      this.checkListDetailForm.push(formGroup);
      //}
    });
    this.checkListForm = this.formBuilder.group({
      checkListDetailForm: this.checkListDetailForm,
    });
  }

  onDownload(id: number) {
    this.auditTransactionService
      .downloadFile(id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          this.snackBar.open(`download error:`, "Close", {
            duration: 3000,
          });
          console.log("download error:", JSON.stringify(error));
          this.message = error;
        },
        () => {
          this.snackBar.open(`Downloaded successfully`, "Close", {
            duration: 3000,
          });
        }
      );
  }

  filerQuestionsForCategory(category: string) {
    this.currentCategory = category;
    this.currentMarks = this.categoryScoreMapping.get(category)["count"];
    this.maxMarkForCategory = this.categoryMaxMarksJson[category];
    this.weightageForCategory = this.categoryWeightagePercentageJson[category];
    this.checkListResponse =
      this.dataResponse.observationResponseDetailDTOs.filter(
        (observationResponseDetail) =>
          observationResponseDetail.category === category
      );
      console.log("Current Category:", this.currentCategory);
    this.initializeForm();
  }
  
  // to update the remarks
  onSubmit(responseDetail) {
    if (responseDetail.id === "") {
      const observationRemarkDTO = new ObservationResponseRemarks();
      observationRemarkDTO.remark = responseDetail.description;
      observationRemarkDTO.observationResponseDetailId = responseDetail.observationResponseDetailId ;
       this.auditTransactionService
        .updateRemarks(observationRemarkDTO)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          (submitResponse) => this.onSubmitSuccessRemarks(submitResponse),
          (submitResponse) => this.onSubmitErrorRemarks(submitResponse)
        );
    }else{
      responseDetail.id = responseDetail.id;
      responseDetail.version = responseDetail.version;
      responseDetail.remark = responseDetail.description;
  
      this.auditTransactionService
        .updateRemarks(responseDetail)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          (submitResponse) => this.onSubmitSuccessRemarksUpdate(submitResponse),
          (submitResponse) => this.onSubmitErrorRemarks(submitResponse)
        );
    }
  }

  private onSubmitSuccessRemarksUpdate(submitResponse) {
    this.loadPage(false);
    this.snackBar.open(`Remark Updated successfully`, "Close", {
      duration: 3000,
    });
  }

  private onSubmitErrorRemarks(submitResponse) {
    const errormessage = submitResponse.error.title;
    this.loadPage(false);
    this.snackBar.open(errormessage, "Close", {
      duration: 3000,
    });
  }

  private onSubmitSuccessRemarks(submitResponse) {
    this.loadPage(false);
    this.snackBar.open(`Remark updated successfully`, "Close", {
      duration: 3000,
      
    });
  }


  onRemarksDialog(checkListDetailForm: ObservationResponseRemarks,  action: any) {
    this.editRemarkDialogRef = this.dialog.open(EditRemarksDialogComponent, {
      maxHeight: "100vh",
      width: "50%",
      data: {
        ObservationResponseRemarks: checkListDetailForm,
        loggedInUser: this.loggedInUser.role,
        action: action,
      },
    });
    this.editRemarkDialogRef.afterClosed().subscribe((res) => {
      this.loadPage(false);
    });
  }

  deleteDialog(id: number) {
    const dialogRef = this.dialog.open(FileDeleteDialogComponent, {
      data: { fileId: id },
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.loadPage(false);
    });
  }

    syncAuditTransactions() {
        this.auditTransactionService.getAuditTransactionDetails(this.id)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.dataResponse = data;
                for (const mainIndex of Object.keys(this.dataResponse.observationResponseDetailDTOs)) {
                    Object.values(this.dataResponse.observationResponseDetailDTOs[mainIndex].
                        complianceHistory).forEach((item, index, arr) => {
                        if (!arr[index + 1]) {
                            this.dataResponse.observationResponseDetailDTOs[mainIndex]["lastModifiedBy"] = item["user"];
                        }
                    });
                }
                if (this.dataResponse.observationResponseDetailDTOs) {
                    this.isChecked(this.dataResponse.observationResponseDetailDTOs);
                }
                // this.categoryPossibleMarksMapping = data.marks;
                if (this.dataResponse.categoryScoreMapping) {
                    this.categoryScoreJson = JSON.parse(this.dataResponse.categoryScoreMapping);
                }
                if (this.dataResponse.marks) {
                    this.categoryMaxMarksJson = JSON.parse(this.dataResponse.marks);
                }
                if (this.dataResponse.branchTotalWeightagePercentage) {
                    this.categoryWeightagePercentageJson = JSON.parse(this.dataResponse.branchTotalWeightagePercentage);
                }

                this.getDistinctQuestionCategories();
                if (this.currentCategory) {
                    this.filterQuestionsOnCategory(this.currentCategory);
                } else {
                    this.filterQuestionsOnCategory(this.categoryScoreMapping.keys().next().value);
                }
            });
    }

    isChecked(complianceHistory: any) {
        if (complianceHistory) {
            complianceHistory.forEach(element => {
                if (element["lastAction"] === "disagree") {
                    this.isDisagree = true;
                }
            });
        }

    }

    ngOnDestroy() {
        this.auditTransactionService.setDiasble(this.isDisagree);

    }

    getDistinctQuestionCategories(): Map<string, AuditTransactionOrderCount> {
        const categorySet: Set<string> = new Set(
            this.dataResponse.observationResponseDetailDTOs
                .map(answer => answer.category));
        Array.from(categorySet).forEach(categoryName => {
            const category = categoryName.trim();
            if (this.categoryScoreJson && this.categoryScoreJson[category]) {
                this.categoryScoreMapping.set(category, this.categoryScoreJson[category]);
            } else if(category != ""){
              const auditTransactionOrderCount: AuditTransactionOrderCount ={
                order: null,
                count: 0
              };
                this.categoryScoreMapping.set(category, auditTransactionOrderCount);
            }
            this.categoryScoreMapping = new Map([...this.categoryScoreMapping].sort((a, b) => a[1].order - b[1].order));
        });
        return this.categoryScoreMapping;
    }

    filterQuestionsOnCategory(category: string) {
        this.currentCategory = category;
        this.currentMarks = this.categoryScoreJson[category]["count"];
        this.maxMarkForCategory = this.categoryMaxMarksJson[category];
        this.weightageForCategory = this.categoryWeightagePercentageJson[category];
        this.checkListResponse = this.dataResponse.observationResponseDetailDTOs
            .filter(observationResponseDetail => observationResponseDetail.category === category);
        //   this.setActionButtonConfigForChecklistResponse(this.checkListResponse);
    }

    toggleRisk(questionnaireDetailId: number) {
        let riskStatus = 'No';
        if (this.scoreRiskObject.questionRiskStatus.has(questionnaireDetailId)) {
            this.scoreRiskObject.questionRiskStatus.delete(questionnaireDetailId);
        } else {
            this.scoreRiskObject.questionRiskStatus.set(questionnaireDetailId, 'Yes');
            riskStatus = 'Yes';
        }
        this.changeRiskStatus(riskStatus, questionnaireDetailId);
    }

    back() {
        this.location.back();
    }

    changeRiskStatus(riskStatus: string, quesionnaireDetailId: number) {
        this.checkListResponse.forEach(response => {
            if (response.id === quesionnaireDetailId) {
                response.riskStatus = riskStatus;
            }
        });
    }

    save() {
        this.auditTransactionService.saveScoreAndRiskStatus(this.scoreRiskObject).pipe(takeUntil(componentDestroyed(this))).
            subscribe((response) => this.onSaveSuccess(response),
                (response) => this.onSaveError(response));
    }

    private onSaveSuccess(response) {
        this.snackBar.open(`Updated successfully`, 'Close', {
            duration: 3000,
        });
    }

    private onSaveError(response) {
        const errormessage = response.error.title;
        this.snackBar.open(errormessage, 'Close', {
            duration: 3000,
        });
    }

    toggleRiskStatus(questionnaireResponseDetail: QuestionnaireResponseDetail, value?) {
        if (value === questionnaireResponseDetail.riskStatus) {
            this.scoreRiskObject.riskStatus = 'NO_RISK';
        } else {
            this.scoreRiskObject.riskStatus = value;
        }
        this.scoreRiskObject.questionName = questionnaireResponseDetail.question.name;
        this.scoreRiskObject.questionnaireResponseDetailId = questionnaireResponseDetail.id;
        this.questionnaireService.chnageQuestionnaireDetailRiskStatus(this.scoreRiskObject).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccessRisk(response), (response) => this.onSaveErrorRisk(response));
    }

    onSaveSuccessRisk(result) {
        this.snackBar.open(`Risk Status Changed successfully`, 'Close', {
            duration: 3000,
        });
        this.changeRiskStatus(this.scoreRiskObject.riskStatus, this.scoreRiskObject.questionnaireResponseDetailId);
    }
    onSaveErrorRisk(response) {
        this.errorMessage = response.error;
    }

    getRiskStatus(currentStatus: string): string {
        let riskStatus = '';
        if (currentStatus == null || currentStatus === 'NO') {
            riskStatus = 'YES';
        } else {
            riskStatus = 'NO';
        }
        return riskStatus;
    }

    updateMarks() {
        this.hasUpdateMarksError = false;
        if (this.currentMarks > this.maxMarkForCategory) {
            this.hasUpdateMarksError = true;
            this.isNegativeMark = false;
            this.noMark = false;
            return;
        }
        if (this.currentMarks < 0) {
            this.isNegativeMark = true;
            this.hasUpdateMarksError = false;
            this.noMark = false;
            return;
        }
        if (this.currentMarks === null) {
            this.noMark = true;
            this.hasUpdateMarksError = false;
            this.isNegativeMark = false;
            return;
        }
        this.scoreRiskObject.auditTransactionDetailId = this.dataResponse.id;
        this.scoreRiskObject.category = this.currentCategory;
        this.scoreRiskObject.score = this.currentMarks;
        this.auditTransactionService.updateCategoryScore(this.scoreRiskObject).pipe(takeUntil(componentDestroyed(this))).
            subscribe((response) => {
              const auditTransactionOrderCount: AuditTransactionOrderCount ={
                order: null,
                count: this.currentMarks
              };
                this.categoryScoreMapping.set(this.currentCategory, auditTransactionOrderCount);
                this.onSaveSuccess(response);
            },
                (response) => this.onSaveError(response));
    }

    updateResponseDetailStatus(responseDetail: ObservationResponseDetail, config: any) {

        //    this.addRemarkDialogRef=this.dialog.open(AddRemarkDialogComponent,{
        //    });
        //    this.addRemarkDialogRef.afterClosed().pipe(filter(remark=>remark))
        //    .subscribe(remark=>this.observationResponseAction.remarks);
        // }
        this.addRemarkDialogRef = this.dialog.open(AddRemarkDialogComponent, {
            width: '500px',
            // height: '300px',
            data: { config: config, observationResponseDetail: responseDetail }
        });
        this.addRemarkDialogRef.afterClosed()
        .pipe(takeUntil(componentDestroyed(this))).subscribe(result => {
            if (result !== null && result !== undefined && result !== '') {
                this.snackBar.open(result.msg, 'Close', {
                    duration: 3000,
                });
                // if(result.config!==null&&result.config!==undefined)
                // {

                    // responseDetail.config.filter(config=>config.action!==result.config.action);
                    // }
                    if (result.config !== null && result.config !== undefined && result.config !== '' 
                    && result.complianceHistory !== null) {
                        /* responseDetail.lastAction = result.config.action;
                        responseDetail.lastModifiedBy = this.loggedInUser.login;
                        responseDetail.complianceHistory = result.complianceHistory; */
                        this.syncAuditTransactions();
                    }
            }
            this.loadPage(false);
        });
    }

    onDialog(responseDetail: ObservationResponseDetail) {


        this.viewRemarkDialogRef = this.dialog.open(ViewRemarkDialogComponent, {
            maxHeight: '80vh',
            width: '50%',
            // maxWidth: '80vh',



            data: {observationResponseDetail: responseDetail }
        });

        this.viewRemarkDialogRef.afterClosed();

    }

    refreshThePage() {
        this.syncAuditTransactions();
    }

}
