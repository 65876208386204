import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchService } from 'src/app/admin/branch/branch.service';
import { ReportService } from '../reports/report.service';
import { RoleService } from 'src/app/admin/role/role.service';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { HttpResponse } from '@angular/common/http';
import { ActivityTrackerDataSource } from './activity-tracker-report.data.source';
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/admin/user/user.service';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { Branch } from 'src/app/admin/branch/branch.model';
import { ActivityTrakerCountDialogComponent } from './activity-traker-count-dialog.component';

@Component({
  selector: 'app-activity-tracker-report',
  templateUrl: './activity-tracker-report.html',
  styleUrls: ['./activity-tracker-report.component.css']
})
export class ActivityTrackerReportComponent implements OnInit, OnDestroy, AfterViewInit {

    dataSource: ActivityTrackerDataSource;
    displayedColumns = ['stateName','branchName', 'employeeName', 'employeeLogin', 'role',
        'bmvCount', 'cmmCount', 'cgtCount', 'grtCount', 'lucCount', 'nonCoreActivityCount', 'ecqCount'];
    loggedInUser: any;
    dropdownList: any[] = [];
    branches: Branch[];
    states: any;
    stateList: string[];
    allBranches: any;
    branchCodes: any[];
    code: string;
    allStates: any;
    roles: string[];
    userStatuses: string[];
    users: any;
    selectedBranches = [];
    selectedBrancheCodes: string[];
    selectedStates: any[];
    selectedRoles: string[];
    userStatus: string;
    selectedUsers: string[];
    fromDate: string;
    toDate: string;
    businessLines: any[];
    businessLine: any;
    selectedBusinessLine: any;

    employeeFormError = {
        selectedStates: "",
        selectedBranches: "",
        selectedRoles: "",
        userStatus: "",
        businessLineId: ""
    };
   
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        
    };
    branchDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'nameCode',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
       // limitSelection: 5
    };
    public formErrors = {
        branchName: '',
        selectedStates: '',
        selectedBranches: '',
        fromDateFilter: '',
        toDateFilter: '',
        code: '',
        businessLineId: ""
    };
    activityFormError = {
        selectedUsers: "",
        fromDate: "",
        toDate: "",
        selectedBranches:"",
        selectedStates:""
    }

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @ViewChild('stateSelect', { static: false }) stateSelect: MatSelect;
    @ViewChild('stateAllSelect', { static: false }) stateAllSelect: MatCheckbox;
    public stateFilter: FormControl = new FormControl();

    @ViewChild('roleSelect', { static: false }) roleSelect: MatSelect;
    @ViewChild('roleAllSelect', { static: false }) roleAllSelect: MatCheckbox;

    @ViewChild('employeeSelect', { static: false }) employeeSelect: MatSelect;
    @ViewChild('employeeAllSelect', { static: false }) employeeAllSelect: MatCheckbox;

    @ViewChild('branchSelect', { static: false }) branchSelect: MatSelect;
    @ViewChild('branchAllSelect', { static: false }) branchAllSelect: MatCheckbox;
    public branchFilter: FormControl = new FormControl();

    fetchEmployeesForm: FormGroup;
    activityTrackerForm: FormGroup;
    isRoleAdmin: boolean;
    selectedBranch: any;
    selectedBrancheValues: any[];
    businessLineId: number;
    status: String;
    

    constructor(private activatedRoute: ActivatedRoute,
        private questionaireService: QuestionaireService,
        private router: Router,
        private branchService: BranchService,
        private reportService: ReportService,
        private roleService: RoleService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private snackBar: MatSnackBar) {
        this.dataSource = new ActivityTrackerDataSource(this.reportService);
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUser = data.loggedInUser.body.role;
            });
    }

    ngOnInit() {
        this.loadRoles();
        this.loadStatuses();
        this.buildForm();
        this.loadBusiness();
        this.stateFilter.valueChanges.pipe(takeUntil(componentDestroyed(this)))
            .subscribe(() => {
                this.filterStates();
            });
        this.branchFilter.valueChanges.pipe(takeUntil(componentDestroyed(this)))
            .subscribe(() => {
                this.filterBranches();
            });
        this.fetchEmployeesForm.controls['businessLineId'].disable();
        if (this.loggedInUser === 'ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.fetchEmployeesForm.controls['businessLineId'].enable();
        } else {
            this.loadStates();
            this.loadBranches();
            this.fetchEmployees();
        }
    }

    filterStates() {
        if (!this.states) {
            return;
        }
        let search = this.stateFilter.value;
        if (search) {
            search = search.toLowerCase();
            const filterData = [];
            this.states = this.allStates;
            for (let i = 0; i < this.states.length; i++) {
                const state = this.states[i];
                if (state.name.toLowerCase().startsWith(search)) {
                    filterData.push(this.states[i]);
                }
            }
            this.states = filterData;
        } else {
            this.states = this.allStates;
        }
    }

    filterBranches() {
        if (!this.branches) {
            return;
        }
        let search = this.branchFilter.value;
        if (search) {
            search = search.toLowerCase();
            const filterData = [];
            this.branches = this.allBranches;
            for (let i = 0; i < this.branches.length; i++) {
                const branches = this.branches[i];
                if (branches.name.toLowerCase().startsWith(search)) {
                    filterData.push(this.branches[i]);
                }
            }
            this.branches = filterData;
        } else {
            this.branches = this.allBranches;
        }
    }

    loadStates() {
        this.branchService.getAllBranchsetStates(this.businessLine)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.allStates = res.body;
                }
            );
    }

    loadBranches() {
        this.selectedBranches = null;
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.questionaireService.getAllBranchesForStates(this.businessLine, this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.branches.forEach(value => {
                        value.nameCode = `${value.code} - ${value.name}`;
                    })
                    this.dropdownList = this.branches;
                });
    }

    loadRoles() {
        this.roleService.getSubordinateRoles()
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<string[]>) => {
                    this.roles = res.body;
                }
            );
    }

    loadStatuses() {
        this.userStatuses = ['ACTIVE', 'INACTIVE'];
    }

    ngAfterViewInit(): void {
    }

    onSubmit() {
        this.loadActivityTrackerReports();
    }

    ngOnDestroy(): void {
    }

    onSelectState(selectedStates: any) {
        console.log(selectedStates, "2")
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        console.log(state, "2")
        this.loadBranches();
    }

    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onSelectBranch(event) {

    }

    onSelectAllBranch(event) {

    }

    onItemDeSelectBranch(item: any) {
        this.selectedBrancheValues = [];
        this.selectedBranch =[];
        this.fetchEmployeesForm.patchValue({
            branchCodes: "",
            branchName: "",
        });
    }

    onItemDeSelectAll(item: any) {
        this.selectedStates = [];
        this.loadBranches();

    }

    buildForm() {
        this.fetchEmployeesForm = this.formBuilder.group({
            selectedStates: ['', Validators.required],
            selBranches: ['', Validators.required],
            selectedRoles: ['', Validators.required],
            userStatus: ['', Validators.required],
            businessLineId: ['', Validators.required]
            
        });
        this.activityTrackerForm = this.formBuilder.group({
            selectedUsers: ['', Validators.required],
            fromDate: [this.fromDate, Validators.required],
            toDate: [this.toDate, Validators.required]
        });
    }

    fetchEmployees() {
        this.selectedStates;
        this.selectedUsers = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.selectedBrancheValues = [];
        if (this.fetchEmployeesForm.value.selBranches !== '') {
            this.fetchEmployeesForm.value.selBranches.forEach(value => {
                this.selectedBrancheValues.push(value.code);
            });
        }
        this.reportService.getAllSubordinatesForStateAndRoleAndLOFAndStatus(this.selectedBrancheValues, this.selectedRoles, this.userStatus, this.businessLine)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.users = res.body;
                    this.employeeAllSelect.checked = false;
                }
            );
    }


    loadBusiness() {
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
    }

    clearFilter() {
        this.selectedStates = [];
        //this.selectedBranches = [];
        // this.selectedRoles = [];
        // this.userStatus = undefined;
        // this.users = [];
        // this.selectedUsers = [];
        // this.fromDate = undefined;
        // this.toDate = undefined;
        // this.roleAllSelect.checked = false;
        // this.stateAllSelect.checked = false;
        // this.businessLine = ''
        // this.dataSource.clear() 
        this.activityTrackerForm.reset();
        this.fetchEmployeesForm.reset();
        if (this.businessLine != undefined) {
            this.states = [];
            this.branches = [];
        }
        this.users = [];
    }

    onSelectBlClear() {
        if (this.businessLine != undefined) {
            this.selectedStates = [];
            this.selectedBranches = [];
            this.selectedRoles = [];
            this.userStatus = null;
        }
    }

    onAllChanged(checked: any, allValues: any, selectId: any, selectType: string) {
        const filterData = [];
        if (checked) {
            if (selectType === 'state') {
                allValues.forEach(value => {
                    filterData.push(value.code);
                });
                this.selectedStates = filterData;
            } else if (selectType === 'branch') {
                allValues.forEach(value => {
                    filterData.push(value.code);
                });
                this.selectedBranches = filterData;
            } else if (selectType === 'role') {
                allValues.forEach(value => {
                    filterData.push(value);
                });
                this.selectedRoles = filterData;
            } else if (selectType === 'employee') {
                allValues.forEach(value => {
                    filterData.push(value.login);
                });
                this.selectedUsers = filterData;
            }
            selectId.value = filterData;
        } else {
            if (selectType === 'state') {
                this.selectedStates = [];
            } else if (selectType == 'branch') {
                this.selectedBranches = [];
            } else if (selectType === 'role') {
                this.selectedRoles = [];
            } else if (selectType === 'employee') {
                this.selectedUsers = [];
            }
            selectId.value = [];
        }
    }

    selectionChanged(selectAllCheckbox: any, selectedValues: any, allValues: any) {
        if (selectedValues.length === allValues.length) {
            selectAllCheckbox.checked = true;
        } else {
            selectAllCheckbox.checked = false;
        }
        if (this.fetchEmployeesForm.value.selectedStates?.length > 0 && this.fetchEmployeesForm.value.selBranches?.length > 0 && this.fetchEmployeesForm.value.userStatus?.length > 0 && selectedValues.length > 0){
            this.fetchEmployees()
        }
    }

    employeeSelectionChanged(selectAllCheckbox: any, selectedValues: any, allValues: any) {
        if (selectedValues.length === allValues.length) {
            selectAllCheckbox.checked = true;
        } else {
            selectAllCheckbox.checked = false;
        }
    }

    loadActivityTrackerReports() {
        this.dataSource.loadActivityTrackerReports(this.selectedUsers,
            formatDate(this.fromDate, 'yyyy-MM-dd', 'en-us'),
            formatDate(this.toDate, 'yyyy-MM-dd', 'en-us'), this.businessLine)
    }

    downloadActivityReport() {
        this.reportService.downloadActivityReport({
            users: this.selectedUsers,
            fromDate : formatDate(this.fromDate, 'yyyy-MM-dd', 'en-us'),
            toDate : formatDate(this.toDate, 'yyyy-MM-dd', 'en-us'),
             businessLineId : this.businessLine})
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(res => {
                console.log('start download:', res);
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            }
        );
    }

    changeBusinessLine(selectedBusniessLine: any) {
        this.onSelectBlClear();
        this.selectedStates = null;
        this.businessLine = selectedBusniessLine.value;
        console.log("BMT businness line : " + this.businessLine);
        this.loadStates();
    }

    openCountDetails(element : any, typeCount: any) {
        console.log("openCountDetails called!");
        console.log("this is element = " + element.employeeLogin);
        console.log('Table Data:', this.dataSource);
        const dateFrom = formatDate(this.fromDate, 'yyyy-MM-dd', 'en-us');
        const dateTo = formatDate(this.toDate, 'yyyy-MM-dd', 'en-us');

        const dialogRef = this.dialog.open(ActivityTrakerCountDialogComponent, {
            data: {element , typeCount, dateFrom, dateTo}
          });
          dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
            if (result && result === 'deleted') {
              this.snackBar.open(`Deleted branch successfully`, 'Close');
              this.ngOnInit();
            }
          });
      }
}
