import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { StatesResolverService } from '../admin/branch/branch.resolve';
import { LoggedInUserResolveService } from '../entities/audit/audit-transaction/audit-transaction-resolve.service';
import { MaterialLibModule } from '../material-lib.module';
import { ActivityTrackerReportComponent } from './activityTrackerReport/activity-tracker-report.component';
import { BmtProductivityReportBarChartComponent } from './bmtProductivityReport/bmt-productivity-report-bar-chart.component';
import { BmtProductivityReportComponent } from './bmtProductivityReport/bmt-productivity-report.component';
import { CihReportComponent } from './cih-report/cih-report.component';
import { BmtProductivityReportCountDataComponent } from './bmtProductivityReport/bmt-productivity-report-count-data.component';
import { BranchVisitMonitorComponent } from './branch-visit-monitor/branch-visit-monitor.component';
import { CrmFileUploadComponent } from './crm-file-upload/crm-file-upload.component';
import { CreditRiskModelComponent } from './credit-risk-model/credit-risk-model.component';
import { ActivityReportComponent } from './activity-report/activity-report.component';
import { CrossFunctionalDeviationComponent } from './cross-functional-deviation/cross-functional-deviation.component';
import { DeviationReportComponent } from './deviation-report/deviation-report.component';
import { EfficiencyReportComponent } from './efficiency-report/efficiency-report.component';
import { EmployeeFileUploadComponent } from './employeeUploadLeaveDetail/employee-file-upload';
import { HardBucketCollectionComponent } from './hard-bucket-collection/hard-bucket-collection.component';
import { HrReportComponent } from './hrReport/hr-report.component';
import { ImportComponent } from './import/import.component';
import { JobFileUploadComponent } from './job-file-upload/job-file-upload.component';
import { MoratoriumConsentComponent } from './moratoriumReport/moratorium-consent.component';
import { MoratoriumReportComponent } from './moratoriumReport/moratorium-report.component';
import { AuditReportComponent } from './reports/audit-report.component';
import { RiskObservationListComponent } from './riskObservation/risk-observation-list.component';
import { RiskObservationComponent } from './riskObservation/risk-observation.component';
import { ScoreCardComponent } from './score-card/score-card.component';
import { RagReportComponent } from './rag-report/rag-report.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivityTrakerCountDialogComponent } from './activityTrackerReport/activity-traker-count-dialog.component';

export const reportRoute: Route = {
    path: 'dashboard',
    canActivate: [AuthenticatedUserService],
    children: [
        {
            path: 'report',
            canActivate: [AuthenticatedUserService],
            component: AuditReportComponent,
            resolve: {
                states: StatesResolverService,
                loggedInUser: LoggedInUserResolveService
            }
        },
        {
            path: 'riskobservation',
            component: RiskObservationComponent
        },
        {
            path: 'list',
            component: RiskObservationListComponent
        },
        {
            path: 'activityTrackerReport',
            canActivate: [AuthenticatedUserService],
            component: ActivityTrackerReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_ACTIVITYTRACKER_REPORT'] }
        },
        {
            path: 'hrReport',
            canActivate: [AuthenticatedUserService],
            component: HrReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_HR_DASHBOARD'] }
        },
        {
            path: 'productivityDashboard',
            component: BmtProductivityReportComponent,
            resolve: {
                states: StatesResolverService,
                loggedInUser: LoggedInUserResolveService
            }
        },
        {
            path: 'efficiencyReport',
            canActivate: [AuthenticatedUserService],
            component: EfficiencyReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_EFFICIENCY_REPORT'] }
        }, {
            path: 'moratorium',
            canActivate: [AuthenticatedUserService],
            component: MoratoriumReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_MORATORIUM_REPORT'] }
        },
        {
            path: 'moratoriumConsent',
            canActivate: [AuthenticatedUserService],
            component: MoratoriumConsentComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_MORATORIUM_CONCENT_REPORT'] }
        },

        {
            path: 'cihreport',
            canActivate: [AuthenticatedUserService],
            component: CihReportComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_CIH_REPORT'] }
        },
        {
            path: 'crmfileupload',
            canActivate: [AuthenticatedUserService],
            component: CrmFileUploadComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_CRM_FILE_UPLOAD'] }
        },
        {
            path: 'deviationReport',
            canActivate: [AuthenticatedUserService],
            component: DeviationReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_DEVIATION_REPORT'] }
        },
        {
            path: 'branchvisitmonitor',
            component: BranchVisitMonitorComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            }
        },
        {
            path: 'jobFileUpload',
            canActivate: [AuthenticatedUserService],
            component: JobFileUploadComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService

            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_JOBDASHBOARD'] }
        },
        {
            path: 'ragReport',
            component: RagReportComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService,
            },
        },
        {
            path: 'import',
            canActivate: [AuthenticatedUserService],
            component: ImportComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_JOBDASHBOARD'] }
        },

        {
            path: 'creditriskmodel',
            canActivate: [AuthenticatedUserService],
            component: CreditRiskModelComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_CRM_DASHBOARD'] }
        },
        {
            path: 'hardbucketcollection',
            canActivate: [AuthenticatedUserService],
            component: HardBucketCollectionComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_HARD_BUCKET_DASHBOARD'] }
        },
        {
            path: 'scorecard',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD'] }
        },
        {
            path: 'crossfunctionaldeviation',
            canActivate: [AuthenticatedUserService],
            component: CrossFunctionalDeviationComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_REPORT_DASHBOARD'] }

        },
        {
            path: 'activityreport',
            canActivate: [AuthenticatedUserService],
            component: ActivityReportComponent,
            resolve: {

                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_MANDATORY_REPORT_DASHBOARD'] }
        },
        {
            path: 'employeeleavedetails',
            canActivate: [AuthenticatedUserService],
            component: EmployeeFileUploadComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_MANDATORY_USER_UPLOAD_DASHBOARD'] }
        },

    ]
};

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        ChartsModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatDialogModule,
    ],
    declarations: [
        AuditReportComponent,
        RiskObservationComponent,
        RiskObservationListComponent,
        ActivityTrackerReportComponent,
        HrReportComponent,
        BmtProductivityReportComponent,
        EfficiencyReportComponent,
        MoratoriumReportComponent,
        MoratoriumConsentComponent,
        BmtProductivityReportBarChartComponent,
        DeviationReportComponent,
        BmtProductivityReportCountDataComponent,
        BranchVisitMonitorComponent,
        CihReportComponent,
        CrmFileUploadComponent,
        CreditRiskModelComponent,
        HardBucketCollectionComponent,
        ActivityReportComponent,
        EmployeeFileUploadComponent,
        CrossFunctionalDeviationComponent,
        ScoreCardComponent,
        JobFileUploadComponent,
        ImportComponent,
        RagReportComponent,
        ActivityTrakerCountDialogComponent
    ],
    entryComponents: [
        AuditReportComponent,
        RiskObservationComponent,
        RiskObservationListComponent,
        ActivityTrackerReportComponent,
        HrReportComponent,
        BmtProductivityReportComponent,
        MoratoriumReportComponent,
        MoratoriumConsentComponent,
        BmtProductivityReportBarChartComponent,
        EfficiencyReportComponent,
        DeviationReportComponent,
        BmtProductivityReportCountDataComponent,
        RagReportComponent,
        ActivityTrakerCountDialogComponent
    ],
    exports: [RouterModule, ReactiveFormsModule, BmtProductivityReportComponent],
})
export class DashboardModule { }
