import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Role} from '../../admin/role/role.model';
import {RoleService} from '../../admin/role/role.service';
import {ActivatedRoute, Router} from '@angular/router';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Questionaire} from './questionaire.model';
import {QuestionaireService} from './questionaire.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'app-questionaire-delete-dialog',
    templateUrl: './questionaire-delete-dialog.component.html',
    styleUrls: ['./questionaire.component.css']
})
export class QuestionaireDeleteDialogComponent implements OnInit, OnDestroy {
    questionaire: Questionaire;
    errorMessage: string;
    constructor(private questionaireService: QuestionaireService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<QuestionaireDeleteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Questionaire) {
        this.questionaire = data;
    }

    ngOnInit() {
    }
    ngOnDestroy(): void {
    }
    delete() {
        this.errorMessage = '';
        this.questionaireService.deleteQuestionaireData(this.questionaire.id).pipe(takeUntil(componentDestroyed(this))).
        subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }
    onSaveSuccess(result) {
        this.snackBar.open(`Deleted questionaire '${this.questionaire.questionnaireVersionCode}'
        of version '${this.questionaire.questionnaireVersionCode}' successfully`, 'Close', {
            duration: 3000,
        });
        this.dialogRef.close();
    }
    onSaveError(response) {
        this.errorMessage = response.error;
    }
    cancel(): void {
        this.dialogRef.close();
    }
}
