import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';
import {Center} from './center.model';
import {CenterService} from './center.service';


export class CenterListDataSource extends  DataSource<Center> {

  private centerSubject = new BehaviorSubject<Center[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private centerService: CenterService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<Center[]> {
    return this.centerSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.centerSubject.complete();
    this.totalCount$.complete();
  }

  loadCenters(sortDirection = 'asc', pageIndex = 0, pageSize = 3,centerStatus: string) {

    this.centerService.findCenters({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      centerStatus: centerStatus,
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.centerSubject.next(response['body']);
    });
  }

}
