import { Component, OnDestroy, OnInit } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuditPlanService } from './audit-plan.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-audit-plan-upload',
  templateUrl: './audit-plan-upload.component.html',
  styleUrls: ['./audit-plan.component.css']
})
export class AuditPlanUploadComponent implements OnDestroy, OnInit {

  file: File;
  message: any[];
  constructor(private router: Router, private auditPlanService: AuditPlanService,
    private snackBar: MatSnackBar) {
  }

  ngOnDestroy(): void {
  }
  ngOnInit(): void {
    this.message = [];
  }

  goToAuditPlanPage() {
    this.router.navigateByUrl('auditPlan');
  }

  onFileSelection(event: any) {
    this.file = event.target.files[0];
  }

  onSubmit() {
    if (this.file === undefined) {
        this.message.push('Please select a file');
    } else {
      this.auditPlanService.uploadAuditPlan(this.file).pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
    }
  }

  private onSubmitSuccess(response) {
    this.snackBar.open('File Uploaded Successfully', 'Close', {
      duration: 3000,
    });
    this.message = [response.body];
  }

  private onSubmitError(response) {
    this.snackBar.open('Failed Saving Audit Plan', 'Close', {
      duration: 3000,
    });
    if (response.error.text === undefined) {
      const errors = response.error ;
      this.message = errors;
      return;
    }
    this.message = response.error.text.split('|');
  }

  downloadTemplate() {
    this.auditPlanService.downloadAuditPlanTemplate()
    .pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      console.log('start download:', res);
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      console.log('download error:', JSON.stringify(error));
    }, () => {
      console.log('Completed file download.');
    });
  }

}
