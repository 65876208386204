import {Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, DoCheck} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReportService} from '../reports/report.service';
import {BmtDataModel} from './bmt-report-useractivity.model';
import {BaseChartDirective} from 'ng2-charts';
import {ProductivityCountDataSource} from './bmt-report-data-source';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {PagingAndSortParams} from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import {ListSource} from 'projects/ig-core/src/lib/list/list-source';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {months} from '../../constants/data.constants';

/**
 * This method is used to load the Mat-Table
 * @author Prabhakar H Patil
 * @since v2.8.2
 */
@Component({
    selector: 'app-count-data-report',
    templateUrl: './bmt-productivity-report-count-data.component.html',
    styleUrls: ['./bmt-productivity-report.component.css']
})
export class BmtProductivityReportCountDataComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
    pagingAndSortParams: PagingAndSortParams;
    dataSource: ProductivityCountDataSource;
    listSource: ListSource;

    stateName: String;
    branchCodes: String[];
    activities: String[];
    roles: String[];
    selectedMonth: any;
    year: any;
    activity: any;
    @Input() bmtProductivityCountData: BmtDataModel[];
    @ViewChild(BaseChartDirective,{static:false}) chart: BaseChartDirective;
    displayedColumns = ['stateName', 'branchName', 'userName', 'userLoginId', 'role', 'submittedDate', 'activity'];
    parmData: any;
    newMonth: any;
    filterPartData: any;

    constructor(private http: HttpClient, private _emp: ReportService,
                private reportService: ReportService,
                private snackBar: MatSnackBar,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog) {
    }

      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;

    ngOnInit() {
        this.parmData = this._emp.bmtTableGet();
    }

    ngDoCheck() {
        if (this.parmData.ProductivityStateData['month'] !== this._emp.bmtTableGet().ProductivityStateData['month'] ||
            this.parmData.ProductivityStateData['stateName'] !== this._emp.bmtTableGet().ProductivityStateData['stateName']) {
            this.parmData = this._emp.bmtTableGet();
            this.year = this.parmData.ProductivityData['year'];
            this.stateName = this.parmData.ProductivityStateData['stateName'];
            this.selectedMonth = this.parmData.ProductivityStateData['month'];
            this.branchCodes = this.parmData.ProductivityData['branchCode'];
            this.activities = this.parmData.ProductivityData['activity'];
            this.roles = this.parmData.ProductivityData['role'];

            this.dataSource = new ProductivityCountDataSource(this.reportService);
            this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
            this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
                .subscribe((listParams) => {
                    this.pagingAndSortParams = listParams.pagingAndSortParam;
                    let sortColumn = this.pagingAndSortParams.sortColumn;
                    if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                        sortColumn = 'id';
                    }
                    this.showActiveUserData();
                    this.loadProductivityActiveCountdata(sortColumn);
                });
        }// end if
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        // initiate sort and pagination change once the view is initialized
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadUseractivePage());
    }


    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }


    loadUseractivePage() {
        this.router.navigate([], this.getQueryParams());
    }

    loadProductivityActiveCountdata(sortColumn: String) {

        this.dataSource.loadProductivityActiveCountdata(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize);
    }

    exportUserData() {
        this.parmData = this._emp.bmtTableGet();
        console.log("year :" + this.parmData.ProductivityData["year"]);
        this.year = this.parmData.ProductivityData["year"];
        this.stateName = this.parmData.ProductivityStateData["stateName"];
        this.selectedMonth = this.parmData.ProductivityStateData["month"];
        this.branchCodes = this.parmData.ProductivityData["branchCode"];
        this.activities = this.parmData.ProductivityData["activity"];
        this.roles = this.parmData.ProductivityData["role"];


        const selectedMonthObject: any = months.filter(month => month.name === this.selectedMonth);
        selectedMonthObject.forEach(element => {
            this.newMonth = element.code;
        });
        this.reportService.downloadUserData({
            stateName: this.stateName,
            branchCodes: this.branchCodes, activities: this.activities, roles: this.roles, months: this.newMonth, year: this.year
        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));

        }, () => {
            console.log('Completed file download.');
        });

    }


    showActiveUserData() {
        this.parmData = this._emp.bmtTableGet();
        console.log('year :' + this.parmData.ProductivityData['year']);
        this.year = this.parmData.ProductivityData['year'];
        this.stateName = this.parmData.ProductivityStateData['stateName'];
        this.selectedMonth = this.parmData.ProductivityStateData['month'];
        this.branchCodes = this.parmData.ProductivityData['branchCode'];
        this.activities = this.parmData.ProductivityData['activity'];
        this.roles = this.parmData.ProductivityData['role'];

        const selectedMonthObject: any = months.filter(month => month.name === this.selectedMonth);
        selectedMonthObject.forEach(element => {
            this.newMonth = element.code;
        });
        this.reportService.showUserData(
            {
                stateName: this.stateName,
                branchCodes: this.branchCodes, activities: this.activities, roles: this.roles, months: this.newMonth, year: this.year
            }).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(result => {
                this.filterPartData = result.body;
            });
    }
}
