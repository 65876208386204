<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>settings_applications</mat-icon>
        </div>
        <div mat-card-title>
            Questionnaire Configuration
        </div>
        <div class="page-title-action">
            <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
                <mat-icon>add_circle</mat-icon>
                Create Questionnaire Configuration
            </button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <form [formGroup]="questionnaireConfigListForm"  >
            <div>
                    <mat-form-field style="padding-right: 20px;">
                      <input matInput type="text" id="questionnaireCode" name="questionnaireCode" placeholder="Enter Questionnaire Code" formControlName="questionnaireCode"/>
                    </mat-form-field>

                    <mat-form-field style="padding-right: 20px;">
                        <mat-select formControlName="businessLine"   placeholder="Business Line">
                          <mat-option  *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{businessLine.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                    <button type="button" mat-raised-button color="primary" (click)="search()">
                      <mat-icon>search</mat-icon>
                    </button>
                    &nbsp;
                    <button style="margin-right: 5px" type="button" mat-raised-button color="primary" (click)="clearFilter()">
                      Clear
                    </button>
            </div>
        </form>

        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear
                   [dataSource]="dataSource" class="mat-elevation-z8">
                    
                        <ng-container matColumnDef="questionnaireCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="questionnaireCode">Questionnaire Code</th>
                            <td mat-cell *matCellDef="let element" data-label="questionnaireCode"> {{element.questionnaireCode}}</td>
                        </ng-container>

                        <ng-container matColumnDef="businessLine">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="businessLine">Business Line</th>
                            <td mat-cell *matCellDef="let element" data-label="Business Line"> {{element.businessLineDTO.name}}</td>
                        </ng-container>
                

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-button-toggle-group #questionnaireConfigurationActionGroup="matButtonToggleGroup" class="table-row-action">
                            <mat-button-toggle [routerLink]="['view', element.id]" title="View More">
                                <mat-icon>more</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle [routerLink]="['update', element.id]" title="Edit">
                                <mat-icon>edit</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle (click)="deleteDialog(element)" title="Delete">
                                <mat-icon>delete</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                           [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"  [pageIndex]="pagingAndSortParams.pageIndex"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
