<mat-card>
    <mat-card-header class="page-title">
      <div mat-card-avatar>
        <mat-icon> list_alt </mat-icon>
      </div>
      <div mat-card-title>
        Application Usage Stats
      </div>
    </mat-card-header>
  
    <mat-card-content>
  
      <div>
          <mat-form-field style="margin-right: 20px">
              <input matInput [(ngModel)]="fromDate"  [matDatepicker]="fromDatePicker" placeholder="From">
              <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
  
            <mat-form-field style="margin-right: 20px">
                <input matInput [(ngModel)]="toDate"  [matDatepicker]="toDatePicker" placeholder="To" >
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
              
          <mat-form-field style="padding-right: 20px;">
              <mat-select [(ngModel)]="role" placeholder="List Of Role">
                <mat-option  *ngFor="let role of roles" [value]="role.code">{{role.code}}</mat-option>
              </mat-select>
            </mat-form-field>
  
        <button type="button" style="margin-right: 10px" mat-raised-button color="primary" (click)="loadApplications('')">
          <mat-icon>search</mat-icon>
        </button>

        <button type="button" style="margin-right: 10px" mat-raised-button color="primary" (click)="downloadAppUsageStats()">
          <mat-icon>get_app</mat-icon>
      </button>
        
        <button type="button" style="margin-right: 10px" mat-raised-button color="primary" (click)="clearFilter()">
          Clear
        </button>
      </div>
  
      <div class="mat-elevation-z8">
        <table mat-table matSort matSortActive="email" matSortDirection="asc" matSortDisableClear
               [dataSource]="dataSource" class="mat-elevation-z8">
  
          <ng-container matColumnDef="principal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="principal"> Login</th>
            <td mat-cell *matCellDef="let element" data-label="Principal"> {{element.principal}} </td>
          </ng-container>
  
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type </th>
            <td mat-cell *matCellDef="let element" data-label="Type"> {{element.type}} </td>
          </ng-container>

          <ng-container matColumnDef="timestamp">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="timestamp">Time</th>
              <td mat-cell *matCellDef="let element" data-label="Timestamp"> {{element.timestamp | date : 'medium'}}</td>
            </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                       [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
  