import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObservationResponseAction } from '../observation-response-action.model';
import { AuditTransactionService } from '../audit-transaction-service';
import { ObservationResponseDetail } from '../../../questionaire/observation-response-detail.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
@Component(
    {
        templateUrl: './add-remark-dialog.component.html'
    }
)
export class AddRemarkDialogComponent implements OnInit, OnDestroy {
    form: FormGroup;
    config: any;
   // observationResponseDetail: ObservationResponseDetail;
    observationResponseAction: ObservationResponseAction;
    public formErrors = {
        remark : ''
    };


    constructor(private igFormService: IgFormService,
        private formBuilder: FormBuilder,
        private auditTransactionService: AuditTransactionService,
        private dialogRef: MatDialogRef<AddRemarkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.config = data.config;
       // this.observationResponseDetail = data.observationResponseDetail;
    }
    ngOnInit() {
        this.observationResponseAction = new ObservationResponseAction();
        this.form = this.formBuilder.group({
            remark: [this.observationResponseAction.remarks, Validators.maxLength(500)]
        });
        if (this.config.isRequired) {
            const remarkFieldControl = this.form.get('remark');
            remarkFieldControl.setValidators(Validators.required);
            remarkFieldControl.updateValueAndValidity();
        }
    }

    ngOnDestroy() {

    }
    submit(form) {
        this.igFormService.markFormGroupTouched(this.form);
        if (!this.form.valid) {
            this.formErrors = this.igFormService.validateForm(this.form, this.formErrors, false);
            return false;
        }
        this.observationResponseAction.remarks = this.form.controls.remark.value;
        this.observationResponseAction.action = this.config.action;
        this.observationResponseAction.observationResponseDetailId = this.data.observationResponseDetail.observationResponseDetailId;
        this.observationResponseAction.nextAction = this.config.nextAction;
        this.observationResponseAction.questionnaireResponseId = this.data.observationResponseDetail.questionnaireResponseId;
        this.observationResponseAction.isUserMarked = true;
        this.auditTransactionService.updateObservationResponseStatus(this.observationResponseAction)
        .pipe(takeUntil(componentDestroyed(this))).subscribe((res) => {
            this.dialogRef.close({ msg: 'Updated successfully', config: this.config, complianceHistory : res });
        },
            (res) => {
                this.dialogRef.close({ msg: res.error.title });
            });

        // this.dialogRef.close('failed');
    }
}
