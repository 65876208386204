<mat-card>
  <mat-card-header class="page-title">
      <div mat-card-avatar>
          <mat-icon>donut_small</mat-icon>
      </div>
      <div mat-card-title>
        Risk Observation
      </div>
  </mat-card-header>
  <mat-card-content>
      <form [formGroup]="riskObservationForm" novalidate (ngSubmit)="onSubmit()">
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
              
            <mat-form-field>
              <mat-select name="name" formControlName="name" placeholder="Branches" [(ngModel)]="selectedBranches" [formControl]="branchSelectIndicator" [attr.tabindex]="0" multiple >
                  <mat-select-trigger>
                      {{branchSelectIndicator.value? branchSelectIndicator.value[0].name : ''}}
                      <span *ngIf="branchSelectIndicator.value?.length > 1">
             (+{{branchSelectIndicator.value.length - 1}} {{branchSelectIndicator.value?.length === 2 ? 'other' : 'others'}})
               </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let branch of branches" [value]="branch">{{branch.name}}</mat-option>
              </mat-select>
              <mat-error align="start" *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-select name="status" formControlName="status" placeholder="Risk Status" [(ngModel)]="status">
              <mat-option *ngFor="let eachStatus of statusLists" [value]="eachStatus">{{eachStatus}}</mat-option>
          </mat-select>
          </mat-form-field>
            &nbsp;
            <button type="button" mat-raised-button color="primary" [routerLink]="['list']" (click)="loadCheckListUnderRisk('')" >
              <mat-icon>search</mat-icon>
            </button>
          </div>
      </form>
      
  </mat-card-content>
</mat-card>

    <div *ngIf = 'isPresent'>
              <div class="margin-7 dull-background" *ngFor="let audit of auditPlan">
                  <div style="padding: 30px;">
                    <h2>BranchCode: {{audit.branchCode}} BranchName: {{audit.branchName}} FromDate: {{audit.tentativeFromDate}}</h2>
                    <div class="margin-7 dull-background mat-card ng-star-inserted" *ngFor="let questionaireResponse of audit.questionaireResponseDetailDTO">
                        <span> {{questionaireResponse.question.name}}</span><br/>
                        <span>{{questionaireResponse.answer | uppercase }}</span>
                        
                    </div>
                    </div>
              </div>
    </div>
    
    

    

      