import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { UserService } from 'src/app/admin/user/user.service';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { ReportService } from '../reports/report.service';


@Component({
  selector: 'app-rag-report',
  templateUrl: './rag-report.component.html',
  styleUrls: ['./rag-report.component.css']
})
export class RagReportComponent implements OnInit,OnDestroy {
  
  public ragReportForm: FormGroup;
  selectedStates: any;
  businessLine: any;
  businessLines: any[];
  selectedBranches: any[];
  stateDropdownList: any[];
  states: any;
  stateList: string[];
  dropdownList: any[]; 
  branches: Branch[];
  fromDateFilter: string;
  toDateFilter: string;
  type: string;
  codes: any[];
  public formError: FormErrorModel;
  public formErrors = {
    businessLineId:'',
    stateName:'',
    name:'',
    selectedStates: '',
    selectedBranches: '',
    fromDateFilter: '',
    toDateFilter: '',
    type: '',

  }
  stateDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  ///branch drop down settings
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  ragReporttypes = ['RAG Trending Report'];

   constructor(private userService: UserService,private igFormService: IgFormService,
    private formBuilder: FormBuilder,private questionaireService: QuestionaireService,
    private reportService: ReportService) {
     }
 
  ngOnInit(): void {
    this.loadBusniessLine();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void {
  }
  
  // for selecting the state  and to load branches
  onSelectState(state: any) {
    this.loadBranches();
  }
  
  //for selecting all the states to get mapped branches
  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }

  //to deselect the selected states
  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  //to deselect the all the selected states
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();
  }

  //to select the branches
  onItemSelect(event){
   };

  //to select the all the branches
  onSelectAll(event){
  };

  //to deselect the selected branches
  onItemDeSelectBranch(item: any) {
  };
  

  buildForm() {
    this.ragReportForm = this.formBuilder.group({
      businessLineId:['',Validators.required],
      stateName: ["", Validators.required],
      name: ["", Validators.required],
      fromDateFilter: [this.fromDateFilter, Validators.required],
      toDateFilter: [this.toDateFilter, Validators.required],
      type: [this.type, Validators.required],
  });
  this.ragReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
  this.formErrors = this.igFormService.validateForm(this.ragReportForm, this.formErrors, true);
});
}


//to get all businessLines
loadBusniessLine() {
  this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
    this.businessLines = businessLines;
  });
}
 
//to get states based on the selected businessLine
loadStates() {
  this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (res: HttpResponse<any>) => {
        this.states = res.body;
        this.stateDropdownList = this.states;
      }
    );
}

// on the selected states to get branches
loadBranches() {
  this.selectedBranches=null;
  this.stateList = [];
  for (let i = 0; i < this.selectedStates.length; i++) {
    this.stateList.push(this.selectedStates[i].name);
  }
  this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (res: HttpResponse<Branch[]>) => {
        this.branches = res.body;
        this.dropdownList = this.branches;
      
      });
}
  // get states this based on selected busniess line
  changeBusinessLine(selectedBusniessLine: any){
    this.selectedStates=null;
    this.businessLine = selectedBusniessLine.value;
    this.loadStates();
   }

   //on from submitt this method is called
   onSubmit(){
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.ragReportForm);
    if (this.ragReportForm.valid) {
      this.downloadRagReport();
    } else {
      this.formErrors = this.igFormService.validateForm(this.ragReportForm, this.formErrors, false);
      return false;
    }
  }
  //ownloading the RAG report
  downloadRagReport(){
    this.codes = [];
    this.selectedBranches.forEach((value) => {
      this.codes.push(value.code);
    });
    //to set the  parameters
    this.reportService.downloadRagReport({
      type: this.ragReportForm.get('type').value, 
      branchCodes: this.codes,
      businessLineId:this.businessLine,
      fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });

  }

   //to clear all the selected fileds
   clearFilter() {
    this.ragReportForm.reset();
    if(this.businessLine !== undefined){
      this.stateDropdownList = [];
    }
    this.dropdownList=[];
   }
 

}
