import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize, take } from "rxjs/operators";
import { AuditPlanService } from "./audit-plan.service";
import { AuditPlan } from "./audit-plan.model";

export class AuditPlanDataSource extends DataSource<AuditPlan> {
  private auditPlanSubject = new BehaviorSubject<AuditPlan[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private auditPlanService: AuditPlanService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<AuditPlan[]> {
    return this.auditPlanSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.auditPlanSubject.complete();
    this.totalCount$.complete();
  }

  loadAuditPlan(
    sortDirection = "desc",
    pageIndex = 0,
    pageSize = 3,
    // branchName: string,
    teamLead: any,
    assistant1: any,
    auditForMonth: number,
    auditForYear: number,
    branchCodes: string[]
  ) {
        //Splitting the values of branch name and code
  //     if (branchName !== '') {
  //     const data = branchName[0];
  //     const splitdata = data.split(" - ");
  //     const splitbranchCodes = splitdata[0];
  //     const splitbranchname = splitdata[1];

  //     branchName = splitbranchname;
  //     branchCodes = splitbranchCodes;
  //  }
    this.auditPlanService
      .auditPlan({
        page: pageIndex,
        size: pageSize,
        sort: sortDirection,
        // branchName: branchName,
        auditForMonth: auditForMonth,
        auditForYear: auditForYear,
        teamLead: teamLead,
        assistant1: assistant1,
        branchCodes: branchCodes,
      })
      .pipe(
        catchError(() => of([])),
        finalize(() => {})
      )
      .pipe(take(1))
      .subscribe((response) => {
        this.totalCount$.next(response["headers"].get("X-Total-Count"));
        this.auditPlanSubject.next(response["body"]);
      });
  }
}
