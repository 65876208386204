<mat-card style="height: 95%; overflow-y: hidden;">
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon>cloud_download</mat-icon>
    </div>
    <div mat-card-title>
      Credit Risk Model Report
    </div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="crmReportForm" novalidate (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="40px" fxLayoutAlign="start">
        &nbsp;
        <mat-form-field *ngIf="isRoleAdmin">
          <mat-select placeholder="Business Line"  formControlName="businessLineId" 
           (selectionChange)="changeBusinessLine($event)">
            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
              {{businessLine.name}} 
            </mat-option>
          </mat-select>
          <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
        </mat-form-field>
          
        <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
          <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'" [data]="stateDropdownList"
            [(ngModel)]="selectedStates" [settings]="stateDropdownSettings" (onSelect)="onSelectState($event)"
            (onSelectAll)="onSelectAllState($event)" (onDeSelect)="onItemDeSelect($event)"
            (onDeSelectAll)="onItemDeSelectAll($event)">
          </ng-multiselect-dropdown>
          <mat-error align="start" *ngIf="formErrors.stateName && crmReportForm.controls['stateName']?.touched ">{{ formErrors.stateName }}</mat-error>
        </div>

        <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
          <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
            [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)">
          </ng-multiselect-dropdown>
          <mat-error align="start" *ngIf="formErrors.name && crmReportForm.controls['name']?.touched ">{{ formErrors.name }}</mat-error>
        </div>

        <mat-form-field style="margin-left: 20px">
          <input matInput name="fromDateFilter" formControlName="fromDateFilter" [max]="toDateFilter"
            (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter "
            [matDatepicker]="fromDatePicker" placeholder="FromDate">
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput name="toDateFilter" formControlName="toDateFilter" [min]="fromDateFilter"
            (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
            [matDatepicker]="toDatePicker" placeholder="ToDate">
          <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter }}</mat-error>
        </mat-form-field>


        <mat-form-field>
          <mat-select name="code" formControlName="code" [(ngModel)]="code" placeholder="Report Type">
            <mat-option *ngFor="let crmCodes of crmReportList" [value]="crmCodes.code">
              {{crmCodes.name}}</mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.code}}</mat-error>
        </mat-form-field>

        <button type="submit" style="height: 40px;" mat-raised-button color="primary">
          <mat-icon>cloud_download</mat-icon> Download Report
        </button>

        <button type="button" style="height: 40px;" mat-raised-button color="primary"
          (click)="clearFilter()">Clear</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>