import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Questionaire} from './questionaire.model';
import {QuestionaireService} from './questionaire.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionaireResolveService implements Resolve<Questionaire> {
    constructor(private cs: QuestionaireService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Questionaire> | Observable<never> {
        return this.cs.getQuestionaire(+route.paramMap.get('id'));
    }
}
