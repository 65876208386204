<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar class="button-icon">
      <button mat-raised-button (click)="goToAuditPlanPage()">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
    </div>
    <mat-card-title class="button-icon-text">Upload Audit Plan</mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <p>Please select a xlsx file to bulk upload Audit Plans.
          <button mat-raised-button small color="accent" class="pull-right" (click)="downloadTemplate()">
              Download Template
          </button>
      </p>
    <form (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row">
        <input id="fileUpload" type="file" (change)="onFileSelection($event)"/>
        <mat-card-actions class="pull-right">
          <button type="submit" mat-raised-button color="primary">Submit</button>
          <button mat-raised-button (click)="goToAuditPlanPage()">Cancel</button>
        </mat-card-actions>
      </div>
    </form>
    <div style="color: red">
      <p *ngFor="let eachError of message">
        {{eachError}}
      </p>
    </div>
  </mat-card-content>
</mat-card>
