import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AuthenticatedUserService} from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import {EditableResolveService} from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import {NonEditableResolveService} from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import {MaterialLibModule} from '../../material-lib.module';
import { QuestionnaireConfigurationListComponent } from './questionnaire-configuration-list/questionnaire-configuration-list.component';
import { QuestionnaireConfigurationResolverService } from './questionnaire-configuration.resolve';
import { QuestionnaireConfigurationDeleteDialogComponent } from './questionnaire-configuration-list/questionnaire-configuration-delete-dialog.component';
import { QuestionnaireConfigurationEditorComponent } from './questionnaire-configuration-edit/questionnaire-configuration-editor.component';
import { MasterFilterUploadComponent } from './master-filter-upload/master-filter-Upload/master-filter-upload.component';



export const questionnaireConfigurationRoute: Route = {
  path: 'questionnaire-configuration',
  canActivate: [AuthenticatedUserService],
  children: [
    {path: '', component: QuestionnaireConfigurationListComponent},
    {path: 'create', component: QuestionnaireConfigurationEditorComponent,
      resolve: {
        editable: EditableResolveService
      }},
    {path: 'update/:id', component: QuestionnaireConfigurationEditorComponent,
      resolve: {
        questionnaireConfiguration: QuestionnaireConfigurationResolverService,
        editable: EditableResolveService
      }
    },
    {path: 'view/:id', component: QuestionnaireConfigurationEditorComponent,
      resolve: {
        questionnaireConfiguration: QuestionnaireConfigurationResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ QuestionnaireConfigurationListComponent, QuestionnaireConfigurationEditorComponent, QuestionnaireConfigurationDeleteDialogComponent, MasterFilterUploadComponent],
  entryComponents: [QuestionnaireConfigurationDeleteDialogComponent]
})
export class QuestionnaireConfigurationModule { }
