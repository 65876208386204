import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { NameValueDto } from 'projects/ig-core/src/lib/interfaces/name-value-dto';
import { takeUntil } from 'rxjs/operators';
import { SCORECARD_MASTER } from 'src/app/constants/data.constants';
import { ReferenceCode } from '../reference-code.model';
import { ReferenceCodeService } from '../reference-code.service';


@Component({
  selector: 'app-reference-code-editor',
  templateUrl: './reference-code-editor.component.html',
  styleUrls: ['../reference-code.component.css']
})
export class ReferenceCodeEditorComponent implements OnInit, OnDestroy {
  referenceCode: ReferenceCode;
  editable = true;
  isNew: boolean;

  public referenceCodeEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    id: '',
    version: '',
    classifier: '',
    name: '',
    code: '',
    parentReferenceCode: '',
    status: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: ''
  };
  public classifierObservable$;
  public parentCodes: NameValueDto[] = [];
  constructor(
    private referenceCodeService: ReferenceCodeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.referenceCode === undefined) {
          this.referenceCode = {};
        } else {
          this.referenceCode = data.referenceCode;
        }
        this.editable = data.editable;
      });
    this.classifierObservable$ = this.referenceCodeService.getClassifiers();
  }

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.referenceCodeEditorForm.disable();
    }
    if (this.referenceCode.classifier === SCORECARD_MASTER.SUBMODULE_MASTER) {
      this.loadParentCodes(SCORECARD_MASTER.MODULE_MASTER);
    }
  }

  loadParentCodes(classifier) {
    if (classifier === SCORECARD_MASTER.SUBMODULE_MASTER) {
      classifier = SCORECARD_MASTER.MODULE_MASTER;
    }
    this.referenceCodeService.getParentCodes(classifier)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.parentCodes = response;
      });
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.isNew = false;
    if (this.referenceCode.id === undefined) {
      this.isNew = true;
    }
    this.referenceCodeEditorForm = this.formBuilder.group({
      id: [this.referenceCode.id, []],

      version: [this.referenceCode.version, []],

      classifier: [this.referenceCode.classifier, [Validators.required]],

      name: [this.referenceCode.name, [Validators.required]],

      code: [this.referenceCode.code, [Validators.required]],

      parentReferenceCode: [this.referenceCode.parentReferenceCode, []],

      status: [this.referenceCode.status, []],

      field1: [this.referenceCode.field1, []],

      field2: [this.referenceCode.field2, []],

      field3: [this.referenceCode.field3, []],

      field4: [this.referenceCode.field4, []],

      field5: [this.referenceCode.field5, []]
    });
    this.referenceCodeEditorForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.referenceCodeEditorForm,
          this.formErrors,
          true
        );
      });

  }
  onSubmit() {
    this.formError = new FormErrorModel(false, '');

    // mark all fields as touched
    this.igFormService.markFormGroupTouched(this.referenceCodeEditorForm);

    if (this.referenceCodeEditorForm.valid) {
      this.referenceCodeService
        .saveReferenceCode(this.referenceCodeEditorForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          response => this.onSubmitSuccess(response),
          response => this.onSubmitError(response)
        );
    } else {
      this.formErrors = this.igFormService.validateForm(
        this.referenceCodeEditorForm,
        this.formErrors,
        false
      );
      return false;
    }
  }

  private onSubmitSuccess(response) {
    let msg = '';
    if (this.referenceCode.id) {
      msg = `Updated referenceCode ${this.referenceCode.id} successfully`;
    } else {
      msg = `Created referenceCode successfully`;
    }
    this.snackBar.open(msg, 'Close');
    this.goToReferenceCodeListPage();
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(
      this.referenceCodeEditorForm,
      this.formErrors,
      response.error
    );
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }

  goToReferenceCodeListPage() {
    if (this.referenceCode.id) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }
}
