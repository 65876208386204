import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IgFormService} from '../../../../projects/ig-core/src/lib/form/form.service';
import {ActivatedRoute} from '@angular/router';
import {ReportService} from '../reports/report.service';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {FormErrorModel} from '../../../../projects/ig-core/src/lib/form/form-error.model';
import {HttpResponse} from '@angular/common/http';
import {bmtProductivityActivities, bmtProductivityRoles, months, years} from '../../constants/data.constants';
import {NameValueDto} from '../../../../projects/ig-core/src/lib/interfaces/name-value-dto';
import {Branch} from '../../admin/branch/branch.model';
import {BmtReportModel} from './bmt-report.model';
import {BmtReportParamsModel} from './bmt-report-params.model';

/**
 * This method is used to load the months
 * @author Anil P
 * @since v2.8.0
 */
@Component({
    selector: 'app-bmt-report',
    templateUrl: './bmt-productivity-report.component.html',
    styleUrls: ['./bmt-productivity-report.component.css']
})
export class BmtProductivityReportComponent implements OnInit, OnDestroy {

    bmtReportParamModels: BmtReportParamsModel;
    downLoadFormatList: any[];
    bmtProductivityList: BmtReportModel[];
    showOnlyMappedBranches = true;
    stateHint = false;
    branchHint = false;
    activityHint = false;
    name: string;
    showProductivity = false;
    dropdownList: any[];
    stateDropdownList: any[];
    selectedItems = [];
    dropdownSettings = {};
    stateDropdownSettings = {};
    activityDropdownSettings = {};
    selectedBranches: Branch[];
    branches: Branch[];
    selectedMonths: any[];
    states: any;
    selectedStates: any[];
    branchCodes: any[];
    month: any[];
    bmtYear: string;
    selectedBmtActivities: any[];
    allMonths: any[];
    allYears: NameValueDto[];
    bmtActivities: any[];
    bmtRoles: any[];
    role: any[];
    code: string;
    branchSelectIndicator = new FormControl();
    dataSource: any;
    stateList: string[];
    public producitvityReportForm: FormGroup;
    @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;
    public formError: FormErrorModel;
    public formErrors = {
        stateName: '',
        selectedStates: '',
        month: '',
        bmtActivity: '',
        bmtYear: '',
        role: '',
        name: ''
    };

    show = false;
    selectedActivitycodes: any;

    constructor(private igFormService: IgFormService, private formBuilder: FormBuilder,
                private snackBar: MatSnackBar, private reportService: ReportService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.loadStates();
        this.loadMonthsAndYears();
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.producitvityReportForm = this.formBuilder.group({
            stateName: [this.selectedStates, Validators.required],
            month: [this.month, Validators.required],
            bmtYear: [this.bmtYear, Validators.required],
            bmtActivity: [this.selectedBmtActivities, Validators.required],
            role: [this.role, Validators.required],
            name: [this.name]
        });
        this.producitvityReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.producitvityReportForm, this.formErrors, true);
        });
    }

    onSelectActivity(event){}

    onSelectAllActivity(event){}

    loadStates() {
        this.reportService.getAllStatesForUser().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.states.splice(0, 0, {
                        code: 'PAN India',
                        displayName: 'PAN India',
                        name: 'PAN India'
                    });
                    this.stateDropdownList = this.states;
                    this.stateDropdownSettings = {
                        singleSelection: false,
                        idField: 'code',
                        textField: 'name',
                        selectAllTest: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        itemsShowLimit: 1,
                        allowSearchFilter: false,
                        enableCheckAll: false,
                        limitSelection: 5,

                    };
                }
            );
    }

    onSelectState(state: any) {
        this.stateHint = false;
        this.selectedBranches = [];
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        this.stateHint = false;
        this.selectedStates = state;
        this.loadBranches();
    }

    onItemDeSelectState(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectAll(item: any) {
        this.stateHint = true;
        this.selectedStates = [];
        this.loadBranches();

    }

    loadBranches() {
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        if (this.stateList.filter(state => state !== 'PAN India').length === 0) {
            return;
        }// end
        this.reportService.getAllBranchesForStatesAndUser(this.stateList, this.showOnlyMappedBranches)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                    this.dropdownSettings = {
                        singleSelection: false,
                        idField: 'code',
                        textField: 'name',
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        itemsShowLimit: 1,
                        allowSearchFilter: true
                    };
                });

    }

    loadMonthsAndYears() {
        this.allMonths = months;
        this.allYears = years;
        this.bmtActivities = bmtProductivityActivities;
        this.bmtRoles = bmtProductivityRoles;

        this.activityDropdownSettings = {
            singleSelection: true,
            idField: 'code',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    onError() {
        this.snackBar.open(`Error Downloading Excel`, 'Close', {
            duration: 3000,
        });
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.producitvityReportForm);
        const selectedBranchcodes: any[] = [];
        this.selectedBranches.forEach(element => {
            selectedBranchcodes.push(element.code);
        });
        // let selectedActivitycodes: any[] = [];
        this.selectedBmtActivities.forEach(element => {
            this.selectedActivitycodes = element.code;
        });
        const selectedRolecodes: any[] = [];
        this.bmtRoles.forEach(element => {
            selectedRolecodes.push(element.code);
        });
        this.reportService.bmtTableSet(selectedBranchcodes, this.selectedActivitycodes, selectedRolecodes, this.bmtYear);
        if (this.producitvityReportForm.valid) {
            this.download();
        } else {
            this.formErrors = this.igFormService.validateForm(this.producitvityReportForm, this.formErrors, false);
            this.setFormErrorValue();
            return false;
        }
    }

    setFormErrorValue() {
        if (this.formErrors.stateName === 'This field is required') {
            this.stateHint = true;
        }
        if (this.formErrors.bmtActivity === 'This field is required') {
            this.activityHint = true;
        }
    }

    download() {
        const bmtReportParamModels = new BmtReportParamsModel();
        this.showProductivity = false;
        let selectedBranchCodes: any;
        if (this.selectedBranches !== undefined && this.selectedBranches.length > 0) {
            selectedBranchCodes = [];
            this.selectedBranches.forEach(element => {
                selectedBranchCodes.push(element.code);
            });
        }
        const selectedAuditForMonths = [];
        this.selectedMonths = [];

        if (this.month !== undefined && this.month.length > 0) {
            this.month.forEach(element => {
                selectedAuditForMonths.push(+element.code);
                this.selectedMonths.push(element.name);
            });
        }
        const selectedActivity = [];
        this.selectedBmtActivities.forEach(i => {
            selectedActivity.push(i.code);
        });
        bmtReportParamModels.stateNames = this.stateList;
        bmtReportParamModels.branchCodes = selectedBranchCodes;
        bmtReportParamModels.months = selectedAuditForMonths;
        bmtReportParamModels.year = this.bmtYear;
        bmtReportParamModels.roles = this.producitvityReportForm.get('role').value;
        bmtReportParamModels.activities = selectedActivity;
        this.reportService.getBMTProductivityData({
            bmtReportParamModels
        }).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any[]>) => {
                    this.bmtProductivityList = res.body;
                    this.showProductivity = true;
                });

    }

    /**
     * This method is used to clear all the selected filters
     */
    clearFilter() {
        this.showProductivity = false;
        this.producitvityReportForm.get('stateName').reset();
        this.producitvityReportForm.get('role').reset();
        this.producitvityReportForm.get('month').reset();
        this.producitvityReportForm.get('bmtYear').reset();
        this.producitvityReportForm.get('bmtActivity').reset();
        this.producitvityReportForm.get('name').reset();
        this.selectedStates = [];
        this.selectedBranches = [];
        this.month = [];
        this.bmtYear = '';
        this.role = [];
        this.selectedBmtActivities = [];
    }
}
