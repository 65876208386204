<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="back()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title class="button-icon-text">CheckLists
            <span>
                
                <button *ngIf="isPresent && isDisagree && this.loggedInUser.role === 'AM' && !isAuditor" [disabled]="disableAuditReturnButton" class="margin-left-10 margin-bottom-10" mat-raised-button
                        (click)="updateStatus('Return')" color="primary">
                        Return to BM</button>

                <button *ngIf="isPresent && hasObservation && !isAuditor"  class="margin-left-10 margin-bottom-10" mat-raised-button
                    (click)="updateStatus('Reject')" color="primary">
                    {{auditReject}}</button>

                <button *ngIf="closeAudit" [disabled]="disableAuditCloseButton" class="margin-left-10 margin-bottom-10"
                    mat-raised-button (click)="updateStatus('Approved')" color="primary">
                    {{actionStatus}}
            </button>

                <button *ngIf="isPresent && !isAuditor && !isDisagree" class="margin-left-10 margin-bottom-10" mat-raised-button
                    (click)="updateStatus('Approved')" color="accent">
                    {{actionName}}
                </button>

            <button  *ngIf="isAuditor && isTeamLead" class="margin-left-10 margin-bottom-10" mat-raised-button
                (click)="onClick('Approved')" color="accent">  {{actionName}}
            </button>


            </span>
        </mat-card-title>
        <div *ngIf="isPresentComment">
            <mat-form-field appearance="outline" class="float-right" style="width: 300px">
                <input matInput placeholder="Comment" [(ngModel)]="comment" />
                <mat-icon matSuffix>comment</mat-icon>
            </mat-form-field>
        </div>
    </mat-card-header>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <div class="mat-elevation-z8">
        <table mat-table matSort matSortActive="" matSortDirection="asc" marSortDisableClear [dataSource]="dataSource"
            class="mat-elevation-z8">

            <ng-container matColumnDef="branchCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch Code </th>
                <td mat-cell *matCellDef="let element"> {{element.branchCode ? element.branchCode : '_'}} </td>
            </ng-container>

            <ng-container matColumnDef="centerCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre Code </th>
                <td mat-cell *matCellDef="let element"> {{element.centerCode ? element.centerCode : '_'}} </td>
            </ng-container>

            <ng-container matColumnDef="submittedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Submitted By </th>
                <td mat-cell *matCellDef="let element"> {{element.submittedBy}} </td>
            </ng-container>

            <ng-container matColumnDef="submittedOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Submitted On </th>
                <td mat-cell *matCellDef="let element"> {{element.submittedOn | date: 'mediumDate'}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list>
                        <mat-chip *ngIf="element.status === 'Completed'" color="accent" selected>
                            {{element.status}}
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'Under_Review'" color="primary" selected>
                            {{element.status}}
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'Initialized'" selected>
                            {{element.status}}
                        </mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="viewCheckList" matTooltip="View" (click)="navigateToCheckListResponse(element)"
                        [disabled]="!element.hasObservationResponse">
                        <mat-icon>more</mat-icon>
                    </button>
                    <button *ngIf="showScore" mat-icon-button matTooltip="Edit"
                        (click)="navigateToDetailInformationResponse(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
        <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
            [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
    </div>
</mat-card>
