import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {IgLayoutComponent} from './ig-layout.component';

const routes: Routes = [
  /*{path: '', outlet: 'layout', component: IgLayoutComponent}*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IgLayoutRoutingModule { }
