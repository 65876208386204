import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { QuestionaireService } from '../../../questionaire/questionaire.service';
import { ScoreRisk } from './score-risk.model';


@Component({
    selector: 'app-risk-confirmation-dialog',
    templateUrl: './risk-confirmation-dialog.component.html',
})
export class RiskConfirmationDialogComponent implements OnInit, OnDestroy {
    scoreRisk: ScoreRisk;
    errorMessage: string;
    infoMsg: string;
    constructor(private questionnaireService: QuestionaireService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<RiskConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ScoreRisk) {
        this.scoreRisk = data;
    }

    ngOnInit() {
        if (this.scoreRisk.riskStatus === 'YES') {
            this.infoMsg = 'Unmark';
        } else {
            this.infoMsg = 'Mark';
        }
    }
    ngOnDestroy(): void {
    }

    changeRiskStatus() {
        this.errorMessage = '';
        this.questionnaireService.chnageQuestionnaireDetailRiskStatus(this.scoreRisk).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }

    onSaveSuccess(result) {
        this.snackBar.open(`Risk Status Changed`, 'Close', {
            duration: 3000,
        });
        this.dialogRef.close();
    }
    onSaveError(response) {
        this.errorMessage = response.error;
    }
    cancel(): void {
        this.dialogRef.close();
    }
}
