import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { CenterListComponent } from './center-list.component';
import { CenterEditorComponent } from './center-editor.component';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { CenterResolverService } from './center.resolve';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from '../../material-lib.module';
import { CenterDeleteDialogComponent } from './center-delete-dialog.component';

export const centerRoute: Route = {
  path: 'center',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: CenterListComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_CENTER_LIST'] }
    },
    {
      path: 'create',
      canActivate: [AuthenticatedUserService],
      component: CenterEditorComponent,
      resolve: {
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_CENTER'] }
    },
    {
      path: 'update/:id', 
      canActivate: [AuthenticatedUserService],
      component: CenterEditorComponent,
      resolve: {
        center: CenterResolverService,
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_CENTER'] }
    },
    {
      path: 'view/:id', 
      canActivate: [AuthenticatedUserService],
      component: CenterEditorComponent,
      resolve: {
        center: CenterResolverService,
        editable: NonEditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_CENTER'] }
    }
  ]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [CenterListComponent, CenterEditorComponent, CenterDeleteDialogComponent],
  entryComponents: [CenterDeleteDialogComponent]
})
export class CenterModule { }
