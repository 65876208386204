import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAutoPopulateTagComponent } from './create-auto-populate-tag/create-auto-populate-tag.component';
import { AutoPopulateTagListComponent } from './auto-populate-tag-list/auto-populate-tag-list.component';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { Route, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MaterialLibModule } from 'src/app/material-lib.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
export const autoPopulateTagRoute: Route = {
  path: 'auto-populate-tag',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: AutoPopulateTagListComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_PLAN_LIST'] }
    },
    {
      path: 'create',
      canActivate: [AuthenticatedUserService],
      component: CreateAutoPopulateTagComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_PLAN_LIST'] }
    },
    {
      path: 'update/:id',
      canActivate: [AuthenticatedUserService],
      component: CreateAutoPopulateTagComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_PLAN_LIST'] }
    },
    {
      path: 'view/:id',
      canActivate: [AuthenticatedUserService],
      component: CreateAutoPopulateTagComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_PLAN_LIST'] }
    },
  ]
}
@NgModule({
  declarations: [
    CreateAutoPopulateTagComponent,
    AutoPopulateTagListComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ]
})
export class AutoPopulateTagModule { }
