import { SelectionModel } from "@angular/cdk/collections";
import { Question } from "../question/question.model";

export class ScoreSet {
    id?: string;
    version?: number;
    code?: string;
    description?: string;
    validFrom?: any;
    validTo?: any;
    createdBy?: string;
    createdDate?: any;
}


export class ModuleScorecardMapping {
    id?: number;
    version?: number;
    scorecardId?: number;
    moduleCode?: string;
    weightage?: number;
    flag?: boolean;
}


export class SubmoduleScorecardMapping {
    id?: number;
    version?: number;
    scorecardId?: number;
    moduleCode?: string;
    submoduleCode?: string;
    weightage?: number;
    flag?: boolean;
}

export class QuestionaireScorecardMapping {
    id?: number;
    version?: number;
    scorecardId?: string;
    moduleCode?: string;
    weightage?: number;
    flag?: boolean;
    questionaireId?: number;
    questionaireDTOs?: QuestionaireScorecard;
    questions?: Question[];
    selection?: SelectionModel<any>;
    name?: string;
    submoduleScoreId?: string;
}


export class BranchOrganisationMapping {
    id?: number;
    version?: number;
    office?: string;
    hierarchy?: string;
    parentId?: number;
    code?: string;
}

export class QuestionaireScorecard {
    id?: number;
    version?: number;
    name?: string;
    code?: string;
    description?: string;
    questionnaireVersionName?: string;
    questionnaireVersionCode?: string;
    scorecardId?: number;
    questions?: Question[];

    // private QuestionaireStatus status;

    // private List<QuestionsDTO> questions = new ArrayList<>();
}

export class BranchScorecardMapping {
    id?: number;
    version?: number;
    branchCode?: string;
    scorecardId?: string;
    scorecardCode?: string;
    office?: string;
    flag?: boolean;
}
