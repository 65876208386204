import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { BranchListComponent } from './branch-list/branch-list.component';
import { BranchEditorComponent } from './branch-edit/branch-editor.component';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { BranchResolverService } from './branch.resolve';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from '../../material-lib.module';
import { BranchDeleteDialogComponent } from './branch-list/branch-delete-dialog.component';



export const branchRoute: Route = {
  path: 'branch',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: BranchListComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_BRANCH_LIST'] }
    },
    {
      path: 'create', 
      canActivate: [AuthenticatedUserService],
      component: BranchEditorComponent,
      resolve: {
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_BRANCH'] }
    },
    {
      path: 'update/:id', 
      canActivate: [AuthenticatedUserService],
      component: BranchEditorComponent,
      resolve: {
        branch: BranchResolverService,
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_BRANCH'] }
    },
    {
      path: 'view/:id', 
      canActivate: [AuthenticatedUserService],
      component: BranchEditorComponent,
      resolve: {
        branch: BranchResolverService,
        editable: NonEditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_BRANCH'] }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [BranchListComponent, BranchEditorComponent, BranchDeleteDialogComponent],
  entryComponents: [BranchDeleteDialogComponent]
})
export class BranchModule { }
