<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon>  face </mat-icon>
    </div>
      <span style="font-weight: bold;"> {{data.element.firstName +'('+data.element.login+')'}}</span>   
    </mat-card-header>

  <mat-card-content style="padding: 15px;">
      <mat-form-field style="width:50%">
        <input matInput [(ngModel)]="data.element.role" placeholder="Role" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;
      
      <mat-form-field style="width:40%">
        <input matInput [(ngModel)]="data.element.appVersion" placeholder="MAVRC APP Version" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:50%">
        <input matInput [(ngModel)]="data.element.cashMangementPackage" placeholder="cash Mangement Package" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:40%">
        <input matInput [(ngModel)]="data.element.cashMangementVersion" placeholder="cash Mangement Version" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:50%">
        <input matInput [(ngModel)]="data.element.lastLogin" placeholder="Last Login" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;
        
      <mat-form-field style="width:40%">
          <input matInput [(ngModel)]="data.element.appInstalledOn" placeholder="App Installed On" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:50%">
        <input matInput [(ngModel)]="data.element.model" placeholder="Model" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:40%">
        <input matInput [(ngModel)]="data.element.osVersion" placeholder="OS Version" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:50%">
        <input matInput [(ngModel)]="data.element.ram" placeholder="Ram" readonly="{{isreadOnly}}">
      </mat-form-field>
      &nbsp;

      <mat-form-field style="width:40%">
         <input matInput [(ngModel)]="data.element.storage" placeholder="Storage" readonly="{{isreadOnly}}">
       </mat-form-field>
      
       <mat-card-actions class="pull-right">
        <button style="margin-right: 10px" type="button" mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
      </mat-card-actions>   
   
    </mat-card-content>
