import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';

@Injectable({providedIn: 'root'})
export class AuthTokenService {
  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService
  ) {
  }

  getToken() {
    return this.$localStorage.get('authenticationToken') || this.$sessionStorage.get('authenticationToken');
  }

  storeAuthenticationToken(jwt, rememberMe) {
    if (jwt && jwt.slice(0, 7) === 'Bearer ') {
      jwt = jwt.slice(7, jwt.length);
    }
    if (rememberMe) {
      this.$localStorage.set('authenticationToken', jwt);
    } else {
      this.$sessionStorage.set('authenticationToken', jwt);
    }
  }

  clearToken() {
    this.$localStorage.remove('authenticationToken');
    this.$sessionStorage.remove('authenticationToken');
  }
}
