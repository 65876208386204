/**
 * This method is used to load the months
 * @author Anil P
 * @since v2.8.0
 */
export class BmtReportParamsModel {
    page: any;
    sort: any;
    size : any;
    constructor(public stateNames?: any[],
                public branchCodes?: string[],
                public months?: any[],
                public roles?: any[],
                public activities?: any[],
                public year?: any,
                public month?: any,
                public isExport?: boolean,
                public activiy?: any,
                public stateName?: any,
                public fromDate?: Date,
                public toDate?: Date,
                public scoreCardCode?:number,
                public type?: string,
                public businessLineId?: number,
                public teamLead?: string,
                public assistant1?: string,
                public status?: String
             ) {

}
}
