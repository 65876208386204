<mat-card [style.width]="'300px'">
    <mat-card-header class="page-title">
        <mat-card-title>Copy Questionaire </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-error ngclass="errorMessage && errorMessage != ''">{{errorMessage | json}}</mat-error>
    </mat-card-content>

    <mat-card-content>
        <form [formGroup]="questionaireCopyForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                class="two-per-row">

                <mat-form-field>
                    <input name="name" matInput type="text" formControlName="name" placeholder="Questionaire Name"
                        [attr.tabindex]="0" />
                    <mat-error align="start" *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="code" matInput type="text" formControlName="code" placeholder="Questionaire Code" />
                    <mat-error align="start" *ngIf="formErrors.code">{{ formErrors.code }}</mat-error>
                </mat-form-field>


                <mat-card-actions>
                    <button mat-raised-button color="primary">
                        <mat-icon>save</mat-icon>Submit
                    </button>
                    <button mat-raised-button color="accent" (click)="cancel()">No Thanks</button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card-content>
</mat-card>