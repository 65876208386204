<mat-card style="height:95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>library_books</mat-icon>
        </div>
        <div mat-card-title>
            Moratorium Report
        </div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="moratoriumReportForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
               
                <mat-form-field *ngIf="isRoleAdmin">
                    <mat-select placeholder="Business Line"  formControlName="businessLineId" 
                     (selectionChange)="changeBusinessLine($event)">
                      <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                        {{businessLine.name}} 
                      </mat-option>
                    </mat-select>
                    <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                  </mat-form-field>
                &nbsp;
                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                                             [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                                             (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                                             (onDeSelect)="onItemDeSelectState($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <mat-hint *ngIf="stateHint" style="font-size: 11px;color: #f44336;">This field is required</mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;

                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                                             [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onSelectBranch($event)"
                                             (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)">
                    </ng-multiselect-dropdown>
                    <mat-hint *ngIf="branchHint" style="font-size: 11px;color: #f44336;">This field is required</mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;

                <mat-form-field >
                    <mat-select name="consentStatus" formControlName="consentStatus" placeholder="Consent status">
                        <mat-option *ngFor="let downLoadFormat of downLoadFormatList" [value]="downLoadFormat.value">{{downLoadFormat.viewValue}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.consentStatus">{{ formErrors.consentStatus }}</mat-error>
                </mat-form-field>

                <button type="submit" mat-raised-button color="primary"  style="height: 40px;">
                    <mat-icon>cloud_download</mat-icon> Download Report
                </button>
                &nbsp;
                <button type="button" style="height: 40px;" mat-raised-button color="primary"
                        (click)="clearFilter()">Clear</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>