export class ScoreRisk {
    constructor(
        public auditTransactionDetailId?: number,
        public questionRiskStatus?: Map<number, string>,
        public categoryScores?: Map<string, number>,
        public category?: string,
        public score?: number,
        public questionnaireResponseDetailId?: number,
        public riskStatus?: string,
        public questionName?: string,
    ) {
        this.questionRiskStatus = new Map<number, string>();
        this.categoryScores = new Map<string, number>();
    }
}
