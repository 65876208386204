<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon>assignment</mat-icon>
    </div>
    <div mat-card-title style="white-space: nowrap;">Auto Populate Tag </div>
    <div class="page-title-action">
      <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
        <mat-icon>add_circle</mat-icon>
        Create Auto Populate Tag
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="tagListForm">
      <div>
        <mat-form-field style="padding-right: 20px;">
          <input formControlName="code" matInput type="text" id="code" name="code" placeholder="Enter Tag Name" />
        </mat-form-field>
        <mat-form-field style="padding-right: 20px; width: 250px;">
          <mat-select formControlName="autoPopulateModuleId" placeholder="Auto populate Module">
            <mat-option *ngFor="let module of modules" [value]="module.id">{{ module.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button type="button" style="margin-right: 4px" mat-raised-button color="primary" (click)="search()">
          <mat-icon>search</mat-icon>
        </button>
        <button type="button" style="margin-right: 4px" mat-raised-button color="primary" (click)="clearFilter()">
          Clear
        </button>
      </div>
    </form>

    <div class="mat-elevation-z8">
      <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource"
        class="mat-elevation-z8">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef> Tag Name</th>
          <td mat-cell *matCellDef="let element" data-label="Code" class="code-column"> {{ element.code }} </td>
        </ng-container>
        <ng-container matColumnDef="module">
          <th mat-header-cell *matHeaderCellDef class="module-column"> Auto Populate Module</th>
          <td mat-cell *matCellDef="let element" data-label="Module" class="module-column">
            {{getModuleNameById(element.autoPopulateModuleId) }}
          </td>
        </ng-container>
        &nbsp;&nbsp;&nbsp;
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="description-column"> Query</th>
          <td mat-cell *matCellDef="let element" data-label="Description" class="description-column">
            {{ element.query}}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let tag">
            <mat-button-toggle-group #autoPopulateTagActionGroup="matButtonToggleGroup" class="table-row-action">
              <mat-button-toggle [routerLink]="['view', tag.id]" title="View More">
                <mat-icon>more</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle [routerLink]="['update', tag.id]" title="Edit">
                <mat-icon>edit</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle (click)="deleteTag(tag)" title="Delete" [disabled]="tag.used">
                <mat-icon>delete</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="dataSource.data.length" [pageSize]="pagingAndSortParams.pageSize"
        [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>