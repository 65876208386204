import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize, take} from 'rxjs/operators';
import { ScoreSet } from './score-card.model';
import { ScoreCardService } from './score-card.service';

export class ScorecardDataSource extends  DataSource<ScoreSet> {
    filter(arg0: (res: any) => any): ScorecardDataSource {
        throw new Error('Method not implemented.');
    }

    private scorecardSubject = new BehaviorSubject<ScoreSet[]>([]);

    public totalCount$ = new BehaviorSubject<number>(0);
    result: any;
    paginator: any;

    constructor(private scoreCardService: ScoreCardService) { super(); }

    connect(collectionViewer: CollectionViewer): Observable<ScoreSet[]> {
        return this.scorecardSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.scorecardSubject.complete();
        this.totalCount$.complete();
    }

    loadAudits(sortDirection = 'asc', pageIndex = 0, pageSize = 3, code: String, description: String) {

        this.scoreCardService.findScoreSets({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection,
            code: code,
            description: description
        }).pipe(
            catchError(() => of([])),
            finalize(() => {})
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.scorecardSubject.next(response['body']);

        });
    }




}
