import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApplicationUsageStats } from './application-usage-stats.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUsageStatsService {

  private API = 'management/audits/';
  private API_Download = 'management/audits/download/loggedInUsers/role';
  constructor(private http: HttpClient) {
  }

  findApplications(req: any): Observable<HttpResponse<ApplicationUsageStats[]>> {
    let params = new HttpParams();
    params = params.set('page', req.page);
    params = params.set('size', req.size);
    params = params.set('sort', req.sort);
    params = params.set('fromDate', req.fromDate);
    params = params.set('toDate', req.toDate);
    if (req.role != null) {
      params = params.set('role', req.role);
    }
    return this.http.get<ApplicationUsageStats[]>(this.API, {
      params, observe: 'response'
    });
  }

 getAllApplication() {
    return this.http.get<any>(this.API);
  }
  downloadAppUsageStats(req: any): Observable<any> {
    let params = new HttpParams();
    if (req.fromDate !== undefined) {
      params = params.set('fromDate', req.fromDate);
    }
    if (req.toDate !== undefined) {
      params = params.set('toDate', req.toDate);
    }
    if (req.role !== undefined) {
      params = params.set('role', req.role);
    }
    return this.http.get(this.API_Download,
      { observe: 'response', responseType: 'blob', params: params }
    ).pipe(map((resp: HttpResponse<Blob>) => {
      const out = { data: null, filename: null };
      out.data = resp.body;
      out.filename = 'Audit Logging Usage' + '.xls';
      return out;
    }));
  }
}
