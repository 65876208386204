<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon> dns</mat-icon>
        </div>
        <div mat-card-title>
            Score Card Templates
        </div>
        <div class="page-title-action">
            <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
                <mat-icon>add_circle</mat-icon>
                Create Score Card
            </button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <div>
            <!-- <mat-form-field>
              <input matInput name="name" [(ngModel)]="name" placeholder="Name">
          </mat-form-field>
          &nbsp; -->
            <!-- <mat-form-field>
              <mat-select name="code" [(ngModel)]="code" placeholder="Code">
                  <mat-option *ngFor="let questionnaireCode of questionnaireCodeList" [value]="questionnaireCode.code">{{questionnaireCode.code}}</mat-option>
              </mat-select>
          </mat-form-field> -->
            <mat-form-field>
                <input name="code" matInput type="text" [(ngModel)]="code" placeholder="Code" />
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <input name="description" matInput type="text" [(ngModel)]="description" placeholder="Description" />
            </mat-form-field>
            <!-- <mat-form-field>
                <mat-select name="status" [(ngModel)]="status" placeholder="Description">
                    <mat-option *ngFor="let questionnaireStatus of questionnaireStatusList"
                        [value]="questionnaireStatus">{{questionnaireStatus}}</mat-option>
                </mat-select>
            </mat-form-field> -->
            &nbsp;
            <button type="button" mat-raised-button color="primary" (click)="loadScorecard('')">
                <mat-icon>search</mat-icon>
            </button>
            &nbsp;
            <button type="button" mat-raised-button color="primary" (click)="clearFilter()">
                Clear
            </button>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="name" matSortDirection="asc" matSortDisableClear
                [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef> Code </th>
                    <td mat-cell *matCellDef="let element" data-label="Code"> {{element.code}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element" data-label="Description">{{element.description}}</td>
                </ng-container>

                <ng-container matColumnDef="validFrom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="validFrom"> Valid_from </th>
                    <td mat-cell *matCellDef="let element" data-label="ValidFrom">{{element.validFrom}}</td>
                </ng-container>

                <ng-container matColumnDef="validTo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="validTo"> Valid_to </th>
                    <td mat-cell *matCellDef="let element" data-label="ValidTo">{{element.validTo}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element" data-label="Status">
                        <mat-chip-list>
                            <mat-chip *ngIf="element.status === 'ACTIVE'" color="primary" selected>
                                {{element.status}}
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'INACTIVE'" color="warn" selected>
                                {{element.status}}
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'DRAFT'" color="accent" selected>
                                {{element.status}}
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- <button mat-icon-button matTooltip="View" [routerLink]="['view', element.id]">
                            <mat-icon>more</mat-icon>
                        </button> -->
                        <button mat-icon-button matTooltip="Edit" [routerLink]="['edit', element.id]">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Delete" (click)="deleteDialog(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Clone" (click)="clone(element.id)">
                            <mat-icon>file_copy</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>