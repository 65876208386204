import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatSort} from '@angular/material/sort';
import {AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';


import { BranchSetService } from '../branch-set.service';
import {  BranchSetListDataSource } from './branch-set-list.data.source';
import {  BranchSetDeleteDialogComponent } from './branch-set-delete-dialog.component';
import {PagingAndSortParams} from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import {ListSource} from 'projects/ig-core/src/lib/list/list-source';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BusinessLineDatamodel } from '../../branch/business-line.model';

@Component({
    selector: 'app-branch-set',
    templateUrl: 'branch-set-list.component.html',
    styleUrls: ['../branch-set.component.css']
})
export class  BranchSetListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource:  BranchSetListDataSource;
    listSource: ListSource;
    name: any;
    code: any;
    businessLines: BusinessLineDatamodel[];
    public branchsetListForm: FormGroup;

    displayedColumns = ['name', 'code','businessLineId', 'action'];

      @ViewChild(MatPaginator,{static:true})
    paginator: MatPaginator;

    @ViewChild(MatSort,{static:true})
    sort: MatSort;

    constructor(private branchSetService: BranchSetService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private formBuilder:FormBuilder,
                ) { }



    ngOnInit() {
      this.branchsetListForm = this.buildForm();
        this.dataSource = new BranchSetListDataSource(this.branchSetService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'id';
                }
                this.loadBranchSets(sortColumn);
            });
              this.branchSetService.getAllBusinessLines().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
                this.businessLines = businessLines;
              });
             
             this.loadBranchSets('');
    }

    buildForm() {
        return this.formBuilder.group({
            name:'',
            code:'',
            businessLineId:1
        });
      }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadBranchSetsPageWithParams());
    }

    loadBranchSetsPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

      loadBranchSets(sortColumn: String) {
        this.dataSource.loadBranchSets(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.branchsetListForm.value['name'] , this.branchsetListForm.value['code'], this.branchsetListForm.value['businessLineId']);
      }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(branchSetData: any) {
        const dialogRef = this.dialog.open(BranchSetDeleteDialogComponent, {
            data: branchSetData
        });
      dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
        if (result && result === 'deleted') {
          this.snackBar.open(`Deleted branch-set successfully`, 'Close');
          this.ngOnInit();
        }
      });
    }
    clearFilter() {
        this.branchsetListForm.get('name').setValue(undefined);
        this.branchsetListForm.get('code').setValue(undefined);
        this.branchsetListForm.get('businessLineId').setValue(1);
        this.search()
      }

      search(){
        this.pagingAndSortParams.pageIndex = 0
        this.paginator.pageIndex = 0
        this.loadBranchSets('id')
        this.loadBranchSetsPageWithParams()
    }

}
