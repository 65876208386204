import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AuditTransactionDetail } from "./audit-transaction-detail/audit-transaction-detail.model";
import { ScoreRisk } from "./audit-transaction-detail/score-risk.model";
import { AuditTransaction } from "./audit-transaction.model";
import { BranchGrade } from "./branch-grade.model";
import { ObservationResponseAction } from "./observation-response-action.model";
import { QuestionnaireResponseDetail } from "../../questionaire/questionnaire-response-detail.model";
import { AuditWorkFlowConfig } from "../audit-transaction/audit-transaction-detail/audit-workflow-config.model";
import { formatDate } from "@angular/common";
import { ObservationResponseRemarks } from "./observation-response-remark.model";
import { ObservationResponseFiles } from "./observation-response-files.model";
import { fileNameHeader } from "src/app/constants/data.constants";
import { AuditPlanCustom } from "./audit-transaction-detail/audit-plan-custom.model";


@Injectable({
  providedIn: "root",
})
export class AuditTransactionService {
  private API = "api/audit-transactions/";
  private auditTransactionStatus: string;
  constructor(private http: HttpClient) {}
  auditTransactionData(req: any): Observable<HttpResponse<AuditTransaction[]>> {
    let params = new HttpParams();
    params = params.set("page", req.page);
    params = params.set("size", req.size);
    params = params.set("sort", req.sort);
    if (req.branchCode) {
      params = params.set("branchCode", req.branchCode);
    }
    if (req.status) {
      params = params.set("status", req.status);
    }
    return this.http.get<AuditTransaction[]>(this.API, {
      params,
      observe: "response",
    });
  }

  getAuditTransactionsOnWorkflow(
    req: any
  ): Observable<HttpResponse<AuditTransaction[]>> {
    let params = new HttpParams();
    params = params.set("page", req.page);
    params = params.set("size", req.size);
    params = params.set("sort", req.sort);
    if (req.branchCode) {
      params = params.set("branchCode", req.branchCode);
    }
    if (req.tentativeFromDate) {
      const formattedFromDate = formatDate(
        req.tentativeFromDate,
        "yyyy-MM-dd",
        "en-us"
      );
      params = params.set("tentativeFromDate", formattedFromDate);
    }
    if (req.tentativeToDate) {
      const formattedToDate = formatDate(
        req.tentativeToDate,
        "yyyy-MM-dd",
        "en-us"
      );
      params = params.set("tentativeToDate", formattedToDate);
    }
    if (req.status) {
      params = params.set("status", req.status);
    }
    return this.http.get<AuditTransaction[]>(
      "api/getAuditTransactions/workflow",
      {
        params,
        observe: "response",
      }
    );
  }

  getAuditTransaction(id: number) {
    return this.http.get<AuditTransaction>(this.API + id);
  }

  deleteAuditTransactionData(id: number) {
    return this.http.delete(this.API + id);
  }

  downloadExcel(req: any): Observable<any> {
    let params = new HttpParams();
    if (req.branchCode) {
      params = params.set("branchCode", req.branchCode);
    }
    if (req.status) {
      params = params.set("status", req.status);
    }
    return this.http
      .get("api/_downloads/audit-transaction", {
        params: params,
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const out = { data: null, filename: null };
          out.data = resp.body;
          out.filename = "AuditTransaction.csv";
          return out;
        })
      );
  }
  // checklist
  getAuditTransactionDetails(
    auditTransactionId: number
  ): Observable<AuditTransactionDetail> {
    return this.http.get<AuditTransaction>(
      "api/audit-transaction-details/auditTransaction/" + auditTransactionId
    );
  }
  getAuditTransactionDetailsofAllResponse(
    auditTransactionId: number
  ): Observable<AuditTransactionDetail> {
    return this.http.get<AuditTransaction>(
      "api/audit-transaction-details/all-responses/" + auditTransactionId
    );
  }
  updateObservationResponseStatus(
    observationResponseAction: ObservationResponseAction
  ): Observable<Object> {
    return this.http.post(
      "api/add/audit-compliance-history",
      observationResponseAction
    );
  }
  getPossibleMarksForAuditTransactionDetail(
    auditTransactionId: number
  ): Observable<String> {
    return this.http.get<String>(
      "api/audit-transaction-details/get-possible-marks/" + auditTransactionId
    );
  }

  saveScoreAndRiskStatus(scoreRisk: ScoreRisk): Observable<Object> {
    return this.http.post(this.API + "update/scores-risk-status", scoreRisk);
  }

  updateCategoryScore(scoreRisk: ScoreRisk): Observable<Object> {
    return this.http.post(
      "api/audit-transaction-details/update/score",
      scoreRisk
    );
  }

  updateStatus(
    auditTransactionId: number,
    actionStauts: string,
    comment: string
  ): Observable<Object> {
    const auditTransaction = new AuditTransaction();
    auditTransaction.id = auditTransactionId;
    auditTransaction.actionStatus = actionStauts;
    auditTransaction.comment = comment;
    
    return this.http.post(
      "api/audit-transaction/update-status",
      auditTransaction
    );
  }
  getScoresAndGrade(
    auditTransactionId: any
  ): Observable<HttpResponse<BranchGrade>> {
    let params = new HttpParams();
    params = params.set("auditTransactionId", auditTransactionId);
    return this.http.get<BranchGrade>(
      "api/audit-transaction/show-scores-and-grades",
      {
        params,
        observe: "response",
      }
    );
  }
  getWorkflowConfigCodes() {
    return this.http.get<any>("api/audit-workflow-configs/getAllCodes", {
      observe: "response",
    });
  }
  getAuditTransactionDetailedInformation(
    auditTransactionDetailId: number
  ): Observable<AuditTransactionDetail> {
    return this.http.get<AuditTransactionDetail>(
      "api/audit-transaction-details/all-responses/" + auditTransactionDetailId
    );
  }
  updateQuestionaireObservation(
    questionaireResponseDetail: QuestionnaireResponseDetail
  ): Observable<Object> {
    return this.http.put(
      "api/questionaire-update-observation",
      questionaireResponseDetail
    );
  }
  getDetailedInfo(
    auditTransactionId: number
  ): Observable<AuditTransactionDetail[]> {
    return this.http.get<AuditTransactionDetail[]>(
      "api/audit-transaction-details/list/" + auditTransactionId
    );
  }
  setAuditTransactionStatus(status: string) {
    this.auditTransactionStatus = status;
  }
  getAuditTransactionStatus() {
    return this.auditTransactionStatus;
  }
  getWorkflowConfigForAuditTransactionStatus(
    auditTransactionId: number
  ): Observable<AuditWorkFlowConfig[]> {
    return this.http.get<AuditWorkFlowConfig[]>(
      "api/audit-transaction/get-audit-workflow-config/" + auditTransactionId
    );
  }
  updateTransactionRemarks(auditTransactionDTO: AuditTransaction) {
    return this.http.put("api/audit-transactions", auditTransactionDTO);
  }
  getDetailedInfos(
    req: any,
    auditTransactionId: number
  ): Observable<HttpResponse<AuditTransactionDetail[]>> {
    let params = new HttpParams();
    params = params.set("page", req.page);
    params = params.set("size", req.size);
    params = params.set("sort", req.sort);
    return this.http.get<AuditTransactionDetail[]>(
      "api/audit-transaction-details/list/" + auditTransactionId,
      {
        params,
        observe: "response",
      }
    );
  }
  getAllObservationResponseFilesByRemarkIdFileId() {
    return this.http.get<AuditTransactionDetail[]>(
      "api/observation-response-remarks/observation-response-files"
    );
  }

  updateRemarks(
    observationResponseRemarks: ObservationResponseRemarks
  ): Observable<HttpResponse<ObservationResponseRemarks>> {
    const copy: ObservationResponseRemarks = Object.assign(
      {},
      observationResponseRemarks
    );
    if (copy.id && copy.id != null) {
      return this.http.put<ObservationResponseRemarks>(
        "api/observation-response-remarks/update",
        copy,
        { observe: "response" }
      );
    } else {
      return this.http.post<ObservationResponseRemarks>(
        "api/observation-response-remarks/create",
        copy,
        { observe: "response" }
      );
    }
  }

  getRemarkFiles(
    id: number,
    observationResponseDetailId: number
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params.set("observationResponseRemarkId", id);
    params.set("observationResponseDetailId", observationResponseDetailId);
    return this.http.get<any>(
      "api/observation-response-remarks/observation-response-files",
      { params: params, observe: "response" }
    );
  }

  deleteFile(id: number) {
    return this.http.delete(`api/observation-response-files/delete/${id}`);
  }
  downloadFile(id: number): Observable<any> {
    return this.http
      .get(`api/file/stream/${id}`, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const out = { data: null, filename: null };
          out.data = resp.body;
          let fileName: string = resp.headers.get(fileNameHeader);
          out.filename = fileName;
          return out;
        })
      );
  }

  saveFiles(
    multipartFiles: any,
    observationResponseRemark,
    observationResponseDetailId
  ): Observable<HttpResponse<String>> {
    let params = new HttpParams();
    if (observationResponseRemark.id) {
      params = params.set(
        "observationResponseRemarkId",
        observationResponseRemark.id
      );
    }
    if (observationResponseDetailId) {
      params = params.set(
        "observationResponseDetailsId",
        observationResponseDetailId
      );
    }
    if (observationResponseRemark.description) {
      params = params.set("remark", observationResponseRemark.description);
    }
    if (observationResponseRemark.remark) {
      params = params.set("remark", observationResponseRemark.remark);
    }

    const formData = new FormData();
    for (let i = 0; i < multipartFiles.length; i++) {
      formData.append(`files`, multipartFiles[i], multipartFiles[i].name);
    }
    return this.http.post<String>(
      "api/observation-response-files/file/upload",
      formData,
      { observe: "response", params: params, responseType: "text" as "json" }
    );
  }

  saveAudit(closeAudit): Observable<HttpResponse<string>> {
    let params = new HttpParams();
  
    if (closeAudit.id) { 
      params = params.set('id', closeAudit.id);
    }
  
    if (closeAudit.commencedDate) {
      params = params.set('commencementDate', closeAudit.commencedDate);
    }
  
    if (closeAudit.finishedDate) {
      params = params.set('finishedDate', closeAudit.finishedDate);
    }
  
    if (closeAudit.remarks) {
      params = params.set('remarks', closeAudit.remarks);
    }
  
    if (closeAudit.status) {
      params = params.set('status', closeAudit.status);
    }
    
    return this.http.post(
      `api/audit-transaction/submit/${closeAudit.id}`,
      params,
      { observe: 'response', responseType: 'text' }
    );
  }

displayTeamLead(auditTransactionId: number,){
   return this.http.get<AuditPlanCustom>(`api/audit-transaction/get-audit-plan/${auditTransactionId}` ,{});
}
    isDisable:boolean;
    setDiasble(isDisable){
        this.isDisable=isDisable;
    }
    getDisable(){
        return this.isDisable;
    }

    getAuditTransactionDetailList(auditTransactionId: number): Observable<AuditTransactionDetail[]> {
        return this.http.get<AuditTransactionDetail[]>('api/audit-transaction-details/all/' + auditTransactionId);
    }
}
