<mat-card>
  <mat-card-header>
      <mat-card-title align="left"> Are you sure you want to Change Remarks?</mat-card-title>
  </mat-card-header>
<mat-card-content style="padding:0px 15px 0px 15px;">
  <!--<mat-form-field style="width:100%">
    <input matInput [(ngModel)]="data.element.remarks">
  </mat-form-field>-->
  <mat-form-field style="width:100%">
    <textarea matInput [(ngModel)]="data.element.remarks"></textarea>
  </mat-form-field>
</mat-card-content>
<mat-card-actions style="padding-left:15px;">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onSubmit(data)" cdkFocusInitial>Save</button>
</mat-card-actions>
</mat-card>