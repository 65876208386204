export class CihReportParms {
    constructor(
        public type?: string,
        public branchCodes?: string[],
        public fromDate?: any,
        public toDate?: any,
        public month?: any,
        public year?: any,
        public statuses?: string[]

    ) { }
}
