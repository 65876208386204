import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuditTransaction } from './audit-transaction.model';
import { AuditTransactionService } from './audit-transaction-service';
import { AuditTransactionDetail } from './audit-transaction-detail/audit-transaction-detail.model';
import { PrincipalService } from 'projects/ig-core/src/lib/authentication/principal.service';
import { LoggedInUserService } from 'projects/ig-core/src/lib/authentication/logged-in-user.service';

@Injectable({
    providedIn: 'root',
})
export class AuditTransactionResolveService implements Resolve<AuditTransaction> {
    constructor(private cs: AuditTransactionService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AuditTransaction> | Observable<never> {
        return this.cs.getAuditTransaction(+route.paramMap.get('id'));
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuditTransactionWorkFlowResolveService implements Resolve<AuditTransaction> {
    constructor(private cs: AuditTransactionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<AuditTransaction> | Observable<never> {
        // return this.cs.getAuditTransactionsOnWorkflow();
        return null;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuditTransactionDetailResolveService implements Resolve<AuditTransactionDetail> {
    constructor(private cs: AuditTransactionService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AuditTransactionDetail> | Observable<never> {
        return this.cs.getAuditTransactionDetails(+route.paramMap.get('id'));
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuditTransactionDetailAllResponseResolveService implements Resolve<AuditTransactionDetail> {
    constructor(private cs: AuditTransactionService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AuditTransactionDetail> | Observable<never> {
        return this.cs.getAuditTransactionDetailsofAllResponse(+route.paramMap.get('id'));
    }
}


@Injectable({
    providedIn: 'root',
})
export class CheckListDetailService {

    private auditTransactionDetail: AuditTransactionDetail;

    public setAuditTransactionDetail(auditTransactionDetail: AuditTransactionDetail) {
        this.auditTransactionDetail = auditTransactionDetail;
    }

    public getAuditTransactionDetail() {
        return this.auditTransactionDetail;
    }

}

@Injectable({
    providedIn: 'root',
})
export class LoggedInUserResolveService implements Resolve<any> {
    constructor(private cs: LoggedInUserService, private router: Router) { }

    resolve(): Observable<any> {
        return this.cs.get();
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuditWorkFlowConfigCodesResolveService implements Resolve<any> {
    constructor(private cs: AuditTransactionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Observable<never> {
        return this.cs.getWorkflowConfigCodes();
    }
}


