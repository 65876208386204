import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../admin/user/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ReportService} from '../reports/report.service';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'app-crm-file-upload',
    templateUrl: './employee-file-upload.html',
})
export class EmployeeFileUploadComponent implements OnInit, OnDestroy, AfterViewInit {
    file: File;
    message: string;
    fileUploader: any;

    constructor(private router: Router, private userService: UserService,
                private snackBar: MatSnackBar, private reportService: ReportService,
    ) {
    }


    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    @ViewChild('fileUploader',{static:false})
    myInputVariable: ElementRef;

    onFileSelection(event: any) {
        this.file = event.target.files[0];
        this.myInputVariable.nativeElement.value = '';
    }


    onSubmit() {
        if (this.file === undefined) {
            this.message = 'Please select a file';
        } else {
            this.reportService.uploadEmployeeLeaveDetailFile(this.file).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        }
    }

    downloadTemplate() {
        this.reportService.downloadEmployeeLeaveDetailTemplate()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
            this.message = error;
        }, () => {
            console.log('Completed file download.');
        });
    }


    private onSubmitSuccess(response) {
        this.snackBar.open(`Updated User BranchSet ${response.body} successfully`, 'Close', {
            duration: 3000,
        });
        this.message = response.body;
    }

    private onSubmitError(response) {
        this.snackBar.open(`Failed Updated User BranchSet ${response.error} `, 'Close', {
            duration: 3000,
        });
        this.message = response.error.text;

    }


}
