<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon> score</mat-icon>
    </div>
    <mat-card-title class="button-icon-text">Score Card</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <p>Please generate the Report data by selecting the Score Card Template, and then Respective Branch.</p>
    <form [formGroup]="scoreCardForm" >
      <div style="width: 50%; margin: 2%;">
        <ng-multiselect-dropdown formControlName="mainModule" [placeholder]="'Scorecard Templates'"
          [data]="scoreCardTemplates" [(ngModel)]="selectedMain" [settings]="mainDropdownSettings">
        </ng-multiselect-dropdown>
        <mat-error align="start" *ngIf="formErrors.mainModule">{{ formErrors.mainModule }}</mat-error>
      </div>

      <div>
        <mat-form-field style="width: 25%; margin: 2%;">
          <input matInput name="fromDateFilter" formControlName="fromDateFilter" [max]="toDateFilter"
            (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter"
            [matDatepicker]="fromDatePicker" placeholder="FromDate" required>
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter }}
          </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 25%; margin: 2%">
          <input matInput name="toDateFilter" formControlName="toDateFilter" [min]="fromDateFilter"
            (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
            [matDatepicker]="toDatePicker" placeholder="ToDate" required>
          <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter }}</mat-error>
        </mat-form-field>
      </div>
      <mat-card-actions class="pull-right">
        <button style="text-align:center" mat-raised-button small color="primary" 
            (click)="downloadAllScoreCardReport()">Download Scoring Report All Branches</button>
        <button mat-raised-button color="primary" (click)="calculatedScore('YES')">Start Score
          Analysis</button>
        <button mat-raised-button color="accent" type="submit" (click)="calculatedScore('NO')">Get Score
          Analysis</button>
        <mat-accordion class="example-headers-align" style="display: flex; flex-direction:column; margin-left: 2%;">
          <mat-expansion-panel class="mat-elevation-z0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Recent Analysis :
                <mat-icon class="material-icons" (click)="getLatestSyncTransaction()">restore</mat-icon>
              </mat-panel-title>
              <mat-panel-description [ngClass]="message">STATUS: {{message}}</mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngIf="syncSchedularLog.reason && syncSchedularLog.status" >{{syncSchedularLog.reason}} & {{syncSchedularLog.status}}: {{syncSchedularLog.updatedCount}}, On
              {{syncSchedularLog.createdDate}}
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-actions>
    </form>
  </mat-card-content>
  <mat-card-content>
    <form [formGroup]="scoreCardForm" novalidate>
      <div style="width: 50%; margin: 2%;">
        <ng-multiselect-dropdown formControlName="branchSelect" [placeholder]="'Branches'" [data]="branches"
          [(ngModel)]="selectedBranch" [settings]="branchdropdownSettings">
        </ng-multiselect-dropdown>
      </div>
      <div>
        <mat-card-actions class="pull-right">
          <button style="text-align:center" mat-raised-button small color="primary"
            (click)="generateTheReport()">Generate Scoring Data</button>
          <button style="text-align:center" mat-raised-button small mat-button [matMenuTriggerFor]="belowMenu"
            color="accent">Download Scoring Data</button>
          <mat-menu #belowMenu="matMenu">
            <button mat-menu-item (click)="downloadScorecardReport('PDF')">PDF</button>
            <button mat-menu-item (click)="downloadScorecardReport('EXCEL')">EXCEL</button>
          </mat-menu>
        </mat-card-actions>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">table_chart</mat-icon>
          Over Views
        </ng-template>
        <br>
        <mat-grid-list cols="2" rowHeight="25rem">
          <mat-grid-tile [colspan]="2">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Department Level</b></caption>
                <table mat-table matSort [dataSource]="departmentLevelData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Department</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="obtainedMarks">Score Achieved</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{ element.obtainedMarks }} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator3 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Sub Module Level</b></caption>
                <table mat-table matSort [dataSource]="submoduleLevelData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> SubModule</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{element.reportCount === null ? '' : element.reportCount}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg. Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator4 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Task Activity Level</b></caption>
                <table mat-table matSort [dataSource]="tasksubmoduleLevelData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Task</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{element.reportCount === null ? '' : element.reportCount}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg. Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator5 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>State Level</b></caption>
                <table mat-table [dataSource]="stateLevelData" matSort>
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> State</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{element.reportCount === null ? '' : element.reportCount}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg. Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator6 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Branch Level</b></caption>
                <table mat-table [dataSource]="branchLevelData" matSort>
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Branch</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{element.reportCount === null ? '' : element.reportCount}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg. Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator7 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Centre Level</b></caption>
                <table mat-table [dataSource]="centreLevelData" matSort>
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Centre</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{element.reportCount === null ? '' : element.reportCount}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg. Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator8 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Role Level</b></caption>
                <table mat-table [dataSource]="roleLevelData" matSort>
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Role</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{element.reportCount === null ? '' : element.reportCount}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg. Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator9 [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>General Level Task Counts</b></caption>
                <table mat-table matSort [dataSource]="allTaskVisitNumber">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Type</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{element.reportType === null ? '' : element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef> Task</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{element.reportModule === null ? '' : element.reportModule}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportSubModule">
                    <th mat-header-cell *matHeaderCellDef> Activity</th>
                    <td mat-cell *matCellDef="let element" data-label="reportSubModule">
                      {{element.reportSubModule === null ? '' : element.reportSubModule}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Visit Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedAllTaskColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedAllTaskColumns;"></tr>
                </table>
                <mat-paginator #tableDataPaginator10 (page)="getUpdate(10,$event)" [pageSize]="4">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">bar_chart</mat-icon>
          Graphs Data
        </ng-template>
        <br>
        <mat-grid-list cols="1" rowHeight="31rem">
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content>
                <caption class="caption"><b>Graphical Data</b></caption>
                <mat-button-toggle-group appearance="standard" (change)="graphChange($event)">
                  <mat-button-toggle value="1"><small>All Data</small></mat-button-toggle>
                  <mat-button-toggle value="2"><small>Year Wise</small></mat-button-toggle>
                  <mat-button-toggle value="3"><small>Month Wise</small></mat-button-toggle>
                  <mat-button-toggle value="4"><small>Week Wise</small></mat-button-toggle>
                  <mat-button-toggle value="5"><small>Day Wise</small></mat-button-toggle>
                </mat-button-toggle-group>
                <div>
                  <div style="display: block">
                    <canvas style="height: 20rem;width:45rem" baseChart [datasets]="chartDatasets"
                      [labels]="chartLabelsets" [options]="barChartOptions" [chartType]="barChartType"
                      (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                  </div>
                  <mat-card-actions class="pull-right">
                    <button mat-raised-button small color="primary" (click)="randomize()">Switch Graph</button>
                  </mat-card-actions>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">view_column</mat-icon>
          Tabular Data
        </ng-template>
        <br>
        <mat-grid-list cols="1" rowHeight="45rem">
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <div [hidden]="!isTabularMain">
                  <mat-form-field style="width:100%; text-align: right">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                  <caption class="caption">Each Task Performance</caption>
                  <table mat-table matSort [dataSource]="tableDataSets">
                    <ng-container matColumnDef="reportModule">
                      <th mat-header-cell *matHeaderCellDef> Module</th>
                      <td mat-cell *matCellDef="let element" data-label="reportModule">
                        {{element.reportModule === null ? '' : element.reportModule}} </td>
                    </ng-container>

                    <ng-container matColumnDef="reportSubModule">
                      <th mat-header-cell *matHeaderCellDef> SubModule</th>
                      <td mat-cell *matCellDef="let element" data-label="reportSubModule">
                        {{element.reportSubModule === null ? '' : element.reportSubModule}} </td>
                    </ng-container>

                    <ng-container matColumnDef="submittedOn">
                      <th mat-header-cell *matHeaderCellDef> Date</th>
                      <td mat-cell *matCellDef="let element" data-label="submittedOn">
                        {{element.submittedOn === null ? '' : element.submittedOn}} </td>
                    </ng-container>

                    <ng-container matColumnDef="branchName">
                      <th mat-header-cell *matHeaderCellDef> Branch Name</th>
                      <td mat-cell *matCellDef="let element" data-label="branchName" matTooltip={{element.branchName}}>
                        {{element.branchName === null ? '' : element.branchName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="stateName">
                      <th mat-header-cell *matHeaderCellDef> State Name</th>
                      <td mat-cell *matCellDef="let element" data-label="stateName" matTooltip={{element.stateName}}>
                        {{element.stateName === null ? '' : element.stateName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="obtainedMarks">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="obtainedMarks"> Score</th>
                      <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                        {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef style="width:5%;"> </th>
                      <td mat-cell *matCellDef="let element" style="width:5%;">
                        <button mat-icon-button matTooltip="View" (click)="getTaskTableDate(element)">
                          <mat-icon>more</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedTTabular"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedTTabular;"></tr>
                  </table>
                  <mat-paginator #tableDataPaginator1 [length]="100" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]">
                  </mat-paginator>
                </div>
                <div [hidden]="isTabularMain">
                  <div mat-card-avatar class="button-icon">
                    <button mat-raised-button (click)="getTaskTableDate(isTabularMain)">
                      <mat-icon>arrow_back</mat-icon> Back
                    </button>
                  </div>
                  <caption class="caption">Each Questions Performance Related to Task</caption>
                  <table mat-table matSort [dataSource]="tableTaskDataSets">
                    <ng-container matColumnDef="branchCode">
                      <th mat-header-cell *matHeaderCellDef> Type</th>
                      <td mat-cell *matCellDef="let element" data-label="branchCode">
                        {{element.branchCode === null ? '' : element.branchCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="stateName">
                      <th mat-header-cell *matHeaderCellDef> Category</th>
                      <td mat-cell *matCellDef="let element" data-label="stateName">
                        {{element.stateName === null ? '' : element.stateName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="branchName">
                      <th mat-header-cell *matHeaderCellDef> Question</th>
                      <td mat-cell *matCellDef="let element" data-label="branchName">
                        {{element.branchName === null ? '' : element.branchName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="reportType">
                      <th mat-header-cell *matHeaderCellDef> Answer</th>
                      <td mat-cell *matCellDef="let element" data-label="reportType" matTooltip={{element.reportType}}>
                        {{element.reportType === null ? '' : element.reportType}} </td>
                    </ng-container>

                    <ng-container matColumnDef="obtainedMarks">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="obtainedMarks"> Score</th>
                      <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                        {{element.obtainedMarks === null ? '' : element.obtainedMarks}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th [ngClass]="'w-10'" mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="View" (click)="setPopupDialogData(element)">
                          <mat-icon>more</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedQTabular"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedQTabular;"></tr>
                  </table>
                  <mat-paginator #tableDataPaginator2 [length]="100" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]">
                  </mat-paginator>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">view_quilt</mat-icon>
          Branch Scorecard
        </ng-template>
        <br>
        <mat-grid-list cols="4" rowHeight="8rem">
          <mat-grid-tile [colspan]="4">
            <mat-card class="dashboard-card mat-elevation-z6">
              <mat-card-title-group>
                <mat-card-subtitle>Branch Info</mat-card-subtitle>
                <mat-card-title>{{ totalBranchsScoreData.branchName }}, {{ totalBranchsScoreData.stateName }}
                </mat-card-title>
                <img mat-card-sm-image src="assets/additional-icons/branch_icon.png">
              </mat-card-title-group>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card mat-elevation-z6">
              <mat-card-title-group>
                <mat-card-subtitle>Score</mat-card-subtitle>
                <mat-card-title>{{ totalBranchsScoreData.obtainedMarks }}</mat-card-title>
                <img mat-card-sm-image src="assets/additional-icons/scoreboard_icon.jpg">
              </mat-card-title-group>
              <mat-card-footer>
                <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
              </mat-card-footer>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card mat-elevation-z6">
              <mat-card-title-group>
                <mat-card-subtitle>Tasks</mat-card-subtitle>
                <mat-card-title>{{ totalBranchsScoreData.reportCount }}</mat-card-title>
                <img mat-card-sm-image src="assets/additional-icons/task_icon.png">
              </mat-card-title-group>
              <mat-card-footer>
                <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
              </mat-card-footer>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card mat-elevation-z6">
              <mat-card-title-group>
                <mat-card-subtitle>Visits</mat-card-subtitle>
                <mat-card-title>{{ totalBranchsScoreData.visitCount }}</mat-card-title>
                <img mat-card-sm-image src="assets/additional-icons/mobilevisit_icon.png">
              </mat-card-title-group>
              <mat-card-footer>
                <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
              </mat-card-footer>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card mat-elevation-z6">
              <mat-card-title-group>
                <mat-card-subtitle>Users</mat-card-subtitle>
                <mat-card-title>{{ totalBranchsScoreData.userCount }}</mat-card-title>
                <img mat-card-sm-image src="assets/additional-icons/users_icon.png">
              </mat-card-title-group>
              <mat-card-footer>
                <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
              </mat-card-footer>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="30rem">
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-subtitle>Users wise Info</mat-card-subtitle>
              <mat-card-content class="dashboard-card-content dashboard-card-table-content">
                <table mat-table matSort [dataSource]="roleNameCountData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Role</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportModule"> Users</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{ element.reportModule }} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['reportType', 'reportCount', 'reportModule']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['reportType', 'reportCount', 'reportModule'];"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-subtitle>Department wise Info</mat-card-subtitle>
              <mat-card-content class="dashboard-card-content dashboard-card-table-content">
                <table mat-table matSort [dataSource]="departmentLevelData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Department</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="weightage">
                    <th mat-header-cell *matHeaderCellDef>weightage Avg Assigned</th>
                    <td mat-cell *matCellDef="let element" data-label="weightage">
                      {{ element.weightage}} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="obtainedMarks">Score</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{ element.obtainedMarks }} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['reportType', 'obtainedMarks','weightage']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['reportType', 'obtainedMarks','weightage'];"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-subtitle>Total Activity Count Info</mat-card-subtitle>
              <mat-card-content class="dashboard-card-content dashboard-card-table-content">
                <table mat-table matSort [dataSource]="allTaskVisitNumberData">
                  <ng-container matColumnDef="reportSubModule">
                    <th mat-header-cell *matHeaderCellDef> Task</th>
                    <td mat-cell *matCellDef="let element" data-label="reportSubModule">
                      {{ element.reportSubModule}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['reportSubModule', 'reportCount']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['reportSubModule', 'reportCount'];"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-subtitle>Total Task Count Info</mat-card-subtitle>
              <mat-card-content class="dashboard-card-content dashboard-card-table-content">
                <table mat-table matSort [dataSource]="branchVisitNumberData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Role</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Count</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef> Module</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{ element.reportModule}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportSubModule">
                    <th mat-header-cell *matHeaderCellDef> SubModule</th>
                    <td mat-cell *matCellDef="let element" data-label="reportSubModule">
                      {{ element.reportSubModule}} </td>
                  </ng-container>
                  <tr mat-header-row
                    *matHeaderRowDef="['reportType', 'reportCount', 'reportModule', 'reportSubModule']"></tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['reportType', 'reportCount', 'reportModule', 'reportSubModule'];">
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <mat-card class="dashboard-card">
              <mat-card-content>
                <mat-button-toggle-group class="allign-centre" appearance="standard"
                (change)="branchGraphsTabChange($event)">
                <mat-button-toggle value="0"><small>Branch Score Trend Performance</small></mat-button-toggle>
                <mat-button-toggle value="1"><small>Branch Score Trend Compared to Division Performance</small> </mat-button-toggle>
                <mat-button-toggle value="2"><small>Branch Score Trend Compared to State Performance</small> </mat-button-toggle>
              </mat-button-toggle-group>
                <div style="display: block">
                  <canvas style="height: 20rem;width:45rem" baseChart [datasets]="branchDatasets"
                    [labels]="branchLabelsets" [options]="barChartOptions" [chartType]="barChartType"
                    [colors]="branchBackgroundColor" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)">
                  </canvas>
                </div>
                <mat-card-actions class="pull-right">
                  <button mat-raised-button small color="primary" (click)="randomize()">Switch Graph</button> 
                </mat-card-actions>    
                     <mat-checkbox *ngIf="showMe" (change)="onDivisionAuditChange($event.checked)" [checked]="isAudit" class="pull-right" color="primary" > 
                      Check box for Audit</mat-checkbox>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-subtitle>Top 5 highest reason which contribute to the score</mat-card-subtitle>
              <mat-card-content class="dashboard-card-content dashboard-card-table-content">
                <table mat-table matSort [dataSource]="highestData">
                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef> Department</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{ element.reportModule}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportType"> Reason</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType }} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg Score Obtained</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{ element.obtainedMarks}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Occurrence</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['reportModule', 'reportType', 'obtainedMarks', 'reportCount']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['reportModule', 'reportType', 'obtainedMarks', 'reportCount'];"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-subtitle>Top 5 least reason which contribute to the score</mat-card-subtitle>
              <mat-card-content class="dashboard-card-content dashboard-card-table-content">
                <table mat-table matSort [dataSource]="lowestData">
                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef> Department</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{ element.reportModule}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportType"> Reason</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType }} </td>
                  </ng-container>

                  <ng-container matColumnDef="obtainedMarks">
                    <th mat-header-cell *matHeaderCellDef> Avg Score Obtained</th>
                    <td mat-cell *matCellDef="let element" data-label="obtainedMarks">
                      {{ element.obtainedMarks}} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reportCount"> Occurrence</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['reportModule', 'reportType', 'obtainedMarks', 'reportCount']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['reportModule', 'reportType', 'obtainedMarks', 'reportCount'];"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">picture_as_pdf</mat-icon>
          Others Dashboard
        </ng-template>
        <br>
        <mat-grid-list cols="2" rowHeight="15rem">
          <mat-grid-tile [colspan]="2">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Center visit details of the branch</b></caption>
                <table class="specific-table-center" mat-table matSort [dataSource]="centreVisitNumberData">
                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef>Active+ In Active</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType }} </td>
                  </ng-container>

                  <ng-container matColumnDef="branchName">
                    <th mat-header-cell *matHeaderCellDef>Active Only</th>
                    <td mat-cell *matCellDef="let element" data-label="branchName">
                      {{ element.branchName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef> Total no. of unique visits done as per MAVRC                      
                    </th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef>Total no. of unique visits done as per Trucell</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{ element.reportModule }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportSubModule">
                    <th mat-header-cell *matHeaderCellDef>Total no. of unique visits done as per Spotways</th>
                    <td mat-cell *matCellDef="let element" data-label="reportSubModule">
                      {{ element.reportSubModule }} </td>
                  </ng-container>

                  <ng-container matColumnDef="stateName">
                    <th mat-header-cell *matHeaderCellDef>Total Count of Non-visited centers for selected Time period (active only -Sum of MAVRC/TRUCELL/SPOTWAYS visits)
                    </th>
                    <td mat-cell *matCellDef="let element" data-label="stateName">
                      {{ element.stateName }} </td>
                  </ng-container>

                  <!-- Header row first group -->
                  <ng-container matColumnDef="header-row-first-group">
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="2">
                      Total No. of Center in the Branch
                    </th>
                  </ng-container>

                  <!-- Header row second group -->
                  <ng-container matColumnDef="header-row-second-group">
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4">
                      Visit Details
                    </th>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 
                  'header-row-second-group']"></tr>
                  <tr mat-header-row *matHeaderRowDef="displayedCentreColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedCentreColumns;"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="15rem">
          <mat-grid-tile [colspan]="2">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <caption class="caption"><b>Branch Assessment Details</b></caption>
                <table class="specific-table-center" mat-table matSort [dataSource]="branchAssessmentNumberData">
                  <ng-container matColumnDef="reportModule">
                    <th mat-header-cell *matHeaderCellDef> Name</th>
                    <td mat-cell *matCellDef="let element" data-label="reportModule">
                      {{ element.reportModule }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef> Total No. of Employees</th>
                    <td mat-cell *matCellDef="let element" data-label="reportType">
                      {{ element.reportType }} </td>
                  </ng-container>

                  <ng-container matColumnDef="reportCount">
                    <th mat-header-cell *matHeaderCellDef> Total No. of Curricular</th>
                    <td mat-cell *matCellDef="let element" data-label="reportCount">
                      {{ element.reportCount }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedBranchColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedBranchColumns;"></tr>
                </table>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="20rem">
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content>
                <caption class="caption"><b>Non Centre Visits Detailed Report</b></caption>
                <br><br>Also, Users can download from below :<br><br>
                Please Go to <b>Menu: Report dashboard -> Sub Menu: Deviation Reports</b> and Select the Type Called
                Non Centre Visit Detailed Report and Select Branch and Download the Report
              </mat-card-content>
              <mat-card-actions class="pull-right">
                <button mat-stroked-button color="primary" (click)="goToReportDownloadPage()">Redirect To Deviation
                  Reports<mat-icon>open_in_new</mat-icon></button>
                <button mat-raised-button color="accent"
                  (click)="downloadAllCommonDetailedReport('NonCentreVisitsDetailedReport')">Download</button>
              </mat-card-actions>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-card class="dashboard-card">
              <mat-card-content>
                <caption class="caption"><b>Employee Assessment Detailed Report</b></caption>
                <br><br>Also, Users can download from below :<br><br>
                Please Go to <b>Menu: Report dashboard -> Sub Menu: Deviation Reports</b> and Select the Type Called
                Employee Assessment Detailed Report and Select Branch and Download the Report
              </mat-card-content>
              <mat-card-actions class="pull-right">
                <button mat-stroked-button color="primary" (click)="goToReportDownloadPage()">Redirect To Deviation
                  Reports <mat-icon>open_in_new</mat-icon></button>
                <button mat-raised-button color="accent"
                  (click)="downloadAllCommonDetailedReport('BranchStaffAssessmentDetails')">Download</button>
              </mat-card-actions>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
<ng-template #myDialog>
  <h2 matDialogTitle>Calculation Part in Ground Level</h2>
  <mat-dialog-content style="overflow-x: hidden; width: 95%; overflow-y: auto;">
    <div>
      <textarea readonly cols="80">{{popupData.branchCode}}</textarea>
      <textarea readonly rows="8" cols="80">{{popupData.reportModule}}</textarea>
      <textarea readonly cols="80">{{popupData.reportType}}</textarea>
      <textarea readonly cols="80">{{popupData.reportSubModule}}</textarea>
      <textarea readonly cols="80">{{popupData.obtainedMarks}}</textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Dismiss</button>
  </mat-dialog-actions>
</ng-template>