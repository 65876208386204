import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { ReportService } from '../reports/report.service';
import {FetchBranchesForStates} from '../../constants/data.constants';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-hr-report',
  templateUrl: './hr-report.component.html',
  styleUrls: ['./hr-report.component.css']
})
export class HrReportComponent implements OnInit, OnDestroy {

  stateDropdownList: any[];
  selectedStates: any[];
  selectedBranches: any[];
  stateList: string[];
  branches: Branch[];
  dropdownList: any[];
  states: any;
  fromDateFilter: string;
  toDateFilter: string;
  type: string;
  codes: any[];
  public hrReportForm: FormGroup;
  stateDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  businessLines: any[];
  selectedBusinessLine: any;

  @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
  @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

  @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
  @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;

  public formError: FormErrorModel;
  public formErrors = {
    type: '',
    selectedStates: '',
    selectedBranches: '',
    fromDateFilter: '',
    toDateFilter: '',
    stateName:'',
    name:'',
    businessLineId:''
  };

  hrReportList = ['ECQ', 'HBVQ'];
  businessLine: any;
  isRoleAdmin:boolean;
  loggedInUserRole: any;
  
  constructor(private questionaireService: QuestionaireService,
    private reportService: ReportService, private igFormService: IgFormService,
    private userService: UserService,
    private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
          this.loggedInUserRole = data.loggedInUser.body.role;
        });
    }

  ngOnInit(): void {
    this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
      this.businessLines = businessLines;
  });
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.hrReportForm.controls['businessLineId'].disable();
    if (this.loggedInUserRole ==='ROLE_ADMIN') {
        this.isRoleAdmin = true;
        this.hrReportForm.controls['businessLineId'].enable();
    }else{  this.loadStates();
    }
  }
  ngOnDestroy(): void {
  }

  onItemSelect(event){}

  onSelectAll(event){}

  onSelectState(state: any) {
    this.selectedStates.push(state);
    this.loadBranches();
  }
  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.hrReportForm);
    if (this.hrReportForm.valid) {
      this.downloadHrReport();
    } else {
      this.formErrors = this.igFormService.validateForm(this.hrReportForm, this.formErrors, false);
      return false;
    }
  }

  buildForm() {
    this.hrReportForm = this.formBuilder.group({
      type: [this.type, Validators.required],
      stateName: [this.selectedStates, Validators.required],
      name: [this.branches, Validators.required],
      fromDateFilter: [this.fromDateFilter, Validators.required],
      toDateFilter: [this.toDateFilter, Validators.required],
      businessLineId:['',Validators.required]
    });
    this.hrReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.hrReportForm, this.formErrors, true);
    });
  }

  loadBranches() {
    this.selectedBranches=null;
    console.log(this.selectedStates);
    this.stateList = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.stateList.push(this.selectedStates[i].name);
    }
    this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<Branch[]>) => {
          this.branches = res.body;
          this.dropdownList = this.branches;
        
        });
  }
  
  loadStates() {
    this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.states = res.body;
          this.stateDropdownList = this.states;
        
        }
      );
  }

  downloadHrReport() {
    this.codes = [];
    this.branches.forEach((value) => {
      this.codes.push(value.code);
    });

    this.reportService.downloadHrReport({
      type: this.type,
      branchCodes: this.codes,
      fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      businessLineId: this.hrReportForm.controls['businessLineId'].value
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  onItemDeSelectBranch(item: any) {
  }
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();
  }

  clearFilter() {
    // this.type = '';
    // this.selectedBranches = [];
    // this.selectedStates = [];
    // this.fromDateFilter = '';
    // this.toDateFilter = '';
    // this.selectedBusinessLine = '';
    this.hrReportForm.reset();
    if(this.businessLine !== undefined){
      this.stateDropdownList = [];
    }
    this.dropdownList=[];
  }

  changeBusinessLine(selectedBusniessLine: any){
    this.selectedStates=null;
    this.businessLine = selectedBusniessLine.value;
    this.loadStates();
  }
}

