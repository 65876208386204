
  <mat-card-header>
    <mat-card-title>
      JVM Memory
      <button mat-icon-button class="more-button" [matMenuTriggerFor]="jvmMemoryMenu" aria-label="Toggle menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #jvmMemoryMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="changeChartType(chartData, 'pie')">PIE</button>
        <button mat-menu-item (click)="changeChartType(chartData, 'doughnut')">Doughnut</button>
      </mat-menu>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <h3>Total {{totalMemory}}
      <mat-form-field class="physical-memory-unit-div">
        <mat-select (selectionChange)="updateChartData($event, chartData)" [(value)]="memoryUnit">
          <mat-option *ngFor="let physicalMemoryUnit of objectKeys(physicalMemoryUnits)"
                      [value]="physicalMemoryUnit">
            {{physicalMemoryUnit}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </h3>
    <div>
      <canvas baseChart #baseChart="base-chart"
              [data]="chartData?.data"
              [labels]="chartData?.labels"
              [chartType]="chartData?.type"
              [options]="chartData?.options"></canvas>
    </div>
  </mat-card-content>
