import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MATemplateHeader} from './activity-role.model';
import {ActivityRoleService} from './activity-role.service';


@Injectable({
    providedIn: 'root'
})
export class ActivityRoleResolverService implements Resolve<MATemplateHeader> {
    constructor(private activityRoleService: ActivityRoleService, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MATemplateHeader> | Observable<never> {
        return this.activityRoleService.getBranchSet(+route.paramMap.get('id'));
    }
}
