import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from 'src/app/material-lib.module';
import { ReferenceCodeEditorComponent } from './reference-code-edit/reference-code-editor.component';
import { ReferenceCodeDeleteDialogComponent } from './reference-code-list/reference-code-delete-dialog.component';
import { ReferenceCodeListComponent } from './reference-code-list/reference-code-list.component';
import { ReferenceCodeResolverService } from './reference-code.resolve';

export const referenceCodeRoute: Route = {
  path: 'reference-code',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', 
    canActivate: [AuthenticatedUserService],
    component: ReferenceCodeListComponent,
    data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_REFRENCE_CODE_LIST'] }
    },
    {
      path: 'create', 
      canActivate: [AuthenticatedUserService],
      component: ReferenceCodeEditorComponent,
      resolve: {
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_REFRENCE_CODE'] }
    },
    {
      path: 'update/:id', 
      canActivate: [AuthenticatedUserService],
      component: ReferenceCodeEditorComponent,
      resolve: {
        referenceCode: ReferenceCodeResolverService,
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_REFRENCE_CODE'] }
    },
    {
      path: 'view/:id',
      canActivate: [AuthenticatedUserService],
      component: ReferenceCodeEditorComponent,
      resolve: {
        referenceCode: ReferenceCodeResolverService,
        editable: NonEditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_REFRENCE_CODE'] }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ReferenceCodeListComponent, ReferenceCodeEditorComponent, ReferenceCodeDeleteDialogComponent],
  entryComponents: [ReferenceCodeDeleteDialogComponent]
})
export class ReferenceCodeModule { }
