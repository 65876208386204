<mat-card style="height: 95%; overflow-y: hidden;">
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon>library_books</mat-icon>
    </div>
    <div mat-card-title>
      Report
    </div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="auditReportForm" novalidate (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">

        <mat-form-field>
          <mat-select (ngModelChange)="onChange($event)" formControlName="reportTypeList"
            [(ngModel)]="reportType" placeholder="Report Type">
            <mat-option *ngFor="let reportType of reportTypeList" [value]="reportType.code">{{reportType.code}}
            </mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.reportTypeList">{{ formErrors.reportTypeList }}</mat-error>
        </mat-form-field>

        <div *ngIf="isWeightageType">
        <mat-form-field>
          <mat-select name="module" formControlName="weightageType" placeholder="Weightage Type">
              <mat-option>--Select--</mat-option>
              <mat-option *ngFor="let referenceCode of referenceCodeList" [value]="referenceCode.code">{{referenceCode.name}}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>

        <div *ngIf="this.isStates">
          <mat-form-field>
            <mat-label>States</mat-label>
            <mat-select formControlName="selectedStates" multiple>
              <mat-option *ngFor="let state of states" [value]="state.name">{{state.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div [ngStyle]="{'display':isBranches?'block':'none'}" style="width: 180px; margin-right: 50px; padding-top: 10px; padding-right: 30px;">
            <ng-multiselect-dropdown
            formControlName="branchCodesSelect"
            [placeholder]="'Select Branches'"
            [data]="dropdownList"
            [(ngModel)] = "selectedBranches"
            [settings]="dropdownSettings">
            </ng-multiselect-dropdown>
            <mat-error align="start" *ngIf="formErrors.branchCodesSelect && auditReportForm.controls['branchCodesSelect']?.touched ">{{ formErrors.branchCodesSelect }}</mat-error>
          </div>

         <div [ngStyle]="{'display':isBranch?'block':'none'}" style="width: 180px; margin-right: 50px; padding-top: 10px;padding-right: 30px;">
          <ng-multiselect-dropdown
          formControlName="branchCode"
          [placeholder]="'Select Branch'"
          [data]="dropdownList"
          [(ngModel)] = "branchCode"
          [settings]="singleDropdownSettings"
          (onSelect)="onItemSelect($event)">
          </ng-multiselect-dropdown>
          <mat-error align="start" *ngIf="formErrors.branchCode && auditReportForm.controls['branchCode']?.touched ">{{ formErrors.branchCode }}</mat-error>
          </div>
        <div  *ngIf="hasAuditPlans" style="width: 180px; margin-right: 50px;">
          <mat-form-field>
              <mat-select (ngModelChange)="onChange($event)" name="auditId" formControlName="auditId"
              [(ngModel)]="auditId" placeholder="Report List">
                <mat-option *ngFor="let idres of internalAuditResponse" [value]="idres.id">{{idres.auditForMonth}} / {{idres.auditForYear}} - {{idres.teamLead}}</mat-option>
              </mat-select>
              <mat-error align="start" *ngIf="formErrors.auditId">{{ formErrors.auditId }}</mat-error>
            </mat-form-field>
        </div>
          <div *ngIf="hasOneMonth">
          <mat-form-field>
            <mat-select formControlName="month" name="month" [(ngModel)]="month" placeholder="Months">
              <mat-option *ngFor="let oneMonth of allMonths" [value]="oneMonth.code">{{oneMonth.name}}</mat-option>
            </mat-select>
            <mat-error align="start" *ngIf="formErrors.month">{{ formErrors.month }}</mat-error>
          </mat-form-field>
          </div>
          <div *ngIf="hasOneMonth">
          <mat-form-field>
            <mat-select formControlName="auditYear" name="auditYear" [(ngModel)]="auditYear" placeholder="Years">
              <mat-option *ngFor="let year of allYears" [value]="year.code">{{year.name}}</mat-option>
            </mat-select>
            <mat-error align="start" *ngIf="formErrors.auditYear">{{ formErrors.auditYear }}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="isMonthYear">
        <mat-form-field>
          <mat-select multiple formControlName="auditMonth" name="auditMonth" [(ngModel)]="auditMonth" placeholder="Months">
            <mat-option *ngFor="let month of allMonths" [value]="month.code">{{month.name}}</mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.auditMonth">{{ formErrors.auditMonth }}</mat-error>
        </mat-form-field>
      </div>    
        <mat-form-field *ngIf="isMonthYear || isYear">
          <mat-select formControlName="auditYear" name="auditYear" [(ngModel)]="auditYear" placeholder="Years">
            <mat-option *ngFor="let year of allYears" [value]="year.code">{{year.name}}</mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.auditYear">{{ formErrors.auditYear }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isFromToDate">
          <input matInput [(ngModel)]="fromDateFilter"[max]="maxDate" readonly (ngModelChange)="onFromDateChange($event)"  [matDatepicker]="fromDatePicker" placeholder="From" formControlName="fromDateFilter">
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isToDate">
          <input matInput [min]="minDate" [max]="maxDate" [(ngModel)]="toDateFilter" readonly [matDatepicker]="toDatePicker" placeholder="To" formControlName="toDateFilter">
          <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter}}</mat-error>
        </mat-form-field>
      <div *ngIf="isQuestionnaireVersion">
        <mat-form-field>
          <mat-select formControlName="questionnaireVersion" name="questionnaireVersion" [(ngModel)]="questionnaireVersion" placeholder="Questionnaire Version">
            <mat-option *ngFor="let questionnaire of versionNameList" [value]="questionnaire.questionnaireVersionCode">{{questionnaire.questionnaireVersionCode}}</mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.questionnaireVersion">{{formErrors.questionnaireVersion}}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-select formControlName="downLoadFormatList" placeholder="Download Format"
          [(ngModel)]="downloadFormat">
          <mat-option *ngFor="let downloadformat of downLoadFormatList" [value]="downloadformat.value">
            {{downloadformat.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error align="start" *ngIf="formErrors.downLoadFormatList">{{formErrors.downLoadFormatList}}</mat-error>
        </mat-form-field>
      </div>
        <button style="margin-right: 10px; margin-left: 20px" type="submit" mat-raised-button color="primary" >
          <mat-icon>get_app</mat-icon>download
      </button>      
      </div>
    </form>
  </mat-card-content>
</mat-card>
