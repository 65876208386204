import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { AuditTransactionService } from "../audit-transaction-service";
import { ObservationResponseRemarks } from "../observation-response-remark.model";
import { catchError, finalize, take, takeUntil } from "rxjs/operators";
import { ObservationResponseFiles } from "../observation-response-files.model";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-edit-remark-dialog",
  templateUrl: "./edit-remark-dialog.component.html",
})
export class EditRemarksDialogComponent implements OnInit, OnDestroy {
  file: File;
  checkListResponse: any;
  public editRemarkForm: FormGroup;
  isAmAndBm: boolean;
  loggedInUser: any;
  isHoAssistantAndHaSupervisor: boolean;
  action: any;
  ObservationResponseFiles: ObservationResponseFiles;
  ObservationResponseRemarks: any;
  observationResponseDetailId: any;
  id: any;
  message: any[];
  selectedFiles: File[] = [];

  constructor(
    private dialogRef: MatDialogRef<EditRemarksDialogComponent>,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private auditTransactionService: AuditTransactionService,
    // private checkDetect:
    @Inject(MAT_DIALOG_DATA)
    private data: any
  ) {
    this.loggedInUser = this.data.loggedInUser;
    this.action = this.data.action;
    console.log(data);
    this.ObservationResponseRemarks = data.ObservationResponseRemarks;
    this.observationResponseDetailId = data.ObservationResponseRemarks.observationResponseDetailId;
  }

  @ViewChild("fileUploader", { static: false })
  myInputVariable: ElementRef;

  @ViewChild("fileUpload")
  fileUpload: ElementRef;

  @Input()
  files: File[] = [];

  @Input()
  multiple;
  @Input()
  chooseLabel = "Choose";
  @Input()
  uploadLabel = "Upload";
  @Input()
  deleteButtonIcon = "close";

  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit() {
    console.log("Remark files:", this.ObservationResponseRemarks, this.observationResponseDetailId);
    if (
      this.action === "view" &&
      (this.loggedInUser === "HO_ASST" ||
        this.loggedInUser === "HA_SUPERVISOR" ||
        this.loggedInUser === "ROLE_ADMIN" ||
        this.loggedInUser === "BM" ||
        this.loggedInUser === "AM")
    ) {
      this.isAmAndBm = false;
    }

    if (
      this.action === "edit" &&
      (this.loggedInUser === "HO_ASST" ||
        this.loggedInUser === "HA_SUPERVISOR" ||
        this.loggedInUser === "ROLE_ADMIN")
    ) {
      this.isAmAndBm = true;
    }
  }

  // to add the files
  onSave() {
    this.auditTransactionService
      .saveFiles(  this.files, this.ObservationResponseRemarks, this.observationResponseDetailId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (saveResponse) => this.onSaveSuccess(saveResponse),
        (saveResponse) => this.onSaveError(saveResponse)
      );
  }
  onSaveSuccess(saveResponse) {
    this.snackBar.open(`File saved successfully`, "Close", {
      duration: 3000,
    });
    this.dialogRef.close();
    
  }
  onSaveError(saveResponse) {
    var obj = JSON.parse(saveResponse.error);
    this.snackBar.open(obj.title, "Close", {
      duration: 5000,
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  // to select the file
  onFileSelection(event: any) {
    let files = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    // console.log("event::::::", event);

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (this.validate(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(file)
        );
        this.files.push(file);
      }
    }
    event.target.value = null;
    // this.myInputVariable.nativeElement.value = "";
  }
  validate(file: File) {
    for (const f of this.files) {
      if (
        f.name === file.name &&
        f.lastModified === file.lastModified &&
        f.size === f.size &&
        f.type === f.type
      ) {
        return false;
      }
    }
    return true;
  }

  selectedFileName = "";

  onClick(event) {
    if (this.fileUpload) this.fileUpload.nativeElement.click();
  }

  isMultiple(): boolean {
    return this.multiple;
  }
  // to remove the selected file
  removeSelectedFile(index) {
    this.files.splice(index, 1);
    this.file = undefined;
  }

  isEditable(): boolean {
    if (this.loggedInUser === "AM" || this.loggedInUser === "BM") {
      return true;
    } else if (
      this.action === "view" &&
      (this.loggedInUser === "ROLE_ADMIN" ||
        this.loggedInUser === "HA_SUPERVISOR" ||
        this.loggedInUser === "HO_ASST")
    ) {
      return true;
    } else {
      return false;
    }
  }
}
