
import {DataSource} from '@angular/cdk/table';
import { AppData } from './app-data.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {AppDataService} from './app-data.service';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';

export class AppDataDataSource extends  DataSource<AppData> {

  private appDataSubject = new BehaviorSubject<AppData[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private appDataService: AppDataService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<AppData[]> {
    return this.appDataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.appDataSubject.complete();
    this.totalCount$.complete();
  }

 loadAppDatas(sortDirection = 'asc', pageIndex = 0, pageSize = 3, status: String, roles: string[], userStatus: String, businessLineId: number, appStatus: string) {
 this.appDataService.findAppData({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      status: status,
      roles: roles,
      businessLineId: businessLineId,
      userStatus: userStatus,
      appStatus: appStatus,
      
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.appDataSubject.next(response['body']);
    });
  }
}
