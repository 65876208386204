import { Branch } from '../branch/branch.model';

export class Center {
    id?: number;
    version?: number;
    name?: string;
    code?: string;
    branchCode?: string;
    branchName?: string;
    public branchNameCode?: string;
    createdBy?: string;
    createdDate?: any;
    centreId?: string;
    branch?: Branch;
    centerStatus?: string;
}
