<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToReferenceCodeListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="!editable" class="button-icon-text">View Refernce code</mat-card-title>
        <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create Reference Code</mat-card-title>
        <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit reference Code</mat-card-title>

    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="referenceCodeEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                class="two-per-row">


                <input name="id" type="hidden" formControlName="id" />



                <input name="version" type="hidden" formControlName="version" />



                <mat-form-field>
                    <mat-select placeholder="Classifier" name="classifier" formControlName="classifier"
                        (selectionChange)="loadParentCodes($event.value)">
                        <mat-option *ngFor="let classifier of classifierObservable$ | async" [value]="classifier.code">
                            {{classifier.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.classifier">
                        {{ formErrors.classifier }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="name" matInput type="text" formControlName="name" placeholder="Name" />
                    <mat-error align="start" *ngIf="formErrors.name">
                        {{ formErrors.name }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="code" matInput type="text" formControlName="code" placeholder="Code" />
                    <mat-error align="start" *ngIf="formErrors.code">
                        {{ formErrors.code }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <mat-select placeholder="Parent Reference Code" name="parentReferenceCode"
                        formControlName="parentReferenceCode">
                        <mat-option *ngFor="let parentCode of parentCodes" [value]="parentCode.code">
                            {{parentCode.code}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.parentReferenceCode">
                        {{ formErrors.parentReferenceCode }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <mat-select placeholder="Status" name="status" formControlName="status">
                        <mat-option [value]="1">
                            Active
                        </mat-option>
                        <mat-option [value]="0">
                            In-Active
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.parentReferenceCode">
                        {{ formErrors.parentReferenceCode }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="field1" matInput type="text" formControlName="field1" placeholder="Field 1" />
                    <mat-error align="start" *ngIf="formErrors.field1">
                        {{ formErrors.field1 }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="field2" matInput type="text" formControlName="field2" placeholder="Field 2" />
                    <mat-error align="start" *ngIf="formErrors.field2">
                        {{ formErrors.field2 }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="field3" matInput type="text" formControlName="field3" placeholder="Field 3" />
                    <mat-error align="start" *ngIf="formErrors.field3">
                        {{ formErrors.field3 }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="field4" matInput type="text" formControlName="field4" placeholder="Field 4" />
                    <mat-error align="start" *ngIf="formErrors.field4">
                        {{ formErrors.field4 }}
                    </mat-error>
                </mat-form-field>



                <mat-form-field>
                    <input name="field5" matInput type="text" formControlName="field5" placeholder="Field 5" />
                    <mat-error align="start" *ngIf="formErrors.field5">
                        {{ formErrors.field5 }}
                    </mat-error>
                </mat-form-field>




                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="primary">Submit</button>
                    <button mat-raised-button (click)="goToReferenceCodeListPage()">Cancel</button>
                </mat-card-actions>
            </div>

        </form>
    </mat-card-content>
</mat-card>