<mat-card>
    <mat-card-title class="button-icon-text">Transaction History</mat-card-title>
    <mat-form-field style="width:70%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter History on any field">
    </mat-form-field>

    <div class="mat-elevation-z8" style="margin-top: 20px">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="user" > User Name/login </th>
                <td mat-cell *matCellDef="let row" data-label="user"> {{row.userName}}/({{row.userLogin}})</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef colspan="2"> Status </th>
                <td mat-cell *matCellDef="let element" data-label="status" colspan="2">
                    <mat-chip-list>
                        <mat-chip *ngIf="element.status === 'WF_AUDIT_STARTED' ">
                            Audit Started
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_AUDIT_SAVED' ">
                            Audit Saved
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_AUDIT_SUBMITTED' ">
                            Audit Submitted
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_HOV' ">
                            HO Verified
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_SV' ">
                            Supervisor Verified
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_BMV' ">
                            BM Verified
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_AMV' ">
                            AM Verified
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_AUDIT_CLOSED' ">
                            Audit Closed
                        </mat-chip>
                        <mat-chip *ngIf="element.status === 'WF_RETURN_TO_BM' ">
                            Audit Return To BM
                        </mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="verifiedOn">
                <th mat-header-cell *matHeaderCellDef > Verified On </th>
                <td mat-cell *matCellDef="let row" data-label="verifiedOn" > {{row.verifiedOn | date:'dd/MM/y, h:mm a'}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="closedOn">
                <th mat-header-cell *matHeaderCellDef> Closed On </th>
                <td mat-cell *matCellDef="let row" data-label="closedOn"> {{row.closedOn}} </td>
            </ng-container> -->

            <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef> Comments </th>
                <td mat-cell *matCellDef="let row" data-label="comments" > {{row.comment ? row.comment : '_'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
    </div>
</mat-card>
