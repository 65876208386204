import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({providedIn: 'root'})
export class RouteStorageService {
    constructor(
        private $sessionStorage: SessionStorageService
    ) {}

    getPreviousState() {
        return this.$sessionStorage.get('previousState');
    }

    resetPreviousState() {
        this.$sessionStorage.remove('previousState');
    }

    storePreviousState(previousStateName, previousStateParams) {
        const previousState = { 'name': previousStateName, 'params': previousStateParams };
        this.$sessionStorage.set('previousState', previousState);
    }

    getDestinationState() {
        return this.$sessionStorage.get('destinationState');
    }

    storeUrl(url: string) {
        this.$sessionStorage.set('previousUrl', url);
    }

    getUrl() {
        return this.$sessionStorage.get('previousUrl');
    }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        const destinationInfo = {
            'destination': {
                'name': destinationState.name,
                'data': destinationState.data,
            },
            'params': destinationStateParams,
            'from': {
                'name': fromState.name,
            }
        };
        this.$sessionStorage.set('destinationState', destinationInfo);
    }
}
