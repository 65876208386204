import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../admin/user/user.model';
import { Question } from './question.model';
import { ConfigurationCustom } from './configuration-custom.model';
import { QuestionLinkMapping } from './question-link-mapping.model';


@Injectable({
    providedIn: 'root'
})
export class QuestionService {
    private API = 'api/questions';
    mQuestionsSequenceList: BehaviorSubject<Array<number>>;
    constructor(
        private http: HttpClient
    ) {
        this.mQuestionsSequenceList = new BehaviorSubject([]);
    }
    questionData(req: any): Observable<HttpResponse<Question[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        return this.http.get<Question[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveQuestion(question: Question): Observable<HttpResponse<Question>> {
        question.category = question.subCategory;
        const copy: User = Object.assign({}, question);
        if (copy.id && copy.id != null) {
            return this.http.put<Question>(this.API, copy, { observe: 'response' });
        } else {
            return this.http.post<Question>(this.API, copy, { observe: 'response' });
        }
    }
    getQuestion(id: number) {
        return this.http.get<Question>(this.API + '/' + id);
    }
    deleteQuestionData(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    downloadQuestionsDetailReport(id: number): Observable<any> {
        const apiUrls = 'api/download/questionsExcelReport/' + id;

        return this.http.get(apiUrls, { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            out.filename = 'Questionnaire Report' + '.xlsx';
            return out;
        }));
    }

    /**
     * @author J Suhas Bhat
     * @description This method is used to set  the question sequnces for a given questionnaire
     */
    setQuestionSequnces(questionsList: Question[]) {
        const questionsSequenceList: Array<number> = [];
        questionsList.forEach(individualQuestion => {
            questionsSequenceList.push(individualQuestion.sequence);
        });
        this.mQuestionsSequenceList.next(questionsSequenceList);
    }

    getParentQuestion(questionId: number, questionnaireId: number): Observable<any> {
        let params = new HttpParams();
        if (questionId !== undefined) {
            params = params.set('questionId', questionId);
        }
        params = params.set('questionnaireId', questionnaireId);
        return this.http.get<Question[]>('api/getAll/filteredQuestions', { params: params, observe: 'response' });
    }

    getParentQuestionOptions(id: number) {
        return this.http.get<Question[]>('api/get/OptionValues' + '/' + id);
    }


    getQuestionLinkMapping(parentQuestionId) {
        return this.http.get<QuestionLinkMapping[]>('api/get/allQuestionLinkMapping' + '/' + parentQuestionId);
    }

}
