<mat-card>
    <mat-card-header class="page-title">
        <mat-card-title class="button-icon-text">Profile Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="profileForm">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row">

                <input name="id" type="hidden" formControlName="id" />
                <!-- <input name="version" type="hidden" formControlName="version" /> -->
                <mat-form-field>
                    <input name="firstName" matInput type="text" formControlName="firstName" placeholder="First Name" />
                    <mat-error align="start" *ngIf="formErrors.firstName">
                        {{ formErrors.firstName }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input name="lastName" matInput type="text" formControlName="lastName" placeholder="Last Name" />
                    <mat-error align="start" *ngIf="formErrors.lastName">
                        {{ formErrors.lastName }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input name="login" matInput type="text" formControlName="login" placeholder="Login" />
                    <mat-error align="start" *ngIf="formErrors.login">
                        {{ formErrors.login }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input name="email" matInput type="text" formControlName="email" placeholder="Email" />
                    <mat-error align="start" *ngIf="formErrors.email">
                        {{ formErrors.email }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input name="role" matInput type="text" formControlName="role" placeholder="Role" />
                    <mat-error align="start" *ngIf="formErrors.role">
                        {{ formErrors.role }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input name="langKey" matInput type="text" formControlName="langKey" placeholder="Language" />
                    <mat-error align="start" *ngIf="formErrors.langKey">
                        {{ formErrors.langKey }}
                    </mat-error>
                </mat-form-field>

            </div>
        </form>
    </mat-card-content>
</mat-card>