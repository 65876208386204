export const admin = 'ROLE_ADMIN';
export const user = 'user';
export const am = 'AM';
export const bm = 'BM';
export const dm = 'DM';
export const rm = 'RM';
export const zm = 'ZM';
export const tl = 'TL';
export const acm = 'ACM';
export const rcm = 'RCM';
export const auditor = 'AUDITOR';
export const ho_assistant = 'HO_ASST';
export const ha_supervisor = 'HA_SUPERVISOR';
export const audit_su = 'AUDIT_SU';
export const fileNameHeader = 'X-FILE-NAME';
export const SCORECARD_MASTER = {
    MODULE_MASTER: "MODULE_MASTER",
    SUBMODULE_MASTER: "SUBMODULE_MASTER",
    QUESTIONNAIRE_MASTER: "QUESTIONNAIRE_MASTER"
};
export const ORGANIZATION_MASTER = {
    HEADQUARTERS_ID: [1],
    HEADQUARTERS: "HEADQUARTERS",
    ZONE: "ZONE",
    STATE: "STATE",
    REGION: "REGION",
    DIVISION: "DIVISION",
    AREA: "AREA",
    BRANCH: "BRANCH",
};
export const SYNC_NAME_MASTER = {
    SCORE_CARD_ANALYSIS: "Score Card Analysis",
};

export const enum FetchBranchesForStates { GET_ALL, GET_MAPPED_ONLY }


export const months = [{ code: '1', name: 'Jan' }, { code: '2', name: 'Feb' }, { code: '3', name: 'Mar' },
{ code: '4', name: 'April' }, { code: '5', name: 'May' }, { code: '6', name: 'June' },
{ code: '7', name: 'July' }, { code: '8', name: 'Aug' }, { code: '9', name: 'Sept' }, { code: '10', name: 'Oct' },
{ code: '11', name: 'Nov' }, { code: '12', name: 'Dec' }];
export const years = [{ code: '2017', name: '2017' }, { code: '2018', name: '2018' },
{ code: '2019', name: '2019' }, { code: '2020', name: '2020' }, { code: '2021', name: '2021' }, { code: '2022', name: '2022' }, { code: '2023', name: '2023' },
{ code: '2024', name: '2024' }, { code: '2025', name: '2025' }];
export const bmtProductivityRoles = [{ code: 'BM', name: 'BM' }, { code: 'AM', name: 'AM' },
{ code: 'DM', name: 'DM' }];
export const bmtProductivityActivities = [{ code: 'BMQ', name: 'Branch Visit' }, { code: 'NCA', name: 'Non-Core' },
{ code: 'CMQ', name: 'Centre Meeting' }, { code: 'CGTQ', name: 'CGT' }, { code: 'GRTQ', name: 'GRT' },
{ code: 'LUQ', name: 'LUC' }];

export const reportTypes = [{
    value: 'answer-scoring-report', viewValue: 'Answer Scoring Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
}, {
    value: 'area-wise-glance', viewValue: 'Areawise Glance',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
},
{
    value: 'audit-compliance-report', viewValue: 'Audit Compliance Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, auditor, bm, am, dm, rm, zm, tl, acm, rcm]
},
{
    value: 'branch-deviation', viewValue: 'Branch Deviation',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, tl]
},
{
    value: 'branch-frequency', viewValue: 'Branch Frequency',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, auditor, tl]
},
{
    value: 'branch-grading-master', viewValue: 'Branch Grading Master',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
},
{
    value: 'branch-grading-report', viewValue: 'Branch Grading Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
},
{
    value: 'branch-parameter-master', viewValue: 'Branch Parameter Master',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, auditor, dm, rm, zm, tl, acm, rcm]
},
{
    value: 'centre-coverage', viewValue: 'Centre Coverage',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, tl]
},
{
    value: 'csat', viewValue: 'Customer Satisfaction Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
},
{
    value: 'internal-audit-report', viewValue: 'Internal Audit Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, auditor, bm, am, dm, rm, zm, tl, acm, rcm]
},
{
    value: 'score-trend', viewValue: 'Score Trend',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, auditor, dm, rm, zm, tl, acm, rcm]
},
{
    value: 'Centre-Count-Update', viewValue: 'Centre Visit Count',
    roles: [admin, ha_supervisor, ho_assistant]
},
{
    value: 'previous-compliance-report', viewValue: 'Previous Compliance Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su, auditor, bm, am, dm, rm, zm, tl, acm, rcm]
},
{
    value: 'parameter-wise-weightage', viewValue: 'Parameter Wise Weightage Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
},
{
    value: 'customer-centre-coverage', viewValue: 'Customer Center Coverage Report',
    roles: [admin, ha_supervisor, ho_assistant, audit_su]
}];


export const digitalDashboardtypes = [
    { code: 'CIH', name: 'Cash In Hand(CIH)', options:  [
        { code: 'SSRS_MATRIX', name: 'SSRS MATRIX' },
        { code: 'SSRS_MONTH_TO_DATE_SUMMARY', name: 'SSRS MONTH TO DATE SUMMARY' }] },
    /* { code: 'CIT', name: 'Cash In Transit(CIT)', options:  [
        { code: 'SSRS_MATRIX', name: 'SSRS MATRIX' },
        { code: 'SSRS_MONTH_TO_DATE_SUMMARY', name: 'SSRS MONTH TO DATE SUMMARY' }] }, */
    { code: 'SMR', name: 'Staff Movement Register(SMR)',options:  [
        { code: 'TAGGING_REPORT', name: 'TAGGING REPORTS' }] }
];

export const bmtReportTypes = [{ code: 'BMQ', name: 'BMQ' }, { code: 'CMQ', name: 'CMQ' },
{ code: 'CGTQ', name: 'CGTQ' }, { code: 'GRTQ', name: 'GRTQ' }, { code: 'LUQ', name: 'Customer Engagement' },
{ code: 'SCMQ', name: 'SCMQ' }, { code: 'CMMQ', name: 'Collection Monitor' }, { code: 'CMTBMQ', name: 'CMTBMQ' },
{ code: 'CMTCMQ', name: 'CMTCMQ' }, { code: 'CMTCGTQ', name: 'CMTCGTQ' }, { code: 'CMTGRTQ', name: 'CMTGRTQ' },
{ code: 'CMTBMQCENTRE', name: 'CMTBMQCENTRE' }, { code: 'CMTBMQCLIENT', name: 'CMTBMQCLIENT' }, { code: 'MACQ', name: 'MAC' }
, { code: 'CCAMQ', name: 'CCA' }];


export const crmReporttypes = [{ code: 'CRMQ', name: 'CRM' }];

export const branchVisitReporttypes = [
    { code: 'CMTBVM', name: 'BMT Monitor report' },
    { code: 'CMTCVM', name: 'CMT Monitor report' }];

export const hardBucketReporttypes = [
    { code: 'DPD_COLLECTION_TRACKER_REPORT', name: 'DPD COLLECTION TRACKER REPORT' }];

export const allFileImportTypes = [
    { code: 'CROCenterVisitDetails', name: 'CRO Centre Visit' },
    { code: 'BranchStaffAssessmentDetails', name: 'Branch Staff Assessment Score' },
    { code: 'CROCENTERALLOCATIONZING', name: 'CRO Center Allocation Zing Upload' },
    { code: 'BULKUSERCREATION', name: 'User Upload' },
    { code: 'BranchesLatLongDetails', name: 'Branches Lat Long Details'}];
export const activityMappingReporttypes = [
    { code: 'ACTIVITY_ROLE_MAPPING_REPORT', name: 'ACTIVITY ROLE MAPPING REPORT' },
    { code: 'DPD_COLLECTION_TRACKER_REPORT', name: 'DPD COLLECTION TRACKER REPORT' }];

export const activityTypes = [
    { code: 'Mandatory Activity', name: 'Mandatory Activity' },
    { code: 'Other Activity', name: 'Other Activity' },
    { code: 'Rag Activity', name: 'Rag Activity' }];

export const activityReportTypes = [
    { code: 'USEREVERYDAYREPORT', name: 'User Daily Report' },
    { code: 'USERPRODUCTIVITYREPORT', name: 'User Productivity Report' }];

export const alljobTypeList = ['Filegeneration', 'Fileprocessing'];

export const alljobStatusList = [
    'CREATED', 'READY', 'UPDATED', 'EXECUTE', 'PROCESSING',
    'PROCESSED', 'SUCCESS', 'FAILURE', 'FAILED', 'CANCELLED',
];

export const alldeviationReportTypes = [
    { code: 'BranchMonitoringdeviationreport', name: 'Branch Deviation Report' },
    { code: 'CentreMonitoringdeviationreport', name: 'Center Deviation Report' },
    { code: 'CMTBranchMonitoringdeviationreport', name: 'CMT Branch Deviation Report' },
    { code: 'CMTCentreMonitoringdeviationreport', name: 'CMT Center Deviation Report' }];

export const allCrossdeviationReportTypes = [
    { code: 'CrossFunctionalBranchdeviationreport', name: 'Cross Functional Branch Deviation Report' },
    { code: 'CrossFunctionalCenterdeviationreport', name: 'Cross Functional Center Deviation Report' },
    { code: 'NonCentreVisitsDetailedReport', name: 'Non Centre Visits Detailed Report' },
    { code: 'BranchStaffAssessmentDetails', name: 'Employee Assessment Detailed Report' }]

    export const monthDates = [
        {code:1, name: '1' },
        {code: 2, name: '2' },
        {code: 3, name: '3' },
        {code: 4, name: '4' },
        {code: 5, name: '5' },
        {code: 6, name: '6' },
        {code: 7, name: '7' },
        {code: 8, name: '8' },
        {code: 9, name: '9' },
        {code: 10, name: '10' },
        {code: 11, name: '11' },
        {code: 12, name: '12' },
        {code: 13, name: '13' },
        {code: 14, name: '14' },
        {code: 15, name: '15' },
        {code: 16, name: '16' },
        {code: 17, name: '17' },
        {code: 18, name: '18' },
        {code: 19, name: '19' }, 
        {code: 20, name: '20' },
        {code: 21, name: '21' },
        {code: 22, name: '22' },
        {code: 23, name: '23' },
        {code: 24, name: '24' },
        {code: 25, name: '25' },
        {code: 26, name: '26' },
        {code: 27, name: '27' },
        {code: 28, name: '28' },
        {code: 29, name: '29' },
        {code: 30, name: '30' },
        {code: 31, name: '31' }
    ];

    export const ragReporttypes =[{ code: 'RAGTrendingReport', name: 'RAG Trending Report' }];
    
    export const appStatusList =[
        {code: 'MAVRC', name: 'MAVRC'},
        {code: 'Cash Management', name: 'Cash Management'} 
    ];
    
    export const cihStatusList = [
        { code: 'REQUEST', name: 'REQUEST' },
        { code: 'APPROVED', name: 'APPROVED' },
        { code: 'RECONSIDER', name: 'RECONSIDER'},
        { code: 'PARTIAL_SUBMIT', name: 'PARTIAL_SUBMIT'},
        { code: 'PARTIAL_SUBMIT_WITHOUT_APPROVAL', name: 'PARTIAL_SUBMIT_WITHOUT_APPROVAL'},
        { code: 'CLOSED', name: 'CLOSED'},
        { code: 'CLOSED_WITHOUT_APPROVAL', name: 'CLOSED_WITHOUT_APPROVAL'},
    ];

    export const statusList =[
        { code:  'WF_AUDIT_STARTED', name: ' Audit Started' },
        { code:  'WF_AUDIT_SAVED', name: ' Audit Saved' },
        { code:  'WF_AUDIT_SUBMITTED', name: 'Audit Submitted' },
        { code:  'WF_HOV', name:  'HO Checked' },
        { code:  'WF_SV', name: 'Supervisor Checked' },
        { code:  'WF_BMV', name: 'BM Checked' },
        { code:  'WF_AMV', name: 'AM Checked' },
        { code:  'WF_AUDIT_CLOSED', name: 'Audit Closed' },
        { code:  'WF_RETURN_TO_BM', name: 'Audit Return' },
    ];
