import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialLibModule } from '../../material-lib.module';
import { QuestionComponent } from './question.component';
import {QuestionEditorComponent} from './question-editor.component';
import {QuestionDeleteDialogComponent} from './question-delete-dialog.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
   ],
  declarations: [QuestionComponent, QuestionEditorComponent, QuestionDeleteDialogComponent],
  entryComponents: [QuestionDeleteDialogComponent],
  exports: [QuestionComponent]
})
export class QuestionModule {}
