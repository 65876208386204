<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon> face </mat-icon>
    </div>
    <div mat-card-title>
      User
    </div>
    <div class="page-title-action">
        <mat-card-actions class="pull-right">
          <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
            <mat-icon>add_circle</mat-icon>
            Create User
          </button>
          <button mat-button mat-raised-button color="primary" [routerLink]="'user-upload'">
            <mat-icon>cloud_upload</mat-icon>
            Upload User Branches Mappings
          </button>
        </mat-card-actions>
    </div>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="userListForm"  >
    <div >
        <mat-form-field style="padding-right: 20px;">
            <input formControlName="name" matInput type="text" id="name" name="name" placeholder="Enter Name" />
        </mat-form-field>

      <mat-form-field style="padding-right: 20px;">
        <input  formControlName="login" matInput type="text" id="login" name="login" placeholder="Enter Login ID" />
      </mat-form-field>

      <mat-form-field style="padding-right: 20px;">
        <mat-select formControlName="role" placeholder="List Of Role">
          <mat-option  *ngFor="let role of roles" [value]="role.code">{{role.code}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="padding-right: 20px;">
          <mat-select placeholder="Business Line" name="businessLineId" formControlName="businessLineId">
            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
              {{businessLine.name}}
            </mat-option>
          </mat-select>
      </mat-form-field> 
     
      <button type="button" style="margin-right: 4px" mat-raised-button color="primary" (click)="search()">
        <mat-icon>search</mat-icon>
      </button>

      <button type="button" style="margin-right: 4px" mat-raised-button color="primary" (click)="clearFilter()">
        Clear
      </button>
      <button type="button" style="margin-right: 4px" title="Please select role to download the user report" mat-raised-button color="primary" (click)="onReportDownload()">
        Download
      </button>
    </div>
  </form>
    <div class="mat-elevation-z8">
      <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear
             [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">Name</th>
          <td mat-cell *matCellDef="let element" data-label="Name"> {{element.firstName}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email</th>
          <td mat-cell *matCellDef="let element" data-label="Email"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef> Login ID</th>
          <td mat-cell *matCellDef="let element" data-label="Login ID"> {{element.login}} </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Role</th>
          <td mat-cell *matCellDef="let element" data-label="Role"> {{element.role}} </td>
        </ng-container>

        <ng-container matColumnDef="isActivated">
          <th mat-header-cell *matHeaderCellDef> Activated</th>
          <td mat-cell *matCellDef="let element" data-label="Activated"> {{element.activated ? 'Yes': 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="createdOn"  >
          <th mat-header-cell *matHeaderCellDef  style="padding-left: 3%;"> Created On</th>
          <td style="padding-left: 3%;" mat-cell *matCellDef="let element" data-label="Created On"> {{element.createdDate | date:'dd MMM, yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-button-toggle-group #userActionGroup="matButtonToggleGroup" class="table-row-action">
              <mat-button-toggle [routerLink]="['view', element.login]" title="View More">
                <mat-icon>more</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle [routerLink]="['update', element.login]" title="Edit">
                <mat-icon>edit</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle (click)="deleteDialog(element)" title="Delete">
                <mat-icon>delete</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle (click)="resetPassword(element)" title="Reset Password">
                <mat-icon>restore</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                     [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
