import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppData } from './app-data.model';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  private API = 'api/users/show/application-usage-stats';
  private API_Download = 'api/users/download/application-usage-stats';

  constructor(private http: HttpClient) {
  }

  findAppData(req: any): Observable<HttpResponse<AppData[]>> {
    let params = new HttpParams();
    params = params.set('page', req.page);
    params = params.set('size', req.size);
    params = params.set('sort', req.sort);
    params = params.set('status', req.status);
    params = params.set('roles', req.roles);
    params = params.set('userStatus', req.userStatus);
    params = params.set('businessLineId', req.businessLineId);
    params = params.set('appStatus', req.appStatus);

    return this.http.get<AppData[]>(this.API + '/', {
      params, observe: 'response'
    });
  }
  downloadAppData(req: any): Observable<any> {
    let params = new HttpParams();
    if (req.status !== undefined) {
        params = params.set('status', req.status);
    }
    if (req.roles !== []) {
        params = params.set('roles', req.selectedRoles);
    }
    if (req.userStatus !== undefined) {
      params = params.set('userStatus', req.userStatus);
    }
    if (req.appStatus !== undefined) {
      params = params.set('appStatus', req.appStatus);
    }
    if (req.businessLineId !== undefined) {
      params = params.set('businessLineId', req.businessLineId);
     }
    
    return this.http.get(this.API_Download,
        { observe: 'response', responseType: 'blob' , params: params}
    ).pipe(map((resp: HttpResponse<Blob>) => {
        const out = { data: null, filename: null };
        out.data = resp.body;
        out.filename = 'App Data Report' + '.csv';
        return out;
    }));
}

}
