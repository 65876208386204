<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon> group </mat-icon>
        </div>
        <div mat-card-title>
            Roles
        </div>
        <div class="page-title-action">
            <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
                <mat-icon>add_circle</mat-icon>
                Create Roles
            </button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear
                   [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
                            <td mat-cell *matCellDef="let element" data-label="Name"> {{element.name}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="code">Code</th>
                            <td mat-cell *matCellDef="let element" data-label="Code"> {{element.code}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="authorities">
                            <th mat-header-cell *matHeaderCellDef>Authorities</th>
                            <td mat-cell *matCellDef="let element" data-label="Authorities">
                                <span *ngIf="element.authorities.length <= 3">
                                    {{element.authorities }}
                                </span>
                                <span *ngIf="element.authorities.length > 3">
                                    {{element.authorities.toString() | slice :0:60  }}...
                                </span>
                            </td>
                        </ng-container>
                     
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-button-toggle-group #roleActionGroup="matButtonToggleGroup" class="table-row-action">
                            <mat-button-toggle [routerLink]="['view', element.id]" title="View More">
                                <mat-icon>more</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle [routerLink]="['update', element.id]" title="Edit">
                                <mat-icon>edit</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle (click)="deleteDialog(element)" title="Delete">
                                <mat-icon>delete</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                           [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()" [pageIndex]="pagingAndSortParams.pageIndex"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
