<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon> android </mat-icon>
    </div>
    <div mat-card-title>
      App Data
    </div>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-form-field style="padding-right: 20px;">
        <mat-select [(ngModel)]="businessLines" placeholder="Business Line">
          <mat-option *ngFor="let businessLine of businessLinesList" [value]="businessLine.id">
            {{businessLine.name}}
          </mat-option>
        </mat-select>
    </mat-form-field> 

    <mat-form-field style="padding-right: 20px;">
    <mat-select multiple placeholder="App Status"  [(ngModel)]="appStatus">
      <mat-option *ngFor="let appList of appLists" [value]="appList.code">{{appList.name}}</mat-option>
    </mat-select>
    </mat-form-field>

  

      <mat-form-field style="padding-right: 20px;">
        <mat-select [(ngModel)]="status" placeholder="Status">
          <mat-option *ngFor="let status of statusList" [value]="status">{{status}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="padding-right: 20px;">
        <mat-label>List Of Role</mat-label>
        <mat-select #roleSelect [(ngModel)]="selectedRoles"
            (selectionChange)="selectionChanged(roleAllSelect, selectedRoles, roles)"
            multiple>
            <mat-checkbox #roleAllSelect style="padding-left:15px;"
                (change)="onAllChanged($event.checked, roles, roleSelect, 'role')">
                    Select All
            </mat-checkbox>
            <mat-option *ngFor="let role of roles" [value]="role.code">
                {{role.code}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="padding-right: 20px;">
        <mat-select [(ngModel)]="isActiveOrInactive" placeholder="UserStatus">
          <mat-option *ngFor="let userStatus of userStatusList" [value]="userStatus">{{userStatus}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button style="margin-right: 10px" type="button" mat-raised-button color="primary" (click)="loadAppDatas('')"[disabled]="!isFormValid">
        <mat-icon>search</mat-icon>
      </button>

      <button style="margin-right: 10px" type="button" mat-raised-button color="primary" (click)="downloadAppDatas()"[disabled]="!isFormValid">
        <mat-icon>get_app</mat-icon>  
      </button>

      <button style="margin-right: 10px" type="button" mat-raised-button color="primary" (click)="clearFilter()">
        Clear
      </button>
    </div>
    &nbsp;

    <div class="mat-elevation-z8">
      <table mat-table matSort matSortActive="email" matSortDirection="asc" matSortDisableClear
        [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          
        <ng-container matColumnDef="stateName">
          <th mat-header-cell *matHeaderCellDef> State</th>
          <td mat-cell *matCellDef="let element" data-label="stateName">
            {{element.stateName === null ? '' : element.stateName}} </td>
        </ng-container>

        <ng-container matColumnDef="branchName">
          <th mat-header-cell *matHeaderCellDef> Branch Name</th>
          <td mat-cell *matCellDef="let element" data-label="branchName" matTooltip={{element.branchName}}>
            {{element.branchName === null ? '' : (element.branchName.length >15) ?(element.branchName | slice:0:15)+'...' :(element.branchName)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="login"> Login Id</th>
          <td mat-cell *matCellDef="let element" data-label="Username">
            {{element.login === null ? '' : element.login}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">Name</th>
          <td mat-cell *matCellDef="let element" data-label="Name">
            {{element.firstName === null ? '' : element.firstName}}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Role</th>
          <td mat-cell *matCellDef="let element" data-label="Role">
            {{element.role === null ? '' : element.role}} </td>
        </ng-container>

        <ng-container matColumnDef="appVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="appVersion"> Mavrc App Version</th>
          <td mat-cell *matCellDef="let element" data-label="App Version">
            {{element.appVersion === null ? '' : element.appVersion}} </td>
        </ng-container>

        <ng-container matColumnDef="cashMangementPackage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="cashMangementPackage"> cash Management Package</th>
          <td mat-cell *matCellDef="let element" data-label=" cash Management Package">
            {{element.cashMangementPackage === null ? '' : element.cashMangementPackage}} </td>
        </ng-container>

        <ng-container matColumnDef="cashMangementVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="cashMangementVersion"> cash Management Version</th>
          <td mat-cell *matCellDef="let element" data-label=" cash Management Version">
            {{element.cashMangementVersion === null ? '' : element.cashMangementVersion}} </td>
        </ng-container>

        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="lastLogin"> lastLogin</th>
          <td mat-cell *matCellDef="let element" data-label="Last Login">
            {{element.lastLogin === null ? '': element.lastLogin | date : 'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="appInstalledOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="appInstalledOn"> App Installed On</th>
          <td mat-cell *matCellDef="let element" data-label="App Installed On">
            {{element.appInstalledOn === null ? '' :element.appInstalledOn | date:'dd MMM, yyyy'}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="model"> Model</th>
          <td mat-cell *matCellDef="let element" data-label="Model">
            {{element.model === null ? '' :element.model}} </td>
        </ng-container>

        <ng-container matColumnDef="osVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="osVersion"> OS Version</th>
          <td mat-cell *matCellDef="let element" data-label="OS Version">
            {{element.osVersion === null ? '' :element.osVersion}} </td>
        </ng-container>

        <ng-container matColumnDef="ram">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="ram"> Ram</th>
          <td mat-cell *matCellDef="let element" data-label="Ram">
            {{element.ram === null ? '' :element.ram}} </td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="storage">
           <th mat-header-cell *matHeaderCellDef mat-sort-header="storage"> Storage</th>
          <td mat-cell *matCellDef="let element" data-label="Storage">
            {{element.storage === null ? '' :element.storage}}
            <button  mat-icon-button matTooltip="View User Details" (click)="getAllUserDetails(element)">
              <mat-icon>more</mat-icon>
             </button>
           </td>
        </ng-container> -->

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
              <button mat-icon-button matTooltip="View User Details" (click)="getAllUserDetails(element)">
                  <mat-icon>more</mat-icon>
              </button>
            </td>
          </ng-container>  
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
      </table>

      
      <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
        [pageSizeOptions]="pagingAndSortParams.appDataDefaultPageSizeOptions()"></mat-paginator>

        

    </div>
  </mat-card-content>
</mat-card>
