import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {MatSortable } from '@angular/material/sort';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
@Component({
    selector: 'app-audit-transaction-workflow-history',
    templateUrl: './audit-transaction-workflow-history.component.html',
})
export class AuditTransactionHistoryComponent implements OnInit {

    @Input() auditHistories: any[];
    displayedColumns: string[] = ['user', 'status', 'verifiedOn', 'comments'];
    dataSource: MatTableDataSource<any>;

      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.dataSource = new MatTableDataSource(this.auditHistories);
        this.sort.sort(<MatSortable>({ id: 'sequence', start: 'asc' }));
        this.sort.direction = 'asc';
        this.dataSource.sort = this.sort;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}
