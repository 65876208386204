import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { DatePipe, formatDate } from '@angular/common';
import { ReportService } from '../reports/report.service';
import { branchVisitReporttypes } from 'src/app/constants/data.constants';

@Component({
  selector: 'app-branch-visit-monitor',
  templateUrl: './branch-visit-monitor.component.html',
  styleUrls: ['./branch-visit-monitor.component.css']
})
export class BranchVisitMonitorComponent implements OnInit, OnDestroy {

  stateDropdownList: any[];
  selectedStates: any[];
  stateDropdownSettings = {};
  selectedBranches: any[];
  stateList: string[];
  branches: Branch[];
  dropdownList: any[];
  dropdownSettings = {};
  states: any;
  fromDateFilter: any;
  toDateFilter: string;
  type: any;
  codes: any[];

  fromdate1: string;
  todate1: string;
  branchCodes: any[];
  branchVisitMonitorReportTypes: any[];
  public branchVisitMonitorReportForm: FormGroup;

  @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
  @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

  @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
  @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;

  public formError: FormErrorModel;
  public formErrors = {
    type: '',
    selectedStates: '',
    selectedBranches: '',
    fromDateFilter: '',
    toDateFilter: '',
  };
  fromDate: string;
  businessLine: any;
  businessLineId: number;

  // branchVisitMonitorReportTypes = [{ code: 'CMTBVM', name: 'BMT Monitor report' },
  // { code: 'CMTCVM', name: 'CMT Monitor report' }];
  

  constructor(private questionaireService: QuestionaireService,
    private reportService: ReportService, private igFormService: IgFormService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(LOCALE_ID) private locale: string
  ) { }


  
  ngOnInit(): void {
    this.loadStates();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.BranchVisitReport();
  }
  ngOnDestroy(): void {
  }

  onItemSelect(event){
  }

  onSelectAll(event){}

  onSelectState(state: any) {
    this.selectedStates.push(state);
    this.loadBranches();
  }
  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.branchVisitMonitorReportForm);
    if (this.branchVisitMonitorReportForm.valid) {
      this.downloadHrReport();
    } else {
      this.formErrors = this.igFormService.validateForm(this.branchVisitMonitorReportForm, this.formErrors, false);
      return false;
    }
  }

  buildForm() {
    this.branchVisitMonitorReportForm = this.formBuilder.group({
      type: [this.type, Validators.required],
      stateName: [this.selectedStates, Validators.required],
      name: [this.branches, Validators.required],
      fromDateFilter: [this.fromDateFilter, Validators.required],
      toDateFilter: [this.toDateFilter, Validators.required]
    });
    this.branchVisitMonitorReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.branchVisitMonitorReportForm, this.formErrors, true);
    });
  }

  loadBranches() {
    console.log(this.selectedStates);
    this.stateList = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.stateList.push(this.selectedStates[i].name);
    }
    this.questionaireService.getAllBranchesForStates(this.businessLineId,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<Branch[]>) => {
          this.branches = res.body;
          this.dropdownList = this.branches;
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'code',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
          };
        });
  }
  loadStates() {
    this.questionaireService.getAllStatesForUser(this.businessLine.id).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.states = res.body;
          this.stateDropdownList = this.states;
          this.stateDropdownSettings = {
            singleSelection: false,
            idField: 'code',
            textField: 'name',
            selectAllTest: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true,
          };
        }
      );
  }

  

  downloadHrReport() {
    // this.branchCodes = [];
    this.codes = [];
    this.selectedBranches.forEach((value) => {
      this.codes.push(value.code);
    });
    // this.selectedBranches.forEach((value) => {
    //   this.branchCodes.push(value.code);
    // }); // end loop
 this.reportService.downloadBranchVisitReport({
      type: this.type,
      branchCodes: this.codes,
      fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
    })

      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {

        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
        this.onError();
      }, () => {
        console.log('Completed file download.');
      });
  }
  onError() {


    this.fromdate1 = formatDate(this.fromDateFilter, 'dd-MM-yyyy', this.locale);
    this.todate1 = formatDate(this.fromDateFilter, 'dd-MM-yyyy', this.locale);
    this.snackBar.open(` Data Not found selected From date: ` + this.fromdate1 + ` To Date: ` + this.todate1 + ``, 'Close', {
      duration: 5000
    });
  }
  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  onItemDeSelectBranch(item: any) {
  }
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();

  }

  clearFilter() {
    this.type = '';
    this.selectedBranches = [];
    this.selectedStates = [];
    this.fromDateFilter = '';
    this.toDateFilter = '';
    this.loadBranches();
  }


  BranchVisitReport() {
    this.branchVisitMonitorReportTypes = branchVisitReporttypes;
}



}
