import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MaterialLibModule } from '../../material-lib.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
/* import {QuestionaireComponent} from './questionaire.component';
import {QuestionaireEditorComponent} from './questionaire-editor.component';
import {QuestionaireDeleteDialogComponent} from './questionaire-delete-dialog.component';
import {QuestionaireResolveService} from './questionaire-resolve.service'; */
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { QuestionModule } from '../question/question.module';
import { QuestionEditorComponent } from '../question/question-editor.component';
import { QuestionResolveService } from '../question/question-resolve.service';
/* import {QuestionaireReportComponent} from './questionaire-report.component'; */
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScoreCardListComponent } from './score-card-list/score-card-list.component';
import { ScoreCardEditComponent } from './score-card-edit/score-card-edit.component';
import { ScoreCardResolverService } from './score-card.resolve';
import { ScorecardDeleteDialogComponent } from './score-card-delete/score-card-delete-dialog.component';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';


export const scoreCardRoute: Route = {

    path: 'scorecard',
    canActivate: [AuthenticatedUserService],
    children: [
        {
            path: '',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardListComponent,
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_LIST'] }
        },
        {
            path: 'create',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardEditComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_CREATE'] }
        },
        {
            path: 'edit/:id',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardEditComponent,
            resolve: {
                scorecard: ScoreCardResolverService,
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_EDIT'] }
        },
        {
            path: 'view/:id',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardEditComponent,
            resolve: {
                scorecard: ScoreCardResolverService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_CREATE'] }
        },
        {
            path: ':scorecardId/question/create',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardEditComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_CREATE'] }
        },
        {
            path: ':scorecardId/question/edit/:id',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardEditComponent,
            resolve: {
                question: ScoreCardResolverService,
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_CREATE'] }
        },
        {
            path: ':scorecardId/question/view/:id',
            canActivate: [AuthenticatedUserService],
            component: ScoreCardEditComponent,
            resolve: {
                question: ScoreCardResolverService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_CREATE'] }
        },
        // {path: 'questionaireReport', component: QuestionaireReportComponent
        // }
    ]
};
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        QuestionModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [ScoreCardListComponent, ScoreCardEditComponent, ScorecardDeleteDialogComponent],
    entryComponents: [ScorecardDeleteDialogComponent],
    exports: [RouterModule, ReactiveFormsModule]

})
export class ScoreCardModule { }
