<mat-card style="overflow-y: hidden; height: 95%;">
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon> supervisor_account</mat-icon>
        </div>
        <mat-card-title class="button-icon-text">Mandatory And Other Activity Report</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="activityReportForm" novalidate (ngSubmit)="onSubmit()">

            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start" >
               
                <mat-form-field *ngIf="isRoleAdmin">
                    <mat-select placeholder="Business Line"  formControlName="businessLineId" 
                    (selectionChange)="changeBusinessLine($event)">
                      <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                        {{businessLine.name}} 
                      </mat-option>
                    </mat-select>
                    <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                  </mat-form-field>
                  &nbsp;&nbsp; 
                  
                <mat-form-field>
                    <mat-select (ngModelChange)="onChange($event)" formControlName="type" name="type" [(ngModel)]="type" placeholder="Report Typesssss">
                        <mat-option *ngFor="let cihReports of mandatoryActivityReportList" [value]="cihReports.code">{{cihReports.name}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.type">{{ formErrors.type }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field style="margin-right: 0.5%;margin-left: 1%;">
                    <mat-select  formControlName="role" [(ngModel)]="role" placeholder="List Of Role">
                        <mat-option  *ngFor="let role of roles" [value]="role.code">{{role.code}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.role">{{ formErrors.role }}
                    </mat-error>
                </mat-form-field>
                &nbsp;&nbsp;
                <div style="width: 180px;margin-right: 2%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'" [data]="stateDropdownList"
                                             [(ngModel)]="selectedStates" [settings]="stateDropdownSettings" (onSelect)="onSelectState($event)"
                                             (onSelectAll)="onSelectAllState($event)" (onDeSelect)="onItemDeSelectState($event)"
                                             (onDeSelectAll)="onItemDeSelectAllState($event)">
                    </ng-multiselect-dropdown>
                  <mat-error align="start" *ngIf="formErrors.stateName && activityReportForm.controls['stateName']?.touched ">{{ formErrors.stateName }}</mat-error>
                </div>
                <div style="width: 180px;margin-left: 1%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                                             [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                             (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)">
                    </ng-multiselect-dropdown>
                 <mat-error align="start" *ngIf="formErrors.name && activityReportForm.controls['name']?.touched ">{{ formErrors.name }}</mat-error>
                </div>

                <div *ngIf="isFromDate" style="width: 180px; margin-left: 3%;">
                <mat-form-field >
                    <input matInput name="fromDateFilter" formControlName="fromDateFilter" [max]="toDateFilter"
                           (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter"
                           [matDatepicker]="fromDatePicker" placeholder="FromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter }}
                    </mat-error>
                </mat-form-field>
                </div>

                <div *ngIf="isToDate" style="width: 180px; margin-left: 3%;">
                <mat-form-field >
                    <input matInput name="toDateFilter" formControlName="toDateFilter" [min]="fromDateFilter"
                           (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
                           [matDatepicker]="toDatePicker" placeholder="ToDate">
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter }}</mat-error>
                </mat-form-field>
                </div>

                <div *ngIf="isYear" style="margin-left: 3%;">
                    <mat-form-field>
                        <mat-select formControlName="year" name="auditYear" [(ngModel)]="year" placeholder="Years">
                            <mat-option *ngFor="let year of allYears" [value]="year.code">{{year.name}}</mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.year">{{ formErrors.year }}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="isMonth" style="margin-left: 1.5%;">
                    <mat-form-field>
                        <mat-select  formControlName="month" name="auditMonth" [(ngModel)]="month" placeholder="Months">
                            <mat-option *ngFor="let month of allMonths" [value]="month.code">{{month.name}}</mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.month">{{ formErrors.month }}</mat-error>
                    </mat-form-field>
                </div>
                &nbsp;&nbsp;&nbsp;
                <button type="submit" matTooltip="Download Report" style="height: 40px;margin-left : 2%;"
                        mat-raised-button color="primary">
                    <mat-icon>cloud_download</mat-icon>
                </button>

                <button type="button" matTooltip="Clear All" style="height: 40px;margin-left : 0.5%;" mat-raised-button
                        color="primary"
                        (click)="clearFilter()">
                    <mat-icon>replay</mat-icon>
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
