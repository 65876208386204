<mat-card style="height: 95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>trending_up_icon</mat-icon>
        </div>
        <div mat-card-title>Efficiency Report</div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="branchEfficiencyReportForm" novalidate (ngSubmit)="onSubmit()">
              <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start">


                <mat-form-field *ngIf="isRoleAdmin">
                    <mat-select placeholder="Business Line"  formControlName="businessLineId" 
                    (selectionChange)="changeBusinessLine($event)">
                      <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                        {{businessLine.name}} 
                      </mat-option>
                    </mat-select>
                    <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                  </mat-form-field>
                  &nbsp;&nbsp;
                  &nbsp;
                  &nbsp;
                <mat-form-field style="margin-right: 1%;">
                    <mat-select [(ngModel)]="type" placeholder="Report Type" formControlName="type">
                        <mat-option *ngFor="let efficienyReportType of efficienyReportTypes"
                            [value]="efficienyReportType">{{efficienyReportType.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.type">{{ formErrors.type }}</mat-error>
                </mat-form-field>
                &nbsp;
                <div style="width: 180px;margin-right: 3%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                        [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                        (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.stateName && branchEfficiencyReportForm.controls['stateName']?.touched ">{{ formErrors.stateName }}</mat-error>
                </div>
                <div style="width: 180px;margin-right: 3%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                        [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onDeSelect)="onItemDeSelectBranch($event)">
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.name && branchEfficiencyReportForm.controls['name']?.touched ">{{ formErrors.name }}</mat-error>
                </div>
                <div style="width: 180px;margin-right: 3%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="monthSelection" [placeholder]="'Months'" [settings]="monthDropdownSettings"
                        [data]="monthDropdownList" [(ngModel)]="selectedMonths" (onSelect)="onMonthSelection($event)" 
                        (onDeSelect)="onMonthDeselection($event)">
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.monthSelection && branchEfficiencyReportForm.controls['monthSelection']?.touched ">{{ formErrors.monthSelection }}</mat-error>
                </div>
                <mat-form-field>
                    <mat-select formControlName="selectedYear" name="selectedYear" [(ngModel)]="selectedYear" placeholder="Years">
                      <mat-option *ngFor="let year of allYears" [value]="year.code">{{year.name}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.selectedYear">{{ formErrors.selectedYear }}</mat-error>
                  </mat-form-field>
                <button type="submit" style="height: 40px;margin-right: 1%;" mat-raised-button color="primary">
                    <mat-icon>cloud_download</mat-icon> Download Report
                </button>
                <button type="button" style="height: 40px;" mat-raised-button color="primary"
                    (click)="clearFilter()">Clear</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>