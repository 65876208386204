import {AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuditDataSource} from './audit.data.source';
import {AuditService} from './audit.service';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import {takeUntil, tap} from 'rxjs/operators';
import {combineLatest, merge} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PagingAndSortParams} from '../../list/paging-and-sort-params';
import {ListSource} from '../../list/list-source';
import {formatDate} from '@angular/common';
import {FormBuilder, FormGroup, Validator, Validators} from '@angular/forms';
import {IgFormService} from '../../form/form.service';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

export class AuditSearchParams {
  constructor(
   public fromDate: string,
   public toDate: string
  ) { }
}

@Component({
  selector: 'ig-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements AfterViewInit, OnInit, OnDestroy {

  searchForm: FormGroup;
  public formErrors = {
    fromDate: ''
  };
  auditSearchParams: AuditSearchParams;
  pagingAndSortParams: PagingAndSortParams;
  dataSource: AuditDataSource;
  listSource: ListSource;

  displayedColumns = ['date', 'user', 'type', 'extra_data'];

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;

  constructor(@Inject(LOCALE_ID) private locale: string,
              private auditService: AuditService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private igFormService: IgFormService) {
  }

  ngOnInit() {
    this.dataSource = new AuditDataSource(this.auditService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this))).subscribe((listParams) => {
      // Load paging and sort params from url
      this.pagingAndSortParams = listParams.pagingAndSortParam;
      let sortColumn = this.pagingAndSortParams.sortColumn;
      if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
        sortColumn = 'auditEventDate';
      }
      // Load other, params and queryParams
      this.auditSearchParams = this.populateAuditSearchParams(listParams.params);
      this.dataSource.loadAudits(this.auditSearchParams.fromDate, this.auditSearchParams.toDate,
        sortColumn + ',' + this.pagingAndSortParams.sortDirection,
        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize);
    });
    this.buildSearchForm();
  }

  buildSearchForm() {
    this.searchForm = this.formBuilder.group({
      fromDate: [this.auditSearchParams.fromDate, Validators.required],
      toDate: [this.auditSearchParams.toDate]
    });
    this.searchForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.searchForm, this.formErrors, true);
    });
  }

  submitSearchForm() {
    this.igFormService.markFormGroupTouched(this.searchForm);
    if (this.searchForm.valid) {
      this.auditSearchParams.fromDate = formatDate(this.searchForm.get('fromDate').value, 'yyyy-MM-dd', this.locale);
      if (this.searchForm.get('toDate').value) {
        this.auditSearchParams.toDate = formatDate(this.searchForm.get('toDate').value, 'yyyy-MM-dd', this.locale);
      } else {
        this.auditSearchParams.toDate = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
      }
      this.loadAuditsPage();
    }
  }

  private populateAuditSearchParams(params: Params): AuditSearchParams {
    let fromDate = this.getDefaultFromDate();
    if (params['fromDate'] && params['fromDate'] !== '') {
      fromDate = params['fromDate'];
    }
    let toDate = this.getDefaultToDate();
    if (params['toDate'] && params['toDate'] !== '') {
      toDate = params['toDate'];
    }
    return new AuditSearchParams(fromDate, toDate);
  }

  getDefaultFromDate() {
    const previoussixmonth = new Date();
    previoussixmonth.setMonth(previoussixmonth.getMonth() - 6);
    return formatDate(previoussixmonth, 'yyyy-MM-dd', this.locale);
  }

  getDefaultToDate() {
    const currentDate = new Date();
    return formatDate(currentDate, 'yyyy-MM-dd', this.locale);
  }

  ngAfterViewInit(): void {
    // initiate sort and pagination change once the view is initialized
    this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadAuditsPage());
  }

  loadAuditsPage() {
    this.router.navigate(['system-admin/audit'], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    queryParams.queryParams['fromDate'] = this.auditSearchParams.fromDate;
    queryParams.queryParams['toDate'] = this.auditSearchParams.toDate;
    return queryParams;
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }
}

