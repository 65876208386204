import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';
import { QuestionnaireConfigurationService } from '../questionnaire-configuration.service';



export class QuestionnaireConfigurationListDataSource extends  DataSource<QuestionnaireConfigurationService> {

  private questionnaireConfigurationSubject = new BehaviorSubject<QuestionnaireConfigurationService[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private questionnaireConfigurationService: QuestionnaireConfigurationService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<QuestionnaireConfigurationService[]> {
    return this.questionnaireConfigurationSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.questionnaireConfigurationSubject.complete();
    this.totalCount$.complete();
  }

  loadquestionnaireConfigs(sortDirection = 'asc', pageIndex = 0, pageSize = 3, questionnaireCode: String, businessLineId: number) {

    this.questionnaireConfigurationService.findQuestionnaireConfigs({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      questionnaireCode: questionnaireCode,
      businessLineId: businessLineId,
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.questionnaireConfigurationSubject.next(response['body']);
    });
  }

}
