<mat-card style="overflow-y: hidden; height:95%;">
  <mat-card-header class="page-title">
    <div mat-card-avatar class="button-icon">
      <button mat-raised-button (click)="goToUserListPage()">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
    </div>
    <mat-card-title *ngIf="!editable" class="button-icon-text">View User</mat-card-title>
    <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create User</mat-card-title>
    <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit User</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="userEditorForm" novalidate (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
        class="three-per-row">
        <input name="id" type="hidden" formControlName="id" />
        <mat-form-field>
          <input name="firstName" matInput type="text" formControlName="firstName" placeholder="First Name"
            [attr.tabindex]="0" />
          <mat-error align="start" *ngIf="formErrors.firstName">{{ formErrors.firstName }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input name="lastName" matInput type="text" formControlName="lastName" placeholder="Last Name" />
          <mat-error align="start" *ngIf="formErrors.lastName">{{ formErrors.lastName }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input name="email" matInput type="text" formControlName="email" placeholder="Email" />
          <mat-error align="start" *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input name="login" matInput type="text" formControlName="login" placeholder="Username" />
          <mat-error align="start" *ngIf="formErrors.login">{{ formErrors.login }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input name="imeiNumber" matInput type="text" formControlName="imeiNumber" placeholder="IMEI Number"
            [attr.tabindex]="0" />
          <mat-error align="start" *ngIf="formErrors.imeiNumber">{{ formErrors.imeiNumber }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input name="employeeId" matInput type="number" min="0" formControlName="employeeId" placeholder="Employee Id"
            [attr.tabindex]="0" />
          <mat-error align="start" *ngIf="formErrors.employeeId">{{ formErrors.employeeId }}</mat-error>
        </mat-form-field>
        <!-- <mat-form-field *ngIf="editable && !user.id">
          <input name="password" matInput type="password" formControlName="password" placeholder="Password" />
          <mat-error align="start" *ngIf="formErrors.password">{{ formErrors.password }}</mat-error>
        </mat-form-field> -->
        <!--<div class="mat-form-field" *ngIf="editable && !user.id">
          <mat-checkbox formControlName="passwordAutoGenerate" (change)="passwordAutoGenerateChange($event)" >Password Auto Generate</mat-checkbox>
        </div>-->
        <mat-form-field>
          <mat-select placeholder="Roles" name="role" formControlName="role" (selectionChange)="onRoleSelection($event)">
            <mat-option *ngFor="let role of roles" [value]="role">
              {{role}}
            </mat-option>
          </mat-select>
          <mat-error align="start" *ngIf="formErrors.role">{{ formErrors.role }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-select placeholder="Business Line" name="businessLineId" formControlName="businessLineId"
          (selectionChange)='onOptionsSelected($event)'>
            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
              {{businessLine.name}}
            </mat-option>
          </mat-select>
          <mat-error align="start">  {{formErrors.businessLineId}}</mat-error>
        </mat-form-field>
        
        <mat-form-field>
          <input name="imageUrl" matInput type="text" formControlName="imageUrl" placeholder="Image Url" />
          <mat-error align="start" *ngIf="formErrors.imageUrl">{{ formErrors.imageUrl }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input name="langKey" matInput type="text" formControlName="langKey" placeholder="Language" />
          <mat-error align="start" *ngIf="formErrors.langKey">{{ formErrors.langKey }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <input type="text" placeholder="Select Branchset" matInput formControlName="branchsetId" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let branch of filteredOptions | async" [value]="branch.name">
              {{branch.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error align="start" *ngIf="formErrors.branchsetId">{{ formErrors.branchsetId }}</mat-error>
        </mat-form-field>&nbsp;&nbsp;
        
        <mat-form-field>
          <input name="templeteHeaderId" readonly matInput type="text" formControlName="templeteHeaderId"
              placeholder="Select Template" />
          <mat-error align="start" *ngIf="formErrors.templeteHeaderId">{{ formErrors.templeteHeaderId }}</mat-error>
      </mat-form-field>
        <!-- <mat-card-actions style="width: 28%;">
          <div>
              <ng-multiselect-dropdown  formControlName="templeteHeaderId" [placeholder]="'Select Template'"
                                       [data]="templateDropdownList" 
                                       [disabled]="!editable"
                                       [(ngModel)]="selectedItems"
                                       [settings]="templateDropdownSettings"
                                       (onSelect)="onSelectTemplate($event)" required>
              </ng-multiselect-dropdown>
              <mat-error *ngIf="formErrors.templeteHeaderId" >{{formErrors.templeteHeaderId}}</mat-error>
          </div>
        </mat-card-actions> -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <div class="mat-form-field" style="padding: 10px;">
          <mat-slide-toggle name="activated" formControlName="activated">Activated</mat-slide-toggle>
        </div>
        
        <mat-card-actions *ngIf="editable" class="pull-right">
          <button mat-raised-button color="primary">
            <mat-icon>save</mat-icon> Submit</button>

          <button mat-raised-button (click)="goToUserListPage()">Cancel</button>
        </mat-card-actions>
      </div>

    </form>
  </mat-card-content>
</mat-card>