<mat-card style=" overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="onCancel()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="isViewMode" class="button-icon-text">
            View Auto Populate Tag
        </mat-card-title>
        <mat-card-title *ngIf="editable && isNew" class="button-icon-text">
            Create Auto Populate Tag
        </mat-card-title>
        <mat-card-title *ngIf="editable && !isNew && !isViewMode" class="button-icon-text">
            Edit Auto Populate Tag
        </mat-card-title>
    </mat-card-header>

    <form [formGroup]="autoPopulateForm">
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="16px" fxLayoutAlign="start">
            <mat-form-field style="flex: 1;">
                <input name="code" matInput type="text" formControlName="code" placeholder="Tag Name" required>
                <mat-error *ngIf="autoPopulateForm.get('code').hasError('required')">Tag Name is required</mat-error>
                <mat-error *ngIf="autoPopulateForm.get('code').hasError('duplicate')">
                    Tag Name already exists. Please use new unique tag name.
                </mat-error>
            </mat-form-field>
            <mat-form-field style="flex: 1;">
                <mat-select placeholder="Auto Populate Module" formControlName="autoPopulateModuleId" required>
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let autoPopulate of autoPopulates" [value]="autoPopulate.id">
                        {{ autoPopulate.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="autoPopulateForm.get('autoPopulateModuleId').hasError('required')">
                    Auto Populate Module is required
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="fill" style="width: 100%; margin-top: 16px;">
                <mat-label>Query</mat-label>
                <textarea matInput formControlName="query" placeholder="Enter your query here" required></textarea>
                <mat-error *ngIf="autoPopulateForm.get('query').hasError('required')">
                    Query is required
                </mat-error>
                <mat-error *ngIf="autoPopulateForm.get('query').hasError('invalidQuery')">Invalid query</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="16px" fxLayoutAlign="start">
            <mat-form-field *ngIf="!isViewMode" style="flex: 0 0 50%;">
                <input name="PrimaryKey" matInput type="text" formControlName="primaryKey" placeholder="PrimaryKey" required>
                <mat-error *ngIf="autoPopulateForm.get('primaryKey').hasError('required')">
                    PrimaryKey is required
                </mat-error>
            </mat-form-field>
            <mat-form-field style="flex: 1;">
                <mat-select placeholder="Datasource" formControlName="dataSourceId" required>
                    <mat-option *ngFor="let datasource of datasources" [value]="datasource.id">
                        {{ datasource.name }}
                    </mat-option>
                    <!-- <mat-option *ngIf="datasources?.length > 0" [value]="datasources[0].id">
                        {{ datasources[0].name }}
                    </mat-option> -->
                </mat-select>
                <mat-error *ngIf="autoPopulateForm.get('dataSourceId').hasError('required')">
                    Datasource is required
                </mat-error>
            </mat-form-field>
        </div>

        <mat-card-actions *ngIf="!isViewMode" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="16px"
            style="margin-top: 16px;">
            <button mat-raised-button color="primary" type="button" [disabled]="isQueryValid" (click)="validateDescription()">
                Validate Query
            </button>
        </mat-card-actions>

        <mat-card-actions *ngIf="!isViewMode" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px"
            style="margin-top: 16px;">
            <button mat-raised-button color="primary" type="button" (click)="onSubmit()"
                [disabled]="!isQueryValid">Submit</button>
            <button mat-raised-button type="button" (click)="onCancel()">Cancel</button>
        </mat-card-actions>
    </form>
    <mat-card-content *ngIf="!isViewMode" class="protocol-box" style="margin-top: 20px;">
        <h3>Instructions</h3>
        <ul>
            <li>Please fill out all required fields.</li>
            <li>The query field should contain a valid SQL query, accepting only the PRIMARYKEY format within curly
                braces {{ '{' }}PRIMARYKEY{{ '}' }}.</li>
            <li>The Primary Key must be unique and correspond to the selected Auto Populate Module. Ensure the Primary
                Key is defined correctly based on the module.</li>
            <li>For the selected Auto Populate Module, the Primary Key should be defined as follows:</li>
            <ul>
                <li><strong>Branch:</strong> `branchid`</li>
                <li><strong>Centre:</strong> `centreid`</li>
                <li><strong>Employee:</strong> `employeeid`</li>
                <li><strong>Customer:</strong> `customerid`</li>
                <li><strong>Loan:</strong> `loanid`</li>
            </ul>
            <li>Select the appropriate data source before submitting the form.</li>
        </ul>
    </mat-card-content>
</mat-card>