import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { AuditTransactionService } from "./audit-transaction-service";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ObservationResponseRemarks } from "./observation-response-remark.model";

@Component({
  selector: "app-file-delete-dialog",
  templateUrl: "./file-delete-dialog.component.html",
})
export class FileDeleteDialogComponent implements OnInit, OnDestroy {
  ObservationResponseRemarks: ObservationResponseRemarks;
  fileId: number;
  errorMessage: string;
  message: string;
  file: any;
  constructor(
    private auditTransactionService: AuditTransactionService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FileDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fileId = data.fileId;
  }

  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }
  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  onDelete() {
    // this.errorMessage = '';
    if(this.fileId) {
    this.auditTransactionService
      .deleteFile(this.fileId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (deleteresponse) => this.deleteSuccess(deleteresponse),
        (deleteresponse) => this.deleteError(deleteresponse)
      );
      this.dialogRef.close();
    }
  }
  deleteSuccess(deleteresponse) {
    this.snackBar.open(`Deleted successfully`, "Close", {
      duration: 3000,
    });
  }
  

  deleteError(deleteresponse) {
    const errormessage = deleteresponse.error.title;
    this.snackBar.open(errormessage, "Close", {
      duration: 3000,
    });
  }
  
  cancel(): void {
    this.dialogRef.close();
  }
}
