export class ObservationResponseAction {
    constructor(
        public observationResponseDetailId?: number,
        public action?: string,
        public nextAction?: string,
        public remarks?: string,
        public actionDate?: string,
        public user?: string,
        public questionnaireResponseId?: number,
        public isUserMarked?: boolean
    ) { }
}
