<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToAuditTransactionPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title class="button-icon-text">View AuditTransaction</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="auditTransactionEditorForm" novalidate>
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row">
                <input name="id" type="hidden" formControlName="id" />
                <mat-form-field>
                    <input name="branchName" matInput type="text" formControlName="branchName" placeholder="branchName" [attr.tabindex]="0" />
                    <mat-error align="start" *ngIf="formErrors.branchName">{{ formErrors.branchName }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="branchCode" matInput type="text" formControlName="branchCode" placeholder="Branch Code" />
                    <mat-error align="start" *ngIf="formErrors.branchCode">{{formErrors.branchCode}}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="auditNo" matInput type="text" formControlName="auditNo" placeholder="auditNo" />
                    <mat-error align="start" *ngIf="formErrors.auditNo">{{ formErrors.auditNo }}</mat-error>
                </mat-form-field>
                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="primary">
                        <mat-icon>save</mat-icon>Submit</button>
                    <button mat-raised-button (click)="goToAuditTransactionPage()">
                        <mat-icon>cancel</mat-icon>Cancel</button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card-content>
</mat-card>
<div>
    <app-audit-transaction-workflow-history [auditHistories]="auditTransaction.auditFlowHistories">
    </app-audit-transaction-workflow-history>
</div>