<mat-card style="height: 95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>cloud_download</mat-icon>
        </div>
        <div mat-card-title>
            Questionnaire Response Report
        </div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="questionaireReportForm" novalidate (ngSubmit)="onSubmit()">
            <div style="display: flex; flex-wrap: wrap;">
                <!-- <mat-form-field>
                        <mat-select name="name" formControlName="name" placeholder="Branches" [(ngModel)]="selectedBranches" [formControl]="branchSelectIndicator" [attr.tabindex]="0" multiple >
                            <mat-select-trigger>
                                {{branchSelectIndicator.value? branchSelectIndicator.value[0].name : ''}}
                                <span *ngIf="branchSelectIndicator.value?.length > 1">
                       (+{{branchSelectIndicator.value.length - 1}} {{branchSelectIndicator.value?.length === 2 ? 'other' : 'others'}})
                         </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let branch of branches" [value]="branch">{{branch.name}}</mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
                    </mat-form-field>
                    &nbsp; -->

                    <mat-form-field *ngIf="isRoleAdmin">
                        <mat-select placeholder="Business Line"  formControlName="businessLineId" 
                        (selectionChange)="changeBusinessLine($event)">
                          <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                            {{businessLine.name}} 
                          </mat-option>
                        </mat-select>
                        <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                      </mat-form-field>
                      &nbsp;&nbsp;
      
                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                        [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                        (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)" required>
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.stateName && questionaireReportForm.controls['stateName']?.touched ">{{ formErrors.stateName }}
                    </mat-error>
                </div>

                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                        [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)" required>
                    </ng-multiselect-dropdown>
                    <mat-error align="start" *ngIf="formErrors.name && questionaireReportForm.controls['name']?.touched ">{{ formErrors.name }}
                    </mat-error>
                </div>

                <mat-form-field style="margin-left: 20px">
                    <input matInput name="fromDateFilter" formControlName="fromDateFilter" [max]="toDateFilter"
                        (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter "
                        [matDatepicker]="fromDatePicker" placeholder="FromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter }}
                    </mat-error>
                </mat-form-field>
                &nbsp;

                <mat-form-field>
                    <input matInput name="toDateFilter" formControlName="toDateFilter" [min]="fromDateFilter"
                        (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
                        [matDatepicker]="toDatePicker" placeholder="ToDate">
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter }}</mat-error>
                </mat-form-field>

                &nbsp;
                <mat-form-field>
                    <mat-select name="code" formControlName="code" [(ngModel)]="code" placeholder="Questionnaire Type">
                        <mat-option *ngFor="let questionnaireCodes of questionnaireCodesList"
                            [value]="questionnaireCodes.code">{{questionnaireCodes.code}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.code}}</mat-error>
                </mat-form-field>

                 &nbsp;
                <div style="margin-top: 10px;">
                    <button type="submit" mat-raised-button color="primary">
                        <mat-icon>cloud_download</mat-icon> Download Report
                    </button>
                    &nbsp;
                    <button type="button" style="height: 40px;" mat-raised-button color="primary"
                        (click)="clearFilter()">Clear</button>
                </div>

            </div>
        </form>
    </mat-card-content>
</mat-card>