import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuditReportParams } from "./audit-report-params.model";
import { DownloadFormatType } from "./download-format";
import { HrReportParms } from "../hrReport/hr.model";
import { fileNameHeader } from "../../constants/data.constants";
import { BMTActivityTrackerReport } from "../activityTrackerReport/bmt-activity-tracker-report.model";
import { Branch } from "../../admin/branch/branch.model";
import { createRequestOption } from "projects/ig-core/src/lib/utils/request-util";
import { BranchEfficiencyParams } from "../efficiency-report/efficiency.model";
import { CihReportParms } from "../cih-report/cih-report.model";
import { BranchDeviationParams } from "../deviation-report/deviation.model";
import { formatDate } from "@angular/common";
import { BmtReportParamsModel } from "../bmtProductivityReport/bmt-report-params.model";
import { BmtDataModel } from "../bmtProductivityReport/bmt-report-useractivity.model";
import { BranchVisitParams } from "../branch-visit-monitor/branch-visit.model";
import { HardBucketReportParms } from "../hard-bucket-collection/hard-bucket.model";
import { Import } from "../import/import.model";
import { CrossFuunctionalDeviationParams } from "../cross-functional-deviation/cross-functional.model";
import { ActivityReportParms } from "../activity-report/activity.model";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient) {}

  private API = "api/bmt-dashboard-user-data";
  private API1 = "api/crm/";
  fromDateFilter: string;
  toDate: string;
  reportType: string;
  branches: string;
  isDetailed = false;
  classifier: string;



  /**
   * this is to make set get methode where ever we need make communicate between component
   */
  barChartToggel: boolean;
  ProductivityData: any;
  ProductivityStateData: any;

  getAllBranchesForUser(): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.set("isDetailed", this.isDetailed.toString());
    return this.http.get<any>("api/branches/user", {
      params,
      observe: "response",
    });
  }

  getAllAuditPlansForBranchCode(
    branchCode: string
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.set("branchCode", branchCode);
    return this.http.get<any>("api/audit-plans/completed/branch", {
      params,
      observe: "response",
    });
  }

  getAllQuestionnaireVersion(code?: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.set("code", code);
    return this.http.get<any>("api/questionaires/version/", {
      params,
      observe: "response",
    });
  }

  downloadExcel(req?: any): Observable<any> {
    const apiUrls = "api/" + req.reportType + "/download/report";
    const params = new AuditReportParams();
    if (req.downloadFormat !== undefined) {
      params.downloadFormat = req.downloadFormat;
    }
    if (req.branchCodes !== undefined) {
      params.branchCodes = req.branchCodes;
    }
    if (req.branchCode !== undefined) {
      params.branchCode = req.branchCode;
    }
    if (req.fromDate !== undefined) {
      params.fromDate = new Date(
        formatDate(req.fromDate, "yyyy-MM-dd", "en-us")
      );
    }
    if (req.toDate !== undefined) {
      params.toDate = new Date(formatDate(req.toDate, "yyyy-MM-dd", "en-us"));
    }
    if (req.auditForMonth !== undefined) {
      params.auditForMonth = req.auditForMonth;
    }

    if (req.auditForMonths !== undefined) {
      params.auditForMonths = req.auditForMonths;
    }
    if (req.auditForYear !== undefined) {
      params.auditForYear = req.auditForYear;
    }
    if (req.states !== undefined) {
      params.stateNames = req.states;
    }
    if (req.auditId !== undefined) {
      params.auditId = req.auditId;
    }
    if (req.questionnaireVersionCode !== undefined) {
      params.questionnaireVersionCode = req.questionnaireVersionCode;
    }
     if(req.weightageType !== undefined){
        params.weightageType = req.weightageType;
     }
    return this.http
      .post(apiUrls, params, { observe: "response", responseType: "blob" })
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const out = { data: null, filename: null };
          out.data = resp.body;
          let fileName: string = resp.headers.get(fileNameHeader);
          if (fileName == null) {
            let type: any;
            type =
              req.downloadFormat === DownloadFormatType.EXCEL
                ? ".xlsx"
                : ".pdf";
            fileName = req.reportType + type;
          }
          out.filename = fileName;
          return out;
        })
      );
  }

  downloadUserDetailsReport(req?: any): Observable<any> {
    const param = new BmtReportParamsModel();

    if (req.roles !== undefined) {
      param.roles = req.roles;
    }
    if (req.businessLineId !== undefined) {
      param.businessLineId = req.businessLineId;
    } //endif

    return this.http
      .post("api/download/user-detail-file", param, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const out = { data: null, filename: null };
          out.data = resp.body;
          let fileName: string = resp.headers.get(fileNameHeader);
          if (fileName == null) {
            let type: any;
            type =
              req.downloadFormat === DownloadFormatType.EXCEL
                ? ".xlsx"
                : ".pdf";
            fileName = "User-Detail-Report" + ".xlsx";
          }
          out.filename = fileName;
          return out;
        })
      );
  }


  getAllReportTypeForUser(): Observable<HttpResponse<any>> {
    this.classifier = "AUDIT_REPORT_TYPE";
    let params = new HttpParams();
    params = params.set('classifier',this.classifier);
    return this.http.get<any>('api/reference-codes/audit-cr', {params, observe: 'response'});
}

    getAllSubordinatesForStateAndRole(states?: any, roles?: any
        , status?: string,businessLineId?:number): Observable<HttpResponse<string[]>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
            params = params.set('businessLineId', businessLineId);
        }
        if (states) {
            params = params.set('states', states);
        }
        if (roles) {
            params = params.set('roles', roles);
        }
        if (status) {
            params = params.set('status', status);
        }
        return this.http.get<any>('api/users/getSubordinates', { params, observe: 'response' });
    }

    loadActivityTrackerReports(request: any): Observable<any> {
        const bmtActivityTrackerReport = new BMTActivityTrackerReport;
        if(request.businessLineId){
           bmtActivityTrackerReport.businessLineId = request.businessLineId;
        }
        if (request.userLogins) {
            bmtActivityTrackerReport.userLogins = request.userLogins;
        }
        if (request.fromDate) {
            bmtActivityTrackerReport.fromDate = request.fromDate;
        }
        if (request.toDate) {
            bmtActivityTrackerReport.toDate = request.toDate;
        }
        return this.http.post('api/dashboard/activity-tracker', bmtActivityTrackerReport, { observe: 'response' });
    }

    downloadActivityReport(request: any): Observable<any> {
        const bmtActivityTrackerReport = new BMTActivityTrackerReport;
        if(request.businessLineId){
            bmtActivityTrackerReport.businessLineId = request.businessLineId;
         }
        if (request.users) {
            bmtActivityTrackerReport.userLogins = request.users;
        }
        if (request.fromDate) {
            bmtActivityTrackerReport.fromDate = request.fromDate;
        }
        if (request.toDate) {
            bmtActivityTrackerReport.toDate = request.toDate;
        }
        return this.http.post('api/dashboard/download/activity-report', bmtActivityTrackerReport,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            out.filename = 'BMTActivityReport' + '.csv';
            return out;
        }));
    }

    getAllBranchesForStates(stateName: any): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.set('stateName', stateName);
        return this.http.get<any>('api/state/branches', { params, observe: 'response' });
    }

    /**
     * This method is used to call the download api for the hr reports
     */
    downloadHrReport(req?: any): Observable<any> {
        const param = new HrReportParms;

        if (req.type !== undefined) {
            param.type = req.type;
        }

        if (req.businessLineId !== undefined) {
            param.businessLineId = req.businessLineId;
        }

        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if

        return this.http.post('api/_download/employee-concern/question-response', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'HRReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }


    getAllStatesForUser(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/branches/branchsetStates', { observe: 'response' });
    }

    getAllBranchesForStatesAndUser(stateName: any, showOnlyMappedBranches: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        params = params.set('stateName', stateName);
        params = params.set('showOnlyMappedBranches', showOnlyMappedBranches);
        return this.http.get<Branch[]>('api/branches/state-search', { params: params, observe: 'response' });
    }

    getBMTProductivityData(request: any): Observable<any> {

        let params = new BmtReportParamsModel();
        params = request.bmtReportParamModels;
        return this.http.post<any>('api/bmtReports/productivity', params, { observe: 'response' });
    }

    /**
     * This method is used to call the download api for the BMT efficiency reports
     * @author J Suhas Bhat
     * @since v2.8.0
     */
    downloadBmtEfficiencyReport(req?: any): Observable<any> {

        const param = new BranchEfficiencyParams;

        if (req.selectedMonths !== undefined) {
            const selectedMonthCodes = [];
            for (const month of req.selectedMonths) {
              selectedMonthCodes.push(month.code);
            }
            param.months = selectedMonthCodes;
          }// end if
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if
        if (req.type !== undefined) {
            param.type = req.type;
        }// end if

        if (req.selectedYear !== undefined) {
            param.year = Number.parseInt(req.selectedYear, 10);
        }// end if
        if(req.businessLineId !== undefined) {
            param.businessLineId = req.businessLineId;
        }
        const options = createRequestOption(req);
        return this.http.post('api/bmtReports/efficiency',
        param, {observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'BmtEfficiencyReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));

    }

    downloadBmtDeviationReport(req?: any): Observable<any> {
        const param = new BranchDeviationParams;
        if (req.type !== undefined) {
            param.type = req.type;
        }// end if

        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if
        if(req.businessLineId !== undefined) {
            param.businessLineId = req.businessLineId;
        }

        const options = createRequestOption(req);
        return this.http.post('api/bmtReports/deviation', param,
          { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'BmtDeviationReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    findProductivityActiveCountData(req: any): Observable<HttpResponse<BmtDataModel[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.stateName) {
            params = params.set('stateName', req.stateName);
        }
        if (req.branchCodes) {
            params = params.set('branchCodes', req.branchCodes);
        }
        if (req.roles) {
            params = params.set('roles', req.roles);
        }
        if (req.months) {
            params = params.set('months', req.months);
        }
        if (req.year) {
            params = params.set('year', req.year);
        }
        if (req.activities) {
            params = params.set('activities', req.activities);
        }
        return this.http.get<BmtDataModel[]>(this.API, {
            params, observe: 'response'
        });
    }

    /**
     * this is to make set get methode where ever we need make communicate between component
     */


    setBarChart() {
        this.barChartToggel = true;
    }

    getBarChart() {

        return this.barChartToggel;
    }

    bmtTableSet(branchCode: any, activity: any, role: any, year: any) {
        const ProductivityData = {
            'branchCode': branchCode,
            'activity': activity,
            'role': role,
            'year': year
        };
        this.ProductivityData = ProductivityData;
    }

    bmtTablesSet(stateName: any, month: any) {
        const ProductivityData = {
            'stateName': stateName,
            'month': month
        };
        this.ProductivityStateData = ProductivityData;
    }

    bmtTableGet() {
        const result = {
            'ProductivityData': this.ProductivityData,
            'ProductivityStateData': this.ProductivityStateData
        };
        return result;
    }


    downloadUserData(req?: any): Observable<any> {
        const apiUrls = 'api/bmt-dashboard-user-export-data';
        const param = new BmtReportParamsModel();

        if (req.stateName !== undefined) {
            param.stateName = req.stateName;
        }// end if
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if
        if (req.activities !== undefined) {
            param.activiy = req.activities;
        }// end if
        if (req.roles !== undefined) {
            param.roles = req.roles;
        }// end if
        if (req.months !== undefined) {
            param.month = req.months;
        }// end if
        if (req.year !== undefined) {
            param.year = req.year;
        }// end if
        param.isExport = true;

        return this.http.post(apiUrls, param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = req.reportType + type;
            }
            out.filename = fileName;
            return out;
        }));
    }

    // downloadScorecardReport(req?: any): Observable<any> {
    //     const options = createRequestOption(req);
    //     return this.http.post('api/scorecard/download/report',
    //         { params: options, observe: 'response', responseType: 'blob' }
    //     ).pipe(map((resp: HttpResponse<Blob>) => {
    //         const out = { data: null, filename: null };
    //         out.data = resp.body;
    //         let fileName: string = resp.headers.get(fileNameHeader);
    //         if (fileName == null) {
    //             let type: any;
    //             type = req.downloadFormat === DownloadFormatType.PDF;
    //             fileName = 'ScorecardReport' + '.pdf';
    //         }
    //         out.filename = fileName;
    //         return out;
    //     }));
    // }


    showUserData(req?: any): Observable<any> {

        const param = new BmtReportParamsModel();

        if (req.stateName !== undefined) {
            param.stateName = req.stateName;
        }// end if
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if
        if (req.activities !== undefined) {
            param.activiy = req.activities;
        }// end if
        if (req.roles !== undefined) {
            param.roles = req.roles;
        }// end if
        if (req.months !== undefined) {
            param.month = req.months;
        }// end if
        if (req.year !== undefined) {
            param.year = req.year;
        }// end if
        param.isExport = false;

        return this.http.post<any>('api/bmt-dashboard-summary-data',
            param, { observe: 'response' });

    }


    /**
     * This method is used to call the download api for the branch Visit Monitor Report.
     */
    downloadBranchVisitReport(req?: any): Observable<any> {
        const param = new BranchVisitParams;

        if (req.type !== undefined) {
            param.type = req.type;
        }

        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }// end if
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if


        return this.http.post('api/bvm-dashboard-monitor-report-data', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'HRReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadCihReport(req?: any): Observable<any> {
        const param = new CihReportParms;

        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.fromDate !== undefined && req.fromDate !== "") {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined && req.toDate !== "") {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if
        if (req.statuses !== undefined && req.statuses !== "") {
            param.statuses = req.statuses;
        }
        if (req.month !== undefined) {
            param.month = req.month;
            console.log(param.month)

        }else{
            console.log("Year")
        }
        if (req.year !== undefined) {
            param.year = req.year;
            console.log(param.year)
        }else{
            console.log("No value")

        }

        return this.http.post('api/cih-report', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'HRReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadStaffMovementReport(req?: any): Observable<any> {
        const param = new CihReportParms;

        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if

        return this.http.post('api/staff-movement-registers/response', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'WatingForResponse' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadCrmTemplate(): Observable<any> {
        return this.http.get('api/crm/download/user-branch-mapping-template',
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            out.filename = 'CreditRiskModelTemplate' + '.xlsx';
            return out;
        }));
    }

    uploadCrmFile(multipartFile: File): Observable<HttpResponse<String>> {
        const formData = new FormData();
        formData.set('multipartFile', multipartFile);
        return this.http.post<String>(this.API1 + 'file-upload', formData, { observe: 'response', responseType: 'text' as 'json' });
    }

   uploadJobFile(jobType: string, brNetUploadDate: string | Date | null, multipartFile: File): Observable<HttpResponse<string>> {
    const formData = new FormData();
    formData.set('files', multipartFile);
    const formattedDate = brNetUploadDate ? this.formatDateAdjusted(brNetUploadDate) : this.formatDateAdjusted(new Date());
    const url = `api/file-upload-common/${jobType}/${formattedDate ?? ''}`;
    return this.http.post<string>(url, formData, { observe: 'response', responseType: 'text' as 'json' });
}

// Assume formatDateAdjusted is defined elsewhere in your class
private formatDateAdjusted(brNetUploadDate: string | Date): string | null {
    const dateObj = new Date(brNetUploadDate);

    if (isNaN(dateObj.getTime())) {
        console.error("Invalid Date:", brNetUploadDate);
        return null;
    }

    // Adjust for the timezone offset
    const timezoneOffset = dateObj.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(dateObj.getTime() - timezoneOffset);

    return adjustedDate.toISOString().split('T')[0];
}


    executeJobFile(jobId: any): Observable<HttpResponse<String>> {
        let params = new HttpParams();
        return this.http.post<String>('api/updateJobDetailsStatus/' + jobId, params, { observe: 'response', responseType: 'text' as 'json' });
    }

    importData(req: any): Observable<HttpResponse<Import[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.jobStatus) {
            params = params.set('jobStatus', req.jobStatus);
        }
        if (req.jobType) {
            params = params.set('jobType', req.jobType);
        }
        if (req.jobName) {
            params = params.set('jobName', req.jobName);
        }
        if (req.uploadedDate) {
            params = params.set('uploadedDate', req.uploadedDate);
        }
        return this.http.get<Import[]>('api/job-details/pageable', {
            params, observe: 'response'
        });

        // return this.http.get<Import[]>('api/job-details/pageable', {
        //      observe: 'response'
        // });
    }

    downloadHardBucketReport(req?: any): Observable<any> {
        const param = new HardBucketReportParms;

        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if

        return this.http.post('api/hardbucketcollection-report', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'HRReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    uploadScoreCardFile(multipartFile: File): Observable<HttpResponse<any>> {
        const formData = new FormData();
        formData.set('multipartFile', multipartFile);
        return this.http.post<any>('api/scorecard/file-upload', formData, { observe: 'response', responseType: 'text' as 'json' });
    }

    downloadScoreCardTemplate(): Observable<any> {
        return this.http.get('api/url',
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            out.filename = 'CreditRiskModelTemplate' + '.xlsx';
            return out;
        }));
    }

    generateReportData(fromDate: string, toDate: string, branchCodes: string[], type: string, scoreCardCode: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        params = params.set('type', type);
        params = params.set('scoreCardCode', scoreCardCode);
        return this.http.post<any>('api/scorecard/generate-reportdata', branchCodes, { params, observe: 'response', responseType: 'text' as 'json' });
    }

    generateStatisticData(fromDate: string, toDate: string, branchCodes: string[], type: string, scoreCardCode: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        params = params.set('type', type);
        params = params.set('scoreCardCode', scoreCardCode);
        return this.http.post<any>('api/scorecard/generate-statisticdata', branchCodes, { params, observe: 'response', responseType: 'text' as 'json' });
    }

    getScorecardTemplates(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/score-card-templates-list', { observe: 'response' });
    }

    getLatestSyncTransaction(syncName: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('syncName', syncName);
        return this.http.get<any>('api/latest-sync-transactions', { params, observe: 'response', responseType: 'text' as 'json' });
    }

    calculatedScore(scoreCardCode: string, isAnalyse: any, fromDate: string, toDate: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('scoreCardCode', scoreCardCode);
        params = params.set('isAnalyse', isAnalyse);
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        if (isAnalyse === "YES") {
            return this.http.get<any>('api/scorecard/calculate-score', { params, observe: 'response', responseType: 'text' as 'json' });
        } else {
            return this.http.get<any>('api/scorecard/get-scorecard-branches', { params, observe: 'response', responseType: 'text' as 'json' });
        }
    }

    

    downloadScorecardReport(fromDate: string, toDate: string, scoreCardCode: string, branchCodes: string[], downloadFormatType: any): Observable<any> {
        let params = new HttpParams;
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        params = params.set('scoreCardCode', scoreCardCode);
        params = params.set('downloadFormatType', downloadFormatType);
        return this.http.post('api/scorecard/download/report', branchCodes, { params, observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                fileName = 'ScorecardReport' + '.pdf';
            }
            out.filename = fileName;
            return out;
        }));
    }

    generateContributerdata(fromDate: string, toDate: string, branchCodes: string[], type: string, scoreCardCode: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        params = params.set('type', type);
        params = params.set('scoreCardCode', scoreCardCode);
        return this.http.post<any>('api/scorecard/generate-contributerdata', branchCodes, { params, observe: 'response', responseType: 'text' as 'json' });
    }

    generateTableDate(fromDate: string, toDate: string, branchCodes: string[], type: string, scoreCardCode: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        params = params.set('type', type);
        params = params.set('scoreCardCode', scoreCardCode);
        return this.http.post<any>('api/scorecard/generate-tabledata', branchCodes, { params, observe: 'response', responseType: 'text' as 'json' });
    }

    getTaskTableDate(type: string, scoreCardCode: string, questionaireResponseId: any): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('questionaireResponseId', questionaireResponseId);
        params = params.set('type', type);
        params = params.set('scoreCardCode', scoreCardCode);
        return this.http.get<any>('api/scorecard/gettask-questionnaireid', { params, observe: 'response', responseType: 'text' as 'json' });
    }

    generateBasicActivityCountdata(fromDate: string, toDate: string, branchCodes: string[], type: string, scoreCardCode: string): Observable<HttpResponse<any>> {
        let params = new HttpParams;
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        params = params.set('type', type);
        params = params.set('scoreCardCode', scoreCardCode);
        return this.http.post<any>('api/scorecard/generate-basicactivitycountdata', branchCodes, { params, observe: 'response', responseType: 'text' as 'json' });
    }

    downloadFileImportTemplate(templateName: string): Observable<any> {
        return this.http.get('api/template/download/' + templateName, { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            out.filename = templateName + '.xlsx';
            return out;
        }));
    }

    downloadEmployeeLeaveDetailTemplate(): Observable<any> {
        return this.http.get('api/download/employee-leave-detail-template',
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            out.filename = 'EmployeeLeaveDetailTemplate' + '.xlsx';
            return out;
        }));
    }



    uploadEmployeeLeaveDetailFile(multipartFile: File): Observable<HttpResponse<String>> {
        const formData = new FormData();
        formData.set('multipartFile', multipartFile);
        return this.http.post<String>('api/employee-leave-details/file-upload',
            formData, { observe: 'response', responseType: 'text' as 'json' });
    }

    downloadCrossFunctionalDeviationReport(req?: any): Observable<any> {
        const param = new CrossFuunctionalDeviationParams;
        if (req.type !== undefined) {
            param.type = req.type;
        }// end if

        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if

        if (req.fromDate !== undefined) {
            param.fromDate = req.fromDate;
        }// end if

        if (req.toDate !== undefined) {
            param.toDate = req.toDate;
        }// end if
        const options = createRequestOption(req);
        return this.http.post('api/bmtReports/deviation',param,{ observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'BmtDeviationReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }


    downloadMandatoryActivityReport(req?: any): Observable<any> {
        const param = new ActivityReportParms;

        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.role !== undefined) {
            param.roles = req.role;
        }
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.month !== undefined) {
            param.month = req.month;
        }
        if (req.year !== undefined) {
            param.year = req.year;
        }
        if(req.businessLineId !== undefined) {
            param.businessLineId = req.businessLineId;
        }
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if

        return this.http.post('api/mandatory-activity-report', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'MAReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadActReport(req?: any): Observable<any> {
        const options = createRequestOption(req);
        return this.http.get('api/bmtReports/questionnaire-response',
            { params: options, observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                fileName = 'MAReport.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadAllCommonReport(req?: any): Observable<any> {
        const param = new CihReportParms;

        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if

        return this.http.post('api/common-report-download', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = req.type + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadInputOutPutFileId(req: any): Observable<any> {
        return this.http.get('api/file-download/filesystem/'+ req.type,
            {observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                fileName = 'file';
            }
            out.filename = fileName + '.xlsx';
            return out;
        }));
    }

    downloadScoreCardBranchscoringReport(req?: any): Observable<any> {
        const param = new BmtReportParamsModel;
        if (req.scoreCardCode !== undefined) {
            param.scoreCardCode = req.scoreCardCode;
        }// end if

        if (req.fromDate !== undefined) {
            param.fromDate = req.fromDate;
        }// end if

        if (req.toDate !== undefined) {
            param.toDate = req.toDate;
        }// end iftoDate
        
       
        return this.http.post('api/scorecard/download-allscore',param, 
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                fileName = 'ScoreCardReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadRagReport(req?: any): Observable<any> {
        const param = new BmtReportParamsModel;
        
        if (req.businessLineId !== undefined) {
            param.businessLineId = req.businessLineId;
        }
        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }

        return this.http.post('api/rag-sub-categories-report', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'RAGReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    getAllSubordinatesForStateAndRoleAndLOFAndStatus(
      branchCodes?: string[],
      roles?: any,
      status?: string,
      businessLineId?: number
    ): Observable<HttpResponse<string[]>> {
      const params = new BmtReportParamsModel();
      if (businessLineId !== undefined) {
        params.businessLineId = businessLineId;
      }
      if (branchCodes) {
        params.branchCodes = branchCodes;
      }
      if (roles) {
        params.roles = roles;
      }
      if (status) {
        params.status = status;
      }
      return this.http.post<any>("api/users/getSubordinates/activity-tracker-report", params ,{
        observe: "response",
      });
    }

}
