import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {BranchSet} from './branch-set.model';
import { LmsDatamodel } from '../user/lms-datasource.model';
import { BusinessLineDatamodel } from '../branch/business-line.model';

@Injectable({
    providedIn: 'root'
})
export class BranchSetService {

    private API = 'api/branchsets';
    constructor(private http: HttpClient) {}

    findBranchSets(req: any): Observable<HttpResponse<BranchSet[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        console.log(req)
        if (req.name) {
            params = params.set('name', req.name);
        }
        if (req.code) {
            params = params.set('code', req.code);
        }
        if (req.businessLineId) {
            params = params.set('businessLineId', req.businessLineId);
        }
        
        return this.http.get<BranchSet[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveBranchSet(branchSet: BranchSet): Observable<HttpResponse<BranchSet>> {
        const copy: BranchSet = Object.assign({}, branchSet);
        if (copy.id && copy.id != null) {
            return this.http.put<BranchSet>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<BranchSet>(this.API, copy, {observe: 'response'});
        }
    }

    getBranchSet(id: number) {
        return this.http.get<BranchSet>(this.API + '/' + id);
    }

    deleteBranchSet(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getAllBranchsets(businessLineId: number): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
            params = params.set('businessLineId', businessLineId);
    }
        return this.http.get<any>('api/_getAll/branchsets', { params: params, observe: 'response'});
    }

    getAllBusinessLines() {
        return this.http.get<BusinessLineDatamodel[]>('api/business-lines');
    }
}

