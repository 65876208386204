import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {catchError, takeUntil} from 'rxjs/operators';
import {Branch} from 'src/app/admin/branch/branch.model';
import {QuestionaireService} from 'src/app/entities/questionaire/questionaire.service';
import {FetchBranchesForStates} from '../../constants/data.constants';
import {ReportService} from '../reports/report.service';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-deviation-report',
    templateUrl: './deviation-report.component.html',
    styleUrls: ['./deviation-report.component.css']
})
export class DeviationReportComponent implements OnInit, OnDestroy {


    stateDropdownList: any[];
    selectedStates: any[];
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    selectedBranches: any[];
    stateList: string[];
    branches: Branch[];
    dropdownList: any[];
    businessLines: any[];
    selectedBusinessLine: any;
    dropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    states: any;
    type: any;
    branchCodes: any[];
    isRoleAdmin:boolean;
    public branchDeviationReportForm: FormGroup;

    @ViewChild('stateSelect',{static:true}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:true}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:true}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:true}) branchAllSelect: MatCheckbox;

    public formError: FormErrorModel;
    public formErrors = {
        type: '',
        selectedStates: '',
        selectedBranches: '',
        stateName:'',
        name:'',
        businessLineId:''
    };
    public deviationReportTypes = [{code: 'BranchMonitoringdeviationreport', name: 'Branch Deviation report'},
        {code: 'CentreMonitoringdeviationreport', name: 'Center Deviation report'},
        {code: 'CMTBranchMonitoringdeviationreport', name: 'CMT Branch Deviation report'},
        {code: 'CMTCentreMonitoringdeviationreport', name: 'CMT Center Deviation report'}];
    businessLine: any;
    loggedInUserRole: any;


    constructor(private questionaireService: QuestionaireService,
                private reportService: ReportService, private igFormService: IgFormService,
                private userService: UserService,private formBuilder: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private snackBar: MatSnackBar) {this.activatedRoute.data
                    .pipe(takeUntil(componentDestroyed(this)))
                    .subscribe((data) => {
                        this.loggedInUserRole = data.loggedInUser.body.role;
                      });

    }

    ngOnInit() {
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        this.branchDeviationReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.branchDeviationReportForm.controls['businessLineId'].enable();
        }else{  this.loadStates();
        }
    }

    onItemSelect(event){
    }
  
    onSelectAll(event){}

    ngOnDestroy(): void {
    }

    onSelectState(state: any) {
        this.selectedStates.push(state);
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        this.loadBranches();
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.branchDeviationReportForm);
        if (this.branchDeviationReportForm.valid) {
            this.downloadbranchEfficiencyReport();
        } else {
            this.formErrors = this.igFormService.validateForm(this.branchDeviationReportForm, this.formErrors, false);
            return false;
        }
    }

    /**
     * THis method is called on select of the deviation report type
     * @since v2.8.2
     * @autohr J Suhas Bhat
     */

    onReportTypeSelection(event): void {
        console.log(event)
        if(event.code === 'CentreMonitoringdeviationreport')
        this.stateDropdownSettings.singleSelection = true
        else
        this.stateDropdownSettings.singleSelection = false
        this.selectedStates = [];
        this.selectedBranches = [];
        this.loadStates();
    }

    buildForm() {
        this.branchDeviationReportForm = this.formBuilder.group({
            type: [this.type, Validators.required],
            stateName: [this.selectedStates, Validators.required],
            name: [this.branches, Validators.required],
            businessLineId: ['', Validators.required]
        });
        this.branchDeviationReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.branchDeviationReportForm, this.formErrors, true);
        });
    }

    loadBranches() {
        this.selectedBranches=null;
        console.log(this.selectedStates);
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, FetchBranchesForStates.GET_MAPPED_ONLY)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                
                });
    }

    loadStates() {
        this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                    console.log(this.type)
                    
                }
            );
    }

    /**
     * This method is used to download the BMT deviation report
     * @author J Suhas Bhat
     * @since v2.8.0
     */
    downloadbranchEfficiencyReport() {
        this.branchCodes = [];
    
        // Populate branchCodes
        this.selectedBranches.forEach((value) => {
            this.branchCodes.push(value.code);
        });
    
        // Call the report service
        this.reportService.downloadBmtDeviationReport({
            type: this.type.code,
            branchCodes: this.branchCodes,
            businessLineId: this.businessLine
        }).pipe(
            takeUntil(componentDestroyed(this)),
            catchError(error => {
                const errorMsg = error.error?.message || 'No records for selected businessLine and branches.';
                this.snackBar.open(errorMsg, 'Close', {
                    duration: 3000,
                });
                return throwError(() => new Error(errorMsg));
            })
        ).subscribe(
            res => {
                console.log('start download:', res);
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            },
            error => {
                // Optional: Log download error if needed
                console.log('Download error:', JSON.stringify(error));
            },
            () => {
                console.log('Completed file download.');
            }
        );
    }
    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectBranch(item: any) {
    }

    onItemDeSelectAll(item: any) {
        this.selectedStates = [];
        this.loadBranches();

    }

    clearFilter() {
        // this.type = '';
        // this.selectedBranches = [];
        // this.selectedStates = [];
        // this.selectedBusinessLine = '';
        this.branchDeviationReportForm.reset();
        if(this.businessLine != undefined){
            this.stateDropdownList= [];
          }
          this.dropdownList= [];
    }

    
    changeBusinessLine(selectedBusniessLine: any){
        this.selectedStates=null;
        this.businessLine = selectedBusniessLine.value;
        this.loadStates();
    }
}
