import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Question} from './question.model';
import {QuestionService} from './question.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'app-question-delete-dialog',
    templateUrl: './question-delete-dialog.component.html',
    styleUrls: ['./question.component.css']
})
export class QuestionDeleteDialogComponent implements OnInit, OnDestroy {
    question: Question;
    errorMessage: string;
    constructor(private questionService: QuestionService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<QuestionDeleteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Question) {
        this.question = data;
    }

    ngOnInit() {
    }
    ngOnDestroy(): void {
    }
    delete() {
        this.errorMessage = '';
        this.questionService.deleteQuestionData(this.question.id)
        .pipe(takeUntil(componentDestroyed(this))).
        subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }
    onSaveSuccess(result) {
        this.dialogRef.close('deleted');
    }
    onSaveError(response) {
        this.errorMessage = "Dependent Question Cannot be deleted";
    }
    cancel(): void {
        this.dialogRef.close('canceled');
    }
}
