import {Component, OnDestroy, OnInit} from '@angular/core';
import {RiskObservationService} from './risk-oveservation.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import { AuditPlan } from 'src/app/entities/audit/audit-plan/audit-plan.model';

@Component({
  selector: 'app-risk-observation-list',
  templateUrl: './risk-observation-list.component.html'
})

export class RiskObservationListComponent implements OnInit, OnDestroy {
    auditPlan: AuditPlan;
    dataSource: any;
    public riskObservationListForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        name: '',
        status: '',
    };

    constructor(private riskObservationService: RiskObservationService,
                private igFormService: IgFormService, private formBuilder: FormBuilder) {
    }
    ngOnInit() {
        this.buildForm();
        this.formError = new FormErrorModel(false, '');

    }
    ngOnDestroy(): void {
    }
    onSubmit() {
    }

    buildForm() {
        this.riskObservationListForm = this.formBuilder.group({
        });
        this.riskObservationListForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.riskObservationListForm, this.formErrors, true);
        });
    }

 loadCheckListUnderRisk(auditPlan: AuditPlan) {
    this.riskObservationService.findRiskObservation(auditPlan);
}

}
