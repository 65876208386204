import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {map, take} from 'rxjs/operators';
import {AsyncSubject, Observable} from 'rxjs';
import {PrincipalService} from './principal.service';
import {AuthTokenService} from './auth-token.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient,
              private principalService: PrincipalService,
              private authTokenService: AuthTokenService,
            private route: Router) { }

  testAsyncSubject(input: any): Observable<any> {
    const ret = new AsyncSubject();
    ret.next('hi');
    setTimeout(function() {
      ret.next('hello');
      ret.complete();
    }, 2000);
    return ret;
  }

  login(credentials: any): Observable<any> {
    this.authTokenService.clearToken();
    const returnAsync = new AsyncSubject();
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe
    };
    this.http.post('api/authenticate', data, {observe: 'response'}).pipe(take(1)).subscribe((response) => {
      if (response.body['id_token']) {
        this.authTokenService.storeAuthenticationToken(response.body['id_token'], credentials.rememberMe);
        this.principalService.identity(true).then((account) => {
          returnAsync.next(account);
          returnAsync.complete();

          // for reset password
          if (account.changePasswordRequired) {
            const changePassword = true;
            this.route.navigate(['reset/finish'], { queryParams: { changePassword } });
          }
        }, (accountError) => {
          returnAsync.error(accountError);
          returnAsync.complete();
        });
      } else {
        returnAsync.error(response);
        returnAsync.complete();
      }
    }, (response) => {
      returnAsync.error(response);
      returnAsync.complete();
    });
    return returnAsync;
  }

    loginWithToken(jwt, rememberMe) {
        this.authTokenService.clearToken();
        const returnAsync = new AsyncSubject();
        if (jwt) {
            this.authTokenService.storeAuthenticationToken(jwt, rememberMe);
            this.principalService.identity(true).then((account) => {
                returnAsync.next(account);
                returnAsync.complete();
            }, (accountError) => {
                returnAsync.error(accountError);
                returnAsync.complete();
            });
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('Authentication rejected'); // Put appropriate error message here
        }
    }

  logout() {
    // TODO needs to implement server side logout api
    this.authTokenService.clearToken();
    this.principalService.authenticate(null);
  }

  sendMail(email: any): Observable<any> {
    return this.http.post('api/account/reset-password/init', email, { observe: 'response' });
  }

  checkResetKey(key: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('key', key);
    return this.http.get<any>('api/account/checkResetKey', {
      params, observe: 'response'
    });
  }

  changePassword(formValue: any): Observable<any> {
    const data = {
      currentPassword: formValue.oldPassword,
      newPassword: formValue.newPassword,
    };
    return this.http.post('api/account/change-password', data, { observe: 'response' });
  }

  resetPassword(password: any, key: any): Observable<any> {
    const data = {
      key: key,
      newPassword: password.value,
    };
    return this.http.post('api/account/reset-password/finish', data, { observe: 'response' });
  }

}
