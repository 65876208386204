import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../admin/user/user.model';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: []
})
export class ProfileComponent implements OnInit, OnDestroy {
    user: User;
    editable = true;
    public profileForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        id: '',
        /* version: '', */
        login: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        langKey: ''
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService,
    ) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
                this.user = data.user;
                this.editable = data.editable;
            });
    }

    ngOnInit() {
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.profileForm.disable();
        }
    }

    ngOnDestroy(): void { }

    buildForm() {
        this.profileForm = this.formBuilder.group({
            id: [this.user.id],

            /* version: [this.user.version], */

            login: [this.user.login, /* [Validators.required] */],

            email: [this.user.email, /* [Validators.required, Validators.email, IgFormValidator.email] */],

            firstName: [this.user.firstName, []],

            lastName: [this.user.lastName, []],

            role: [this.user.role, []],

            langKey: [this.user.langKey, /* [IgFormValidator.contactNumber] */],

            externalSystems: this.formBuilder.array([])
        });

        this.profileForm.valueChanges
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
                this.formErrors = this.igFormService.validateForm(
                    this.profileForm,
                    this.formErrors,
                    true
                );
            });
    }
}
