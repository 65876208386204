import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';
import { ScorecardDeleteDialogComponent } from '../score-card-delete/score-card-delete-dialog.component';
import { ScorecardDataSource } from '../score-card.data.source';
import { ScoreCardService } from '../score-card.service';



@Component({
    selector: 'app-score-card-list',
    templateUrl: './score-card-list.component.html',
    styleUrls: ['./score-card-list.component.css']
})
export class ScoreCardListComponent implements OnInit, OnDestroy, AfterViewInit {


    title: string;
    pagingAndSortParams: PagingAndSortParams;
    dataSource: ScorecardDataSource;
    listSource: ListSource;
    scorecardId: number;
    errorMessage: string;
    refreshData: any;
    displayedColumns = ['code', 'description', 'validFrom', 'validTo', 'status', 'action'];
    name: String;
    code: String;
    description: String;
    status: String;
    required = false;
    constructor(private scoreCardService: ScoreCardService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private referenceCodeService: ReferenceCodeService) { }

      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;

    ngOnInit() {

        this.dataSource = new ScorecardDataSource(this.scoreCardService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'description';
                }

                this.loadScorecard(sortColumn);
            });

    }

    ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(() => this.loadScorecardPage());
    }

    loadScorecard(sortColumn: String) {
        this.dataSource.loadAudits(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.code, this.description);
    }

    loadScorecardPage() {
        this.router.navigate(['scorecard'], this.getQueryParams());
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(scorecardData: any) {
        const dialogRef = this.dialog.open(ScorecardDeleteDialogComponent, {
            data: scorecardData
        });
        dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe(result => {
            this.ngOnInit();
        });
    }

    clone(element: any) {
        this.scorecardId = element;
        this.scoreCardService.scorecardClone(this.scorecardId).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }

    scorecardActive(element: any) {
        this.scorecardId = element;
        this.scoreCardService.scorecardActive(this.scorecardId).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }

    onSaveSuccess(result) {
        this.snackBar.open(result.body, 'Close', { duration: 3000 });
        this.ngOnInit();
    }

    onSaveError(response) {
        this.errorMessage = response;
    }

    clearFilter() {
        this.code = undefined;
        this.description = undefined;
        this.loadScorecard('');
    }
}