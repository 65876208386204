import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { QuestionnaireConfigurationListDataSource } from './questionnaire-configuration-list.data.source';
import { QuestionnaireConfigurationDeleteDialogComponent } from './questionnaire-configuration-delete-dialog.component';
import {PagingAndSortParams} from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import {ListSource} from 'projects/ig-core/src/lib/list/list-source';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuestionnaireConfigurationService } from '../questionnaire-configuration.service';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';

@Component({
  selector: 'app-questionnaire-configuration',
  templateUrl: 'questionnaire-configuration-list.component.html',
  styleUrls: ['../questionnaire-configuration.component.css']
})
export class QuestionnaireConfigurationListComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: QuestionnaireConfigurationListDataSource;
  listSource: ListSource;
  displayedColumns = ['questionnaireCode', 'businessLine',  'action'];
    @ViewChild(MatPaginator,{static:true})
  paginator: MatPaginator;
  @ViewChild(MatSort,{static:true})
  sort: MatSort;
  public questionnaireConfigListForm: FormGroup;
  businessLines: any[];
  
  constructor(
    private questionnaireConfigurationService: QuestionnaireConfigurationService,
    private questionaireService: QuestionaireService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder:FormBuilder
  ) {}
  ngOnInit() {
    this.questionnaireConfigListForm = this.buildForm();
    this.dataSource = new QuestionnaireConfigurationListDataSource(this.questionnaireConfigurationService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this))).subscribe(listParams => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
          sortColumn = 'id';
        }
        this.loadquestionnaireConfigs('');
      });
      this.loadBusinessLine();
  }
  
  buildForm() {
    return this.formBuilder.group({
        questionnaireCode:'',
        businessLine:'',
    });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadQuestionnaireconfigPageWithParams());
  }

  loadQuestionnaireconfigPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  loadquestionnaireConfigs(sortColumn: String) {
    this.dataSource.loadquestionnaireConfigs(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.questionnaireConfigListForm.value['questionnaireCode'],
         this.questionnaireConfigListForm.value['businessLine']);
  }

loadBusinessLine(){
  this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
        subscribe(businessLineDTO => this.businessLines = businessLineDTO);
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  deleteDialog(QuestionnaireData: any) {
    const dialogRef = this.dialog.open(QuestionnaireConfigurationDeleteDialogComponent, {
      data: QuestionnaireData
    });

    dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
        if (result && result === 'deleted') {
          this.snackBar.open(`Deleted Questionnaire configuration successfully`, 'Close');
          this.ngOnInit();
        }
      });
  }
  
  clearFilter() {
    this.questionnaireConfigListForm.get('questionnaireCode').setValue(undefined);
    this.questionnaireConfigListForm.get('businessLine').setValue(undefined);
    this.search()
  }

  search(){
    this.pagingAndSortParams.pageIndex = 0
    this.paginator.pageIndex = 0
    this.loadquestionnaireConfigs('id')
    this.loadQuestionnaireconfigPageWithParams()
}
}
