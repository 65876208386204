<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>gavel</mat-icon>
    </div>
    <mat-card-title>
      Audit
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="searchForm" novalidate (ngSubmit)="submitSearchForm()">
      <div fxLayout="row" fxLayoutGap="10px" fxFlex>
        <mat-form-field fxFlex="auto">
          <input matInput [matDatepicker]="fromDate" placeholder="From Date" formControlName="fromDate">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.fromDate">{{ formErrors.fromDate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
          <input matInput [matDatepicker]="toDate" placeholder="To Date" formControlName="toDate">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
        <div fxFlex="auto">
        <button mat-raised-button color="primary" [attr.tabindex]="0" >Search</button>
        </div>
      </div>
    </form>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource"
             matSort matSortActive="auditEventDate" matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="auditEventDate"> Date</th>
          <td mat-cell *matCellDef="let row"> {{row.timestamp| date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="principal"> User</th>
          <td mat-cell *matCellDef="let row">{{row.principal}}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="auditEventType"> Type</th>
          <td mat-cell *matCellDef="let row">{{row.type}}
          </td>
        </ng-container>

        <ng-container matColumnDef="extra_data">
          <th mat-header-cell *matHeaderCellDef> Extra data</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.data">{{row.data.message}}</span>
            <span *ngIf="row.data"><span>Remote Address</span> {{row.data.remoteAddress}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
    <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                   [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
  </mat-card-content>
</mat-card>
