import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import {MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCodeService } from '../reference-code.service';
import { ReferenceCodeDeleteDialogComponent } from './reference-code-delete-dialog.component';
import { ReferenceCodeListDataSource } from './reference-code-list.data.source';


@Component({
    selector: 'app-reference-code',
    templateUrl: 'reference-code-list.component.html',
    styleUrls: ['../reference-code.component.css']
})
export class  ReferenceCodeListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource:  ReferenceCodeListDataSource;
    listSource: ListSource;
    classifierFilter: string;
    nameFilter: string;
    codeFilter: string;

    displayedColumns = [      'classifier',    'name',    'code',    'parentReferenceCode',    'status',    'action'];

      @ViewChild(MatPaginator,{static:true})
    paginator: MatPaginator;

    @ViewChild(MatSort,{static:true})
    sort: MatSort;

    constructor(private referenceCodeService: ReferenceCodeService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private snackBar: MatSnackBar) { }



    ngOnInit() {
        this.dataSource = new ReferenceCodeListDataSource(this.referenceCodeService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'id';
                }
                this.loadReferenceCodes(sortColumn);
            });
    }

    loadReferenceCodes(sortColumn: String) {
        this.dataSource.loadReferenceCodes(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.classifierFilter,
            this.nameFilter, this.codeFilter);
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadReferenceCodesPageWithParams());
    }

    loadReferenceCodesPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(referenceCodeData: any) {
        const dialogRef = this.dialog.open(ReferenceCodeDeleteDialogComponent, {
            data: referenceCodeData
        });
        dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
          if (result && result === 'deleted') {
            this.snackBar.open(`Deleted referenceCode successfully`, 'Close');
            this.loadReferenceCodes(this.pagingAndSortParams.sortColumn || 'id');
          }
        });
    }

    clearFilter() {
        this.codeFilter = undefined;
        this.nameFilter = undefined;
        this.classifierFilter = undefined;
        this.search()
    }

    search(){
        this.pagingAndSortParams.pageIndex = 0
        this.paginator.pageIndex = 0
        this.loadReferenceCodes('id')
        this.loadReferenceCodesPageWithParams()
    }

}
