import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  constructor(private http: HttpClient) {}

  get(): Observable<HttpResponse<any>> {
    return this.http.get<any>('api/account', { observe: 'response' });
  }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post('api/account', account, { observe: 'response' });
  }
}
