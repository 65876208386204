<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>bar_chart</mat-icon>
        </div>
        <div mat-card-title>
            Productivity Dashboard
        </div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="producitvityReportForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                <div style="width: 170px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                        [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                        (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                        (onDeSelect)="onItemDeSelectState($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <mat-hint *ngIf="stateHint" style="font-size: 11px;color: #f44336;">This field is required
                    </mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;

                <div style="width: 170px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                        [(ngModel)]="selectedBranches" [settings]="dropdownSettings">
                    </ng-multiselect-dropdown>
                    <mat-hint style="font-size: 11px;color: #808080;">&nbsp; This field is not mandatory
                    </mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;

                <div style="width: 170px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="bmtActivity" [placeholder]="'Activities'"
                        [data]="bmtActivities" [(ngModel)]="selectedBmtActivities" [settings]="activityDropdownSettings"
                        (onSelect)="onSelectActivity($event)" (onSelectAll)="onSelectAllActivity($event)">
                    </ng-multiselect-dropdown>
                </div>

                &nbsp; &nbsp; &nbsp; &nbsp;
                <mat-form-field>
                    <mat-select multiple formControlName="role" name="role" [(ngModel)]="role" placeholder="Role">
                        <mat-option *ngFor="let bmtRole of bmtRoles" [value]="bmtRole.code">{{bmtRole.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.role">{{ formErrors.role }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-select multiple formControlName="month" name="month" [(ngModel)]="month" placeholder="Months">
                        <mat-option *ngFor="let month of allMonths" [value]="month">{{month.name}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.month">{{ formErrors.month }}</mat-error>
                </mat-form-field>
                <div>

                    <mat-form-field style="width: 170px; margin-right: 20px; margin-left: 10px;">
                        <mat-select formControlName="bmtYear" name="bmtYear" [(ngModel)]="bmtYear" placeholder="Years">
                            <mat-option *ngFor="let year of allYears" [value]="year.code">{{year.name}}</mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.bmtYear">{{ formErrors.bmtYear }}</mat-error>
                    </mat-form-field>
                    <button style="margin-right: 10px; margin-left: 10px;margin-top: 10px; width: 120px;" type="submit"
                        mat-raised-button color="primary">
                        <mat-icon>search</mat-icon>Search
                    </button>
                    <button type="button" style="margin-right: 10px; width: 120px;" mat-raised-button color="primary"
                        (click)="clearFilter()">
                        Clear
                    </button>
                </div>

            </div>
        </form>
    </mat-card-content>
</mat-card>
<div *ngIf="showProductivity">
    <app-bar-chart-report [bmtDashboardDataElement]="bmtProductivityList"
        [bmtDashboardMonthSelectedElement]="selectedMonths" [selectedBranches]="selectedBranches"
        [selectedStates]="selectedStates" [bmtRoles]="bmtRoles" [bmtYear]="bmtYear"
        [selectedBmtActivities]="selectedBmtActivities">
    </app-bar-chart-report>

</div>