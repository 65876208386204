import {  Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from 'src/app/entities/audit/audit-transaction/audit-transaction-remarks-dialog.component';
import { Router } from '@angular/router';


@Component({
  templateUrl: './user-details.component.html',
})



export class UserDetailsComponent implements  OnInit  {
  isreadOnly = true;



  constructor(  private router: Router,
    public dialogRef:MatDialogRef<UserDetailsComponent>,
     @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  ngOnInit(): void {
  }
           
  onNoClick(){
    this.dialogRef.close(this.data);
  }
}
