import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {BranchOrganisationMapping, MATemplateHeader} from './activity-role.model';
import {Branch} from '../../admin/branch/branch.model';
import {BranchSet} from '../../admin/branch-set/branch-set.model';
import {ActivityReportParms} from '../../dashboard/activity-report/activity.model';
import {formatDate} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ActivityRoleService {

    private API = 'api/ma-template-headers';

    constructor(private http: HttpClient) {
    }


    getBranchSet(id: number) {
        return this.http.get<MATemplateHeader>(this.API + '/' + id);
    }

    getAllTemplates(businessLineId: number, roleCode: string): Observable<HttpResponse<MATemplateHeader[]>> {
        let params = new HttpParams();
        params = params.set('businessLineId', businessLineId);
        params = params.set('roleCode',roleCode);
        return this.http.get<MATemplateHeader[]>(this.API, { params: params, observe: 'response'});
    }

    getAllUsers() {
        return this.http.get<MATemplateHeader[]>(this.API);
    }

    getUsresAndRoles(id: number) {
        return this.http.get<MATemplateHeader>(this.API + '/' + id);
    }

    saveActivityHeader(template: MATemplateHeader): Observable<HttpResponse<MATemplateHeader>> {
        const copy: MATemplateHeader = Object.assign({}, template);
        if (copy.id && copy.id != null) {
            return this.http.put<MATemplateHeader>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<MATemplateHeader>(this.API, copy, {observe: 'response'});
        }
    }

    saveMapping(req?: any): Observable<HttpResponse<MATemplateHeader[]>> {
        const param = new ActivityReportParms;

        if (req.role !== undefined) {
            param.role = req.role;
        }
        if (req.templateList !== undefined) {
            param.templateId = req.templateList;
        }
        if (req.areaList !== undefined) {
            param.orgnationId = req.areaList;
        }
        if (req.userLogin !== undefined) {
            param.userLogin = req.userLogin;
        }
        if (req.branchId !== undefined) {
            param.branchId = req.branchId;
        }
        if (req.stateNames !== undefined) {
            param.stateNames = req.stateNames;
        }
        return this.http.post<MATemplateHeader[]>('api/ma-template-to-role-hierarchy-mappings-save', param, {observe: 'response'});
    }

    findTemplats(req: any): Observable<HttpResponse<BranchSet[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.name) {
            params = params.set('name', req.name);
        }
        if (req.code) {
            params = params.set('code', req.code);
        }
        return this.http.get<MATemplateHeader[]>('api/ma-template-headers-pagination', {
            params, observe: 'response'
        });
    }

    getAllActivityData(classifier: string) {
        return this.http.get<any>('api/_refs/reference-codes/codes' + '/' + classifier);
    }

    /*getAllBranchesForStates(hierarchy: string, parentId: any[]): Observable<HttpResponse<BranchOrganisationMapping[]>> {
        let params = new HttpParams();
        const parentIds = parentId.toString();
        params = params.set('hierarchy', hierarchy);
        params = params.append('parentId', parentIds);
        return this.http.get<BranchOrganisationMapping[]>('api/organisation-mappings/hierarchy', {params: params, observe: 'response'});
    }*/

    templateClone(id: number): Observable<any> {
        const reqURl = 'api/ma-template-headers/clone/';
        return this.http.post<any>(reqURl + id, null, {observe: 'response', responseType: 'text' as 'json'});
    }

    deleteTaskMapping(id: number) {
        return this.http.delete('api/ma-template-task-mappings' + '/' + id);
    }

    getAllStatesForUser(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/branches/branchsetStates', { observe: 'response' });
    }

    getAllBranchesForStates(stateName: any, showOnlyMappedBranches: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        params = params.set('stateName', stateName);
        params = params.set('showOnlyMappedBranches', showOnlyMappedBranches);
        return this.http.get<Branch[]>('api/branches/state-search', { params: params, observe: 'response'});
    }

    getAllSubordinatesForBranchAndRole(roles?: any
        , barnchIds?: any, stateIds?: any, businessLineId?: number): Observable<HttpResponse<string[]>> {
        let params = new HttpParams();
        params = params.set('businessLineId', businessLineId);
        if (roles) {
            params = params.set('roles', roles);
        }
        if (barnchIds) {
            params = params.set('barnchIds', barnchIds);
        }
        if (stateIds) {
            params = params.set('states', stateIds);
            params = params.set('status','ACTIVE');
        }
        if (barnchIds.length>0) {
            return this.http.get<any>('api/users/barnch/role/getSubordinates', { params, observe: 'response' });
        } else {
            return this.http.get<any>('api/users/getSubordinates', { params, observe: 'response' });
        }
    
    }

    getLatestTemplates(businessLineId: number, roleCode: string): Observable<HttpResponse<MATemplateHeader>> {
        let params = new HttpParams();
        params = params.set('businessLineId', businessLineId);
        params = params.set('roleCode',roleCode);
        return this.http.get<MATemplateHeader>('api/ma-template-headers/latest-template', { params: params, observe: 'response'});
    }


}

