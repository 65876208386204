import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { takeUntil } from 'rxjs/operators';
import { QuestionDeleteDialogComponent } from './question-delete-dialog.component';
import { Question } from './question.model';


@Component({
  selector: 'app-questions',
  styleUrls: ['./question.component.css'],
  templateUrl: './question.component.html',
})
export class QuestionComponent implements OnInit, OnDestroy {

  @Input() questions: Question[];
  @Input() questionaireStatus: String;
  questionerId: any;
  pagingAndSortParams: PagingAndSortParams;
  displayedColumns: string[] = ['questionName', 'category', 'action'];
  dataSource: MatTableDataSource<Question>;

    @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:true}) sort: MatSort;

  constructor(
      private dialog: MatDialog,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
  ) {
    this.activatedRoute.params.pipe(takeUntil(componentDestroyed(this))).subscribe(params => {
       this.questionerId = params.id;
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.questions);
    this.sort.sort(<MatSortable>({id: 'sequence', start: 'asc'}));
    // this.dataSource.paginator = this.paginator;
    this.sort.direction = 'asc';
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy(): void {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  deleteDialog(questionData: any) {
    const dialogRef = this.dialog.open(QuestionDeleteDialogComponent, {
      data: questionData
    });
    dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this)))
        .subscribe((result) => {
      if (result === 'deleted') {
        const questionIndex = this.questions.indexOf(questionData);
        if (questionIndex > -1) {
          this.questions.splice(questionIndex, 1);
        }
        this.ngOnInit();
        // this.dataSource.paginator.firstPage();
        this.snackBar.open(`Delete question ${questionData.name} successfully`, 'Close', {
          duration: 3000,
        });
      }
    });
  }

  redirect(question: Question, redirectionType: String) {
      this.router.navigate(['questionaire' , this.questionerId, 'question', redirectionType , question.id]);
  }
}
