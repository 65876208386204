import {Component, OnDestroy, OnInit} from '@angular/core';
import {ServerConfigurationService} from './server-configuration.service';
import {MatTableDataSource} from '@angular/material/table';
import {Log} from '../log-management/log.model';
import {ServerConfigurationModel} from './server-configuration.model';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'ig-server-configuration',
  templateUrl: './server-configuration.component.html',
  styleUrls: ['./server-configuration.component.css']
})
export class ServerConfigurationComponent implements OnInit, OnDestroy {

  allConfiguration: any = null;

  displayedColumns: String[] = ['name', 'level'];
  configurationDataSource: MatTableDataSource<ServerConfigurationModel>;

  constructor(
    private configurationService: ServerConfigurationService
  ) {
    this.configurationDataSource = new MatTableDataSource<ServerConfigurationModel>();
  }


  ngOnInit() {
    this.configurationService.getConfigurations().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => {
      this.configurationDataSource = new MatTableDataSource<ServerConfigurationModel>(response);
    });

    this.configurationService.getEnv().pipe(takeUntil(componentDestroyed(this))).subscribe(configuration => {
      this.allConfiguration = configuration;
    });
  }

  ngOnDestroy(): void {
  }

  applyFilter(filterValue: string) {
    this.configurationDataSource.filter = filterValue.trim().toLowerCase();
  }
}
