import { HttpClient } from "@angular/common/http";
import { AutoPopulateModule } from "./auto-populate-module.model";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AutoPopulateService {

    constructor(private http: HttpClient) {}

    getAutoPopulateModules(){
        return this.http.get<AutoPopulateModule[]>('api/auto-populate-modules');
    }
}