<mat-card [style.width]="'263px'">
    <mat-card-header class="page-title">
        <mat-card-title>Delete AutoPopulateTag</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        {{ data.message }}
        <!-- <mat-error ngclass="errorMessage && errorMessage != ''">{{errorMessage | json}}</mat-error> -->
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="onConfirm()">Delete</button>
        <button mat-raised-button color="accent" (click)="onCancel()">No Thanks</button>
    </mat-card-actions>
</mat-card>