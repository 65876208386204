export class Branch {
    id?: number;
    version?: number;
    name?: string;
    code?: string;
    sourceBranchId?: number;
    sourceBranchCode?: string;
    stateId?: string;
    regionId?: string;
    regionName?: string;
    nameCode?: string;
    stateName?: string;
    createdBy?: string;
    createdDate?: any;
    currentLocation?: string;
    businessLineId?: number;
    latitude?: number;
    longitude?: number;
    branchStatus?: string;
}
