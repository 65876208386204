
<mat-card style="height: 95%; overflow-y: hidden;">
  <mat-card-header class="page-title">
      <div mat-card-avatar>
          <mat-icon>call_split</mat-icon>
      </div>
      <div mat-card-title>Deviation Report</div>
  </mat-card-header>
  <mat-card-content>
      <form [formGroup]="branchDeviationReportForm" novalidate (ngSubmit)="onSubmit()">

          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start">

            <mat-form-field *ngIf="isRoleAdmin">
                <mat-select placeholder="Business Line"  formControlName="businessLineId" 
                 (selectionChange)="changeBusinessLine($event)">
                  <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                    {{businessLine.name}} 
                  </mat-option>
                </mat-select>
                <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
              </mat-form-field>
              &nbsp;
              &nbsp;

              <mat-form-field style="margin-right: 1%;">
                  <mat-select [(ngModel)]="type" placeholder="Report Type" formControlName="type"  (ngModelChange)="onReportTypeSelection($event)">
                      <mat-option  *ngFor="let deviationReportType of deviationReportTypes"
                          [value]="deviationReportType">{{deviationReportType.name}}
                      </mat-option>
                  </mat-select>
                  <mat-error align="start" *ngIf="formErrors.type">{{ formErrors.type }}</mat-error>
              </mat-form-field>
                &nbsp;
              <div style="width: 180px;margin-right: 3%;margin-top: 4.6px;">
                  <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                      [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                      (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                      (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                  </ng-multiselect-dropdown>
                  <mat-error align="start" *ngIf="formErrors.stateName && branchDeviationReportForm.controls['stateName']?.touched ">{{ formErrors.stateName }}</mat-error>
              </div>
              <div style="width: 180px;margin-right: 3%;margin-top: 4.6px;">
                  <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                      [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)">
                  </ng-multiselect-dropdown>
                  <mat-error align="start" *ngIf="formErrors.name && branchDeviationReportForm.controls['name']?.touched ">{{ formErrors.name }}</mat-error>
              </div>
              
              <button type="submit" style="height: 40px;margin-right: 1%;" mat-raised-button color="primary">
                  <mat-icon>cloud_download</mat-icon> Download Report
              </button>

              <button type="button" style="height: 40px;" mat-raised-button color="primary"
                    (click)="clearFilter()">Clear</button>
          </div>
      </form>
  </mat-card-content>
</mat-card>