<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToScorecardListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="isSave" class="button-icon-text">Create Scorecard Template</mat-card-title>
        <mat-card-title *ngIf="isPresent" class="button-icon-text">{{ editOrView }} Scorecard</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="scorecardEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                class="two-per-row">
                <input name="id" type="hidden" formControlName="id" />
                <mat-form-field>
                    <input name="code" matInput type="text" formControlName="code" placeholder="Code"
                        [attr.tabindex]="0" />
                    <mat-error align="start" *ngIf="formErrors.code">{{ formErrors.code }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="description" matInput type="text" formControlName="description"
                        placeholder="Description" />
                    <mat-error align="start" *ngIf="formErrors.description">{{ formErrors.description }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input matInput name="validFrom" formControlName="validFrom"
                        [max]="scorecardEditorForm.get('ValidTo').value" (focus)="tentativeFromDatePicker.open()"
                        (click)="tentativeFromDatePicker.open()" [matDatepicker]="tentativeFromDatePicker"
                        placeholder="Valid Date">
                    <mat-datepicker-toggle matSuffix [for]="tentativeFromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #tentativeFromDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.validFrom">{{ formErrors.validFrom }}
                    </mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="ValidTo" matInput type="text" formControlName="ValidTo" placeholder="Valid_to" />
                </mat-form-field>

                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="primary">
                        <mat-icon>save</mat-icon>Submit
                    </button>
                    <button mat-raised-button (click)="goToScorecardListPage()">
                        <mat-icon>cancel</mat-icon>Cancel
                    </button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-header class="page-title">
        <mat-card-title>Mapping of Scorecard Template</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group dynamicHeight #matMaingroup>
            <mat-tab label="Module">
                <div *ngIf="moduleTotal!==0">Total:{{moduleTotal}}</div>
                <div *ngIf="isPresent">
                    <p *ngFor="let module of modules">

                        <mat-checkbox [checked]="module.flag == false " [value]="module"
                            (change)="yourfuncModule(module, $event.checked)" color="primary">{{module.moduleCode}}
                        </mat-checkbox>
                        &nbsp;&nbsp;
                        <mat-slider thumbLabel (change)="moduleSliderFunction($event)" tickInterval="25" step="1"
                            min="0" max="100" aria-label="units" [disabled]="module.flag" [(ngModel)]="module.weightage"
                            (percent)="module.weightage"></mat-slider>

                        <mat-form-field appearance="standard" *ngIf='module.flag == false'>
                            <mat-label>weightage</mat-label>
                            <input type="number" matInput (change)="moduleSliderFunction($event)"
                                [(ngModel)]="module.weightage">
                        </mat-form-field>
                    </p>
                </div>
                <div *ngIf="isSave">
                    <p *ngFor="let moduleCodes of moduleCodeList">
                        <mat-checkbox color="primary">{{moduleCodes.code}}</mat-checkbox>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <mat-form-field appearance="standard">
                            <mat-label>weightage</mat-label>
                            <input type="number" matInput placeholder="weightage">
                        </mat-form-field>
                    </p>
                </div>

                <mat-error *ngIf="moduleTotal!==100 && moduleTotal!=0">Total weightage should be: 100%</mat-error>
                <button mat-raised-button color="primary" (click)="onSaveModule()">
                    <mat-icon>save</mat-icon>&nbsp;Save
                </button>
            </mat-tab>
            <mat-tab label="Sub Module">
                <div *ngFor="let module of modules">
                    <h3>{{module.moduleCode}}</h3>
                    <div *ngFor="let submodule of submodules">
                        <div *ngIf="module.moduleCode===submodule.moduleCode">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <mat-checkbox color="primary" [checked]="submodule.flag == false " [disabled]="module.moduleCode == 'AUDIT'" [value]="submodule"
                                (change)="yourfuncSubmodule(submodule, $event.checked)">{{submodule.submoduleCode}}
                            </mat-checkbox>&nbsp;&nbsp;
                            <mat-slider thumbLabel (change)="submoduleSliderFunction($event)" tickInterval="25" step="1"
                                min="0" max="100" aria-label="units" [disabled]="submodule.flag || module.moduleCode == 'AUDIT'"
                                [(ngModel)]="submodule.weightage" (percent)="submodule.weightage"></mat-slider>
                            <mat-form-field appearance="standard" *ngIf="submodule.flag == false">
                                <mat-label>weightage</mat-label>
                                <input type="number" matInput (change)="submoduleSliderFunction($event)" [disabled]="module.moduleCode == 'AUDIT'"
                                    [(ngModel)]="submodule.weightage" placeholder="weightage">
                            </mat-form-field>
                        </div>

                    </div>

                    <mat-error *ngIf="module.submoduleTotal!==100 && module.submoduleTotal!==0">Total weightage should
                        be: 100%
                    </mat-error>
                    <div *ngIf="module.submoduleTotal!==0">Total:{{module.submoduleTotal}}</div>
                </div>
                <div *ngIf="isSave">
                    <div *ngFor="let module of moduleCodeList">
                        <h3>{{module.code}}</h3>
                        <div *ngFor="let submoduleCodes of submoduleCodeList">
                            <div *ngIf="module.code===submoduleCodes.parentReferenceCode">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <mat-checkbox color="primary">{{submoduleCodes.code}}</mat-checkbox>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <mat-form-field appearance="standard">
                                    <mat-label>weightage</mat-label>
                                    <input type="number" matInput placeholder="weightage">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <mat-error *ngIf="submoduleTotal!==100 && submoduleTotal!==0">Total weightage should be: 100%</mat-error>
                    <div *ngIf="submoduleTotal!==0">Total:{{submoduleTotal}}</div> -->
                <button mat-raised-button color="primary" (click)="onSaveSubmodule()">
                    <mat-icon>save</mat-icon>&nbsp;Save
                </button>
            </mat-tab>
            <mat-tab label="Question">
                <ng-template mat-tab-label>
                    Question
                </ng-template>
                <mat-tab-group [(selectedIndex)]="selectedTabIndex"
                    (selectedTabChange)="questionnaireTabChange($event);">
                    <mat-tab *ngFor="let submoduleCodeForQuestionarie of questionnaireScorecard; let i = index">
                        <ng-template mat-tab-label>
                            {{submoduleCodeForQuestionarie.name}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
                <div>
                    <br>
                    <div class="container wrapper" style="display: flex; width: 95%;">
                        <ng-multiselect-dropdown class="container wrapper" [placeholder]="'Questionnaire'"
                            [data]="questionnaireDropDown.questionaireDTOs"
                            [(ngModel)]="questionnaireDropDown.selectedMain" [settings]="questionnaireDropdownSettings"
                            [disabled]="questionnaireDropDown.selectedMain ? questionnaireDropDown.selectedMain.length===1 : false"
                            (onSelect)="getAllTheQuestions(selectedTabIndex)">
                        </ng-multiselect-dropdown>
                        <button style="margin-left: 3%;" mat-stroked-button matTooltip="Clear"
                            (click)="deleteAllTheQuestions(selectedTabIndex)">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                    <br>
                    <mat-form-field style="width:100%; text-align: right">
                        <input matInput (keyup)="applyFilter($event.target.value)"
                            placeholder="Search for the Questions">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <table mat-table class="table-container" [dataSource]="questionsDataSource" matSort>
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="questionName">
                            <th mat-header-cell *matHeaderCellDef colspan="2"> Question Name </th>
                            <td mat-cell *matCellDef="let question" data-label="name" colspan="2">
                                {{question.name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let question" data-label="type"> {{question.type}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="weightage">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="weightage"> Weightage </th>
                            <td mat-cell *matCellDef="let question" data-label="weightage">
                                <mat-form-field>
                                    <input name="weightage" matInput type="number" [(ngModel)]="question.weightage"
                                        placeholder="Weightage" />
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                    <mat-paginator #tableDataPaginator (page)="getUpdate($event)" [pageSize]="5"
                        [pageSizeOptions]="[5, 8, 10]">
                    </mat-paginator>
                </div>
                <mat-error *ngIf="questionnaireTotal!==100 && questionnaireTotal!==0">Total weightage should be: 100%
                </mat-error>
                <div *ngIf="questionnaireTotal!==0">Total:{{questionnaireTotal}}</div>
                <button mat-raised-button color="primary" (click)="onSaveQuestion()">
                    <mat-icon>save</mat-icon>&nbsp;Save
                </button>
            </mat-tab>
            <mat-tab label="Branch">
                <br><br>
                <div style="display: flex; justify-content: space-evenly;">
                    <div class="container wrapper" style="display:inline-block; width: 45%;">
                        <ng-multiselect-dropdown class="container" [placeholder]="'Select Zones'"
                            [data]="zoneOfBranchOrganisation" [(ngModel)]="zoneNgModel"
                            [settings]="organizationDropdownSettings" (onSelect)="onSelectZone($event)"
                            (onSelectAll)="onSelectAllZone($event)" (onDeSelect)="onItemDeSelectZone($event)"
                            (onDeSelectAll)="onZoneDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="container wrapper" style="display:inline-block; width: 45%;">
                        <ng-multiselect-dropdown class="container" [placeholder]="'Select States'"
                            [data]="stateOfBranchOrganisation" [(ngModel)]="stateNgModel"
                            [settings]="organizationDropdownSettings" (onSelect)="onSelectState($event)"
                            (onSelectAll)="onSelectAllState($event)" (onDeSelect)="onItemDeSelectState($event)"
                            (onDeSelectAll)="onStateDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <br><br>
                <div style="display: flex; justify-content: space-evenly;">
                    <div class="container wrapper" style="display:inline-block; width: 45%;">
                        <ng-multiselect-dropdown class="container" [placeholder]="'Select Region'"
                            [settings]="organizationDropdownSettings" [(ngModel)]="regionNgModel"
                            [data]="regionOfBranchOrganisation" (onSelect)="onSelectRegion($event)"
                            (onSelectAll)="onSelectAllRegion($event)" (onDeSelect)="onItemDeSelectRegion($event)"
                            (onDeSelectAll)="onRegionDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="container wrapper" style="display:inline-block; width: 45%;">
                        <ng-multiselect-dropdown class="container" [placeholder]="'Select Division'"
                            [settings]="organizationDropdownSettings" [data]="divisionOfBranchOrganisation"
                            (onSelect)="onSelectDivision($event)" [(ngModel)]="divisionNgModel"
                            (onSelectAll)="onSelectAllDivision($event)" (onDeSelect)="onItemDeSelectDivision($event)"
                            (onDeSelectAll)="onDivisionDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <br><br>
                <div style="display: flex; justify-content: space-evenly;">
                    <div class="container wrapper" style="display:inline-block; width: 45%;">
                        <ng-multiselect-dropdown class="container" [placeholder]="'Select Area'"
                            [settings]="organizationDropdownSettings" [data]="areaOfBranchOrganisation"
                            [(ngModel)]="areaNgModel" (onSelect)="onSelectArea($event)"
                            (onSelectAll)="onSelectAllArea($event)" (onDeSelect)="onItemDeSelectArea($event)"
                            (onDeSelectAll)="onAreaDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="container wrapper" style="display:inline-block; width: 45%;">
                        <ng-multiselect-dropdown class="container" [placeholder]="'Select Branchs'"
                            [settings]="branchOrganizationDropdownSettings" [data]="branchOfBranchOrganisation"
                            [(ngModel)]="branchNgModel" (onSelect)="onSelectBranch($event)"
                            (onSelectAll)="onSelectAllBranch($event)" (onDeSelect)="onItemDeSelectBranch($event)"
                            (onDeSelectAll)="onBranchDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <br><br>
                <button mat-raised-button color="primary" (click)="onSaveBranch()">
                    <mat-icon>save</mat-icon>&nbsp;Save
                </button>
                <div class="table-container"></div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
<!-- <div *ngIf="isPresent">
  <app-questions [questions]="questionaire.questions" [questionaireStatus]="questionaire.status"></app-questions>
</div> -->