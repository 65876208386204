export class ValidateQueryModel {

    constructor(
        public query?: string,
        public primaryKey?: string ,
        public dataSourceId?:any

    ) {

    }
    
}