import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BranchService } from '../branch/branch.service';
import { Center } from './center.model';
import { CenterService } from './center.service';
import { Branch } from '../branch/branch.model';
import { UserService } from '../user/user.service';


@Component({
  selector: 'app-center-editor',
  templateUrl: './center-editor.component.html'
})
export class CenterEditorComponent implements OnInit, OnDestroy {
  center: Center;
  editable = true;
  centreId: string;
  isNew: boolean;
  isBranchCode = false;
  public centerEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    'name': '',
    'code': '',
    'branchNameCode': '',
    'centreId': '',
    'centerStatus':''
  };
  branches: any[];
  filteredBranches: Observable<any[]>;
  public allCenters$;
  constructor(private centerService: CenterService,
    private branchService: BranchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,private userService: UserService
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.center === undefined) {
          this.center = {};
        } else {
          this.center = data.center;
          this.center.branchNameCode = data.center.branch.name + '-' + this.center.branchCode;
        }
        this.editable = data.editable;
      });

    this.allCenters$ = this.centerService.getAllCenters();
  }

  ngOnInit() {
    this.loadBranches();
    this.buildForm();
    if(Boolean(this.center.branch.branchStatus) === false ){
      this.isBranchCode = true;
    }
    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.centerEditorForm.disable();
    }
    this.filteredBranches = this.centerEditorForm.controls['branchNameCode'].valueChanges
      .pipe(map(branch => this._filter(branch)));
  }
  ngOnDestroy(): void {
  }

  buildForm() {
    this.isNew = false;
    if (this.center.id === undefined) {
      this.isNew = true;
    }
      this.centerEditorForm = this.formBuilder.group({
        id: [this.center.id],
        version: [this.center.version],
        branchNameCode: [this.center?.branch?.code, Validators.required],
        code: [{value:this.center.centreId ? this.center.centreId : '', disabled:this.center.id}, Validators.required],
        name: [this.center.name, [Validators.required]],
        branchCode: [this.center.branchCode],
        branchName: [this.center.branchName],
        createdBy: [this.center.createdBy, []],
        createdDate: [this.center.createdDate, []],
        centreId: [this.center.centreId],
        centerStatus: [{ value:this.center.centerStatus? "ACTIVE" : "INACTIVE", disabled: "true"} , Validators.required]
      });
      this.centerEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
        this.formErrors = this.igFormService.validateForm(this.centerEditorForm, this.formErrors, true);
      });
      this.centerEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
        this.formErrors = this.igFormService.validateForm(this.centerEditorForm, this.formErrors, true);
      });
    }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.centerEditorForm);
    //const branch = this.getBranchFromBranchNameCode(this.centerEditorForm.controls['branchNameCode'].value);
    if (this.centerEditorForm.valid) {
       this.centerEditorForm.controls['branchCode'].setValue(this.centerEditorForm.get("branchNameCode").value);
      // this.centerEditorForm.controls['name'].setValue(branch.name);
      this.centerEditorForm.controls['centreId'].setValue(this.centerEditorForm.controls['code'].value);
      //this.centerEditorForm.controls['version'].setValue(1);
      this.centerEditorForm.get('code')?.enable();
      this.centerService.saveCenter(this.centerEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => this.onSubmitSuccess(response),
          (response) => this.onSubmitError(response));
    } else {
      this.formErrors = this.igFormService.validateForm(this.centerEditorForm, this.formErrors, false);
      return false;
    }

  }

  private onSubmitSuccess(response) {
    let msg = '';
    if (this.center.id) {
      msg = `Updated center ${this.center.id} successfully`;
    } else {
      msg = `Created center successfully`;
    }
    this.snackBar.open(msg, 'Close');
    this.goToCenterListPage();
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(this.centerEditorForm, this.formErrors, response.error);
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }

  goToCenterListPage() {
    if (this.center.id) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }
  loadBranches() {
    this.branchService.getAllActiveBranchesForUser().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.branches = res.body;
          this.branches.forEach(branch => {
            branch.branchNameCode = branch.name + '-' + branch.code;
          });
          console.log(this.branches);
        },
        (res: HttpResponse<any>) => {
          console.log('error in getting branches');
        }
      );
  }
  private _filter(value: string): any[] {
    const filterOptions: any[] = [];
    this.branches.filter(function (branch) {
      if (branch.branchNameCode.toLowerCase().indexOf(value.toLowerCase()) === 0) {
        filterOptions.push(branch);
      }
    });
    return filterOptions;
  }

  getBranchFromBranchNameCode(branchNameCode: string): any {
    let indesxOfSelectedBranch;
    for (const branch of this.branches) {
      if (branch.branchNameCode === branchNameCode) {
        indesxOfSelectedBranch = this.branches.indexOf(branch);
        return this.branches[indesxOfSelectedBranch];
      }
    }
  }

}
