<mat-card class="login-card ig-card-as-panel">
  <mat-card-header class="ig-background-primary">
    <mat-card-title>Reset Password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="resetForm" novalidate (ngSubmit)="onSubmit()">
      <div fxLayout="column" fxLayoutGap="10px">
        <mat-form-field *ngIf="!success">
          <input name="email" matInput type="email" formControlName="email" placeholder="Email" [attr.tabindex]="0" />
          <mat-error align="start" *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
        </mat-form-field>
        <div *ngIf="success">
          <h3>Success! Please check your mail</h3>
        </div>
        <button *ngIf="!success" type="submit" mat-raised-button color="primary">Reset</button>
        <button *ngIf="success" type="button" mat-raised-button (click)="onOkClick()" color="primary">OK</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>