import {DataSource} from '@angular/cdk/table';
import { Authority } from './authority.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import { AuthorityService} from './authority.service';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

export class AuthorityDataSource extends  DataSource<Authority> {

  private userSubject = new BehaviorSubject<Authority[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private authorityService: AuthorityService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<Authority[]> {
    return this.userSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userSubject.complete();
    this.totalCount$.complete();
  }

  loadAudits(sortDirection = 'asc', pageIndex = 0, pageSize = 3) {

    this.authorityService.authorityData({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(takeUntil(this.userSubject)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.userSubject.next(response['body']);
    });
  }

}
