import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {User} from './user.model';
import {map} from 'rxjs/operators';
import { LmsDatamodel } from './lms-datasource.model';
import { businessLinemodel } from './businessLine.model';
import { MATemplateHeader } from 'src/app/entities/activity-role/activity-role.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private API = 'api/users/';
    private roleAPI = 'api/_ref/roles';
    private lmsDatasourceAPI = 'api/lms-datasources'
    private userPI1 = 'api/users/Byrole';
    private businessAPI = 'api/business-lines';
    private matemplateAPI = 'api/ma-template-headers';
    
    constructor(private http: HttpClient) {
    }

    findUsers(req: any): Observable<HttpResponse<User[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.name) {
            params = params.set('name', req.name);
        }
        if (req.login) {
            params = params.set('login', req.login);
        }
        if (req.role) {
            params = params.set('role', req.role);
        }
        if (req.businessLineId) {
            params = params.set('businessLineId', req.businessLineId);
        }
        return this.http.get<User[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveUser(user: User): Observable<HttpResponse<User>> {
        const copy: User = Object.assign({}, user);
        if (copy.id && copy.id != null) {
            return this.http.put<User>("api/users", copy, {observe: 'response'});
        } else {
            return this.http.post<User>("api/users", copy, {observe: 'response'});
        }
    }

    getUser(username: string) {
        return this.http.get<User>(this.API + username);
    }

    deleteUser(username: string) {
        return this.http.delete(this.API + username);
    }

    getAllRoles() {
        return this.http.get<any>(this.roleAPI);
    }
    getAllLmsDatasources() {
        return this.http.get<LmsDatamodel[]>(this.lmsDatasourceAPI);
    }
    getBusinessLine() {
        return this.http.get<businessLinemodel[]>(this.businessAPI);
    }


//   getAllRoles(req:any):Observable<HttpResponse<ActivityRoleParms[]>>{
//   let params = new HttpParams(); 
//   params = params.set('role',req.role);
//   return this.http.get<ActivityRoleParms[]>(this.roleAPI, {
//       params, observe: 'response'
//   });
// }

    getUserByRole(req: any): Observable<HttpResponse<User[]>> {
        let params = new HttpParams();
        params = params.set('role', req.role);
        return this.http.get<any>(this.API + 'Byrole', {params, observe: 'response'});
    }

    uploadUserBranchSet(multipartFile: File): Observable<HttpResponse<String>> {
        const formData = new FormData();
        formData.set('multipartFile', multipartFile);
        return this.http.post<String>(this.API + 'file-upload', formData, {observe: 'response', responseType: 'text' as 'json'});
    }

    downloadUserBranchMappingTemplate(): Observable<any> {
        return this.http.get('api/users/download/user-branch-mapping-template',
            {observe: 'response', responseType: 'blob'}
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = {data: null, filename: null};
            out.data = resp.body;
            out.filename = 'UserBranchMappingTemplate' + '.xlsx';
            return out;
        }));
    }

    downloadBranch(): Observable<any> {
        return this.http.get('api/users/download/existing-user-branch-mapping',
            {observe: 'response', responseType: 'blob'}
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = {data: null, filename: null};
            out.data = resp.body;
            out.filename = 'UserBranchMappingList' + '.xlsx';
            return out;
        }));
    }

    resetPassword(user: any): Observable<HttpResponse<any>> {
        let params = new HttpParams().set('userId', user.id);
        return this.http.post('api/account/resetDefaultPassword', null, { params, observe: 'response' });
    }


//   getAllRolesForUser(): Observable<HttpResponse<any>> {
//     return this.http.get<any>('api/users/Byrole', { observe: 'response' });
// }

    getAllRolesForUser(req: any): Observable<HttpResponse<any[]>> {
        let params = new HttpParams();
        params = params.set('role', req.role);
        return this.http.get<any[]>(this.userPI1, {
            params, observe: 'response'
        });
    }

}
