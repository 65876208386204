import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IgLayoutRoutingModule } from './ig-layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {MaterialLibModule} from '../material-lib.module';
import {LayoutModule} from '@angular/cdk/layout';
import { IgLayoutComponent } from './ig-layout.component';
import { TwoColumnComponent } from './two-column/two-column.component';
import {MultiLevelMenuModule} from 'projects/multi-level-menu/src/lib/multi-level-menu.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    MaterialLibModule,
    LayoutModule,
    IgLayoutRoutingModule,
    MultiLevelMenuModule,
    FlexLayoutModule
  ],
  declarations: [IgLayoutComponent, HeaderComponent, FooterComponent, TwoColumnComponent],
  exports: [IgLayoutComponent]
})
export class IgLayoutModule { }
