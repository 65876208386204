import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuditPlan } from './audit-plan.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AuditPlanService } from './audit-plan.service';
import { HttpResponse } from '@angular/common/http';
import { QuestionaireService } from '../../questionaire/questionaire.service';
import { Observable } from 'rxjs';
import { UserService } from '../../../admin/user/user.service';
import { User } from '../../../admin/user/user.model';
import { NameValueDto } from 'projects/ig-core/src/lib/interfaces/name-value-dto';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import {formatDate} from '@angular/common';
import {months, years} from '../../../constants/data.constants';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { BranchService } from 'src/app/admin/branch/branch.service';

@Component({
  selector: 'app-audit-plan-editor',
  templateUrl: './audit-plan-editor.component.html',
  styleUrls: ['./audit-plan.component.css']
})
export class AuditPlanEditorComponent implements OnInit, OnDestroy {

  auditPlan: AuditPlan;
  editable: true;
  isPresent: boolean;
  isSave: boolean;
  auditForMonth: number;
  auditForYear: number;
  allMonths: NameValueDto[];
  allYears: NameValueDto[];
  public auditPlanEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    tentativeFromDate: '',
    tentativeToDate: '',
    branchNameCode: null,
    teamLead: '',
    assistant1: '',
    assistant2: '',
    assistant3: '',
    auditForMonth: null,
    auditForYear: null,
  };
  branches: any[];
  filteredBranches: Observable<any[]>;
  auditors: User[];
  filteredTeamLead: Observable<any[]>;
  filteredAssistant1: Observable<any[]>;
  filteredAssistant2: Observable<any[]>;
  filteredAssistant3: Observable<any[]>;
  
  constructor(private auditPlanService: AuditPlanService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,
    private router: Router,
    private questionaireService: QuestionaireService,
    private userService: UserService,
    private branchService: BranchService) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.auditPlan === undefined) {
          this.isSave = true;
          this.auditPlan = {};
        } else {
          this.isPresent = true;
          this.auditPlan = data.auditPlan;
          this.auditPlan.branchNameCode = this.auditPlan.branchCode + '-' + this.auditPlan.branchName;

        }
        this.editable = data.editable;
      });
  }

  ngOnInit() {
    this.loadMonthsAndYears();
    this.loadBranches();
    this.loadAuditors();
    this.buildForm();
   

    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.auditPlanEditorForm.disable();
    }
    this.filteredBranches = this.auditPlanEditorForm.controls['branchNameCode'].valueChanges
      .pipe(map(branch => this._filter(branch)));
       
    this.filteredTeamLead = this.auditPlanEditorForm.controls['teamLead'].valueChanges
    .pipe(map(auditor => this.teamLeadFilters(auditor)));

    this.filteredAssistant1= this.auditPlanEditorForm.controls['assistant1'].valueChanges
    .pipe(map(auditor => this.teamLeadFilters(auditor)));

    this.filteredAssistant2= this.auditPlanEditorForm.controls['assistant2'].valueChanges
    .pipe(map(auditor => this.teamLeadFilters(auditor)));

    this.filteredAssistant3= this.auditPlanEditorForm.controls['assistant3'].valueChanges
    .pipe(map(auditor => this.teamLeadFilters(auditor)));
  
  }

  loadMonthsAndYears() {
    this.allMonths = months;
    this.allYears = years;
  }


  private _filter(value: string): any[] {
    const filterOptions: any[] = [];
    this.branches.filter(function (branch) {
      if (branch.name.toLowerCase().indexOf(value.toLowerCase()) === 0 || branch.branchNameCode.toLowerCase().indexOf(value.toLowerCase()) === 0) {
        filterOptions.push(branch);
      }
    });
    return filterOptions;
  }

  loadBranches() {
    this.branchService
      .getAllActiveBranchesForUser().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.branches = res.body;
          this.branches.forEach(branch => {
            branch.branchNameCode = branch.code + '-' + branch.name;
          });
          console.log(this.branches);
        },
        (res: HttpResponse<any>) => {
          console.log('error in getting branches');
        }
      );
  }

  loadAuditors() {
    this.userService.getUserByRole({ role: 'AUDITOR' }).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<User[]>) => {
          this.auditors = res.body;
        }
      );
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.auditPlanEditorForm = this.formBuilder.group({
      id: [this.auditPlan.id],
      tentativeFromDate: [this.auditPlan.tentativeFromDate, Validators.required],
      tentativeToDate: [this.auditPlan.tentativeToDate, Validators.required],
      branchNameCode: [this.auditPlan.branchNameCode, Validators.required],
      version: [this.auditPlan.version],
      teamLead: [this.auditPlan.teamLead, Validators.required],
      assistant1: [this.auditPlan.assistant1],
      assistant2: [this.auditPlan.assistant2],
      assistant3: [this.auditPlan.assistant3],
      branchCode: [this.auditPlan.branchCode],
      branchName: [this.auditPlan.branchName],
      auditForMonth: [this.auditPlan.auditForMonth , Validators.required],
      auditForYear: [this.auditPlan.auditForYear, Validators.required]
    });
    this.auditPlanEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.auditPlanEditorForm, this.formErrors, true);
      /* const branch = this.getBranchFromBranchNameCode(this.auditPlanEditorForm.controls['branchNameCode'].value);
      if (!branch) {
        this.formErrors.branchNameCode = 'Select a Valid Branch';
      } */
    });
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.auditPlanEditorForm);
    const branch = this.getBranchFromBranchNameCode(this.auditPlanEditorForm.controls['branchNameCode'].value);
    if (this.auditPlanEditorForm.valid) {
      this.auditPlanEditorForm.controls['branchCode'].setValue(branch.code);
      this.auditPlanEditorForm.controls['branchName'].setValue(branch.name);
      this.auditPlanEditorForm.value.tentativeFromDate =
          formatDate(this.auditPlanEditorForm.value.tentativeFromDate, 'yyyy-MM-dd', 'en-us');
      this.auditPlanEditorForm.value.tentativeToDate =
          formatDate(this.auditPlanEditorForm.value.tentativeToDate, 'yyyy-MM-dd', 'en-us');
       this.auditPlanService.saveAuditPlan(this.auditPlanEditorForm.value).pipe(takeUntil(componentDestroyed(this))).
        subscribe((response) => this.onSubmitSuccess(response),
          (response) => this.onSubmitError(response));
    } else {
      this.formErrors = this.igFormService.validateForm(this.auditPlanEditorForm, this.formErrors, false);
      return false;
    }
  }
  private onSubmitSuccess(response) {
    this.snackBar.open(`Updated Audit Plan successfully`, 'Close', {
      duration: 3000,
    });
    this.goToAuditPlanPage();
  }

  private onSubmitError(response) {
    const Message = response.error.entityName;
     this.snackBar.open(Message, 'Close', {
      duration: 3000,
     });
      // const errorModel = this.igFormService.handleServerError(this.auditPlanEditorForm,
    //   this.formErrors, response.error);
    // if (errorModel && errorModel.isValidationError) {
    //   this.formErrors = errorModel.formErrors;
    // } else if (errorModel && !errorModel.isValidationError) {
    //   this.formError.hasError = true;
    //   this.formError.error = errorModel.otherError;
    // }
  }
  goToAuditPlanPage() {
    this.router.navigateByUrl('auditPlan');
  }


  getBranchFromBranchNameCode(branchNameCode: string): any {
    let indesxOfSelectedBranch;
    for (const branch of this.branches) {
      if (branch.branchNameCode === branchNameCode) {
        indesxOfSelectedBranch = this.branches.indexOf(branch);
        return this.branches[indesxOfSelectedBranch];
      }
    }
    this.auditPlanEditorForm.controls['branchNameCode'].setValue('')
  }

private teamLeadFilters(value: string): any[] {
  const filteredAuditors: any[] = this.auditors.filter(function(auditor) {
    const lowerCaseValue = value.toLowerCase();
    const lowerCaseLogin = auditor.login.toLowerCase();
    const lowerCaseFirstName = auditor.firstName.toLowerCase();

    return lowerCaseLogin.indexOf(lowerCaseValue) === 0 || 
    lowerCaseFirstName.indexOf(lowerCaseValue) === 0 || lowerCaseLogin.includes(lowerCaseValue)
  });

  return filteredAuditors;
}

}



