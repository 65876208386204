export class AuditPlan {
    constructor(
        public id?: number,
        public version?: number,
        public tentativeFromDate?: any,
        public tentativeToDate?: any,
        public active?: boolean,
        public completed?: boolean,
        public branchName?: string,
        public branchCode?: string,
        public teamLead?: string,
        public assistant1?: string,
        public assistant2?: string,
        public assistant3?: string,
        public branchNameCode?: string,
        public teamLeadName?: string,
        public assistant1Name?: string,
        public auditForMonth?: number,
        public auditForYear?: number,
        public status?: string
    ) {}
}
