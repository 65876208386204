import {formatDate} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, FormControlName} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {takeUntil, take} from 'rxjs/operators';
import {QuestionaireService} from './questionaire.service';
import {Branch} from 'src/app/admin/branch/branch.model';
import { ActivatedRoute } from '@angular/router';
import { Console } from 'console';
@Component({
    selector: 'app-questionaire-report-creation',
    templateUrl: './questionaire-report.component.html'
})

export class QuestionaireReportComponent implements OnInit, OnDestroy {
    dropdownList: any[];
    stateDropdownList: any[];
    selectedItems = [];
    selectedBranches: any[];
    branches: Branch[];
    states: any;
    isRoleAdmin:boolean;
    selectedStates: any[];
    fromDateFilter: string;
    toDateFilter: string;
    branchCodes: any[];
    code: string;
    branchSelectIndicator = new FormControl();
    dataSource: any;
    stateList: string[];
    businessLineId:number;
    businessLine:number;
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,

    };
    dropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    questionnaireCodesList: any[];
    businessLines: any[];
    selectedBusinessLine: any;
    loggedInUserRole: any;
    public questionaireReportForm: FormGroup;
    @ViewChild('stateSelect',{static:true}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:true}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:true}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:true}) branchAllSelect: MatCheckbox;
    public formError: FormErrorModel;
    public formErrors = {
        stateName: '',
        name: '',
        selectedStates: '',
        selectedBranches: '',
        fromDateFilter: '',
        toDateFilter: '',
        code: '',
        businessLineId:""
    };
    constructor(private questionaireService: QuestionaireService,
                private igFormService: IgFormService, private formBuilder: FormBuilder,
                private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute
                 ) { this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUserRole = data.loggedInUser.body.role;
              });
    }

    ngOnInit() {
        this.buildForm();
        this.loadBusiness();
        this.questionaireReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.questionaireReportForm.controls['businessLineId'].enable();
        }else{  
            this.loadStates();
            this.BMTReport();
        }
        this.formError = new FormErrorModel(false, '');
    }

    ngOnDestroy(): void {
    }

    onItemSelect(event){
        console.log(event,"1")
    }

    onSelectAll(event){
        console.log(event,"1")
    }

    BMTReport() {
        console.log("BMT businness line  "  +this.businessLine)
        this.questionaireService.getAllQuestionnaireConfigsQuestionnaire(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
            (res: HttpResponse<any>) => {
                this.questionnaireCodesList = res.body;    
            }
        );
    }

    onSelectState(state: any) {
        console.log(state,"2")
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        console.log(state,"2")
        this.selectedStates = state;
        this.loadBranches();
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.questionaireReportForm);
        if (this.questionaireReportForm.valid) {
            this.download();
        } else {
            this.formErrors = this.igFormService.validateForm(this.questionaireReportForm, this.formErrors, false);
            return false;
        }
    }

    buildForm() {
        this.questionaireReportForm = this.formBuilder.group({
            stateName: [this.selectedStates, Validators.required],
            name: [this.branches, Validators.required],
            fromDateFilter: [this.fromDateFilter, Validators.required],
            toDateFilter: [this.toDateFilter, Validators.required],
            code: [this.code, Validators.required],
            businessLineId:['',Validators.required]
        });
        this.questionaireReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.questionaireReportForm, this.formErrors, true);
        });
    }

    loadBranches() {
        this.selectedBranches=null;
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList,true).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                });
    }

    loadStates() {
        console.log("the selected value is comming on load state method " + this.businessLine);
        this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                }
            );
    }

    loadBusiness() {
        this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
          subscribe(businessLineDTO => this.businessLines = businessLineDTO);
      }

    download() {
        this.branchCodes = [];
        this.selectedBranches.forEach((value) => {
            this.branchCodes.push(value.code);
        });
        this.questionaireService.downloadExcel({
            fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
            toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
            branchCodes: this.branchCodes,
            type: this.code,
            stateNames: this.stateList,
            businessLineId: this.businessLine
        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
            this.onError();
        }, () => {
            console.log('Completed file download.');
        });
    }

    onError() {
        this.snackBar.open(`Error Downloading Excel`, 'Close', {
            duration: 3000,
        });
    }

    clearFilter() {
         //this.stateSelect = undefined;
        // this.selectedBranches = undefined;
         //this.selectedStates = [];
        // this.states = undefined;
        // this.toDateFilter = undefined;
        // this.fromDateFilter = undefined;
        // this.code = undefined;
        // this.businessLine=undefined;
        this.questionaireReportForm.reset();
        if(this.businessLine != undefined){
        this.stateDropdownList= [];
        this.questionnaireCodesList=[];
        }
        this.dropdownList=[];
    }

    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectBranch(item: any) {

    }

    onItemDeSelectAll(item: any) {
        this.selectedStates = [];
        this.loadBranches();

    }

    changeBusinessLine(selectedBusniessLine: any){
        this.selectedStates=null;
        this.businessLine = selectedBusniessLine.value;
        this.loadStates();
        this.BMTReport();
        this.loadBranches();
    }
}
