import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule} from '@angular/material/dialog';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatIconModule} from '@angular/material/icon';
import {  MatMenuModule } from '@angular/material/menu';

import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ReferenceCodeModule } from '../admin/reference-code/reference-code.module';
import { AuditPlanModule, auditPlanRoute } from './audit/audit-plan/audit-plan.module';
import { AuditTransactionModule, auditTransactionRoute } from './audit/audit-transaction/audit-transaction.module';
import { QuestionModule } from './question/question.module';
import { QuestionaireModule, questionaireRoute } from './questionaire/questionaire.module';
import { DashboardModule, reportRoute } from '../dashboard/dashboard.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScoreCardModule, scoreCardRoute } from './score-card/score-card.module';
import { ActivityRoleModule, activityRoleRoute } from './activity-role/activity-role.module';

const routes: Routes = [questionaireRoute, auditTransactionRoute, auditPlanRoute, reportRoute, activityRoleRoute, scoreCardRoute];

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatCardModule,
        MatIconModule,
        MatGridListModule,
        MatButtonModule,
        LayoutModule,
        MatMenuModule,
        NgxJsonViewerModule, /*https://github.com/hivivo/ngx-json-viewer#readme*/
        FlexLayoutModule,
        ChartsModule,
        HttpClientModule,
        QuestionaireModule,
        QuestionModule,
        AuditTransactionModule,
        AuditPlanModule,
        ReferenceCodeModule,
        DashboardModule,
        RouterModule.forChild(routes),
        NgMultiSelectDropDownModule.forRoot(),
        ActivityRoleModule,
        ScoreCardModule,
        MatDialogModule
    ],
    declarations: [],
    exports: [],
    entryComponents: [

    ],
    providers: []
})
export class EntityModule { }
