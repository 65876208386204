import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize, take} from 'rxjs/operators';
import {Questionaire} from './questionaire.model';
import {QuestionaireService} from './questionaire.service';

export class QuestionaireDataSource extends  DataSource<Questionaire> {

    private questionaireSubject = new BehaviorSubject<Questionaire[]>([]);

    public totalCount$ = new BehaviorSubject<number>(0);
    result: any;
    paginator: any;

    constructor(private questionaireService: QuestionaireService) { super(); }

    connect(collectionViewer: CollectionViewer): Observable<Questionaire[]> {
        return this.questionaireSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.questionaireSubject.complete();
        this.totalCount$.complete();
    }

    loadAudits(sortDirection = 'asc', pageIndex = 0, pageSize = 3, name: String, code: String, status: String) {

        this.questionaireService.questionaireData({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection,
            name: name,
            code: code,
            status: status
        }).pipe(
            catchError(() => of([])),
            finalize(() => {})
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.questionaireSubject.next(response['body']);

        });
    }




}
