import { Component, OnInit , Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTableDataSource } from '@angular/material/table';
import { ObservationResponseDetail } from '../../../questionaire/observation-response-detail.model';



@Component({
  selector: 'app-view-remark-dialog',
  templateUrl: './view-remark-dialog.component.html'
})
export class ViewRemarkDialogComponent implements OnInit {


    observationResponseDetail: ObservationResponseDetail;
    // compliance:AuditComplianceDTO[];
    dataSource: any;
    displayedColumns = ['user', 'action', 'remarks', 'actionDate'];


    constructor(
        private dialogRef: MatDialogRef<ViewRemarkDialogComponent>,

        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.observationResponseDetail = data.observationResponseDetail;
        this.dataSource = new MatTableDataSource(data.observationResponseDetail.complianceHistory);
        // this.compliance=this.observationResponseDetail.complianceHistory;
    }

  ngOnInit() {
  }

}
