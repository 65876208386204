export class ActivityReportParms {
    constructor(
        public type?: string,
        public branchCodes?: string[],
        public fromDate?: any,
        public toDate?: any,
        public roles?: string[],
        public role?: string,
        public orgnationId?: any[],
        public templateId?: any[],
        public userLogin?: string[],
        public branchId?: any[],
        public stateNames?: any[],
        public year?: any,
        public month?: any,
        public businessLineId?: number
    ) {
    }
}
