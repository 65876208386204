import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SystemAdminRoutingModule } from './system-admin-routing.module';
import {MaterialLibModule} from 'src/app/material-lib.module';
import {SwaggerComponent} from './swagger/swagger.component';
import {HealthComponent} from './health/health.component';
import {LogManagementComponent} from './log-management/log-management.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ServerConfigurationComponent } from './server-configuration/server-configuration.component';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import { AuditComponent } from './audit/audit.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MetricsComponent } from './metrics/metrics.component';
import { LayoutModule } from '@angular/cdk/layout';
import {ChartsModule} from 'ng2-charts';
import {MetricsResolver} from './metrics/metrics.resolver';
import { JvmMemoryChartComponent } from './metrics/jvm-memory-chart/jvm-memory-chart.component';
import {ChangeLogLevelBottomSheetComponent} from './log-management/change-log-level-bottom-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialLibModule,
    SystemAdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule, /*https://github.com/hivivo/ngx-json-viewer#readme*/
    FlexLayoutModule,
    ChartsModule
  ],
  declarations: [SwaggerComponent, HealthComponent, LogManagementComponent,
    ChangeLogLevelBottomSheetComponent, ServerConfigurationComponent, AuditComponent,
    MetricsComponent, JvmMemoryChartComponent],
  exports: [SwaggerComponent, HealthComponent],
  entryComponents: [
    ChangeLogLevelBottomSheetComponent
  ],
  providers: [MetricsResolver]
})
export class SystemAdminModule { }
