<mat-card>
    <mat-card-header>
        <mat-card-title align="left"> Are you sure you want to Change the OverAll score?</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <mat-form-field style="padding-left:15px;">
            <input matInput [(ngModel)]="data.element.scorePercentage" placeholder="Calculated Score" readonly>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;
        <mat-form-field>
            <input matInput type="number" [(ngModel)]="data.element.reducedScorePercentage" placeholder="Reduced Score" readonly="{{isreadOnly}}">
            <mat-hint *ngIf="hasError" style="color: red;">{{errorMsg}}</mat-hint>
        </mat-form-field>

    </mat-card-content>
    <mat-card-content style="padding:0px 15px 0px 15px;">
        <mat-form-field style="width:100%">
            <input matInput [(ngModel)]="data.element.userComments" placeholder="Comments" readonly="{{isreadOnly}}">
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions style="padding-left:15px;">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onSubmit(data)" cdkFocusInitial>Save</button>
    </mat-card-actions>
</mat-card>