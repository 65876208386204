<button style="margin-left: 80%;position:relative; top:45px;" type="button" mat-raised-button color="primary"
  (click)="exportUserData()">
  <mat-icon>get_app</mat-icon>Export User Data
</button>
<p style="font-weight: bold;">summary:{{filterPartData.summary}}</p>

<div style="padding-top: 3%;">

  <mat-card>
    <mat-card-content>

      <div class="mat-elevation-z8">
        <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource"
          class="mat-elevation-z8">

          <ng-container matColumnDef="stateName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">State</th>
            <td mat-cell *matCellDef="let element" data-label="Statename">{{element.stateName}}</td>
          </ng-container>

          <ng-container matColumnDef="branchName">
            <th mat-header-cell *matHeaderCellDef>Branch</th>
            <td mat-cell *matCellDef="let element" data-label="BranchName">{{element.branchName}}</td>
          </ng-container>

          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef>Employee</th>
            <td mat-cell *matCellDef="let element" data-label="UserName"> {{element.userName}}</td>
          </ng-container>

          <ng-container matColumnDef="userLoginId">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element" data-label="UserLoginId"> {{element.submittedDate}}</td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element" data-label="role"> {{element.role}}</td>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <th mat-header-cell *matHeaderCellDef>logIn</th>
            <td mat-cell *matCellDef="let element" data-label="SubmittedDate">{{element.userLoginId}}</td>
          </ng-container>

          <ng-container matColumnDef="activity">
            <th mat-header-cell *matHeaderCellDef>Activity</th>
            <td mat-cell *matCellDef="let element" data-label="Activity">{{element.activity}}</td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
          [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>

      </div>
    </mat-card-content>

  </mat-card>
</div>