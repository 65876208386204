import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Center} from './center.model';

@Injectable({
    providedIn: 'root'
})
export class CenterService {

    private API = 'api/centres';
    private APIs = 'api/centers';

    constructor(private http: HttpClient) {}

    findCenters(req: any): Observable<HttpResponse<Center[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if(req.centerStatus){
            if(req.centerStatus == "ACTIVE"){
                params = params.set('centerStatus', 1);
            } else {
                params = params.set('centerStatus', 0);
            }
        }
        return this.http.get<Center[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveCenter(center: Center): Observable<HttpResponse<Center>> {
        const copy: Center = Object.assign({}, center);
        if (copy.id && copy.id != null) {
            return this.http.put<Center>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<Center>(this.APIs, copy, {observe: 'response'});
        }
    }

    getCenter(id: number) {
        return this.http.get<Center>(this.APIs + '/' + id);
    }

    deleteCenter(id: number) {
        return this.http.delete(this.APIs + '/' + id);
    }

    getAllCenters() {
      return this.http.get<Center[]>(this.API + '/' + 'user');
    }
}

