<mat-card style="height: 95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToRoleListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="!editable" class="button-icon-text">View Role</mat-card-title>
    <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create Role</mat-card-title>
    <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit Role</mat-card-title>
        
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="roleEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap"fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row" >
                
                    
                        <input name="id" type="hidden" formControlName="id" />
                    
                
                    
                        <input name="version" type="hidden" formControlName="version" />
                    
                
                    
                        <mat-form-field>
                            <input name="name" matInput type="text"
                                   formControlName="name" placeholder="Name" required/>
                            <mat-error align="start" *ngIf="formErrors.name">
                                {{ formErrors.name }}
                            </mat-error>
                        </mat-form-field>
                    
                
                    
                        <mat-form-field>
                            <input name="code" matInput type="text"
                                   formControlName="code" placeholder="Code" required/>
                            <mat-error align="start" *ngIf="formErrors.code">
                                {{ formErrors.code }}
                            </mat-error>
                        </mat-form-field>
                                        
                        <div style="width: 40%;">
                            <ng-multiselect-dropdown  formControlName="authorities" [placeholder]="'Select Authorities'"
                            [data]="authoritiesObservable$ | async" 
                            [disabled]="!editable"
                            [settings]="authorityDropdownSettings"
                            (onSelect)="onSelect($event)"
                            (onDeSelect)="onDeSelect($event)"
                            (onDeSelectAll)="onDeselectAll()"
                            (onSelectAll)="onSelectAll($event)"
                            >
                             </ng-multiselect-dropdown>
                        </div>

            
                <mat-card-actions *ngIf="editable" class="pull-right" >
                    <button mat-raised-button color="primary">Submit</button>
                    <button mat-raised-button (click)="goToRoleListPage()">Cancel</button>
                </mat-card-actions>
            </div>

        </form>
    </mat-card-content>
</mat-card>
