import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuditPlan } from './audit-plan.model';
import { AuditPlanService } from './audit-plan.service';


@Injectable({
    providedIn: 'root',
})
export class AuditPlanResolveService implements Resolve<AuditPlan> {
    constructor(private cs: AuditPlanService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<AuditPlan> | Observable<never> {
        return this.cs.getAuditPlan(+route.paramMap.get('id'));
    }
}
