import { Component, OnInit } from '@angular/core';
import { ApplicationUsageStatsService } from './application-usage-stats.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { ApplicationUsageStatsDataSource } from './application-usage-stats.data.source';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { MatPaginator} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { RoleService } from '../../../app/admin/role/role.service';
import { Role } from '../../../app/admin/role/role.model';
import { formatDate } from '../../../../node_modules/@angular/common';

@Component({
  selector: 'app-application-usage-stats',
  templateUrl: './application-usage-stats.component.html',
  styleUrls: ['./application-usage-stats.component.css']
})
export class ApplicationUsageStatsComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: ApplicationUsageStatsDataSource;
  listSource: ListSource;
  roles: Role[];
  role: any;
  timestamp: any;
  principal: any;
  type: any;
  fromDate: string;
  toDate: string;
  displayedColumns = ['principal', 'type', 'timestamp'];

    @ViewChild(MatPaginator,{static:true})
  paginator: MatPaginator;

  @ViewChild(MatSort,{static:true})
  sort: MatSort;


  constructor(private applicationUsageStatsService: ApplicationUsageStatsService,
    private activatedRoute: ActivatedRoute,
    private router: Router, private snackBar: MatSnackBar,
    private dialog: MatDialog, private roleService: RoleService) { }

  ngOnInit() {
    this.roleService.getAllRoles().pipe(takeUntil(componentDestroyed(this))).subscribe(roles => {
      this.roles = roles.body;
    });
    const last = new Date(Date.now() - 240 * 3600 * 1000);
    this.fromDate =  last.toDateString();
    this.toDate = new Date().toDateString();
    this.dataSource = new ApplicationUsageStatsDataSource(this.applicationUsageStatsService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
          sortColumn = '';
        }
        this.loadApplications(sortColumn);
      });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }
  ngAfterViewInit(): void {
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadApplicationPageWithParams());
  }
  loadApplicationPageWithParams() {
    this.router.navigate(['admin/application-usage-stats'], this.getQueryParams());
  }

  loadApplications(sortColumn: String) {

    this.fromDate = formatDate(this.fromDate, 'yyyy-MM-dd', 'en-us');
    this.toDate = formatDate(this.toDate, 'yyyy-MM-dd', 'en-us');

    this.dataSource.loadApplications(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
    this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.fromDate, this.toDate, this.role);

  }
  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  downloadAppUsageStats() {
    this.applicationUsageStatsService.downloadAppUsageStats({
      fromDate : this.fromDate,
      toDate : this.toDate,
      role: this.role
    }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      console.log('start download:', res);
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      console.log('download error:', JSON.stringify(error));
    }, () => {
      console.log('Completed file download.');
    });
  }


  clearFilter() {
    this.role = null;
    this.loadApplications('');
  }

}
