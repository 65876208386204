<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>list_alt</mat-icon>
        </div>
        <div mat-card-title>
            AuditTransaction 
        </div>
    </mat-card-header>
    <mat-card-content>
        <!-- <form [formGroup]="auditTransactionForm" novalidate>
            <div fxlayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                <div style="width: 180px;margin-right: 45px;margin-top: 8px;float: left;">
                    <ng-multiselect-dropdown
                        formControlName="selectedBranches"
                        [placeholder]="'Branches'"
                        [data]="branchDropdownList"
                        [(ngModel)]="selectedBranches"
                        [settings]="branchDropdownSettings"
                    >
                    </ng-multiselect-dropdown>
                </div>
                <mat-form-field>
                    <mat-label>Select Status</mat-label>
                    <mat-select [(ngModel)]="selectedStatus" (selectionChange)="applyFilter()" formControlName="selectedStatus">
                        <mat-option [value]="">None</mat-option>
                        <mat-option *ngFor="let statusCode of statusCodes" [value]="statusCode.code">
                            {{statusCode.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;
                <button (click)="onSubmit('')" type="button" mat-button mat-raised-button color="primary">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </form> -->
        <form [formGroup]="auditTransactionForm" >
        <div style="display:flex;">
                <div style="width: 210px;margin-right: 3%;float: left;margin-top:8px;">
                <ng-multiselect-dropdown formControlName="branchFilter" [placeholder]="'Branches'" [data]="filteredBranchList" [settings]="branchDropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onDeselect($event)">
              </ng-multiselect-dropdown>
            </div>
            &nbsp;

            <mat-form-field style="margin-left: 84x">
                <input matInput name="fromDateFilter" formControlName="tentativeFromDate" [max]="toDateFilter"
                    (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter "
                    [matDatepicker]="fromDatePicker" placeholder="From Date">
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;
            <mat-form-field>
                <input matInput name="toDateFilter" formControlName="tentativeToDate" [min]="fromDateFilter"
                    (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
                    [matDatepicker]="toDatePicker" placeholder="To Date">
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;
              <mat-form-field style="width: 180px;margin-right: 10px;">
                <mat-select name="status" formControlName="status" placeholder="Status">
                <mat-option *ngFor="let statusList of statusLists" [value]="statusList.code">{{ statusList.name }}</mat-option>
                </mat-select>
              </mat-form-field>

            <span  style="width: 210px;margin-right: 3%;float: left;margin-top:6px;">
                <button (click)="onSubmit('')" type="button" mat-raised-button color="primary">
                    <mat-icon>search</mat-icon>
                </button>
                <button type="button"  mat-raised-button color="primary" style="margin-left: 8px"
                 (click)="clear()">Clear</button>
                  </span>
        </div>
              </form>
        <div class="mat-elevation-z8" style="margin-top: 20px">
            <table mat-table matSort matSortActive="branchName" matSortDirection="asc" matSortDisableClear
                [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="auditNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="auditNo">Audit Number</th>
                    <td mat-cell *matCellDef="let element" data-label="WorkFlowStatus"> {{element.auditNo === null ? '': element.auditNo}} </td>
                </ng-container>

                <ng-container matColumnDef="branchName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="branchName">Branch Name</th>
                    <td mat-cell *matCellDef="let element" data-label="BranchName"> {{element.branchName}} </td>
                </ng-container>

                <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element" data-label="branchCode"> {{element.branchCode}} </td>
                </ng-container>

                <ng-container matColumnDef="planFromDate">
                    <th mat-header-cell *matHeaderCellDef>From Date</th>
                    <td mat-cell *matCellDef="let element" data-label="planFromDate"> {{element.auditPlanDTO === null ?'' : element.auditPlanDTO.tentativeFromDate | date:'mediumDate'}} </td>
                </ng-container>

                <ng-container matColumnDef="planToDate">
                    <th mat-header-cell *matHeaderCellDef>To Date</th>
                    <td mat-cell *matCellDef="let element" data-label="planToDate"> {{element.auditPlanDTO === null ? '' : element.auditPlanDTO.tentativeToDate | date:'mediumDate'}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element" data-label="Status">
                        <mat-chip-list>
                            <mat-chip *ngIf="element.status === 'WF_AUDIT_STARTED' " color="primary" selected>
                                Audit Started
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_AUDIT_SAVED' " color="primary" selected>
                                Audit Saved
                            </mat-chip>   
                            <mat-chip *ngIf="element.status === 'WF_AUDIT_SUBMITTED' " color="primary" selected>
                                Audit Submitted
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_HOV' " color="primary" selected>
                                HO Checked
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_SV' " color="primary" selected>
                                Supervisor Checked
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_BMV' " color="primary" selected>
                                BM Checked
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_AMV' " color="accent" selected>
                                AM Checked
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_AUDIT_CLOSED' " color="primary" selected>
                                Audit Closed
                            </mat-chip>
                            <mat-chip *ngIf="element.status === 'WF_RETURN_TO_BM' " color="primary" selected>
                                Audit Return
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="View History" [routerLink]="['view', element.id]">
                            <mat-icon>more</mat-icon>
                        </button>

                        <button mat-icon-button matTooltip="Checklists" (click)="getCheckList(element.id, element.status)" >
                            <mat-icon>view_list</mat-icon>
                        </button>

                        <button [disabled]="!element.auditNo || !editScore || element.status == 'WF_AUDIT_CLOSED'" mat-icon-button matTooltip="Change Remarks" (click)="getRemarksDialog(element)">
                            <mat-icon>change_history</mat-icon>
                        </button>

                        <button *ngIf="showScore" mat-icon-button matTooltip="View Scores" (click)="showScores(element.id)" >
                            <mat-icon>score</mat-icon>
                        </button>

                        <button  mat-icon-button matTooltip="Reduce Overall Score" (click)="getReducedScoreDialog(element)">
                            <mat-icon>poll</mat-icon>
                        </button>

                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" 
            [pageSize]="pagingAndSortParams.pageSize" [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
