<mat-drawer-container class="full-height">
  <mat-drawer class="full-height" mode="side" opened position="end" disableClose="true" style="width: 30%">
    <mat-list *ngIf="showScore">
      <h3 mat-subheader>Categories</h3>
      <mat-list-item *ngFor="let key of categoryScoreMapping.keys()">
        <button mat-raised-button (click)="filterQuestionsOnCategoryOnClick(key)" [color]="currentCategory === key ? 'primary' : ''" [matBadge]="categoryScoreMapping.get(key)?.count" matBadgeColor="accent">
          {{ key }}
        </button>
      </mat-list-item>
    </mat-list>
    <mat-list *ngIf="!showScore">
      <h3 mat-subheader>Categories</h3>
      <mat-list-item *ngFor="let key of categoryScoreMapping.keys()">
        <button mat-raised-button (click)="filterQuestionsOnCategoryOnClick(key)" [color]="currentCategory === key ? 'primary' : ''">
          {{ key }}
        </button>
      </mat-list-item>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content style="width: 70%">
    <mat-card-header class="page-title">
      <div mat-card-avatar class="button-icon">
        <button mat-raised-button (click)="back()">
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
      </div>
      <mat-card-title class="button-icon-text">CheckLists</mat-card-title>
    </mat-card-header>
    <p style="margin: 0px 0px 0px 10px">
      Category: <b>{{ currentCategory }}</b>
      <span *ngIf="showScore" class="margin-left-7-percent">
        <b>Marks: </b>{{ currentMarks }}
      </span>
      <span class="margin-left-7-percent">
        Weightage: <b>{{ weightageForCategory }}</b>
      </span>
    </p>
    <form [formGroup]="detailInformationForm">
      <div formArrayName="detailInformationFormArray">
        <div *ngFor="let detailInfo of detailInformationFormArray.controls; let i = index">
          <div [formGroup]="detailInfo">
            <mat-card class="margin-7 dull-background">
              <div>
                <div>
                  <div>{{detailInfo.get('questionName').value}}</div>
                  <ng-container *ngFor="let entry of detailInfo.get('answer').value | keys">
                    <div *ngIf="entry.key == 'answer'; else observation">
                      <label><b>{{ entry.key }}:</b></label> {{ entry.value }}
                      <button *ngIf="showAMVerifyed && canEdit && editScore" mat-icon-button matTooltip="Edit" (click)="openDialog(detailInfo.value)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </div>
                    <ng-template #observation>
                      <div>
                        <label><b>{{ entry?.key }}:</b></label>
                        <div [formArray]="detailInfo?.get('observationResponseDetailDTOs')">
                          <div *ngFor="let observationResponseRemarksDTO of detailInfo?.get('observationResponseDetailDTOs')?.controls; let k = index">
                            <div [formGroup]="observationResponseRemarksDTO">
                              <div style="flex: 1">{{k+1}} ) {{observationResponseRemarksDTO.get('answer').value}}: 
                                <strong>{{observationResponseRemarksDTO.get('noOfInstancesVerified').value}}</strong>
                                Instance and 
                                <strong>{{observationResponseRemarksDTO.get('noOfDeviationsFound').value}} </strong>
                                Deviations 
                                <span *ngIf="observationResponseRemarksDTO.get('isRectified').value!=null">and Rectified
                                  <strong>{{observationResponseRemarksDTO.get('isRectified').value}}</strong></span>
                              </div>
                              <br>
                              <div style="flex: 1">
                                <input type="hidden" formControlName="id" />
                                <input type="hidden" formControlName="version" />
                                <input type="hidden" formControlName="observationResponseDetailId" />
                                <mat-form-field appearance="outline" fxFlex="500px">
                                  <mat-label  style="color: black; font-weight: bold; font-size: medium;">Observation Description</mat-label>
                                  <textarea  rows="5" matInput formControlName="remark" [readonly]="!showAMVerifyed"></textarea>
                                </mat-form-field>
                                <div style="margin-bottom: 80px; margin-left: auto;">
                                  <button *ngIf="showAMVerifyed" mat-icon-button matTooltip="Attach files" (click)="onRemarksDialog(observationResponseRemarksDTO?.value)">
                                    <mat-icon>cloud_upload</mat-icon>
                                  </button>

                                </div>
                              </div>
                             
                              <ng-container *ngFor="let file of observationResponseRemarksDTO.get('observationResponseFilesDTOs').controls; let j = index">
                              <ng-container *ngIf="observationResponseRemarksDTO.get('observationResponseDetailId').value===file.get('observationResponseDetailId').value">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                  <span style="flex: 1">{{ file.get('fileDTO')?.value?.fileName }}</span>
                                  <div>
                                    <button (click)="onDownload(file.get('fileId').value)"*ngIf="ShowAmAndBm" matTooltip="Download File" style="padding-right:10px; border: none; background: none;">
                                      <i class="material-icons" style="padding: 10px;">download</i>
                                    </button>
                                    <button  (click)="deleteDialog(file.get('id').value)" *ngIf="ShowAmAndBm && showAMVerifyed"  matTooltip="Delete File" style="border: none; background: none; margin-left: -20px;">
                                      <i class="material-icons" style="padding: 10px;">delete</i>
                                    </button>  
                                  </div>
                                </div>
                                </ng-container>
                              </ng-container>
                              <br />
                              <br />
                            </div>
                            <div style="text-align: right; margin-bottom: 15px;"> 
                              <button *ngIf="showAMVerifyed" style="margin-left: 10px;" mat-raised-button color="primary" (click)="onSubmit(observationResponseRemarksDTO.value)">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </form>
  </mat-drawer-content>
</mat-drawer-container>

