import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CenterListDataSource } from './center-list.data.source';
import { CenterDeleteDialogComponent } from './center-delete-dialog.component';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { CenterService } from './center.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
    selector: 'app-center-list',
    templateUrl: 'center-list.component.html',
    styles: [`
    .mat-column-name {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 45% !important;
    width: 45% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    word-break: break-word;
  
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
    `]
})
export class CenterListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource: CenterListDataSource;
    listSource: ListSource;
    centerName: any;
    code: any;
    public centerListForm: FormGroup;
    centerStatus: String;
    centerStatuses: String[];

    displayedColumns = ['name', 'code', 'branch', 'status', 'action'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    constructor(private centerService: CenterService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder) { }


    ngOnInit() {
        this.centerListForm = this.buildForm();
        this.dataSource = new CenterListDataSource(this.centerService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'id';
                }
                this.loadCentre('');
            });
        this.loadStatuses();
    }

    buildForm() {
        return this.formBuilder.group({
          centerStatus: ''
        });
      }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadCentersPageWithParams());
    }

    loadCentersPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(centerData: any) {
        const dialogRef = this.dialog.open(CenterDeleteDialogComponent, {
            data: centerData
        });
        dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
            if (result && result === 'deleted') {
                this.snackBar.open(`Deleted center successfully`, 'Close');
                this.ngOnInit();
            }
        });
    }

    loadCentre(sortColumn: String) {
        this.dataSource.loadCenters(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex,
            this.pagingAndSortParams.pageSize,
            this.centerListForm.value['centerStatus']);
    }

    clearFilter() {
        this.centerName = undefined;
        this.code = undefined;
        this.centerListForm.get('centerStatus').setValue(undefined);
        this.search()
    }


    search() {
        this.pagingAndSortParams.pageIndex = 0
        this.paginator.pageIndex = 0
        this.loadCentre('id');
        this.loadCentersPageWithParams();
        this.loadStatuses();
    }

    loadStatuses() {
        this.centerStatuses = ['ACTIVE', 'INACTIVE'];
    }
}
