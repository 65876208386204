import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './user.model';
import { HttpResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { IgPatternValidator } from 'projects/ig-core/src/lib/form/additional-validators/ig-pattern-validator';
import { isEmpty, takeUntil, startWith, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BranchSetService } from '../branch-set/branch-set.service';
import { Observable } from 'rxjs';
import { ActivityRoleService } from 'src/app/entities/activity-role/activity-role.service';
import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { Role } from '../role/role.model';
import { MatDialog } from '@angular/material/dialog';
import { UserEditTemplateComponent } from './user-edit-template.component';
@Component({
  selector: 'app-user-creation',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user.component.css']
})
export class UserEditorComponent implements OnInit, OnDestroy {
  user: User;
  isPresent: boolean;
  roles: any[];
  businessLine: number;
  lmsDatasources: any[];
  businessLines: any[];
  branchsets: any[];
  editable = true;
  isNew: boolean;
  public userEditorForm: FormGroup;
  public formError: FormErrorModel;
  templateDropdownList: any[];
  templates: any;
  latestTemplate : any;
  templateName : any; 
  templateDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    limitSelection: 1,

  };
  templeteHeaderId = [];
  selectedItems = [];
  selectTempleteid: [];
  public formErrors = {
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    role: '',
    branchset: '',
    imageUrl: '',
    langKey: '',
    /* password: '', */
    imeiNumber: '',
    employeeId: '',
    branchsetId: '',
    templeteHeaderId: '',
    businessLineId: ""
  };
  filteredOptions: Observable<any[]>;
  constructor(private activityService: ActivityRoleService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private igFormService: IgFormService, private branchSetService: BranchSetService) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.user === undefined) {
          this.user = {};
        } else {
          this.selectedItems.push(data.user.templateHeaderDTO);
          this.user = data.user;
        }
        this.editable = data.editable;
      });
  }

  ngOnInit() {
    this.loadRoles();
    this.loadBusiness();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.userEditorForm.disable();
    }
    if (this.user.id) {
      this.loadBranchsets(this.userEditorForm.get('businessLineId').value);
      this.loadLatestTemplates(this.userEditorForm.get("businessLineId").value, this.userEditorForm.get("role").value);
    }
    if (Array.isArray(this.selectedItems) && this.selectedItems.length) {
      const template = this.selectedItems[0];
      this.selectTempleteid = template.id;
    }
   
  }
  ngOnDestroy(): void {
  }

  buildForm() {
    if (this.user.id === undefined) {
      this.isNew = true;
      this.user.activated = false
      this.userEditorForm = this.formBuilder.group({
        id: [this.user.id],
        firstName: [this.user.firstName],
        lastName: [this.user.lastName],
        email: [this.user.email, Validators.required],
        login: [this.user.login, Validators.required],
        role: [this.user.role, Validators.required],
        businessLineId: [this.user.businessLineId, Validators.required],
        branchsetId: [this.user.branchsetId],
        imageUrl: [this.user.imageUrl],
        langKey: [this.user.langKey],
        /* password: [this.user.password, Validators.required], */
        activated: [this.user.activated],
        imeiNumber: [this.user.imeiNumber],
        employeeId: [this.user.employeeId],
        templeteHeaderId: [this.user.templeteHeaderId],
      });
    } else {
      this.isNew = false;
      this.userEditorForm = this.formBuilder.group({
        id: [this.user.id],
        firstName: [this.user.firstName],
        lastName: [this.user.lastName],
        email: [this.user.email, Validators.required],
        login: [this.user.login, Validators.required],
        role: [this.user.role, Validators.required],
        businessLineId: [this.user.businessLineId, Validators.required],
        branchsetId: [this.user.branchsetId],
        imageUrl: [this.user.imageUrl],
        langKey: [this.user.langKey],
        activated: [this.user.activated],
        imeiNumber: [this.user.imeiNumber],
        employeeId: [this.user.employeeId],
        templeteHeaderId: [this.user.templateHeaderDTO ? this.user.templateHeaderDTO.name : null ],
        templeteHeaderDtoId: [this.user.templateHeaderDTO ? this.user.templateHeaderDTO.id : null ],
        changePasswordRequired: [this.user.changePasswordRequired]
      });
    }

    if (this.editable && !this.user.id) {
      this.userEditorForm.controls['password'].setValidators(Validators.compose([Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      IgPatternValidator.patternValidator(/\d/, {
        hasNumber: true
      }),
      IgPatternValidator.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      IgPatternValidator.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      IgPatternValidator.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8)]));
    }
    this.userEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, true);
    });
  }

  passwordAutoGenerateChange($event) {
    if ($event.checked) {
      this.userEditorForm.controls['password'].disable();
    } else {
      this.userEditorForm.controls['password'].enable();
    }
  }

  loadRoles() {
    this.userService.getAllRoles()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.roles = data;
      }, (res: HttpResponse<any>) => this.roleLoadError(res.body));
  }

  loadBranchsets(selectedBusinessLine: number) {
    this.branchSetService.getAllBranchsets(selectedBusinessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data: HttpResponse<any[]>) => {
          this.branchsets = data.body;
          const branchsetId = this.user.branchsetId;
          if (branchsetId !== undefined && branchsetId !== null) {
            const branchset = this.getBranchsetFromBranchsetId(branchsetId);
            this.user.branchsetId = branchset.name;
            this.userEditorForm.controls['branchsetId'].setValue(this.user.branchsetId);
          }
          this.filteredOptions = this.userEditorForm.controls['branchsetId'].valueChanges
            .pipe(startWith(''), map(value => this._filter(value)));
        }, (res: HttpResponse<any>) => this.branchsetLoadError(res.body));
  }

  private _filter(value: string): any[] {
    const filterOptions: any[] = [];
    this.branchsets.filter(function (branch) {
      if (branch.name.toLowerCase().includes(value.toLowerCase())) {
        filterOptions.push(branch);
      }
    });
    return filterOptions;
  }

  getBranchsetFromBranchsetName(branchsetName: string): any {
    let indesxOfSelectedBranch;
    for (const branchset of this.branchsets) {
      if (branchset.name === branchsetName) {
        indesxOfSelectedBranch = this.branchsets.indexOf(branchset);
        return this.branchsets[indesxOfSelectedBranch];
      }
    }
  }

  getBranchsetFromBranchsetId(id: number): any {
    let indesxOfSelectedBranch;
    for (const branchset of this.branchsets) {
      if (branchset.id === id) {
        indesxOfSelectedBranch = this.branchsets.indexOf(branchset);
        return this.branchsets[indesxOfSelectedBranch];
      }
    }
  }

  
  loadLatestTemplates(selectedBusinessLine: number, selectedRoles: string) {
    this.templateDropdownList = []
    this.templates = []
    if (selectedBusinessLine !== 0 && selectedRoles !== null) {
      this.activityService.getLatestTemplates(selectedBusinessLine, selectedRoles).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => {
          this.templates = response.body;
          this.latestTemplate = this.templates.id;
        });
    }
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.userEditorForm);
    const branchset = this.getBranchsetFromBranchsetName(this.userEditorForm.controls['branchsetId'].value);

    if(this.userEditorForm.controls['id'].value == null){
      if (this.userEditorForm.get('templeteHeaderId') !== undefined) {
        this.userEditorForm.controls['templeteHeaderId'].setValue(this.templates.id);
      }
      if (this.userEditorForm.valid) {
        if (branchset !== undefined) {
          this.userEditorForm.controls['branchsetId'].setValue(branchset.id);
        }
        this.userService.saveUser(this.userEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => this.onSubmitSuccess(response),
            (response) => this.onSubmitError(response));
      } else {
        this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, false);
        return false;
      }
    }else{
      
      if (this.userEditorForm.get('templeteHeaderDtoId') !== undefined && this.templates.id != undefined &&
      this.userEditorForm.get('templeteHeaderId') !== undefined &&
      this.userEditorForm.controls['templeteHeaderDtoId'].value!=this.templates.id) {
         this.templateName = this.userEditorForm.controls['templeteHeaderId'].value;
         const oldtempalte = this.userEditorForm.controls['templeteHeaderDtoId'].value;
        if (this.userEditorForm.valid) {
          if (branchset !== undefined) {
            this.userEditorForm.controls['branchsetId'].setValue(branchset.id);
          }
        } else {
          this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, false);
          return false;
        }
        this.userEditorForm.controls['templeteHeaderId'].setValue(this.templates.id);
        this.templateMapDialog(this.userEditorForm.value, oldtempalte);
      }

      else if(this.userEditorForm.controls['templeteHeaderDtoId'].value == null || 
      this.userEditorForm.controls['templeteHeaderId'].value == null){
        if (this.userEditorForm.valid) {
          if (branchset !== undefined) {
            this.userEditorForm.controls['branchsetId'].setValue(branchset.id);
          }
          this.userEditorForm.controls['templeteHeaderId'].setValue(this.templates.id);
          this.userService.saveUser(this.userEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
        } else {
          this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, false);
          return false;
        }
      }

      else if (this.userEditorForm.get('templeteHeaderId') !== undefined && this.user.templateHeaderDTO != null &&
      this.userEditorForm.controls['templeteHeaderId'].value==this.user.templateHeaderDTO.name) {
        this.userEditorForm.controls['templeteHeaderId'].setValue(this.user.templateHeaderDTO.id);
        if (this.userEditorForm.valid) {
          if (branchset !== undefined) {
            this.userEditorForm.controls['branchsetId'].setValue(branchset.id);
          }
          this.userEditorForm.controls['templeteHeaderId'].setValue(this.templates.id);
          this.userService.saveUser(this.userEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
        } else {
          this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, false);
          return false;
        }
      }
      
      else if(this.userEditorForm.get('templeteHeaderId') !== undefined) {
        if (this.userEditorForm.valid) {
          if (branchset !== undefined) {
            this.userEditorForm.controls['branchsetId'].setValue(branchset.id);
          }
          this.userEditorForm.controls['templeteHeaderId'].setValue(this.templates.id);
          this.userService.saveUser(this.userEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
        } else {
          this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, false);
          return false;
        }
      }
    }
  }

  templateMapDialog(userData: any, oldtemplate: any) {
    const dialogRef = this.dialog.open(UserEditTemplateComponent, {
      data: {
        userData: userData,
        oldtemplate: oldtemplate
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe(result => {
      this.userEditorForm.controls['templeteHeaderId'].setValue(this.templateName);
    });
}

  private onSubmitSuccess(response) {
    this.snackBar.open(`Updated user ${this.user.login} successfully`, 'Close', {
      duration: 10,
    });
    this.goToUserListPage();
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(this.userEditorForm, this.formErrors, response.error);
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }

  private roleLoadError(response) {
    this.snackBar.open('Failed to get roles, Please try again later [' + response + ']', 'Close', {
      duration: 10,
    });
  }

  private branchsetLoadError(response) {
    this.snackBar.open('Failed to get branchsets, Please try again later [' + response + ']', 'Close', {
      duration: 10,
    });
  }

  goToUserListPage() {
    this.router.navigateByUrl('admin/user');
  }

  loadTemplates(selectedBusinessLine: number, selectedRoles: string) {
    this.templateDropdownList = []
    this.templates = []
    if (selectedBusinessLine !== 0 && selectedRoles !== null) {
      this.activityService.getLatestTemplates(selectedBusinessLine, selectedRoles).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => {
          this.templates = response.body;
          this.userEditorForm.controls['templeteHeaderId'].setValue(this.templates.name);
          this.userEditorForm.controls['templeteHeaderDtoId'].setValue(this.templates.id);
        },
          (error) => {
            let errorMessage = "Business Line: " + selectedBusinessLine + " and role: " + selectedRoles + " does not have any template";
            if (error && error.error && error.error.detail) {
              errorMessage = error.error.detail;
            }
            this.snackBar.open(errorMessage, 'close', {
              duration: 10000,
            });
            console.error(errorMessage, error);
          }
        );
    }
  }

  onSelectTemplate(event) {
    this.selectTempleteid = event.id;
  }

  loadBusiness() {
    this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
      subscribe(businessLineDTO => this.businessLines = businessLineDTO);
  }

  onOptionsSelected(selectedBusniessLIne: any) {
    this.branchsets = [];
    this.selectTempleteid = null;
    this.userEditorForm.get('branchsetId').setValue(undefined);
    this.userEditorForm.get('templeteHeaderId').setValue(undefined);
    this.selectTempleteid = undefined;
    this.loadTemplates(selectedBusniessLIne.value, this.userEditorForm.get("role").value);
    this.loadBranchsets(selectedBusniessLIne.value);
  }

  onRoleSelection(event) {
    this.selectTempleteid = null;
    this.userEditorForm.get("templeteHeaderId").reset();
    if (this.userEditorForm.get("businessLineId").value && this.userEditorForm.get("role").value) {
      this.loadTemplates(this.userEditorForm.get("businessLineId").value, this.userEditorForm.get("role").value);
    }
  }

}

