import {
  HttpClient,
  HttpParams,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createRequestOption } from "projects/ig-core/src/lib/utils/request-util";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuditPlan } from "./audit-plan.model";
import { isUndefined } from "util";
import { BmtReportParamsModel } from "src/app/dashboard/bmtProductivityReport/bmt-report-params.model";

@Injectable({
  providedIn: "root",
})
export class AuditPlanService {
  private API = "api/audit-plans";
  constructor(private http: HttpClient) {}
 
  auditPlan(req: any): Observable<HttpResponse<AuditPlan[]>> {
    let params = new HttpParams();

params = params.set('page', req.page);
params = params.set('size', req.size);
params = params.set('sort', req.sort);

if (req.branchCodes) {
params = params.set('branchCodes', req.branchCodes);
}
if (req.auditForMonth) {
params = params.set('month', req.auditForMonth);
}
if (req.auditForYear) {
params = params.set('year', req.auditForYear);
}
if (req.teamLead) {
params = params.set('teamLead', req.teamLead);
}
if (req.assistant1) {
params = params.set('assistant1', req.assistant1);
}

return this.http.get<AuditPlan[]>(this.API, {
params, observe: 'response'
});


}

  getAuditPlan(id: number) {
    return this.http.get<AuditPlan>(this.API + '/' + id);
  }

  deleteAuditPlan(id: number) {
    return this.http.delete(this.API + '/' +  id);
  }

  saveAuditPlan(auditPlan: AuditPlan): Observable<HttpResponse<AuditPlan>> {
    const copy: AuditPlan = Object.assign({}, auditPlan);
    if (copy.id && copy.id != null) {
      return this.http.put<AuditPlan>(this.API, copy, { observe: "response" });
    } else {
      return this.http.post<AuditPlan>(this.API, copy, { observe: "response" });
    }
  }

  uploadAuditPlan(multipartFile: File): Observable<HttpResponse<string>> {
    const formData = new FormData();
    formData.set("multipartFile", multipartFile);
    return this.http.post<string>(this.API + "/file-upload", formData, {
      observe: "response",
      responseType: 'text' as 'json'
    });
  }

  downloadAuditPlanTemplate(): Observable<any> {
    return this.http
      .get(this.API + "/download/template", {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const out = { data: null, filename: null };
          out.data = resp.body;
          out.filename = "AuditPlan Upload Template" + ".xlsx";
          return out;
        })
      );
  }

  downloadAuditPlanReport(req: any): Observable<any> {
    let params = new HttpParams();
    if (req.branchCodes) {
      params = params.set('branchCodes', req.branchCodes);
      }
      if (req.auditForMonth) {
      params = params.set('auditMonth', req.auditForMonth);
      }
      if (req.auditForYear) {
      params = params.set('auditYear', req.auditForYear);
      }
      if (req.teamLead) {
      params = params.set('teamLead', req.teamLead);
      }
      if (req.assistant1) {
      params = params.set('assistant1', req.assistant1);
      }
      
    return this.http
      .get(this.API + "/download/report", {
        observe: "response",
        responseType: "blob",
        params: params,
      })
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const out = { data: null, filename: null };
          out.data = resp.body;
          out.filename = "AuditPlan Report" + ".xlsx";
          return out;
        })
      );
  }
}
