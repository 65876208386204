<div class="container" fxLayout="row" fxFlexFill fxLayoutAlign="center">
  <div fxFlex.gt-sm="50">
    <mat-card class="login-card ig-card-as-panel">
      <mat-card-header class="ig-background-primary">
        <mat-card-title>Reset Password</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="resetForm" novalidate (ngSubmit)="onSubmit()">
          <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field *ngIf="changePassword">
              <input name="oldPasswordTxt" matInput type="password" formControlName="oldPassword"
                placeholder="Old Password" [attr.tabindex]="0" autocomplete="off" />
              <mat-error align="start" *ngIf="formErrors.oldPassword">{{ formErrors.oldPassword }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input name="passwordTxt" matInput type="password" formControlName="password" placeholder="New Password"
                [attr.tabindex]="0" autocomplete="off" />
              <mat-error align="start" *ngIf="formErrors.password">{{ formErrors.password }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input name="newPasswordTxt" matInput type="password" formControlName="newPassword"
                placeholder="Confirm New Password" [attr.tabindex]="0" autocomplete="off" />
              <mat-error align="start" *ngIf="formErrors.newPassword">{{ formErrors.newPassword }}</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" [attr.tabindex]="0"> Reset</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>