import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiskObservation } from './risk-observation.model';
import { createRequestOption } from 'projects/ig-core/src/lib/utils/request-util';
import { AuditPlan } from 'src/app/entities/audit/audit-plan/audit-plan.model';

@Injectable({
    providedIn: 'root'
})
export class RiskObservationService {
    private API = 'api/audit-plans/get-checklists/under-risk';
    constructor(private http: HttpClient) { }

      findRiskObservation(req?: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('branchCode', req.branchCode);
        params = params.set('status', req.status);
        return this.http.get<any>(this.API, {
            params, observe: 'response'
        });
    }
    getAllBranchesForUser(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/branches/user', { observe: 'response' });
    }


}
