import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { UserListComponent } from './user-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MaterialLibModule } from '../../material-lib.module';
import { UserEditorComponent } from './user-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserDeleteDialogComponent } from './user-delete-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserResolverService } from './user-resolve.service';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { UserUploadComponent } from './user-upload.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserEditTemplateComponent } from './user-edit-template.component';


export const userRoute: Route = {
  path: 'user',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: UserListComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_USER_LIST'] }
    },
    {
      path: 'create',
      component: UserEditorComponent,
      canActivate: [AuthenticatedUserService],
      resolve: {
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_USER'] }
    },
    {
      path: 'update/:username',
      canActivate: [AuthenticatedUserService],
      component: UserEditorComponent,
      resolve: {
        user: UserResolverService,
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_USER'] }
    },
    {
      path: 'view/:username',
      canActivate: [AuthenticatedUserService],
      component: UserEditorComponent,
      resolve: {
        user: UserResolverService,
        editable: NonEditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_USER'] }
    },
    {
      path: 'user-upload',
      canActivate: [AuthenticatedUserService],
      component: UserUploadComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_UPLOAD_USER'] }
    },
  ]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [UserListComponent, UserEditorComponent, UserDeleteDialogComponent, UserUploadComponent, UserEditTemplateComponent],
  entryComponents: [UserDeleteDialogComponent],
  exports: [RouterModule]
})
export class UserModule { }
