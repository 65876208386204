import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import {QuestionnaireConfigurationService} from '../questionnaire-configuration.service';
import {QuestionnaireConfiguration} from '../questionnaire-configuration.model';

@Component({
  selector: 'app-questionnaire-configuration-delete-dialog',
  templateUrl: './questionnaire-configuration-delete-dialog.component.html',
  styleUrls: ['../questionnaire-configuration.component.css']
})
export class QuestionnaireConfigurationDeleteDialogComponent implements OnInit, OnDestroy {
  questionnaireConfiguration: QuestionnaireConfiguration;
  errorMessage: string;
  constructor(private questionnaireConfigurationService: QuestionnaireConfigurationService,
              public dialogRef: MatDialogRef<QuestionnaireConfigurationDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: QuestionnaireConfiguration) {
    this.questionnaireConfiguration = data;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  delete() {
    this.errorMessage = '';
    this.questionnaireConfigurationService.deleteQuestionnaireConfig(this.questionnaireConfiguration.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
  }

  onSaveSuccess(result) {
    this.dialogRef.close('deleted');
  }

  onSaveError(response) {
    this.errorMessage = response;
  }

  cancel(): void {
    this.dialogRef.close('cancelled');
  }
}
