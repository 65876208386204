import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AuditTransactionDetail } from './audit-transaction-detail.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { CheckListDetailService } from '../audit-transaction-resolve.service';
import { Location } from '@angular/common';
import { AuditTransactionService } from '../audit-transaction-service';

import {AuditWorkFlowConfig} from './audit-workflow-config.model';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { AuditTransactionDetailDataSource } from './audit-transaction-detail.data.source';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CloseAuditDialogComponent } from './close-audit-dialog.component';

@Component({
    selector: 'app-audit-transaction-detail',
    templateUrl: './audit-transaction-detail.component.html',
    styleUrls: ['../audit-transaction.component.css']
})
export class AuditTransactionDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    auditTransactionDetails: AuditTransactionDetail[];
    pagingAndSortParams: PagingAndSortParams;
    dataSource: AuditTransactionDetailDataSource;
    displayedColumns: string[] = ['branchCode', 'centerCode', 'submittedBy', 'submittedOn', 'status', 'action'];
    listSource: ListSource;
    loggedInUser: any;
    actionName: string;
    isPresent: boolean;
    actionStatus: string;
    comment: string;
    status: string;
    auditReject: string;
    isValue: boolean;
    id: number;
    auditTransactionDetail: AuditTransactionDetail;
    showScore: boolean;
    viewCheckList: boolean;
    auditTransactionStatus: string;
    auditWorkflowConfig: AuditWorkFlowConfig[];
    isEnableAuditReject = false;
    disableAuditCloseButton = false;
    isPresentComment = true;
    closeAuditDialog: MatDialogRef<CloseAuditDialogComponent>;
    isAuditor: boolean;
    isTeamLead: boolean;
    isDisagree:boolean=false;
    auditTransactionDetailList:any;
    closeAudit:boolean =false;
    hasObservation:boolean = false;
      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;
    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private checkListDetailService: CheckListDetailService,
        private location: Location, private auditTransactionService: AuditTransactionService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                // this.auditTransactionDetails = data.auditTransactionDetails;
                this.loggedInUser = data.loggedInUser.body;
            });
        this.activatedRoute.params.pipe(takeUntil(componentDestroyed(this))).subscribe(params => {
            this.status = params.status;
            this.id = params.id;
            if (this.status === 'WF_AUDIT_CLOSED' ) {
                this.isPresentComment = false;
            }
        });
    }

    applyFilter(value){}

    ngOnInit() {
        this.showScore = false;
        this.viewCheckList = false;
        this.isAuditor = false;
        this.auditDetailList();
        this.auditTransactionService.getWorkflowConfigForAuditTransactionStatus(this.id)
        .pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
           this.auditWorkflowConfig = data;
           this.setActionName(this.auditWorkflowConfig);
         });
       /*  this.auditTransactionService.getDetailedInfo(this.id)
            .pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
               this.auditTransactionDetails = data.body;
                if (this.auditTransactionDetails.findIndex((auditTransactionDetail) =>
                auditTransactionDetail.hasObservationResponse === true) >= 0) {
                this.disableAuditCloseButton = true;
                }
            }); */
            this.dataSource = new AuditTransactionDetailDataSource(this.auditTransactionService);
            this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
            this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
            this.pagingAndSortParams = listParams.pagingAndSortParam;
            let sortColumn = this.pagingAndSortParams.sortColumn;
            if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
              sortColumn = '';
            }
            this.loadAuditTransactionDetail(sortColumn);
          });
        if (this.loggedInUser.authorities.indexOf('ROLE_SHOW_SCORES') >= 0) {
            this.showScore = true;
        }
        if(this.loggedInUser.authorities.indexOf('WF_AUDIT_SAVED') >= 0) {
            this.isAuditor = true; 
        }
        if (this.loggedInUser.authorities.indexOf('ROLE_VIEW_CHECKlIST_DETAIL') >= 0) {
            this.viewCheckList = true;
        }
        this.auditTransactionService.displayTeamLead(this.id).pipe(takeUntil(componentDestroyed(this))).subscribe((data) =>{
          console.log(data);
          this.isTeamLead = data.isTeamLead;
          if(this.isTeamLead == true){
            this.isPresentComment = false;
        }
        });
    }
    ngOnDestroy(): void {
        this.listSource.disconnect();
        this.auditTransactionService.setDiasble(false);
      }

      ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange()
          .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadAuditTransactionDetailPage());
      }

      loadAuditTransactionDetailPage() {
        // this.router.navigate(['auditTransaction/response'], this.getQueryParams());
        this.router.navigate([], this.getQueryParams());
      }

      getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
      }
    navigateToCheckListResponse(auditTransactionDetail: AuditTransactionDetail) {
        this.checkListDetailService.setAuditTransactionDetail(auditTransactionDetail);
        this.auditTransactionService.setAuditTransactionStatus(this.status);
        this.router.navigate(['auditTransaction/response' + '/' + auditTransactionDetail.id]);
    }

    back() {
        this.router.navigateByUrl('auditTransaction');
    }

    updateStatus(status: string) {
            this.auditTransactionService.updateStatus(this.id, status, this.comment)
            .pipe(takeUntil(componentDestroyed(this))).
            subscribe((response) => this.onSaveUpdateStatus(response),
              (response) => this.onSaveError(response)
              );
    }
    private onSaveUpdateStatus(response) {
        this.snackBar.open(`Updated Status Successfully`, 'Close', {
            duration: 3000,
        });
        this.back();
    }

    private onSaveError(response) {
        this.snackBar.open(response.error.title, 'Close', {
            duration: 7000,
        });
    }

    setActionName(auditWorkFlowConfigArray: AuditWorkFlowConfig[]): void {
        if(this.status==='WF_RETURN_TO_BM'){
            this.isDisagree = false;
        }
        if (auditWorkFlowConfigArray.length > 0) {
            this.isPresent = true;
            if (auditWorkFlowConfigArray.findIndex((item) => item.forwordStageLable === 'Close') >= 0) {
            this.isEnableAuditReject = true;
            }
            auditWorkFlowConfigArray.every((config) => {
                if (config.forwordStageLable === 'Close') {
                    this.actionStatus = config.forwordStageLable + 'Audit';
                    this.closeAudit = true;
                    return true;
                } else {
                    if (this.isEnableAuditReject) {
                    this.auditReject = config.forwordStageLable;
                    } else {
                    this.actionName = config.forwordStageLable;
                    }
                }
                return true;
            });
        }
    }
    navigateToDetailInformationResponse(auditTransactionDetail: AuditTransactionDetail) {
        this.router.navigate(['auditTransaction/edit-detailed-info/' + this.status + '/' + auditTransactionDetail.id]);
    }
    loadAuditTransactionDetail(sortColumn: String) {
        this.dataSource.loadAuditTransactionDetail(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.id);
    }

    onClick(status: string) {
        console.log("Logged-in user: ", this.loggedInUser);
        if (
            (this.loggedInUser.role && this.loggedInUser.role=='AUDITOR') &&
            (this.isTeamLead)
        ) {
            console.log("id: " + this.id);
    
            this.closeAuditDialog = this.dialog.open(CloseAuditDialogComponent, {
                maxHeight: "300vh",
                width: "50%",
                data: { id: this.id,  status : status}
            });
    
            this.closeAuditDialog.afterClosed().subscribe(result => {
            });
        } else {
            console.log("User is not an auditor or a team lead.");
            this.snackBar.open('User is not an auditor or a team lead.', 'Close', {
                duration: 7000,
            });
        }
    }

    isChecked(complianceHistory: any) {
        if (complianceHistory) {
            complianceHistory.forEach(element => {
                if (element['lastAction'] === 'disagree') {
                    this.isDisagree = true;
                }
                if (element['lastAction'] === 'OPEN') {
                    this.closeAudit = true;
                }
            });
        }
    }

    auditDetailList() {
        this.auditTransactionService.getAuditTransactionDetailList(this.id).
            pipe(takeUntil(componentDestroyed(this))).subscribe(response => {
                this.auditTransactionDetailList = response;
                this.auditTransactionDetailList.forEach(element => {
                    if (element['hasObservationResponse'] === true) {
                        this.isChecked(element['observationResponseDetailDTOs']);
                        this.hasObservation = true;
                    }
                });
            });
    }
}

    

