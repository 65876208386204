import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "../../../../../../node_modules/@angular/material/snack-bar";
import { componentDestroyed } from "../../../../../../node_modules/@w11k/ngx-componentdestroyed";
import { takeUntil } from "../../../../../../node_modules/rxjs/operators";
import { AuditTransactionService } from "../audit-transaction-service";
import { AuditTransactionDetail } from "./audit-transaction-detail.model";
import { AuditTransactionQuestionComponent } from "./audit-transaction-question.component";
import { EditRemarksDialogComponent } from "./edit-remark-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { ObservationResponseRemarks } from "../observation-response-remark.model";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FileDeleteDialogComponent } from "../file-delete-dialog.component";
import { Subscription } from "rxjs";
import { AuditTransactionOrderCount } from "./audit-transaction-order-count.model";

@Pipe({ name: "keys" })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    if (typeof value === "string") {
      value = JSON.parse(value);
    }
    const keys = [];
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        let changedKey = key;
        if (key === "observationConfig") {
          changedKey = "Observation";
        }
        keys.push({ key: changedKey, value: value[key] });
      }
    }
    return keys;
  }
}

@Component({
  selector: "app-audit-transaction-detail-information-response",
  templateUrl: "./audit-transaction-detail-information-response.component.html",
  styleUrls: ["../audit-transaction.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditTransactionDetailInformationResponseComponent
  implements OnInit, OnDestroy
{
  auditTransactionDetail: AuditTransactionDetail;
  checkListResponse: any;
  categories: string[] = [];
  currentCategory: string;
  categoryScoreJson: any;
  categoryScoreMapping: Map<string, AuditTransactionOrderCount> = new Map<string, AuditTransactionOrderCount>();
  currentMarks: number;
  loggedInUser: any;
  errorMessage: string;
  answerJsons: JSON[];
  showScore: boolean;
  categoryPossibleMarksMapping: String;
  maxMarkForCategory: number;
  auditTransactionDetailId: number;
  canEdit: boolean;
  workflowStatus: string;
  editScore: boolean;
  categoryWeightagePercentageJson: any;
  weightageForCategory: number;
  ShowAmAndBm: boolean;
  showAMVerifyed: boolean;
  isHoAssistantAndHaSupervisor: boolean;
  editRemarkDialogRef: MatDialogRef<EditRemarksDialogComponent>;
  fileDeleteDialogRef: MatDialogRef<FileDeleteDialogComponent>;
  file: File;
  isAdmin: boolean;
  // myForm: FormGroup;
  // answerDialogRef: MatDialogRef<AuditTransactionQuestionComponent>;

  message: string;
  detailInformationForm: FormGroup;
  // detailInformationArray: FormArray;

  constructor(
    private location: Location,
    private auditTransactionService: AuditTransactionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.auditTransactionDetailId = params.id;
      });
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.workflowStatus = params.status;
      });
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.loggedInUser = data.loggedInUser.body;
        if(data.auditTransactionDetail) {
          this.auditTransactionDetail = data.auditTransactionDetail;
        if (this.auditTransactionDetail.categoryScoreMapping) {
          this.categoryScoreJson = JSON.parse(
            this.auditTransactionDetail.categoryScoreMapping
          );
        }
        if (this.auditTransactionDetail.branchTotalWeightagePercentage) {
          this.categoryWeightagePercentageJson = JSON.parse(
            this.auditTransactionDetail.branchTotalWeightagePercentage
          );
        }
        }
      });
  }

  @ViewChild("fileUploader", { static: false })
  myInputVariable: ElementRef;

  @ViewChild("fileUpload")
  fileUpload: ElementRef;

  @Input()
  files: File[] = [];

  @Input()
  multiple;
  @Input()
  chooseLabel = "Choose";
  @Input()
  uploadLabel = "Upload";
  @Input()
  deleteButtonIcon = "close";
  updateSuccessSubscription: Subscription;

  ngOnInit() {
    this.showScore = true;
    this.canEdit = true;
    this.editScore = false;
    this.ShowAmAndBm = false;
    this.isHoAssistantAndHaSupervisor = false;
    this.isAdmin = false;
    this.showAMVerifyed = true;
    this.syncAuditTransactions(true);
    if (this.loggedInUser.authorities.indexOf("ROLE_SHOW_SCORES") >= 0) {
      this.showScore = true;
    }
    if (this.workflowStatus === "WF_AUDIT_CLOSED") {
      this.canEdit = false;
      this.ShowAmAndBm = false;
      this.showAMVerifyed = false;
    }
    if (this.loggedInUser.authorities.indexOf("ROLE_EDIT_SCORES") >= 0) {
      this.editScore = true;
    }
    if (this.loggedInUser.authorities.indexOf("ROLE_SHOW_OBSERVATION_REMARK_VIEW_FILES") >= 0) {
      this.ShowAmAndBm = true;
    }
    if (this.workflowStatus == "WF_AMV") {
      this.showAMVerifyed = !this.showAMVerifyed;
    }
   
    this.getDistinctQuestionCategories();
    // this.auditTransactionService.getPossibleMarksForAuditTransactionDetail(this.auditTransactionDetail.id)
    //     .pipe(takeUntil(componentDestroyed(this)))
    //     .subscribe((success) => {
    //         this.categoryPossibleMarksMapping = success;
    //         this.filterQuestionsOnCategory(this.categoryScoreMapping.keys().next().value);
    //     });
    // this.filterQuestionsOnCategory(
    //   this.categoryScoreMapping.keys().next().value
    // );
   
    // new
    this.checkListResponse = this.filterQuestionsOnCategory(
      this.categoryScoreMapping.keys().next().value
    );

    // this.changeDetector.detectChanges();
    this.initializeForm();

    this.changeDetector.detectChanges();

  }
  // syncAuditTransactions() {
  //   this.auditTransactionService
  //     .getAuditTransactionDetailedInformation(this.auditTransactionDetailId)
  //     .pipe(takeUntil(componentDestroyed(this)))
  //     .subscribe((response) => {
  //       this.auditTransactionDetail = response;
  //       if (this.auditTransactionDetail.categoryScoreMapping) {
  //         this.categoryScoreJson = JSON.parse(
  //           this.auditTransactionDetail.categoryScoreMapping
  //         );
  //       }
  //       if (this.auditTransactionDetail.branchTotalWeightagePercentage) {
  //         this.categoryWeightagePercentageJson = JSON.parse(
  //           this.auditTransactionDetail.branchTotalWeightagePercentage
  //         );
  //       }
  //       this.getDistinctQuestionCategories();
  //       // this.auditTransactionService.getPossibleMarksForAuditTransactionDetail(this.auditTransactionDetail.id)
  //       //     .pipe(takeUntil(componentDestroyed(this)))
  //       //     .subscribe((success) => {
  //       //         this.categoryPossibleMarksMapping = success;
  //       //         this.filterQuestionsOnCategory(this.categoryScoreMapping.keys().next().value);
  //       //     });
  //       this.filterQuestionsOnCategory(
  //         this.categoryScoreMapping.keys().next().value
  //       );
  //     });
  // }

  syncAuditTransactions(categoryRefresh: boolean): void {
    this.auditTransactionService
      .getAuditTransactionDetailedInformation(this.auditTransactionDetailId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.auditTransactionDetail = response;
        if (this.auditTransactionDetail.categoryScoreMapping) {
          this.categoryScoreJson = JSON.parse(
            this.auditTransactionDetail.categoryScoreMapping
          );
        }
        if (this.auditTransactionDetail.branchTotalWeightagePercentage) {
          this.categoryWeightagePercentageJson = JSON.parse(
            this.auditTransactionDetail.branchTotalWeightagePercentage
          );
        }
        this.getDistinctQuestionCategories();
        // this.auditTransactionService.getPossibleMarksForAuditTransactionDetail(this.auditTransactionDetail.id)
        //     .pipe(takeUntil(componentDestroyed(this)))
        //     .subscribe((success) => {
        //         this.categoryPossibleMarksMapping = success;
        //         this.filterQuestionsOnCategory(this.categoryScoreMapping.keys().next().value);
        //     });
        if(categoryRefresh){
          this.filterQuestionsOnCategory(
            this.categoryScoreMapping.keys().next().value
          );
        }
        this.checkListResponse = this.auditTransactionDetail.questionaireResponse.answers.filter((ques) => ques.question.category === this.currentCategory);
        this.initializeForm();
      });
  }

  ngOnDestroy() {
  
  }

  getDistinctQuestionCategories(): Map<string, AuditTransactionOrderCount> {
    const categorySet: Set<string> = new Set(
      this.auditTransactionDetail.questionaireResponse.answers.map(
        (answer) => (answer.question) ? (answer.question.category) : ""
      )
    );
    Array.from(categorySet).forEach((categoryName) => {
      const category = categoryName.trim();
      if (this.categoryScoreJson && this.categoryScoreJson[category]) {
        this.categoryScoreMapping.set(
          category,
          this.categoryScoreJson[category]
        );
      } else if(category != ""){
        const auditTransactionOrderCount: AuditTransactionOrderCount ={
          order: null,
          count: 0
        };
        this.categoryScoreMapping.set(category, auditTransactionOrderCount);
      }
      this.categoryScoreMapping = new Map([...this.categoryScoreMapping].sort((a, b) => a[1].order - b[1].order));
    });
    return this.categoryScoreMapping;
  }

  // filterQuestionsOnCategory(category: string) {
  //   this.currentCategory = category;
  //   this.currentMarks = this.categoryScoreMapping.get(category);
  //   this.weightageForCategory = this.categoryWeightagePercentageJson[category];
  //   // this.maxMarkForCategory = this.categoryPossibleMarksMapping[category];
  //   this.checkListResponse =
  //     this.auditTransactionDetail.questionaireResponse.answers.filter(
  //       (ques) => ques.question.category === category
  //     );
  //   console.log("categories", this.currentCategory);

  //   console.log("checklist", this.checkListResponse);

  //   // this.detailInformationForm = this.initializeForm();
  //   // this.initializeForm();
  //   // console.log('checklist', this.checkListResponse[4]?.observationResponseDetailDTOs[0]?.observationResponseRemarksDTO?.observationResponseFilesDTOs);
  // }

  filterQuestionsOnCategoryOnClick(category: string) {
    this.checkListResponse = this.filterQuestionsOnCategory(
      category
    );
    this.initializeForm();
  }


  // new
  filterQuestionsOnCategory(category: string) {
    this.currentCategory = category;
    if(this.categoryScoreJson[category]){
      const auditTransactionOrderCount = this.categoryScoreJson[category];
      this.currentMarks = auditTransactionOrderCount["count"];
    }
    this.weightageForCategory = this.categoryWeightagePercentageJson[category];
    return  this.auditTransactionDetail.questionaireResponse.answers.filter((ques) => ques.question.category === category);
  }

  back() {
     this.location.back()
  }

  openDialog(detailAboutObservations: any): void {
    console.log('detailAboutObservations', detailAboutObservations);
    let responseDetailsObjectDataFromForm = {
      id: detailAboutObservations.id,
      version : detailAboutObservations.version,
      questionnarieResponseDetailsId : detailAboutObservations.id,
      answer: detailAboutObservations.answer,
      question: detailAboutObservations.question,
      questionnaireResponseId: detailAboutObservations.questionnaireResponseId,
      questionId: detailAboutObservations.questionId,
      obtainedMarks: detailAboutObservations.obtainedMarks,
      observationChoicesConfig: detailAboutObservations.observationConfig,
      riskStatus: detailAboutObservations.riskStatus,
      observationResponseDetailDTOs: detailAboutObservations.observationResponseDetailDTOs
    }

      // this.openDialog();
       const answerDialogRef = this.dialog.open(AuditTransactionQuestionComponent, {
        data: {
          responseDetailObject: responseDetailsObjectDataFromForm,
        },
        });

        answerDialogRef.afterClosed().subscribe(() => {
            this.syncAuditTransactions(false);
        });
   
    
    //   this.answerDialogRef.afterClosed().subscribe((res) => {
    //   if(res=== 'close') {
    //     this.syncAuditTransactions(false);
    //  }
    //     });

        // this.answerDialogRef.afterClosed().subscribe((res) => {
        //   console.log('calling subscriebe method before close');
        //   if (res === 'close') {
        //     this.syncAuditTransactions(false);
        //   console.log('calling subscriebe method After close');
        //   }
        // });

  }
    
    // window.location.reload();
    /* dialogRef.afterClosed()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(result => {
                if (result !== null && result !== undefined && result !== '') {
                    if (result.data !== null && result.data !== undefined) {
                        responseDetail.responseDetailObject = result.data;
                        if (result.data.indexOf('success')) {
                            this.syncAuditTransactions();
                        }
                    }
                    this.snackBar.open(result.msg, 'Close', {
                        duration: 3000,
                    });
                }
            });
         */

    // dialogRef
    //   .beforeClosed()
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((result) => {
    //     if (result !== null && result !== undefined && result !== "") {
    //       if (result.data !== null && result.data !== undefined) {
    //         detailAboutObservations.responseDetailObject = result.data;
    //         this.syncAuditTransactions();
    //       }
    //       this.snackBar.open(result.msg, "Close", {
    //         duration: 3000,
    //       });
    //     }
    //   });
  // }

  initializeForm() {
    const detailInformationArray = this.formBuilder.array([]);
 
    // Iterate through the checkListResponse array
    this.checkListResponse.forEach((checkListResponseItem, index) => {
      // Create a FormGroup for the current checkListResponseItem
      const checklistResponseForm = this.formBuilder.group({
        id: [checkListResponseItem.id ? checkListResponseItem.id : ''],
        version: [checkListResponseItem.version ? checkListResponseItem.version : 0],
        questionName: [checkListResponseItem.question?.name  ? checkListResponseItem.question.name : ''],
        questionConfiguration: [checkListResponseItem.question?.configuration  ? checkListResponseItem.question.configuration : ''],
        question: [checkListResponseItem.question  ? checkListResponseItem.question : ''],
        questionId: [checkListResponseItem.questionId ? checkListResponseItem.questionId : ''],
        answer: [checkListResponseItem.answer ? checkListResponseItem.answer : ''],
        questionnaireResponseId: [checkListResponseItem.questionnaireResponseId ? checkListResponseItem.questionnaireResponseId : ''],
        observationResponseDetailDTOs: this.formBuilder.array([]), // Initialize as an empty FormArray
      });
  
      // Check if observationResponseDetailDTOs is an array and not empty
      if (checkListResponseItem.observationResponseDetailDTOs 
        && checkListResponseItem.observationResponseDetailDTOs.length > 0) {
        // Initialize a FormArray to hold observationResponseDetailDTOs
        let observationResponseDetailArray = new FormArray([]);

        let observationResponseFilesDTOsArray = new FormArray([]);
  
        // Iterate through the observationResponseDetailDTOs for each checkListResponseItem
        checkListResponseItem.observationResponseDetailDTOs.forEach((observationResponseDetailDTO) => {

         if(observationResponseDetailDTO.observationResponseRemarksDTO != null) {
          if(observationResponseDetailDTO.observationResponseRemarksDTO.observationResponseFilesDTOs && observationResponseDetailDTO.observationResponseRemarksDTO.observationResponseFilesDTOs.length >0) {
            observationResponseDetailDTO?.observationResponseRemarksDTO?.observationResponseFilesDTOs.forEach((observationResponseFileDTO)=> {
              const observationResponseFileDTOForm = this.formBuilder.group({
                id: [observationResponseFileDTO.id? observationResponseFileDTO.id:''],
                fileId: [observationResponseFileDTO.fileId ? observationResponseFileDTO.fileId : ''],
                observationResponseRemarksId: [observationResponseDetailDTO.observationResponseRemarksDTO?.id ? observationResponseDetailDTO.observationResponseRemarksDTO?.id : ''],
                observationResponseDetailId: [observationResponseDetailDTO.id ? observationResponseDetailDTO.id : ''],
                fileDTO: this.formBuilder.group({
                  id: [observationResponseFileDTO.fileDTO.id ? observationResponseFileDTO.fileDTO.id :''],
                  fileName: [observationResponseFileDTO.fileDTO.fileName? observationResponseFileDTO.fileDTO.fileName : ''],
                }),
              });

              observationResponseFilesDTOsArray.push(observationResponseFileDTOForm);
            });
          }
        }
          

          // Create a FormGroup for observationResponseDetail
          const observationResponseDetailForm = this.formBuilder.group({
            id: [observationResponseDetailDTO.observationResponseRemarksDTO ? 
              observationResponseDetailDTO.observationResponseRemarksDTO?.id : ''],
            version: [observationResponseDetailDTO.observationResponseRemarksDTO ? 
                observationResponseDetailDTO.observationResponseRemarksDTO?.version : 0],
            remark: [observationResponseDetailDTO.observationResponseRemarksDTO ? 
              observationResponseDetailDTO.observationResponseRemarksDTO?.remark : ''],
            observationResponseDetailId: [observationResponseDetailDTO.id],
            answer: [observationResponseDetailDTO.answer],
            noOfInstancesVerified: [observationResponseDetailDTO.noOfInstancesVerified],
            noOfDeviationsFound: [observationResponseDetailDTO.noOfDeviationsFound],
            isRectified : [observationResponseDetailDTO.isRectified],
            observationResponseFilesDTOs: observationResponseFilesDTOsArray
          });
  
          // Push the observationResponseDetailForm to the FormArray
          observationResponseDetailArray.push(observationResponseDetailForm);
         
        });
  
        checklistResponseForm.setControl('observationResponseDetailDTOs', observationResponseDetailArray);
      }
      
     
      // Push the chcklistResponseForm to the detailInformationArray FormArray
      detailInformationArray.push(checklistResponseForm);
    });
  
    this.detailInformationForm = this.formBuilder.group({
      detailInformationArray: detailInformationArray,
    });
  
    console.log('detailInformationForm', this.detailInformationForm.value);
    console.log('detailInformationArray', this.detailInformationForm.get('detailInformationArray').value)
    console.log(this.checkListResponse)
  }

  get detailInformationFormArray() {
    return this.detailInformationForm.controls['detailInformationArray'] as FormArray;
  }

  get observationResponseDetailDTOs() {
    return this.detailInformationForm.controls['observationResponseDetailDTOs'] as FormArray;
  }
  
  get observationResponseRemarksDTO() {
    return this.detailInformationForm.controls['observationResponseRemarksDTO'] as FormControl;
  }

  get observationResponseFilesDTOs() {
    return this.detailInformationForm.controls['observationResponseFilesDTOs'] as FormArray;
  }

  
  onSubmit(observationRemarkDTO:any) {
    console.log(observationRemarkDTO);

    var observationResponseRemarksDTO = {
      id: '',
      version: '',
      observationResponseDetailId: '',
      remark: '',
    };

    if(observationRemarkDTO) {
      observationResponseRemarksDTO.id = observationRemarkDTO.id;
      observationResponseRemarksDTO.version = observationRemarkDTO.version;
      observationResponseRemarksDTO.observationResponseDetailId = observationRemarkDTO.observationResponseDetailId;
      observationResponseRemarksDTO.remark = observationRemarkDTO.remark;
    }

    this.auditTransactionService
      .updateRemarks(observationRemarkDTO)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe({
        next:(submitResponse) => this.onSubmitSuccessRemarksUpdate(submitResponse),
        error:(submitResponse) => this.onSubmitErrorRemarks(submitResponse)
      });

  }
  
  private onSubmitSuccessRemarksUpdate(submitResponse) {
     this.syncAuditTransactions(false);
    this.snackBar.open(`Remark Updated successfully`, "Close", {
      duration: 3000,
    });
  }

  private onSubmitErrorRemarks(submitResponse) {
    const errormessage = submitResponse.error.title;
    this.snackBar.open(errormessage, "Close", {
      duration: 3000,
    });
  }

  private onSubmitSuccessRemarks(submitResponse) {
   this.syncAuditTransactions(false);
    this.snackBar.open(`Remark Create successfully`, "Close", {
      duration: 3000,
    });
  }

  onDownload(id: number) {
    this.auditTransactionService
      .downloadFile(id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res) => {
          console.log("start download:", res);
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          this.snackBar.open(`download error:`, "Close", {
            duration: 3000,
          });
          console.log("download error:", JSON.stringify(error));
          this.message = error;
        },
        () => {
          this.snackBar.open(`Downloaded successfully`, "Close", {
            duration: 3000,
          });
          console.log("Completed file download.");
        }
      );
  }

  onRemarksDialog(ObservationForFiles: any) {
    console.log('ObservationResponseRemarks', ObservationForFiles);
    const observationRemarkDTO = new ObservationResponseRemarks();
    observationRemarkDTO.id =  ObservationForFiles.id;
    observationRemarkDTO.version = ObservationForFiles.version;
    observationRemarkDTO.observationResponseDetailId =  ObservationForFiles.observationResponseDetailId;
    observationRemarkDTO.remark = ObservationForFiles.remark;
    this.editRemarkDialogRef = this.dialog.open(EditRemarksDialogComponent, {
      maxHeight: "100vh",
      width: "50%",
      data: {
        ObservationResponseRemarks: observationRemarkDTO,
        loggedInUser: this.loggedInUser.role,
        action: 'edit',
      },
    });
    this.editRemarkDialogRef.afterClosed().subscribe((res) => {
    this.syncAuditTransactions(false);
    });
  }

  deleteDialog(id: number ) {
    this.fileDeleteDialogRef = this.dialog.open(FileDeleteDialogComponent, {
      data: { fileId: id },
    });
    this.fileDeleteDialogRef.afterClosed().subscribe((res) => {
      this.syncAuditTransactions(false);
      });
   
  }

}
