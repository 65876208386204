import {DataSource} from '@angular/cdk/table';
import {ApplicationUsageStats} from './application-usage-stats.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {ApplicationUsageStatsService} from './application-usage-stats.service';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';


export class ApplicationUsageStatsDataSource extends  DataSource<ApplicationUsageStats> {

  private applicationUsageStatsSubject = new BehaviorSubject<ApplicationUsageStats[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);
  constructor(private applicationUsageStatsService: ApplicationUsageStatsService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<ApplicationUsageStats[]> {
    return this.applicationUsageStatsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.applicationUsageStatsSubject.complete();
    this.totalCount$.complete();
  }
    loadApplications(sortDirection = 'asc', pageIndex = 0, pageSize = 3, fromDate: string,
    toDate: string, role: String) {
    this.applicationUsageStatsService.findApplications({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      fromDate: fromDate,
      toDate: toDate,
      role: role
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.applicationUsageStatsSubject.next(response['body']);
    });
  }

}
