import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { RoleListComponent } from './role-list/role-list.component';
import { RoleEditorComponent } from './role-edit/role-editor.component';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { RoleResolverService } from './role.resolve';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from '../../material-lib.module';
import { RoleDeleteDialogComponent } from './role-list/role-delete-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

export const roleRoute: Route = {
  path: 'role',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: RoleListComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_ROLE_LIST'] }
    },
    {
      path: 'create',
      canActivate: [AuthenticatedUserService],
      component: RoleEditorComponent,
      resolve: {
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_ROLE'] }
    },
    {
      path: 'update/:id',
      canActivate: [AuthenticatedUserService],
      component: RoleEditorComponent,
      resolve: {
        role: RoleResolverService,
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_ROLE'] }
    },
    {
      path: 'view/:id',
      canActivate: [AuthenticatedUserService],
      component: RoleEditorComponent,
      resolve: {
        role: RoleResolverService,
        editable: NonEditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_ROLE'] }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [RoleListComponent, RoleEditorComponent, RoleDeleteDialogComponent],
  entryComponents: [RoleDeleteDialogComponent]
})
export class RoleModule { }
