import { MATemplateHeader } from "src/app/entities/activity-role/activity-role.model";

export class User {
    constructor(
        public id?: number,
        public email?: string,
        public firstName?: string,
        public imageUrl?: string,
        public langKey?: string,
        public lastName?: string,
        public login?: string,
        public role?: number,
        public activated?: boolean,
        public password?: string,
        public passwordAutoGenerate?: boolean,
        public imeiNumber?: string,
        public employeeId?: number,
        public branchset?: any,
        public branchsetId?: any,
        public changePasswordRequired?: boolean,
        public templeteHeaderId?: number,
        public businessLineId?: number,   
        public templateHeaderDTO?: MATemplateHeader,
    ) {}
}