import {DataSource} from '@angular/cdk/table';
import {User} from './user.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {UserService} from './user.service';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';

export class UserListDataSource extends  DataSource<User> {

  private userSubject = new BehaviorSubject<User[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private userService: UserService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.userSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userSubject.complete();
    this.totalCount$.complete();
  }

  loadUsers(sortDirection = 'asc', pageIndex = 0, pageSize = 3, name: String, login: String, role: String, businessLineId: number) {

    this.userService.findUsers({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      name: name,
      login: login,
      role: role,
      businessLineId: businessLineId,
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.userSubject.next(response['body']);
    });
  }

}
