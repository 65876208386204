import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect} from '@angular/material/select';
import { MatCheckbox} from '@angular/material/checkbox';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {QuestionaireService} from 'src/app/entities/questionaire/questionaire.service';
import {Branch} from 'src/app/admin/branch/branch.model';

import {BranchSet} from '../branch-set.model';
import {BranchSetService} from '../branch-set.service';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {BranchService} from '../../branch/branch.service';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {FetchBranchesForStates} from 'src/app/constants/data.constants';
import {ViewChild} from '@angular/core';
import { ReferenceCodeService } from '../../reference-code/reference-code.service';

@Component({
    selector: 'app-branch-set-editor',
    templateUrl: './branch-set-editor.component.html',
    styleUrls: ['../branch-set.component.css']
})
export class BranchSetEditorComponent implements OnInit, OnDestroy {
    branchSet: BranchSet;
    businessLines: any[];
    editable = true;
    isNew: boolean;
    statesMap:Map<string, string> = new Map()
    selectedStates: any[] = [];
    stateList: string[] = [];
    branches: Branch[];
    dropdownList: any[];
    dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'nameCode',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    selectedBranches: any[];
    stateDropdownList: any[];
    states: any = {};
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };

    @ViewChild('stateSelect',{static:true}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:true}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:true}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:true}) branchAllSelect: MatCheckbox;

    public branchSetEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        'name': '', 'code': '', 'selectedStates': '', 'selectedBranches': '', 'stateName':'', 'branchIds':'', 'businessLineId': ''
    };
    public allBranches$;
    businessLine: any;
    businessLineId: number;
    selectedBusinessLine: any;



    constructor(private branchSetService: BranchSetService,
                private questionaireService: QuestionaireService,
                private branchService: BranchService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private igFormService: IgFormService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.branchSet === undefined) {
                    this.branchSet = {};
                } else {
                    this.branchSet = data.branchSet;
                    this.updateBranchIds();
                }
                this.editable = data.editable;
            });
        this.allBranches$ = this.branchService.getAllBranches();
    }

    updateBranchIds() {
        this.selectedBranches = [];
        this.branchSet.branches.forEach((branch) => {
            this.selectedBranches.push(branch);
            if(!this.statesMap.has(branch.stateName) && branch.stateName !=null)
            this.statesMap.set(branch.stateName, branch.stateName)
        });
        this.branchSet.branches.forEach(function (value, key) {
            value.nameCode = value.name + '-' + value.code;
        });
        this.dropdownList = this.branchSet.branches;
        this.statesMap.forEach(x=>{
            this.selectedStates.push({code:x, name:x})
        })
    //    this.loadBranches()
    }

    ngOnInit() {
        this.buildForm();
        this.loadBusiness();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.branchSetEditorForm.disable();
        }
        if(this.branchSet.id){
            this.businessLine=this.branchSetEditorForm.get('businessLineId').value;
            console.log("this is bbbbb"+this.businessLine)
            this.loadStates();
            this.loadBranches();
           }
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.isNew = false;
        if (this.branchSet.id === undefined) {
            this.isNew = true; 
        }
        this.branchSetEditorForm = this.formBuilder.group({
            'id': [this.branchSet.id],
            'version': [this.branchSet.version],
            'stateName': [this.selectedStates,[Validators.required]],
            'name': [this.branchSet.name, [Validators.required]],
            'code': [this.branchSet.code, [Validators.required]],
            'businessLineId': [this.branchSet.businessLineId, [Validators.required]],
            'branchIds': [this.branchSet.branchIds,[Validators.required]],
            'createdBy': [this.branchSet.createdBy, []],
            'createdDate': [this.branchSet.createdDate, []],
            
        });

        this.branchSetEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.branchSetEditorForm, this.formErrors, true);
        });
    }

    loadBranches() {
        this.branches = [];
        this.stateList = [];
        this.dropdownList = [];
        this.branchSet.branches = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        
        this.questionaireService.getAllActiveBranchesForStates(this.businessLine,this.stateList, FetchBranchesForStates.GET_MAPPED_ONLY)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    const emptyVarible = '';
                    if (Object.keys(this.branchSet).length > 0) {
                        this.branchSet.branches.forEach((branch) => {
                            if (this.branches.findIndex(x => x.id === branch.id) === -1) {
                                this.branches.splice(0, 0, branch);
                            }
                        });
                        this.selectedBranches.forEach((branch) => {
                            if (this.branches.findIndex(x => x.id === branch.id) === -1) {
                                this.branches.splice(0, 0, branch);
                            }
                        });
                    }
                    this.branches.forEach(function (value, key) {
                        value.nameCode = value.name + '-' + value.code;
                    });
                    this.dropdownList = this.branches;
                    this.dropdownSettings = {
                        singleSelection: false,
                        idField: 'id',
                        textField: 'nameCode',
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        itemsShowLimit: 1,
                        allowSearchFilter: true
                    };
                });
    }

    loadStates() {
        this.stateDropdownList = [];
        this.dropdownList = [];
        this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;

                }
            );
    }
    loadBusiness() {
        this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
          subscribe(businessLineDTO => this.businessLines = businessLineDTO);
   
    }

    onSelectState(state: any) {
        this.selectedStates == undefined;
        this.selectedStates.push(state);
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        this.dropdownList = [];
        this.loadBranches();
    }

    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.dropdownList = [];
        this.loadBranches();
    }

    onItemSelect(event){}

    onSelectAll(event){}

    onItemDeSelectAll(item: any) {
        this.selectedStates = [];
        this.selectedBranches =[];
        this.dropdownList = [];
        this.loadBranches();

    }

    onItemDeSelectBranch(item: any) {
    }


    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.branchSetEditorForm);
        if (this.branchSetEditorForm.valid) {
            const submittedForm = this.branchSetEditorForm.value;
            submittedForm.branchIds = this.branchSetEditorForm.value.branchIds.map(v => v.id);
            this.branchSetService.saveBranchSet(submittedForm).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.branchSetEditorForm, this.formErrors, false);
            return false;
        }

    }

    private onSubmitSuccess(response) {
        let msg = '';
        if (this.branchSet.id) {
            msg = `Updated branchSet ${this.branchSet.id} successfully`;
        } else {
            msg = `Created branchSet successfully`;
        }
        this.snackBar.open(msg, 'Close');
        this.goToBranchSetListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.branchSetEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    }

    goToBranchSetListPage() {
        if (this.branchSet.id) {
            this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        }
    }
    changeBusinessLine(selectedBusniessLine: any){
        this.selectedStates = [];
        this.selectedBranches = [];
        this.dropdownList = [];
        this.businessLine = selectedBusniessLine.value;
        this.loadStates();
    }

      }
      



