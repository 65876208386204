import {formatDate} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild, LOCALE_ID, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import { MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {takeUntil} from 'rxjs/operators';
import {Branch} from 'src/app/admin/branch/branch.model';
import {RoleService} from 'src/app/admin/role/role.service';
import {QuestionaireService} from 'src/app/entities/questionaire/questionaire.service';
import {ReportService} from '../reports/report.service';
import {activityReportTypes, hardBucketReporttypes, months, years} from '../../constants/data.constants';
import {Role} from '../../admin/role/role.model';
import {NameValueDto} from '../../../../projects/ig-core/src/lib/interfaces/name-value-dto';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-activity-report',
    templateUrl: './activity-report.component.html',
    styleUrls: ['./activity-report.component.css']
})
export class ActivityReportComponent implements OnInit, OnDestroy {

    stateDropdownList: any[];
    selectedStates: any[];
    selectedBranches: any[];
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    dropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    stateList: string[];
    branches: Branch[];
    dropdownList: any[];
    states: any;
    fromDateFilter: string;
    toDateFilter: string;
    type: string;
    role: string;
    codes: any[];
    fromdate1: string;
    todate1: string;
    mandatoryActivityReportList: any[];
    roles: Role[];
    allMonths: any[];
    allYears: NameValueDto[];
    year: number;
    month: number;
    isMonth: boolean;
    isYear: boolean;
    isFromDate: boolean;
    isToDate: boolean;
    businessLine: any;
    businessLineId: number;
    businessLines: any[];
    isRoleAdmin:boolean;
    loggedInUserRole: any;

    public activityReportForm: FormGroup;

    @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;

    public formError: FormErrorModel;
    public formErrors = {
        type: '',
        role: '',
        selectedStates: '',
        selectedBranches: '',
        fromDateFilter: '',
        toDateFilter: '',
        year: '',
        month: '',
        stateName:'',
        name:'',
        businessLineId:''
    };

    constructor(private questionaireService: QuestionaireService,
                private reportService: ReportService, private igFormService: IgFormService,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private roleService: RoleService,
                @Inject(LOCALE_ID) private locale: string,
                    private activatedRoute: ActivatedRoute
                 ) { this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUserRole = data.loggedInUser.body.role;
              });
    }

    ngOnInit(): void {
        this.mandatoryActivityReports();
        this.loadBusiness();
        this.loadRoles();
        this.buildForm();
        this.loadMonthsAndYears();
        this.activityReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.activityReportForm.controls['businessLineId'].enable();
        }else{  
            this.loadStates();
        }
        this.formError = new FormErrorModel(false, '');
    }

    ngOnDestroy(): void {
    }

    loadBusiness() {
        this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
          subscribe(businessLineDTO => this.businessLines = businessLineDTO);
      }

    buildForm() {
        this.activityReportForm = this.formBuilder.group({
            type: [this.type, Validators.required],
            role: [this.type, Validators.required],
            stateName: [this.selectedStates, Validators.required],
            name: [this.branches, Validators.required],
            fromDateFilter: [this.fromDateFilter],
            toDateFilter: [this.toDateFilter],
            year: [this.year],
            month: [this.month],
            businessLineId: ['',Validators.required]
        });
        this.activityReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.activityReportForm, this.formErrors, true);
        });
    }

    loadRoles() {
        this.roleService.getAllRoles().pipe(takeUntil(componentDestroyed(this))).subscribe(roles => {
            this.roles = roles.body;
        });
    }

    // On selection of type of report this method is called
    public onChange(event): void {
        const selectedReportType = event;
        if (selectedReportType === 'USEREVERYDAYREPORT') {
            const fromDateFilterControl = this.activityReportForm.get('fromDateFilter');
            fromDateFilterControl.setValidators(Validators.required);
            fromDateFilterControl.updateValueAndValidity();
            const toDateFilterControl = this.activityReportForm.get('toDateFilter');
            toDateFilterControl.setValidators(Validators.required);
            toDateFilterControl.updateValueAndValidity();
            this.activityReportForm.controls['fromDateFilter'].enable();
            this.activityReportForm.controls['toDateFilter'].enable();
            this.activityReportForm.controls['month'].disable();
            this.activityReportForm.controls['year'].disable();
            this.isFromDate = true;
            this.isToDate = true;
            this.isMonth = false;
            this.isYear = false;
            this.clearFilter1();
        } else if (selectedReportType === 'USERPRODUCTIVITYREPORT') {
            const monthControl = this.activityReportForm.get('month');
            monthControl.setValidators(Validators.required);
            monthControl.updateValueAndValidity();
            const yearControl = this.activityReportForm.get('year');
            yearControl.setValidators(Validators.required);
            yearControl.updateValueAndValidity();
            this.activityReportForm.controls['fromDateFilter'].disable();
            this.activityReportForm.controls['toDateFilter'].disable();
            this.activityReportForm.controls['month'].enable();
            this.activityReportForm.controls['year'].enable();
            this.isFromDate = false;
            this.isToDate = false;
            this.isMonth = true;
            this.isYear = true;
            this.clearFilter1();
        }
    }

    clearFilter1() {
        this.month = undefined;
        this.year = undefined;
        this.fromDateFilter = undefined;
        this.toDateFilter = undefined;
    }

    onItemSelect(event){
    }
  
    onSelectAll(event){}

    loadStates() {
        this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                }
            );
    }

    onSelectState(state: any) {
        this.selectedStates.push(state);
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        this.loadBranches();
    }

    onItemDeSelectState(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectAllState(item: any) {
        this.selectedStates = [];
        this.loadBranches();

    }

    loadBranches() {
        console.log(this.selectedStates);
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches; 
                });
    }

    onItemDeSelectBranch(item: any) {
    }

    mandatoryActivityReports() {
        this.mandatoryActivityReportList = activityReportTypes;
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.activityReportForm);
        if (this.activityReportForm.valid) {
            this.downloadMandatoryActivityReport();
        } else {
            this.formErrors = this.igFormService.validateForm(this.activityReportForm, this.formErrors, false);
            return false;
        }
    }

    loadMonthsAndYears() {
        this.allMonths = months;
        this.allYears = years;
    }

    downloadMandatoryActivityReport() {
        this.codes = [];
        const rs = [];
        this.selectedBranches.forEach((value) => {
            this.codes.push(value.code);
        });
        if (this.fromDateFilter !== undefined || this.toDateFilter !== undefined) {
            this.fromDateFilter = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us');
            this.toDateFilter = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us');
        }

        rs.push(this.role);
        this.reportService.downloadMandatoryActivityReport({
            type: this.type,
            role: rs,
            branchCodes: this.codes,
            fromDate: this.fromDateFilter,
            toDate: this.toDateFilter,
            month: this.month,
            year: this.year,
            businessLineId: this.businessLine
        })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(res => {
                console.log('start download:', res);
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => {
                console.log('download error:', JSON.stringify(error));
                this.onError();
            }, () => {
                console.log('Completed file download.');
            });
    }

    onError() {
        if (this.fromDateFilter === undefined) {
            this.snackBar.open(` Data Not found selected Year: ` + this.year + ` And Month: ` + this.month + ``, 'Close', {
                duration: 5000
            });
        } else {
            this.fromdate1 = formatDate(this.fromDateFilter, 'dd-MM-yyyy', this.locale);
            this.todate1 = formatDate(this.toDateFilter, 'dd-MM-yyyy', this.locale);
            this.snackBar.open(` Data Not found selected From date: ` + this.fromdate1 + ` To Date: ` + this.todate1 + ``, 'Close', {
                duration: 5000
            });
        }
    }

    clearFilter() {
        this.type = '';
        this.selectedBranches = [];
        this.selectedStates = [];
        this.fromDateFilter = '';
        this.toDateFilter = '';
        this.role = null;
        this.month = undefined;
        this.year = undefined;
        this.loadBranches();
    }

    changeBusinessLine(selectedBusniessLine: any){
        this.selectedStates=null;
        this.businessLine = selectedBusniessLine.value;
        this.loadStates();
        this.loadBranches();
    }


}
