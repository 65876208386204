import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {MATemplateHeader} from '../activity-role.model';
import { ActivityRoleService } from '../activity-role.service';
import { BranchSetService } from 'src/app/admin/branch-set/branch-set.service';
import { BranchSet } from 'src/app/admin/branch-set/branch-set.model';

// import {BranchSetService} from '../branch-set.service';
// import {BranchSet} from '../branch-set.model';

@Component({
  selector: 'app-activity-role-delete-dialog',
  templateUrl: './activity-role-delete-dialog.component.html',
  styleUrls: ['../activity-role.component.css']
})
export class ActivityRoleDeleteDialogComponent implements OnInit, OnDestroy {
 branchSet: BranchSet;
  activityRole:MATemplateHeader;
  errorMessage: string;
  constructor(private branchSetService: BranchSetService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              private activityRoleService: ActivityRoleService,
              public dialogRef: MatDialogRef<ActivityRoleDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: BranchSet) {
    this.branchSet = data;
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }

  delete() {
    this.errorMessage = '';
    this.branchSetService.deleteBranchSet(this.branchSet.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
  }

  onSaveSuccess(result) {
    this.dialogRef.close('deleted');


  }

  onSaveError(response) {
    this.errorMessage = response;
  }

  cancel(): void {
    this.dialogRef.close('canceled');
  }
}
