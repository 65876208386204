<mat-card>
  <mat-card-title class="button-icon-text">Questions</mat-card-title>
  <mat-form-field style="width:70%">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter questions on any field">
  </mat-form-field>

  <div class="mat-elevation-z8" style="margin-top: 20px">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="questionName">
        <th mat-header-cell *matHeaderCellDef  colspan="2"> Question Name </th>
        <td mat-cell *matCellDef="let row" data-label="name"  colspan="2"> {{row.name}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef > Category </th>
        <td mat-cell *matCellDef="let row" data-label="category" > {{row.category}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row">
              <mat-button-toggle-group #userActionGroup="matButtonToggleGroup" class="table-row-action">
            <mat-button-toggle  matTooltip="View" (click)="redirect(row,'view')">
              <mat-icon>more</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle  matTooltip="Edit" (click)="redirect(row,'edit')" *ngIf="questionaireStatus === 'DRAFT'">
              <mat-icon>edit</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle   matTooltip="Delete"  (click)="deleteDialog(row)"  *ngIf="questionaireStatus === 'DRAFT'">
              <mat-icon>delete</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
          </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
</mat-card>
