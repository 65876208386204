import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {PagingAndSortParams} from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import {ListSource} from 'projects/ig-core/src/lib/list/list-source';
import {ActovityRoleMappingDataSource} from './activity-role-list.data.source';
import {ActivityRoleService} from '../activity-role.service';
import { BusinessLineDatamodel } from 'src/app/admin/branch/business-line.model';
import { BranchService } from 'src/app/admin/branch/branch.service';

@Component({
    selector: 'app-activity-role-list',
    templateUrl: './activity-role-list.component.html',
    styleUrls: ['./activity-role-list.component.css']
})
export class ActivityRoleListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource: ActovityRoleMappingDataSource;
    listSource: ListSource;
    name: any;
    code: any;
    templateId: number;
    errorMessage: string;
    businessLines: BusinessLineDatamodel[];

    displayedColumns = ['name', 'code', 'effectiveDate','businessLine', 'action'];
      @ViewChild(MatPaginator,{static:true})
    paginator: MatPaginator;
    @ViewChild(MatSort,{static:true})
    sort: MatSort;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private branchService:BranchService,
        private activityRoleService: ActivityRoleService
    ) {
    }

    ngOnInit() {
        this.dataSource = new ActovityRoleMappingDataSource(this.activityRoleService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this))).subscribe(listParams => {
            this.pagingAndSortParams = listParams.pagingAndSortParam;
            let sortColumn = this.pagingAndSortParams.sortColumn;
            if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                sortColumn = 'id';
                this.pagingAndSortParams.sortDirection= "desc"
            }
            this.loadTemplates(sortColumn);
        });
        this.branchService.getAllBusinessLines().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
          });
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadTemplatesPageWithParams());
    }

    loadTemplates(sortColumn: String) {
        this.dataSource.loadTemplates(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.name, this.code);
    }

    loadTemplatesPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    clearFilter() {
        this.name = undefined;
        this.code = undefined;
        this.loadTemplates('');
    }

    clone(element: any) {
        this.templateId = element;
        this.activityRoleService.templateClone(this.templateId).pipe(takeUntil(componentDestroyed(this))).
        subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }

    onSaveSuccess(result) {
        this.snackBar.open(result.body, 'Close', {
            duration: 3000,
        });
        this.ngOnInit();
    }

    onSaveError(response) {
        const result = JSON.parse(response.error);
        this.snackBar.open(result.errorKey, 'Close', {
            duration: 50000,
        });
    }

}
