import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { AuditTransactionDetail } from './audit-transaction-detail.model';
import { AuditTransactionService } from '../audit-transaction-service';

export class AuditTransactionDetailDataSource extends DataSource<AuditTransactionDetail> {


    private auditTransactionDetailSubject = new BehaviorSubject<AuditTransactionDetail[]>([]);

    public totalCount$ = new BehaviorSubject<number>(0);

    constructor(private auditTransactionService: AuditTransactionService) { super(); }

    connect(collectionViewer: CollectionViewer): Observable<AuditTransactionDetail[]> {
        return this.auditTransactionDetailSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.auditTransactionDetailSubject.complete();
        this.totalCount$.complete();
    }

    loadAuditTransactionDetail (sortDirection = 'desc', pageIndex = 0, pageSize = 3, auditTransactionId: any) {

        this.auditTransactionService.getDetailedInfos({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection
        }, auditTransactionId).pipe(
            catchError(() => of([])),
            finalize(() => { })
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.auditTransactionDetailSubject.next(response['body']);
        });
    }
}
