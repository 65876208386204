import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil, take } from 'rxjs/operators';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { Branch } from 'src/app/admin/branch/branch.model';
import { crmReporttypes } from 'src/app/constants/data.constants';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { AuditTransactionHistoryComponent } from 'src/app/entities/audit/audit-transaction/audit-transaction-workflow-history.component';
@Component({
  selector: 'app-credit-risk-model',
  templateUrl: './credit-risk-model.component.html',
  styleUrls: ['./credit-risk-model.component.css']
})
export class CreditRiskModelComponent implements OnInit, OnDestroy {
  dropdownList: any[];
  stateDropdownList: any[];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  stateDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,

  };
  selectedBranches: any[];
  branches: Branch[];
  states: any;
  selectedStates: any[];
  fromDateFilter: string;
  toDateFilter: string;
  branchCodes: any[];
  code: string;
  branchSelectIndicator = new FormControl();
  dataSource: any;
  stateList: string[];
  crmReportList: any[];
  businessLines: any[];
  isRoleAdmin:boolean;
  selectedBusinessLine: any;
  public crmReportForm: FormGroup;
  @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
  @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

  @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
  @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;
  public formError: FormErrorModel;
  public formErrors = {
    stateName: '',
    name: '',
    selectedStates: '',
    selectedBranches: '',
    fromDateFilter: '',
    toDateFilter: '',
    code: '',
    businessLineId:''
  };
  businessLine: any;
  loggedInUserRole: any;
  constructor(private questionaireService: QuestionaireService,

    private igFormService: IgFormService, private formBuilder: FormBuilder,
    private userService: UserService, private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar) {
      this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
          this.loggedInUserRole = data.loggedInUser.body.role;
        });
  }
  ngOnInit() {
    this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
      this.businessLines = businessLines;
      this.crmReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.crmReportForm.controls['businessLineId'].enable();
        }else{  this.loadStates();
        }
  });
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.crmReport();
  }
  ngOnDestroy(): void {
  }
  crmReport() {
    this.crmReportList = crmReporttypes;
  }
  onSelectState(state: any) {
    this.loadBranches();
  }

  onItemSelect(event){
  }

  onSelectAll(event){}

  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.crmReportForm);
    if (this.crmReportForm.valid) {
      this.download();
    } else {
      this.formErrors = this.igFormService.validateForm(this.crmReportForm, this.formErrors, false);
      return false;
    }
    
  }

  buildForm() {
    this.crmReportForm = this.formBuilder.group({
      stateName: [this.selectedStates, Validators.required],
      name: [this.branches, Validators.required],
      fromDateFilter: [this.fromDateFilter, Validators.required],
      toDateFilter: [this.toDateFilter, Validators.required],
      code: [this.code, Validators.required],
      businessLineId:['',Validators.required]
    });
    this.crmReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.crmReportForm, this.formErrors, true);
    });
  }

  loadBranches() {
    this.selectedBranches=null;
    this.stateList = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.stateList.push(this.selectedStates[i].name);
    }
    this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<Branch[]>) => {
          this.branches = res.body;
          this.dropdownList = this.branches;
        });
  }

  loadStates() {
    this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.states = res.body;
          this.stateDropdownList = this.states;
        }
      );
  }

  download() {
    this.branchCodes = [];
    this.selectedBranches.forEach((value) => {
      this.branchCodes.push(value.code);
    });
    this.questionaireService.downloadExcel({
      fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCodes: this.branchCodes,
      type: this.code,
      stateNames: this.stateList
    }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      console.log('start download:', res);
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      console.log('download error:', JSON.stringify(error));
      this.onError();
    }, () => {
      console.log('Completed file download.');
    });
  }
  onError() {
    this.snackBar.open(`Error Downloading Excel`, 'Close', {
      duration: 3000,
    });
  }

  clearFilter() {
    // this.stateSelect = undefined;
    // this.selectedBranches = undefined;
    // this.selectedStates = [];
    // this.states = undefined;
    // this.toDateFilter = undefined;
    // this.fromDateFilter = undefined;
    // this.code = undefined;
    // this.selectedBusinessLine = ''
    this.crmReportForm.reset();
    if(this.businessLine !== undefined){
      this.stateDropdownList = [];
    }
    this.dropdownList=[];
  }

  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  onItemDeSelectBranch(item: any) {

  }
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();
  }
  changeBusinessLine(selectedBusniessLine: any){
    this.selectedStates=null;
    this.businessLine = selectedBusniessLine.value;
    this.loadStates();
  }
}
