import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UserService } from 'src/app/admin/user/user.service';
import { ReportService } from '../reports/report.service';
import { OtsJobStatus, OtsJobType } from './import.model';
import { ImportDataSource } from './import.data.source';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { alljobStatusList, alljobTypeList } from 'src/app/constants/data.constants';


@Component({
    selector: 'app-import-upload',
    templateUrl: './import.component.html'
})
export class ImportComponent implements OnInit, AfterViewInit, OnDestroy {
    file: File;
    message: string;
    fileUploader: any;
    otsImportDetails = [];
    importCodeList = [];
    importDatas;
    listSource: ListSource;
    pagingAndSortParams: PagingAndSortParams;

    jobName?: String;
    jobStatus?: String;
    uploadedDate?: Date;
    jobType?: String;

    displayedColumns: string[] = ['jobName', 'uploadedDate', 'totalCount', 'successCount', 'failureCount', 'jobStatus', 'action'];
    dataSource: ImportDataSource;
    jobTypeList = alljobTypeList;
    jobStatusList = alljobStatusList;

    constructor(private router: Router, private userService: UserService,
        private snackBar: MatSnackBar,
        private reportService: ReportService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {
    }
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    ngOnInit() {
        this.dataSource = new ImportDataSource(this.reportService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'uploadedDate';
                }

                this.loadImports(sortColumn);
            });

    }



    ngAfterViewInit() {
        this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(() => this.loadImportsPage());
    }

    loadImports(sortColumn: String) {
        this.dataSource.loadOtsImport(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.jobName, this.jobStatus, this.jobType, this.uploadedDate);
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    loadImportsPage() {
        this.router.navigate(['dashboard/import'], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    clearFilter() {
        this.jobName = undefined;
        this.jobStatus = undefined;
        this.jobType = undefined;
        this.uploadedDate = undefined;
        this.search()
    }

    getErrorAndOutPutFileId(element: any) {
        console.log(element.errorFileId);
        this.reportService.downloadInputOutPutFileId({
            type: element.errorFileId
        })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(res => {
                console.log('start download:', res);
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            });
    }

      search(){
        this.pagingAndSortParams.pageIndex = 0
        this.paginator.pageIndex = 0
        this.loadImports('')
        this.loadImportsPage()
    }

}
