import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MasterFilters } from './master-filter.model';
import { HttpResponse } from '@angular/common/http';
import { ReportService } from 'src/app/dashboard/reports/report.service';
import { Import } from 'src/app/dashboard/import/import.model';

@Component({
  selector: 'app-master-filter-upload',
  templateUrl: './master-filter-upload.component.html',
  styleUrls: ['./master-filter-upload.component.css']
})
export class MasterFilterUploadComponent implements OnInit, OnDestroy {
  masterFilterParams: MasterFilters;
  file: File;
  message: string;
  businessLines: any[];
  questionnaires: any[];
  masterFiltersList:any[];
  public masterfilterform: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    businessLineId: ' ',
    questionnaireCode: ' ',
    masterfilter:' ',
  }

  inputFileName: string
  masterFilterString:string;
  businessLine:number;
  jobDetails: Import = {};
  

  constructor(private formBuilder: FormBuilder,
    private snackBar: MatSnackBar, private reportService: ReportService,
    private igFormService: IgFormService,private questionaireService: QuestionaireService,
    private sanitizer: DomSanitizer,private referenceCodeService:ReferenceCodeService)
   {
    this.masterFilterParams = {};
   }
  ngOnDestroy(): void {
  }
  @Input()
  multiple;

  @Input()
  files: File[] = [];

  @Input()
  chooseLabel = 'Choose';

  @Input()
  uploadLabel = 'Upload';

  @Input()
  deleteButtonIcon = 'close';
   
  @ViewChild('fileUpload')
  fileUpload: ElementRef;

  @ViewChild('fileUploader', {static:false})
  myInputVariable: ElementRef;
  
  onClick(event) {
    if (this.fileUpload)
      this.fileUpload.nativeElement.click();
  }

  onInput(event: any) {
   }
  
  ngOnInit(): void { 
    this.loadBusiness();
    this.loadMasterFilters();
    this.buildForm();
   }

  buildForm() {
    this.masterfilterform = this.formBuilder.group({
      questionnaireCode: ['', Validators.required],
      businessLineId: ['', Validators.required],
      masterfilter: ['', Validators.required]
    })
    this.masterfilterform.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.masterfilterform, this.formErrors, true);
    });
    
  }
  // to load the businessLine
  loadBusiness() {
    this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
        subscribe(businessLineDTO => this.businessLines = businessLineDTO);
    }
    //to load the questionnariecode
    loadQuestionnaireCode(){
      this.questionaireService.getAllQuestionnaireConfigsQuestionnaire(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
          (res: HttpResponse<any>) => {
              this.questionnaires = res.body;    
          }
      );
    }  
    //to load the all master filters
    loadMasterFilters(){
      this.referenceCodeService.getModuleMastersCodes('MASTER_FILTER_MODULE')
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => {
          this.masterFiltersList = response;
        });
      }
    
    // to choose the  files
    onFileSelection(event: any) {
      let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
  
        if (this.validate(file)) {
          file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
          if (!this.isMultiple()) {
            this.files = []
          }
          this.files.push(files[i]);
        }
      }
      this.file = event.target.files[0];
      event.target.value = null
      this.myInputVariable.nativeElement.value = '';
    }

    isMultiple(): boolean {
      return this.multiple
    }
     //to  remove the selected file
    removeSelectedFile(index) {
      this.files.splice(index, 1);
      this.file = undefined;
      this.message = "";
    }

    //file validation function
    validate(file: File) {
      for (const f of this.files) {
        if (f.name === file.name
          && f.lastModified === file.lastModified
          && f.size === f.size
          && f.type === f.type
        ) {
          return false
        }
      }
      return true
    }
    // on selection of master filters download the file
    downloadFile(event: any) {
      const downloadLink = event.value.field1;
      const link = document.createElement('a');
      link.setAttribute('href',downloadLink);
      link.click();
      link.remove();
    }

    // to upload the selected file
    onSubmit(type: String) {
      this.formError = new FormErrorModel(false, '');
      this.igFormService.markFormGroupTouched(this.masterfilterform);
      if (this.masterfilterform.valid) {
        if (type === "FILEUPLOAD") {
          this.masterFilterUpload()
        } else {
          this.masterUploadExcecution();
        }
      } else {
        this.formErrors = this.igFormService.validateForm(this.masterfilterform, this.formErrors, false);
        return false;
      }
    }
    //to set the required parameter for required Api
    masterFilterUpload()  {
      if (this.file === undefined) {
        this.message = 'Please select a file';
      } else {
        this.masterFilterParams.businessLineId =  this.masterfilterform.get('businessLineId').value;
        this.masterFilterParams.masterFilter =  this.masterfilterform.get('masterfilter').value.name;
        this.masterFilterParams.questionnaireCode =  this.masterfilterform.get('questionnaireCode').value;
        this.masterFilterString = JSON.stringify(this.masterFilterParams);
        this.questionaireService.uploadMasterFile(this.masterFilterString, this.file).pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => this.onSubmitSuccess(response),
            (response) => this.onSubmitError(response));
      }
      
    }
    masterUploadExcecution() {
      if (this.jobDetails.id === undefined) {
        this.message = 'Please create the jobs';
      } else {
        this.reportService.executeJobFile(this.jobDetails.id).pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response: any) => {
            if (response.body === "This job is not an immediate job.") {
              this.message = response.body;
            } else {
              this.message = "File Processing started successfully \n\n"
                + "Please check in Import History For More Details";
            }
            this.snackBar.open(`${this.message}`, 'Close', { duration: 3000 });
          });
      }
    }
    
    //to clear the fields
    clearFilter() {
      this.files = [];
      this.file = undefined;
      this.message="", 
      this.masterfilterform.reset();
    }

    private onSubmitSuccess(response) {
      this.jobDetails = JSON.parse(response.body);
      this.message = "MasterFilter file Uploaded Successfully:\n"
      + "\n User can click Execute to Start the File Processing Schedular will Handle on Time"
      + "\n Job Name: " + this.jobDetails.jobName
      + "\n Job Id and Uploaded Date: " + this.jobDetails.id + " on " + this.jobDetails.uploadedDate
      + "\n Job Status : " + this.jobDetails.jobStatus + " for " + this.jobDetails.jobType;
      this.snackBar.open(`${this.message}`, 'Close', {
        duration: 3000,
    });
     
    }

    private onSubmitError(response) {
      this.snackBar.open(`Failed Updated ${response.error} `, 'Close', {
        duration: 3000,
      });
      this.message = response.error.text;
    }

    changeBusinessLine(selectedBusniessLine: any){
      this.businessLine = selectedBusniessLine.value;
      this.loadQuestionnaireCode();
    }
  
}