import {Component, Inject, OnDestroy, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Questionaire} from './questionaire.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { QuestionaireService } from './questionaire.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';

@Component({
    selector: 'app-questionaire-copy',
    templateUrl: './questionnaire-copy.component.html',
    styleUrls: ['./questionaire.component.css']
})
export class QuestionaireCopyComponent implements OnInit, OnDestroy {
    questionaireCode = []
    questionaire: Questionaire
    errorMessage: string
    referenceCodeId: number
    questionnairId:number
    public questionaireCopyForm: FormGroup;
    code: string;
    name: string;
    public formError: FormErrorModel;
    public formErrors = {
        name: '',
        code:''
    }

    constructor(private questionaireService: QuestionaireService,
                private snackBar: MatSnackBar,
                private formBuilder: FormBuilder,
                private igFormService: IgFormService,
                public dialogRef: MatDialogRef<QuestionaireCopyComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Questionaire) {
                    this.questionaire = data
    }

    ngOnInit() {  
        this.buildForm();

    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.questionaireCopyForm = this.formBuilder.group({
            code: ['',Validators.required],
            name: ['',Validators.required],
        });
    }
    
     onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.questionaireCopyForm);
        if (this.questionaireCopyForm.valid) {
            this.questionaireService.questionnaireCopy(this.questionaire.id,this.questionaireCopyForm.value.code, this.questionaireCopyForm.value.name).
        subscribe((response) => this.onSaveSuccess(response),
                    (error) => this.onSaveError(error));
        } else {
            this.formErrors = this.igFormService.validateForm(this.questionaireCopyForm, this.formErrors, false);
            return false;
          }

    }
    onSaveSuccess(result) {
        this.snackBar.open(`Copyed Questionnaire sucessfully `, 'Close', {
            duration: 3000,
        });
        this.dialogRef.close();
    }
    onSaveError(response) {
        var obj = JSON.parse(response.error);
        this.errorMessage = obj.title;
    }
    cancel(): void {
        this.dialogRef.close();
    }
}


