   
   <div style="display: flex; flex-direction: column; border: 1px solid #080808; background-color: #f1f0f0; padding:5px;">

<div style="display: flex; justify-content: flex-start; margin-right:0px;">
          <div>
            <button   mat-raised-button color="primary" style="border-radius: 50%" (click)="onClick($event)">
            <mat-icon> attach_file </mat-icon>
        </button>
        &nbsp;
        <span>Please attach a file</span>
          </div>
  </div>
  <input #fileUpload id="fileUpload" type="file" style="display:none;" (change)="onFileSelection($event)" #fileInput
  accept=".png, .xlsx, .csv, .pdf, .doc, .docx, .jpg" multiple>
  
  <div *ngFor="let f of files; let i = index" [attr.data-index]="i" >
    <span>{{f.name}}</span>
    <button mat-icon-button color="primary" style="color: rgb(211, 8, 8);"
      [attr.title]="deleteButtonLabel ? deleteButtonLabel : null" (click)="removeSelectedFile(i)">
      <mat-icon>{{deleteButtonIcon}}</mat-icon>
    </button>
  </div>
  <br>  

  <div style="display: flex; justify-content: flex-end; margin-left: 500px;">
    <button 
    style="margin-left: 10px; margin-bottom: 10px;"
     mat-raised-button color="primary" (click)="onSave()">
      Save</button>
      <button 
      style="margin-left:15px; margin-bottom: 10px;"
     mat-raised-button color="accent" (click)="onCancel()">
      Cancel</button>
  </div>
  </div>

