<mat-drawer-container class="full-height">
  <mat-drawer
    class="full-height"
    mode="side"
    opened
    position="end"
    disableClose="true"
    style="width: 30%"
  >
    <mat-list *ngIf="showScore">
      <h3 mat-subheader>Categories</h3>
      <mat-list-item *ngFor="let key of categoryScoreMapping.keys()">
        <button
          mat-raised-button
          (click)="filerQuestionsForCategory(key)"
          [color]="currentCategory === key ? 'primary' : ''"
          [matBadge]="categoryScoreMapping.get(key)?.count"
          matBadgeColor="accent"
        >
          {{ key }}
        </button>
      </mat-list-item>
    </mat-list>
    <mat-list *ngIf="!showScore">
      <h3 mat-subheader>Categories</h3>
      <mat-list-item *ngFor="let key of categoryScoreMapping.keys()">
        <button
          mat-raised-button
          (click)="filerQuestionsForCategory(key)"
          [color]="currentCategory === key ? 'primary' : ''"
        >
          {{ key }}
        </button>
      </mat-list-item>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content style="width: 70%">
    <mat-card-header class="page-title">
      <div mat-card-avatar class="button-icon">
        <button mat-raised-button (click)="back()">
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
      </div>
      <mat-card-title
        *ngIf="auditTransactionDetail.centerCode"
        class="button-icon-text"
        >Observations for Center
        {{ auditTransactionDetail.centerCode }}</mat-card-title
      >
      <mat-card-title
        *ngIf="!auditTransactionDetail.centerCode"
        class="button-icon-text"
        >Observations for Branch
        {{ auditTransactionDetail.branchCode }}</mat-card-title
      >
    </mat-card-header>
    <div *ngIf="currentCategory">
      <p style="margin: 0px 0px 0px 10px">
        Category:
        <b>{{ currentCategory }}</b>
        <span *ngIf="editScore && showScore" class="margin-left-7-percent">
          <mat-form-field>
            <input
              matInput
              placeholder="Marks"
              type="number"
              [(ngModel)]="currentMarks"
            />
          </mat-form-field>
          <span *ngIf="hasUpdateMarksError"
            >Marks cannot be greater than {{ maxMarkForCategory }}!</span
          >
          <span *ngIf="isNegativeMark">Marks cannot be less than 0!</span>
          <span *ngIf="noMark">Enter marks!</span>

          <button
            class="margin-left-10"
            mat-stroked-button
            (click)="updateMarks()"
            color="primary"
          >
            Update Marks For Category
          </button>
        </span>

        <span *ngIf="!editScore && showScore" class="margin-left-7-percent">
          Marks: <b>{{ currentMarks }}</b>
        </span>
        <span
          *ngIf="
            weightageForCategory === null || weightageForCategory === undefined
          "
          class="margin-left-7-percent"
        >
          Weightage: <b>0</b>
        </span>
        <span
          *ngIf="
            !(
              weightageForCategory === null ||
              weightageForCategory === undefined
            )
          "
          class="margin-left-7-percent"
        >
          Weightage: <b>{{ weightageForCategory }}</b>
        </span>
      </p>
    </div>
    <!-- <div *ngIf="!currentCategory">
            <h3 align="center">No observations for any of the categories!</h3> 
        </div> -->
    <form [formGroup]="checkListForm">
      <div formArrayName="checkListDetailForm">
        <div
          *ngFor="
            let checkListDetailForm of checkListForm.get('checkListDetailForm')[
              'controls'
            ];
            let i = index
          "
        >
          <div formGroupName="{{ i }}">
            <mat-card class="margin-7 dull-background">
              <div>
                <span>{{ checkListDetailForm.get("answers").value }} </span>
                <span
                  ><b>Instances: </b
                  >{{ checkListDetailForm.get("instancesVerified").value }}
                </span>
                <span
                  ><b>Deviations: </b
                  >{{ checkListDetailForm.get("deviationsFound").value }}
                </span>
                <mat-card-actions>
                  <ng-container *ngIf="!isAuditClosed">
                 <div *ngFor="let config of observationResponseActionButtons; let i=index" style="display: inline-block">
                   
                  <button
                  *ngIf=" checkListDetailForm?.value.lastAction === config.action &&
                  checkListDetailForm?.value.lastModifiedBy===loggedInUser.login"
                  mat-raised-button
                  (click)="updateResponseDetailStatus(checkListDetailForm?.value,config)"
                  color="primary"
                >{{ config.buttonTitle }}</button>
               
               <button *ngIf="checkListDetailForm?.value.lastAction===config.action 
                && checkListDetailForm?.value.lastModifiedBy!==loggedInUser.login" mat-raised-button
                    (click)="updateResponseDetailStatus(checkListDetailForm?.value,config)">{{ config.buttonTitle}}</button>
               
               <button
                  *ngIf="checkListDetailForm?.value.lastAction !== config.action"
                  mat-raised-button
                  (click)="updateResponseDetailStatus(checkListDetailForm?.value,config)"
                >{{ config.buttonTitle }}</button>
        

                 </div>
               </ng-container>
                  <button
                    mat-icon-button
                    matTooltip="View Compliance History"
                    style="float: right; padding-bottom: 20px"
                    (click)="onDialog(checkListDetailForm.value)"
                  >
                    <mat-icon>view_list</mat-icon>
                  </button>
                  <br />
                  <br />
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <div style="flex: 1">
                      <input type="hidden" formControlName="id" />
                      <input type="hidden" formControlName="version" />
                      <mat-form-field appearance="outline" fxFlex="500px">
                        <mat-label  
                          style="
                            color: black;
                            font-weight: bold;
                            font-size: medium;
                          "
                          >Observation Description</mat-label
                        >
                        <textarea
                          row="5"
                          matInput
                          type="text"
                          formControlName="description"
                          [readonly]="!ShowRemarkSave">
                          </textarea>
                      </mat-form-field>
                    </div>
                    <div style="margin-bottom: 80px" >
                      <button
                        mat-icon-button
                        *ngIf="ShowFileSave"
                        matTooltip="Attach files"
                        (click)="onRemarksDialog(checkListDetailForm.value, 'edit')"
                      >
                        <mat-icon>cloud_upload</mat-icon>
                      </button>
                    </div>
                  </div>

                  <ng-container
                    *ngFor="let file of checkListResponse?.[i]?.observationResponseRemarksDTO?.observationResponseFilesDTOs; let j = index"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <span style="flex: 1">{{ file.fileDTO?.fileName }}</span>
                      <div >
                        <button
                          (click)="onDownload(file.fileDTO?.id)"
                          *ngIf="file.id"
                          matTooltip="Download File"
                          style="
                            padding-right: 10px;
                            border: none;
                            background: none;
                          "
                        >
                          <i class="material-icons" style="padding: 10px"
                            >download</i
                          >
                        </button>
                        <button
                          (click)="deleteDialog(file.id)"
                          *ngIf="ShowDeleteFiles"
                          matTooltip="Delete File"
                          style="
                            border: none;
                            background: none;
                          "
                        >
                          <i class="material-icons" style="padding: 10px"
                            >delete</i
                          >
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <br />
                  <br />
                  <div style="text-align: right">
                    <button
                      *ngIf="ShowRemarkSave"
                      mat-raised-button
                      color="primary"
                      (click)="
                        onSubmit(checkListDetailForm.value
                        )
                      "
                    >
                      Submit
                    </button>
                  </div>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </form>
  </mat-drawer-content>
</mat-drawer-container>
