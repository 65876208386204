import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {IgLayoutService} from '../ig-layout.service';
import {PrincipalService} from 'projects/ig-core/src/lib/authentication/principal.service';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import { Router } from '@angular/router';

@Component({
  selector: 'ig-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  loggedInUser: any;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
              private igLayoutService: IgLayoutService,
              private principalService: PrincipalService,
              private router: Router) { }

  ngOnInit() {
    this.principalService.getAuthenticationState()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((account) => {
      this.loggedInUser = account;
    });
  }

  ngOnDestroy(): void {
  }

  toggleSideNav() {
    if (!this.loggedInUser) {
      this.igLayoutService.closeSideNav();
    } else {
      this.igLayoutService.toggleSideNav();
    }
  }

  changePassword() {
    const changePassword = true;
    this.router.navigate(['reset/finish'], { queryParams: { changePassword } });
  }
}
