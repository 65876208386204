import { LmsDatamodel } from "../user/lms-datasource.model";

export class BranchSet {
    id?: number;
    version?: number;
    name?: string;
    code?: string;
    branchIds?: number[];
    branches?: BranchSetBranch[];
    createdBy?: string;
    createdDate?: any;
    businessLineId?: number;

}

export class BranchSetBranch {
  id?: number;
  version?: number;
  name?: string;
  code?: string;
  nameCode?: string;
  createdBy?: string;
  createdDate?: any;
  stateName?:string;
}
