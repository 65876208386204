import { Component, OnInit } from "@angular/core";
import { UserService } from "./user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PagingAndSortParams } from "projects/ig-core/src/lib/list/paging-and-sort-params";
import { UserListDataSource } from "./user-list.data.source";
import { ListSource } from "projects/ig-core/src/lib/list/list-source";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { AfterViewInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserDeleteDialogComponent } from "./user-delete-dialog.component";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { RoleService } from "../role/role.service";
import { Role } from "../role/role.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { businessLinemodel } from "./businessLine.model";
import { FormErrorModel } from "projects/ig-core/src/lib/form/form-error.model";
import { IgFormService } from "projects/ig-core/src/lib/form/form.service";
import { ReportService } from "src/app/dashboard/reports/report.service";

@Component({
  selector: "app-user",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: UserListDataSource;
  listSource: ListSource;
  roles: Role[];
  businessLines = [];
  businessLinemodel: businessLinemodel[];
  public userListForm: FormGroup;

  displayedColumns = [
    "firstName",
    "email",
    "login",
    "role",
    "isActivated",
    "createdOn",
    "action",
  ];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private roleService: RoleService,
    private formBuilder: FormBuilder,
    private igFormService: IgFormService,
    private reportService: ReportService
  ) {}

  public formError: FormErrorModel;
  public formErrors = {
    roles: "",
    businessLines: "",
  };

  ngOnInit() {
    this.userListForm = this.buildForm();
    this.roleService
      .getAllRoles()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((roles) => {
        this.roles = roles.body;
      });
    this.userService
      .getBusinessLine()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.businessLines = response;
      });

    this.dataSource = new UserListDataSource(this.userService);
    this.listSource = new ListSource(
      this.activatedRoute,
      this.paginator,
      this.sort
    );
    this.listSource
      .getListParams()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === "" || sortColumn === undefined) {
          sortColumn = "firstName";
        }
        this.loadUsers(sortColumn);
      });

    this.loadUsers("");
  }

  buildForm() {
    return this.formBuilder.group({
      role: "",
      login: "",
      name: "",
      lmsDatasource: 1,
      businessLineId: 1,
    });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    this.listSource
      .subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadUsersPageWithParams());
  }

  loadUsersPageWithParams() {
    this.router.navigate(["admin/user"], this.getQueryParams());
  }

  loadUsers(sortColumn: String) {
    this.dataSource.loadUsers(
      sortColumn + "," + this.pagingAndSortParams.sortDirection,
      this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize,
      this.userListForm.value["name"],
      this.userListForm.value["login"],
      this.userListForm.value["role"],
      this.userListForm.value["businessLineId"]
    );
  }
  getQueryParams() {
    const queryParams =
      this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  deleteDialog(userData: any) {
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
      data: userData,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        if (result && result === "deleted") {
          this.snackBar.open(`Deleted User successfully`, "Close");
          this.ngOnInit();
        }
      });
  }

  search() {
    this.paginator.pageIndex = 0;
    this.pagingAndSortParams.pageIndex = 0;
    this.loadUsers("");
    this.loadUsersPageWithParams();
  }

  clearFilter() {
    this.userListForm.get("name").setValue(undefined);
    this.userListForm.get("login").setValue(undefined);
    this.userListForm.get("role").setValue(undefined);
    this.userListForm.get("businessLineId").setValue(1);
    this.search();
  }

  resetPassword(user: any) {
    this.userService
      .resetPassword(user)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.snackBar.open(`Password updated to default successfully`, "Close");
      });
  }

  onReportDownload() {
    if (this.userListForm.value["role"] != "") {
      this.downloadUserDetailsReport();
    } else {
      this.roleLoadError();
    }
  }

  private roleLoadError() {
    this.snackBar.open('Please select the role', 'Close', {
      duration: 1000,
      horizontalPosition: 'center',
    });
  }

  //This method is used to download the user details report
  downloadUserDetailsReport() {
    let roleList = [];
    roleList.push(this.userListForm.value["role"]);
    this.reportService
      .downloadUserDetailsReport({
        roles: roleList,
        businessLineId: this.userListForm.value["businessLineId"],
      })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res) => {
          console.log("start download:", res);
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {
          console.log("download error:", JSON.stringify(error));
        },
        () => {
          console.log("Completed file download.");
        }
      );
  }
}
