import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { FormErrorModel } from "projects/ig-core/src/lib/form/form-error.model";
import { IgFormService } from "projects/ig-core/src/lib/form/form.service";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditTransactionService } from "../audit-transaction-service";
import { formatDate } from "@angular/common";
import { Subject } from "rxjs";


@Component({
    selector: "app-close-audit-dialog",
    templateUrl: "./close-audit-dialog.component.html",
  })

export class CloseAuditDialogComponent implements OnInit, OnDestroy {

commencedDate: number;
finishedDate: number;
public formError: FormErrorModel;
public closeAuditForm: FormGroup;
public formErrors = {
  commencedDate: '',
  finishedDate: '',
}
id: number;
remarks: string;
status: string;
currentDate = new Date();

constructor(
    private dialog: MatDialogRef<CloseAuditDialogComponent>,
    private  formBuilder: FormBuilder, private snackBar: MatSnackBar,
    private igFormService: IgFormService, private router: Router,
     private auditTransactionService: AuditTransactionService,
     @Inject(MAT_DIALOG_DATA) public data: { id: number , status : string, comment :string}
      ){
 console.log("data", data);
}

  
buildForm() {
  this.closeAuditForm = this.formBuilder.group({
    id: [this.data.id],
    commencedDate: [this.commencedDate, Validators.required],
    finishedDate: [this.finishedDate, Validators.required],
    remarks: [this.remarks], 
    status: [this.data.status]
  });
  this.closeAuditForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
  this.formErrors = this.igFormService.validateForm(this.closeAuditForm, this.formErrors, true);
});
}

ngOnInit(): void {
  this.buildForm();
  this.formError = new FormErrorModel(false, ''); 

}

ngOnDestroy(){
 
}

submitAudit(){
  this.formError = new FormErrorModel(false, '');
  this.formError = new FormErrorModel(false, '');
  this.igFormService.markFormGroupTouched(this.closeAuditForm);
  if (this.closeAuditForm.valid) {
      this.closeAuditForm.value.commencedDate = formatDate(this.commencedDate, 'dd-MM-yyyy', 'en-us');
      this.closeAuditForm.value.finishedDate = formatDate(this.finishedDate, 'dd-MM-yyyy', 'en-us');
      console.log("closeAuditForm",this.closeAuditForm);
      this.auditTransactionService.saveAudit(this.closeAuditForm.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (response) => 
          this.onSaveUpdateAudit(response),
        (response) => 
          this.onSaveAuditError(response)
      );
    } else {
    this.formErrors = this.igFormService.validateForm(this.closeAuditForm, this.formErrors, false);
  }

}

 onSaveUpdateAudit(response) {
  this.snackBar.open(`Successfully completed audit`, 'Close', {
    duration: 3000,
  });
  this.dialog.close();
  this.back();
}


 onSaveAuditError(response) {
this.snackBar.open(response.error.title, 'Close', {
  duration: 7000,
});
}


onCancel(){
  this.dialog.close();
  // window.location.reload();

}

back() {
  this.router.navigateByUrl('auditTransaction');
}


}