import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigureService} from 'src/app/build-config/configure.service';

@Injectable()
export class IgAPIInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigureService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('api') || req.url.startsWith('management')) {
      const apiReq = req.clone({url: `${this.configService.config.API_URL}/${req.url}`});
      return next.handle(apiReq);
    } else {
      return next.handle(req);
    }
  }

}
