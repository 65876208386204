import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { CenterService } from './center.service';
import { Center } from './center.model';

@Injectable({
    providedIn: 'root'
})
export class CenterResolverService implements Resolve<Center> {
    constructor(private centerService: CenterService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Center> | Observable<never> {
        return this.centerService.getCenter(+route.paramMap.get('id'));
    }
}
