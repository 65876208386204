<!--The content below is only a placeholder and can be replaced.-->
<div style="padding: 10px">
  <h1>
    Welcome to {{ title }}!
  </h1>
  <h3>
    <p style="text-align: left;">Mavrc is a Samasta App for Business Monitoring. It is an ACRONYMN for Monitoring,
      Audit, Vigilance, Risk and Compliance. It has a mobile app for users such as
      auditors, reviewers, vigilance team members and managers to survey or
      assess branches and centres with respect to predefined checklists or
      questionnaires. Questionnaires can be configured in the Mavrc Web app.
      Questionnaires can be versioned when questions need to change.</p>
    <p  style="text-align: left;">
      Mavrc Web app will enable review of submitted data and workflow among the
      managing team members such as BM, AM and DM. Reports can be extracted
      out of the information captured. Mavrc Web app connects to LMS to obtain
      required branch master, centre masters as well as loan operational data.</p>
  </h3>
</div>
