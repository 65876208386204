import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-upload',
  templateUrl: './user-upload.component.html',
  styleUrls: ['./user.component.css']
})
export class UserUploadComponent implements OnDestroy, OnInit {

  file: File;
  message: string;
  constructor(private router: Router, private userService: UserService,
    private snackBar: MatSnackBar) {
  }

  ngOnDestroy(): void {
  }
  ngOnInit(): void {
  }

  goToUserPage() {
    this.router.navigateByUrl('admin/user');
  }

  onFileSelection(event: any) {
    this.file = event.target.files[0];
  }

  onSubmit() {
    if (this.file === undefined) {
      this.message = 'Please select a file';
    } else {
      this.userService.uploadUserBranchSet(this.file).pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
    }
  }

  downloadTemplate() {
    this.userService.downloadUserBranchMappingTemplate()
    .pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      console.log('start download:', res);
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      console.log('download error:', JSON.stringify(error));
      this.message = error;
    }, () => {
      console.log('Completed file download.');
    });
  }

  downloadExcitingMapping() {
    this.userService.downloadBranch()
        .pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      console.log('start download:', res);
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      console.log('download error:', JSON.stringify(error));
      this.message = error;
    }, () => {
      console.log('Completed file download.');
    });
  }

  private onSubmitSuccess(response) {
    this.snackBar.open(`Updated User BranchSet ${response.body} successfully`, 'Close', {
      duration: 3000,
    });
    this.message = response.body;
  }

  private onSubmitError(response) {
    this.snackBar.open(`Failed Updated User BranchSet ${response.error} `, 'Close', {
      duration: 3000,
    });
    this.message = response.error.text;
  }
}
