import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatTabGroup} from '@angular/material/tabs';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';
import { SCORECARD_MASTER } from 'src/app/constants/data.constants';
import { ORGANIZATION_MASTER } from 'src/app/constants/data.constants';
import { Question } from '../../question/question.model';
import { BranchOrganisationMapping, BranchScorecardMapping, QuestionaireScorecardMapping, ScoreSet } from '../score-card.model';
import { ScoreCardService } from '../score-card.service';

@Component({
    selector: 'app-score-card-edit',
    templateUrl: './score-card-edit.component.html',
    styleUrls: ['./score-card-edit.component.css']
})
export class ScoreCardEditComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('tableDataPaginator',{static:false}) tableDataPaginator: MatPaginator;
    @ViewChild('matMaingroup',{static:false}) matMaingroup: MatTabGroup;
    /* @ViewChild('tableDataPaginator') set paginator(value: MatPaginator) {
        if (this.questionsDataSource) {
            this.questionsDataSource.paginator = value;
        }
    }; */
    paginationDetail = new BehaviorSubject({ length: 10, pageIndex: 0, pageSize: 10 });

    scorecard: ScoreSet;
    editable: true;
    isPresent: boolean;
    isSave: boolean;
    ischecked: boolean;
    editOrView: string;
    modules: any;
    submodules: any;
    questionaire: any;
    branchCodes: any;

    moduleCodeList: any = [];
    submoduleCodeList: any = [];
    questionnaireScorecard: any = [];
    displayedColumns: string[] = ['select', 'questionName', 'type', 'weightage'];

    zoneOfBranchOrganisation: BranchOrganisationMapping[] = [];
    zoneNgModel: BranchOrganisationMapping[] = [];
    zoneMultiId: any = [];
    stateOfBranchOrganisation: BranchOrganisationMapping[] = [];
    stateNgModel: BranchOrganisationMapping[] = [];
    stateMultiId: any = [];
    regionOfBranchOrganisation: BranchOrganisationMapping[] = [];
    regionNgModel: BranchOrganisationMapping[] = [];
    regionMultiId: any = [];
    divisionOfBranchOrganisation: BranchOrganisationMapping[] = [];
    divisionNgModel: BranchOrganisationMapping[] = [];
    divisionMultiId: any = [];
    areaOfBranchOrganisation: BranchOrganisationMapping[] = [];
    areaNgModel: BranchOrganisationMapping[] = [];
    areaMultiId: any = [];
    branchOfBranchOrganisation: BranchOrganisationMapping[] = [];
    branchNgModel: BranchOrganisationMapping[] = [];
    branchMultiId: any = [];

    public scorecardEditorForm: FormGroup;
    public scorecardBranchEditorForm: FormGroup;
    public formError: FormErrorModel;

    questionnaireDropDown: any = [];
    questionsDataSource: MatTableDataSource<any>;
    selection = new SelectionModel<Question[]>(true, []);
    selectedTabIndex = 0;
    moduleTotal: any = 0;
    submoduleTotal: any = 0;
    questionnaireTotal: any = 0;

    public formErrors = {
        code: '',
        description: '',
        validFrom: '',
        ValidTo: '',
        hierarchy: '',
        name: '',
        selectedStates: '',
        selectedBranches: '',
        fromDateFilter: '',
        toDateFilter: '',

    };
    questionnaireDropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'smartSearch',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    organizationDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'office',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    branchOrganizationDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'office',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };


    constructor(private scoreCardService: ScoreCardService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService,
        private router: Router,
        private referenceCodeService: ReferenceCodeService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.scorecard === undefined) {
                    this.isSave = true;
                    this.scorecard = {};

                } else {
                    this.isPresent = true;
                    this.scorecard = data.scorecard;
                }
                this.editable = data.editable;
            });
    }

    ngOnInit() {

        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        this.editOrView = 'Edit';
        if (!this.editable) {
            this.scorecardEditorForm.disable();
            this.editOrView = 'View';
        }

        if (this.isPresent === true) {
            this.getModuleMapping();
            this.getSubModuleMapping();
            this.getQuestionnaireMapping();
            this.getBranchCodesMapping();
            this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.ZONE, ORGANIZATION_MASTER.HEADQUARTERS_ID);

        }
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.scorecardEditorForm = this.formBuilder.group({
            id: [this.scorecard.id],
            code: [this.scorecard.code, Validators.required],
            description: [this.scorecard.description],
            validFrom: [this.scorecard.validFrom, Validators.required],
            ValidTo: [{ value: this.scorecard.validTo, disabled: true }],
            version: [this.scorecard.version],
        });
        this.scorecardEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
            this.formErrors = this.igFormService.validateForm(this.scorecardEditorForm, this.formErrors, true);
        });

        this.scorecardBranchEditorForm = this.formBuilder.group({
            zone: [this.zoneMultiId],
            state: [this.stateMultiId],
            region: [this.regionMultiId],
            division: [this.divisionMultiId],
            area: [this.areaMultiId],
            branch: [this.branchMultiId],
        });
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.scorecardEditorForm);
        if (this.scorecardEditorForm.valid) {
            this.scoreCardService.saveScoreSet(this.scorecardEditorForm.value).pipe(takeUntil(componentDestroyed(this))).
                subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.scorecardEditorForm, this.formErrors, false);
            return false;
        }
    }

    getModuleMapping() {
        this.scoreCardService.getModuleScorecardMappingById(this.scorecard.id).
            pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
                this.modules = response;
                this.moduleWeightageValidator();
            });
    }

    getSubModuleMapping() {
        this.scoreCardService.getSubmoduleScorecardMappingById(this.scorecard.id).
            pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
                this.submodules = response;
                this.subModuleWeightageValidator();
            });
    }

    getQuestionnaireMapping() {
        this.scoreCardService.getQuestionnaireScorecardMappingById(this.scorecard.id)
            .pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
                this.questionnaireScorecard = response;
                this.questionnaireScorecard.map(element => {
                    if (element.questionaireId) {
                        element.selectedMain = element.questionaireDTOs.filter(eachQuestionnaire =>
                            eachQuestionnaire.id === element.questionaireId);
                    } else {
                        element.selectedMain = [];
                    }
                    element.questionaireDTOs.map(eachQuestionnaire =>
                        eachQuestionnaire.smartSearch = eachQuestionnaire.name + "-" +
                        eachQuestionnaire.description + "-" + eachQuestionnaire.version + "-" +
                        eachQuestionnaire.status);
                });
                if (this.questionnaireScorecard.length > this.selectedTabIndex) {
                    this.questionnaireDropDown = this.questionnaireScorecard[this.selectedTabIndex];
                    this.questionsDataSource = new MatTableDataSource<any>(this.questionnaireScorecard[this.selectedTabIndex].questions);
                    this.checkSelectedQuestions(this.questionnaireScorecard[this.selectedTabIndex].questions);
                } else {
                    this.questionnaireDropDown = [];
                    this.selection.clear();
                    this.questionsDataSource = new MatTableDataSource<any>([]);
                }
            });
    }

    getBranchCodesMapping() {
        this.scoreCardService.getBranchScorecardMappingById(this.scorecard.id).
            pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
                this.branchCodes = response;
                this.checkSelectedBranchCodes(this.branchCodes);
            });
    }

    checkSelectedQuestions(data) {
        this.selection.clear();
        data.forEach(values => {
            if (values.flag === true) {
                this.selection.select(values);
            }
            if (!values["weightage"]) {
                values["weightage"] = 0;
            }
        });
        this.questionsDataSource.paginator = this.tableDataPaginator;
    }

    checkSelectedBranchCodes(data) {
        this.branchMultiId = data.map(function (item) { return item.branchCode; });
        this.branchNgModel = [];
        let dropDownList = [];
        for (let iterator of data) {
            let branchOrg = new BranchOrganisationMapping();
            branchOrg.code = iterator.branchCode;
            branchOrg.office = iterator.office;
            this.branchNgModel.push(branchOrg);
            branchOrg.id = iterator.id;
            branchOrg.hierarchy = iterator.branchCode;
            branchOrg.parentId = iterator.id;
            dropDownList.push(branchOrg);
        }
        this.branchOfBranchOrganisation = dropDownList;
    }

    switchChangeTab(type) {
        if (type === "MAINGROUP") {
            this.matMaingroup.selectedIndex = (this.matMaingroup.selectedIndex + 1) % this.matMaingroup._tabs.length;
        } else {
            this.selectedTabIndex = (this.selectedTabIndex + 1) % this.questionnaireScorecard.length;
        }
    }

    onSaveModule() {
        if (this.checkWeightageReCalculationBeforeSave(SCORECARD_MASTER.MODULE_MASTER)) {
            this.scoreCardService.saveModuleScorecardMapping(this.modules).
                pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
                    this.getModuleMapping();
                    this.getSubModuleMapping();
                    this.getQuestionnaireMapping();
                    this.switchChangeTab("MAINGROUP");
                });
        }
    }

    onSaveSubmodule() {
        if (this.checkWeightageReCalculationBeforeSave(SCORECARD_MASTER.SUBMODULE_MASTER)) {
            this.scoreCardService.saveSubmoduleScorecardMapping(this.submodules).
                pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
                    this.getSubModuleMapping();
                    this.getQuestionnaireMapping();
                    this.switchChangeTab("MAINGROUP");
                });
        }
    }

    public questionnaireTabChange(event) {
        this.selectedTabIndex = event.index;
        this.questionnaireDropDown = this.questionnaireScorecard[this.selectedTabIndex];
        this.questionsDataSource = new MatTableDataSource<any>(this.questionnaireScorecard[this.selectedTabIndex].questions);
        this.checkSelectedQuestions(this.questionsDataSource.data);
        if (this.questionsDataSource.data.length === 0) {
            this.getAllTheQuestions(this.selectedTabIndex);
        }
    }

    onSaveQuestion() {
        this.questionnaireTotal = 0;

        let questionaireScorecardMapping = new QuestionaireScorecardMapping();
        questionaireScorecardMapping.scorecardId = this.scorecard.id;
        questionaireScorecardMapping.submoduleScoreId = this.questionnaireScorecard[this.selectedTabIndex].submoduleScoreId;
        if (this.selection.selected.length === 0) {
            this.questionsDataSource.data.map(element => element.flag = false);
            questionaireScorecardMapping.questions = this.questionsDataSource.data;
            this.scoreCardService.saveAndUpdateQuestionnaireScorecard(questionaireScorecardMapping).
                pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
                    this.getQuestionnaireMapping();
                    this.switchChangeTab("QUESTIONGROUP");
                });
        } else {
            this.questionsDataSource.data.map(element => element.flag = false);
            this.selection.selected.forEach(dataElement => {
                this.questionsDataSource.data.find(item => item.id === dataElement["id"]).flag = true;
                this.questionnaireTotal += dataElement["weightage"] ? dataElement["weightage"] : 0;
            });
            // tslint:disable-next-line:no-shadowed-variable
            questionaireScorecardMapping.questions = this.questionsDataSource.data;
            if (this.checkWeightageReCalculationBeforeSave(SCORECARD_MASTER.QUESTIONNAIRE_MASTER)) {
                // tslint:disable-next-line:no-shadowed-variable
                this.scoreCardService.saveAndUpdateQuestionnaireScorecard(questionaireScorecardMapping).
                    pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
                        this.getQuestionnaireMapping();
                        this.switchChangeTab("QUESTIONGROUP");
                    });
            }
        }
    }

    onSaveBranch() {
        this.branchCodes.map(element => element.flag = false);
        this.branchMultiId.forEach(dataElement => {
            let branchScorecardMapping = this.branchCodes.find(x => x.branchCode === dataElement);
            if (branchScorecardMapping === undefined) {
                branchScorecardMapping = new BranchScorecardMapping();
                branchScorecardMapping.version = 0;
                branchScorecardMapping.scorecardCode = this.scorecard.code;
                branchScorecardMapping.scorecardId = this.scorecard.id;
                branchScorecardMapping.branchCode = dataElement;
                branchScorecardMapping.flag = true;
                this.branchCodes.push(branchScorecardMapping);
            } else {
                this.branchCodes.find(x => x.branchCode === dataElement).flag = true;
            }
        });
        this.scoreCardService.saveBranchScorecardMapping(this.branchCodes).
            pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
                this.getBranchCodesMapping();
                this.switchChangeTab("MAINGROUP");
            });

    }

    checkWeightageReCalculationBeforeSave(typeCheck): boolean {
        let allowSave = true;
        switch (typeCheck) {
            case SCORECARD_MASTER.MODULE_MASTER:
                if (this.moduleTotal ? Math.round(this.moduleTotal) !== 100 : false) {
                    allowSave = false;
                }
                break;
            case SCORECARD_MASTER.SUBMODULE_MASTER:
                this.modules.forEach(values => {
                    if (values.submoduleTotal ? Math.round(values.submoduleTotal) !== 100 : false) {
                        allowSave = false;
                    }
                });
                break;
            case SCORECARD_MASTER.QUESTIONNAIRE_MASTER:
                if (this.questionnaireTotal ? Math.round(this.questionnaireTotal) !== 100 : false) {
                    allowSave = false;
                }
                break;
            default: break;
        }
        if (!allowSave) {
            this.snackBar.open("Please Check the Error Message before saving !!", 'Close', { duration: 3000 });
        }
        return allowSave;
    }

    private onSubmitSuccess(response) {
        this.snackBar.open(`Updated scorecard '${response.body.description}'
      of version '${response.body.description}' successfully`, 'Close', {
            duration: 3000,
        });
        this.goToScorecardListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.scorecardEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        } else if (errorModel && !errorModel.isValidationError) {
            this.formError.hasError = true;
            this.formError.error = errorModel.otherError;
        }
    }

    goToScorecardListPage() {
        this.router.navigateByUrl('scorecard');
    }

    createQuestion() {
        this.router.navigate(['scorecard/' + this.scorecard.id + '/question/create']);
    }

    yourfuncModule(module: any, ischecked) {
        if (ischecked) {
            module.flag = false;
        }
        else if (!ischecked) {
            module.flag = true;
        }
        this.moduleSliderFunction(ischecked);

    }

    yourfuncSubmodule(submodule: any, ischecked) {
        if (ischecked) {
            submodule.flag = false;
        }
        else if (!ischecked) {
            submodule.flag = true;
        }

        this.submoduleSliderFunction(ischecked);

    }

    onDeSelectOrganizationHeirachy(hierarchy) {
        // please dont apply the break, functionality works
        /*eslint no-fallthrough: "error"*/
        switch (hierarchy) {
            case ORGANIZATION_MASTER.ZONE:
                this.zoneMultiId = [];
                this.zoneNgModel = [];
            // falls through
            case ORGANIZATION_MASTER.STATE:
                this.stateMultiId = [];
                this.stateNgModel = [];
            // falls through
            case ORGANIZATION_MASTER.REGION:
                this.regionMultiId = [];
                this.regionNgModel = [];
            // falls through
            case ORGANIZATION_MASTER.DIVISION:
                this.divisionMultiId = [];
                this.divisionNgModel = [];
            // falls through
            case ORGANIZATION_MASTER.AREA:
                this.areaMultiId = [];
                this.areaNgModel = [];
            // falls through
            case ORGANIZATION_MASTER.BRANCH:
                this.branchMultiId = [];
                this.branchNgModel = [];
                this.checkSelectedBranchCodes(this.branchCodes);
                break;
            default: break;
        }
    }

    getAllOrganizationHeirachy(hierarchy, ids) {
        if (ids.length > 0) {
            this.scoreCardService.getAllBranchesForStates(hierarchy, ids).
                pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
                    this.populateToDataSource(hierarchy, response.body);
                });
        } else {
            this.populateToDataSource(hierarchy, []);
        }
    }

    populateToDataSource(hierarchy, responseData) {
        switch (hierarchy) {
            case ORGANIZATION_MASTER.ZONE:
                this.zoneOfBranchOrganisation = responseData;
                break;
            case ORGANIZATION_MASTER.STATE:
                this.stateOfBranchOrganisation = responseData;
                break;
            case ORGANIZATION_MASTER.REGION:
                this.regionOfBranchOrganisation = responseData;
                break;
            case ORGANIZATION_MASTER.DIVISION:
                this.divisionOfBranchOrganisation = responseData;
                break;
            case ORGANIZATION_MASTER.AREA:
                this.areaOfBranchOrganisation = responseData;
                break;
            case ORGANIZATION_MASTER.BRANCH:
                this.branchOfBranchOrganisation = responseData;
                break;
            default: break;
        }
    }

    onSelectZone(event) {
        this.zoneMultiId.push(event.id);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.STATE, this.zoneMultiId);
    }

    onSelectAllZone(data) {
        this.zoneMultiId = data.map(function (item) { return item.id; });
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.STATE, this.zoneMultiId);
    }

    onItemDeSelectZone(data) {
        this.zoneMultiId.splice(this.zoneMultiId.indexOf(data.id), 1);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.STATE, this.zoneMultiId);
    }

    onZoneDeSelectAll(data) {
        this.zoneMultiId = data;
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.STATE, this.zoneMultiId);
        this.onDeSelectOrganizationHeirachy(ORGANIZATION_MASTER.STATE);
    }

    onSelectState(event) {
        this.stateMultiId.push(event.id);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.REGION, this.stateMultiId);
    }

    onSelectAllState(data) {
        this.stateMultiId = data.map(function (item) { return item.id; });;
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.REGION, this.stateMultiId);
    }

    onItemDeSelectState(data) {
        this.stateMultiId.splice(this.zoneMultiId.indexOf(data.id), 1);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.REGION, this.stateMultiId);
    }

    onStateDeSelectAll(data) {
        this.stateMultiId = data;
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.REGION, this.stateMultiId);
        this.onDeSelectOrganizationHeirachy(ORGANIZATION_MASTER.REGION);
    }

    onSelectRegion(event) {
        this.regionMultiId.push(event.id);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.DIVISION, this.regionMultiId);
    }

    onSelectAllRegion(data) {
        this.regionMultiId = data.map(function (item) { return item.id; });
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.DIVISION, this.regionMultiId);
    }

    onItemDeSelectRegion(data) {
        this.regionMultiId.splice(this.regionMultiId.indexOf(data.id), 1);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.DIVISION, this.regionMultiId);
    }

    onRegionDeSelectAll(data) {
        this.regionMultiId = data;
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.DIVISION, this.regionMultiId);
        this.onDeSelectOrganizationHeirachy(ORGANIZATION_MASTER.DIVISION);
    }

    onSelectDivision(event) {
        this.divisionMultiId.push(event.id);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.AREA, this.divisionMultiId);
    }

    onSelectAllDivision(data) {
        this.divisionMultiId = data.map(function (item) { return item.id; });
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.AREA, this.divisionMultiId);
    }

    onItemDeSelectDivision(data) {
        this.divisionMultiId.splice(this.divisionMultiId.indexOf(data.id), 1);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.AREA, this.regionMultiId);
    }

    onDivisionDeSelectAll(data) {
        this.divisionMultiId = data;
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.DIVISION, this.divisionMultiId);
        this.onDeSelectOrganizationHeirachy(ORGANIZATION_MASTER.AREA);
    }

    onSelectArea(event) {
        this.areaMultiId.push(event.id);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.BRANCH, this.areaMultiId);
    }

    onSelectAllArea(data) {
        this.areaMultiId = data.map(function (item) { return item.id; });
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.BRANCH, this.areaMultiId);
    }

    onItemDeSelectArea(data) {
        this.areaMultiId.splice(this.areaMultiId.indexOf(data.id), 1);
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.BRANCH, this.areaMultiId);
    }

    onAreaDeSelectAll(data) {
        this.areaMultiId = data;
        this.getAllOrganizationHeirachy(ORGANIZATION_MASTER.BRANCH, this.areaMultiId);
        this.onDeSelectOrganizationHeirachy(ORGANIZATION_MASTER.BRANCH);
    }

    onSelectBranch(event) {
        this.branchMultiId.push(event.code);
    }

    onSelectAllBranch(data) {
        this.branchMultiId = data.map(function (item) { return item.code; });
    }

    onItemDeSelectBranch(data) {
        this.branchMultiId.splice(this.branchMultiId.indexOf(data.code), 1);
    }

    onBranchDeSelectAll(data) {
        this.branchMultiId = data;
    }

    moduleSliderFunction(value: any) {
        this.moduleWeightageValidator();
        return value + '%';
    }

    moduleWeightageValidator() {
        this.moduleTotal = 0;
        this.modules.forEach(values => {
            if (values.flag === false) {
                this.moduleTotal += values.weightage;
            }
        });
    }

    submoduleSliderFunction(value: any) {
        this.subModuleWeightageValidator();
        return value + '%';
    }

    subModuleWeightageValidator() {
        this.modules.forEach(eachModule => {
            this.submoduleTotal = 0;
            this.submodules.forEach(eachSubModule => {
                if (eachSubModule.flag === false && eachModule.moduleCode === eachSubModule.moduleCode && eachSubModule.moduleCode !== 'AUDIT') {
                    this.submoduleTotal = this.submoduleTotal + eachSubModule.weightage;
                }
            });
            eachModule["submoduleTotal"] = this.submoduleTotal;
        });
    }

    getAllTheQuestions(index) {
        this.questionnaireScorecard[index]["questionaireId"] = this.questionnaireScorecard[index]["selectedMain"][0]["id"];
        if (this.questionnaireScorecard[index]["questionaireId"]) {
            this.scoreCardService.getQuestionScorecardMappingById(this.questionnaireScorecard[index]["questionaireId"]).
                pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
                    response.forEach(values => values["weightage"] = 0);
                    this.questionsDataSource = new MatTableDataSource<any>(response);
                    this.checkSelectedQuestions(this.questionsDataSource.data);
                });
        } else {
            this.questionsDataSource = new MatTableDataSource<any>([]);
            this.checkSelectedQuestions(this.questionsDataSource.data);
        }
    }

    deleteAllTheQuestions(index) {
        if (confirm("Are you sure to clear the existing Questions Mapping ?")) {
            if (this.questionnaireScorecard[index]["submoduleScoreId"]) {
                this.scoreCardService.deleteBySubmoduleScoreId(
                    this.questionnaireScorecard[index]["scorecardId"],
                    this.questionnaireScorecard[index]["submoduleScoreId"]).
                    pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
                        this.getQuestionnaireMapping();
                    });
            } else {
                this.getQuestionnaireMapping();
            }
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.questionsDataSource.data.length;
        return numSelected === numRows;

    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.questionsDataSource.data.forEach(row => this.selection.select(row));
    }

    getUpdate(event) {
        this.paginationDetail.next(event);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.questionsDataSource.filter = filterValue;
    }

}
