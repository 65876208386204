import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { Authority } from './authority.model';
@Injectable({
  providedIn: 'root'
})
export class AuthorityService {


  constructor(private http: HttpClient) {
  }
  authorityData(req: any): Observable<HttpResponse<Authority[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
    const requestURL = 'api/authorities';
        return this.http.get<Authority[]>(requestURL, {
            params, observe: 'response'
          });
      }
}
