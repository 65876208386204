import { DataSource } from '@angular/cdk/table';
import { ActivityTracker } from './activity-tracker.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ReportService } from '../reports/report.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, take } from 'rxjs/operators';

export class ActivityTrackerDataSource extends DataSource<ActivityTracker> {

    private activityTrackerSubject = new BehaviorSubject<ActivityTracker[]>([]);
    public totalCount$ = new BehaviorSubject<number>(0);

    constructor(private reportService: ReportService) { super(); }

    connect(collectionViewer: CollectionViewer): Observable<ActivityTracker[]> {
        return this.activityTrackerSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.activityTrackerSubject.complete();
        this.totalCount$.complete();
    }

    loadActivityTrackerReports(userLogins: string[], fromDate: string, toDate: string, businessLineId: number): void {
        this.reportService.loadActivityTrackerReports({
            userLogins: userLogins,
            fromDate : fromDate,
            toDate : toDate,
            businessLineId : businessLineId
        }).pipe(
            catchError(() => of([])),
            finalize(() => {})
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.activityTrackerSubject.next(response['body']);
        });
    }
    clear(){
        this.activityTrackerSubject.next([])
    }
}
