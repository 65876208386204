import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { cashManagementcihStatusList, cihStatusList, digitalDashboardtypes } from 'src/app/constants/data.constants';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { ReportService } from '../reports/report.service';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { months, years } from '../../constants/data.constants';


@Component({
  selector: 'app-cih-report',
  templateUrl: './cih-report.component.html',
  styleUrls: ['./cih-report.component.css']
})
export class CihReportComponent implements OnInit, OnDestroy {

  stateDropdownList: any[];
  selectedStates: any[];
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  selectedBranches: any[];
  stateList: string[];
  branches: Branch[];
  dropdownList: any[];
  stateDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  states: any;
  isRoleAdmin:boolean;
  isCihStatus: boolean;
  fromDateFilter: string;
  toDateFilter: string;
  type: string;
  register: string;
  busniessLineId: number = 1;
  codes: any[];
  fromdate1: string;
  todate1: string;
  typeReportList: any[];
  businessLines: any[];
  selectedBusinessLine: any;
  loggedInUserRole: any;
  businessLine: number;
  digitalRegisterList: any[];
  cihStatustypes: any;
  month: any;
  year: any;
  monthDropdownList = [];
  allYears = [];
  isYear: boolean;
  isMonth: boolean;
  isFromDate: boolean;
  isToDate: boolean;
  public cihReportForm: FormGroup;

  @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
  @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

  @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
  @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;



  public formError: FormErrorModel;
  public formErrors = {
    register: '',
    type: '',
    selectedStates: '',
    selectedBranches: '',
    fromDateFilter: '',
    toDateFilter: '',
    stateName:'',
    name:'',
    businessLineId:'',
    cihStatus: '',
    monthSelection: '',
    selectedYear: '',
  }
 

  constructor(private questionaireService: QuestionaireService,
    private reportService: ReportService, private igFormService: IgFormService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService, private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string) {
      this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUserRole = data.loggedInUser.body.role;
              });
     }


  ngOnInit(): void {

    this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
      this.businessLines = businessLines;
    });
    this.buildForm();
    this.cihReportForm.controls['businessLineId'].disable();
    if (this.loggedInUserRole ==='ROLE_ADMIN') {
        this.isRoleAdmin = true;
        this.cihReportForm.controls['businessLineId'].enable();
    }else{ 
       this.loadStates();
    }
    this.formError = new FormErrorModel(false, '');
    this.digitalRegisterList = digitalDashboardtypes;
    this.isFromDate = true;
    this.isToDate = true;
    this.cihReportForm.controls['fromDateFilter'].enable();
    this.cihReportForm.controls['toDateFilter'].enable();
    
  }
  
  loadBusiness() {
    throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
  }

  onSelectState(state: any) {
    this.selectedStates.push(state);
    this.loadBranches();
  }

  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }
  onItemSelect(event){
  }

  onSelectAll(event){}

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.cihReportForm);
    if (this.cihReportForm.valid) {
      this.downloadCihReport();
    } else {
      this.formErrors = this.igFormService.validateForm(this.cihReportForm, this.formErrors, false);
      return false;
    }
  }

  buildForm() {
    this.cihReportForm = this.formBuilder.group({
      register: [this.register, Validators.required],
      type: [this.type, Validators.required],
      stateName: [this.selectedStates, Validators.required],
      name: [this.branches, Validators.required],
      fromDateFilter: [this.fromDateFilter, Validators.required],
      toDateFilter: [this.toDateFilter, Validators.required],
      businessLineId:['',Validators.required],
      cihStatus: ['',Validators.required],
      month: [this.month, Validators.required],
      year: [this.year, Validators.required]
    });
    this.cihReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.cihReportForm, this.formErrors, true);
    });
  }

  loadBranches() {
    this.selectedBranches=null;
    this.stateList = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.stateList.push(this.selectedStates[i].name);
    }
    this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<Branch[]>) => {
          this.branches = res.body;
          this.dropdownList = this.branches;
          
        });
  }

  loadStates() {
    this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.states = res.body;
          this.stateDropdownList = this.states;
          
        }
      );
  }

  downloadCihReport() {
    this.codes = [];
    this.selectedBranches.forEach((value) => {
      this.codes.push(value.code);
    });

    if (this.register === "SMR") {
      this.reportService.downloadStaffMovementReport({
        type: this.type,
        branchCodes: this.codes,
        fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
        toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us')
      })
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(res => {
          console.log('start download:', res);
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }, error => {
          console.log('download error:', JSON.stringify(error));
          this.onError();
        }, () => {
          console.log('Completed file download.');
        });
    } 
    else {
      const branchCodeControl = this.cihReportForm.get('cihStatus');
      const fDate = this.fromDateFilter === undefined ? this.fromDateFilter : formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us');
      const tDate = this.toDateFilter === undefined ? this.toDateFilter : formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us');
      this.reportService.downloadCihReport({
        type: this.type,
        branchCodes: this.codes,
        fromDate: fDate,
        toDate: tDate,
        statuses: branchCodeControl.value,
        month: this.month,
        year: this.year
      })
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(res => {
          console.log('start download:', res);
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }, error => {
          console.log('download error:', JSON.stringify(error));
            this.onError();
        }, () => {
          console.log('Completed file download.');
        });
    }
  }

  onError() {
    if(this.cihReportForm.controls["type"].value === "BRANCH_BALANCE_SUMMARY"){
      this.snackBar.open(` Data Not found selected From date: ` + this.month + ` To Date: ` + this.year + ``, 'Close', {
        duration: 5000
      });
    }else{
    this.fromdate1 = formatDate(this.fromDateFilter, 'dd-MM-yyyy', this.locale);
    this.todate1 = formatDate(this.toDateFilter, 'dd-MM-yyyy', this.locale);
    this.snackBar.open(` Data Not found selected From date: ` + this.fromdate1 + ` To Date: ` + this.todate1 + ``, 'Close', {
      duration: 5000
    });
  }
  }
  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  onItemDeSelectBranch(item: any) {
  }
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();
 }

  clearFilter() {
    this.cihReportForm.reset();
    if(this.businessLine !== undefined){
      this.stateDropdownList = [];
    }
    this.isCihStatus = false;
    this.isYear = false;
    this.isMonth = false;
    this.isFromDate = true;
    this.isToDate = true;
    this.cihReportForm.controls['cihStatus'].disable();
    this.cihReportForm.controls['month'].disable();
    this.cihReportForm.controls['year'].disable();
    this.cihReportForm.controls['fromDateFilter'].enable();
    this.cihReportForm.controls['toDateFilter'].enable();
    this.dropdownList=[];
    this.onSelectRegister('');
  }

  onSelectRegister(register) {
    this.typeReportList = [];
    if (register !== '') {
      this.typeReportList = this.digitalRegisterList.find(x => x.code === register).options;
    }
    this.isCihStatus = false;
    this.isYear = false;
    this.isMonth = false;
    this.isFromDate = true;
    this.isToDate = true;
    this.cihReportForm.controls['cihStatus'].disable();
    this.cihReportForm.controls['month'].disable();
    this.cihReportForm.controls['year'].disable();
    this.cihReportForm.controls['fromDateFilter'].enable();
    this.cihReportForm.controls['toDateFilter'].enable();

  }

  changeBusinessLine(selectedBusniessLine: any){

    this.digitalRegisterList = [];
    if (selectedBusniessLine.value === this.busniessLineId) {
      this.digitalRegisterList = digitalDashboardtypes;
    } else {
      digitalDashboardtypes.forEach((value) => {
        if(value.code === "CIH" || value.code === "CMCIH"){
          this.digitalRegisterList.push(value);
        }
      });
    }
    this.selectedStates=null;
    this.businessLine = selectedBusniessLine.value;
    this.loadStates();
  }

  changeRegister(selectedRegister:any){

    if (selectedRegister.value === "SSRS_MATRIX") {
      this.cihStatustypes = cihStatusList;

      this.cihReportForm.get('month').setValue(undefined);
      this.cihReportForm.get('year').setValue(undefined);

      this.isCihStatus = true;
      this.isMonth = false;
      this.isYear = false;
      this.cihReportForm.controls['cihStatus'].enable();
      this.cihReportForm.controls['month'].disable();
      this.cihReportForm.controls['year'].disable();
    } 
    else if (selectedRegister.value === "SSRS_MATRIX_CASH_MANAGEMENT" ||
            selectedRegister.value === "CIH_DEVIATION") {
      this.cihStatustypes = cashManagementcihStatusList;

      this.cihReportForm.get('month').setValue(undefined);
      this.cihReportForm.get('year').setValue(undefined);

      this.isCihStatus = true;
      this.isMonth = false;
      this.isYear = false;
      this.isFromDate = true;
      this.isToDate = true;
      this.cihReportForm.controls['fromDateFilter'].enable();
      this.cihReportForm.controls['toDateFilter'].enable();
      this.cihReportForm.controls['cihStatus'].enable();
      this.cihReportForm.controls['month'].disable();
      this.cihReportForm.controls['year'].disable();
    }
    else if(selectedRegister.value === "BRANCH_BALANCE_SUMMARY"){
      this.monthDropdownList = months;

      this.cihReportForm.get('fromDateFilter').setValue(undefined);
      this.cihReportForm.get('toDateFilter').setValue(undefined);
      this.cihReportForm.get('cihStatus').setValue(undefined);

      this.allYears = years;
      this.isYear = true;
      this.isMonth = true;
      this.isCihStatus = false;
      this.isFromDate = false;
      this.isToDate = false;
      this.cihReportForm.controls['month'].enable();
      this.cihReportForm.controls['year'].enable();
      this.cihReportForm.controls['cihStatus'].disable();
      this.cihReportForm.controls['fromDateFilter'].disable();
      this.cihReportForm.controls['toDateFilter'].disable();
    }
    
    else {
      this.cihReportForm.get('month').setValue(undefined);
      this.cihReportForm.get('year').setValue(undefined);
      this.cihReportForm.get('cihStatus').setValue(undefined);

      this.isCihStatus = false;
      this.isYear = false;
      this.isMonth = false;
      this.isFromDate = true;
      this.isToDate = true;
      this.cihReportForm.controls['cihStatus'].disable();
      this.cihReportForm.controls['month'].disable();
      this.cihReportForm.controls['year'].disable();
      this.cihReportForm.controls['fromDateFilter'].enable();
      this.cihReportForm.controls['toDateFilter'].enable();
    }
  }
}
