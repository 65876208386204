import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from '../../../material-lib.module';
import { AuditPlanResolveService } from './audit-plan-resolve.service';
import { AuditPlanComponent } from './audit-plan.component';
import { AuditPlanEditorComponent } from './audit-plan-editor.component';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { AuditPlanDeleteDialogComponent } from './audit-plan-delete-dialog.component';
import { AuditPlanUploadComponent } from './audit-plan-upload.component';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

export const auditPlanRoute: Route = {
    path: 'auditPlan',
    canActivate: [AuthenticatedUserService],
    children: [
        {
            path: '',
            canActivate: [AuthenticatedUserService],
            component: AuditPlanComponent,
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_PLAN_LIST'] }
        },
        {
            path: 'create',
            canActivate: [AuthenticatedUserService],
            component: AuditPlanEditorComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_AUDIT_PLAN'] }
        },
        {
            path: 'upload',
            canActivate: [AuthenticatedUserService],
            component: AuditPlanUploadComponent,
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_UPLOAD_AUDIT_PLAN'] }
        },
        {
            path: 'edit/:id',
            canActivate: [AuthenticatedUserService],
            component: AuditPlanEditorComponent,
            resolve: {
                auditPlan: AuditPlanResolveService,
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_AUDIT_PLAN'] }
        },
        {
            path: 'view/:id',
            canActivate: [AuthenticatedUserService],
            component: AuditPlanEditorComponent,
            resolve: {
                auditPlan: AuditPlanResolveService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_PLAN'] }
        },
    ]
};
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule
    ],
    declarations: [AuditPlanComponent, AuditPlanEditorComponent, AuditPlanDeleteDialogComponent, AuditPlanUploadComponent],
    entryComponents: [AuditPlanDeleteDialogComponent],
    exports: []
})
export class AuditPlanModule { }
