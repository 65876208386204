import {DownloadFormatType} from './download-format';
export class AuditReportParams {
    constructor(
        public branchCode?: string,
        public branchCodes?: [],
        public auditForMonth?: [],
        public auditForMonths?: [],
        public auditForYear?: number,
        public stateNames?: [],
        public downloadFormat?: DownloadFormatType,
        public auditId?: number,
        public fromDate?: Date,
        public toDate?: Date,
        public questionnaireVersionCode?: string,
        public weightageType?: string
    ) {}
}
