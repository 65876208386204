<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToCenterListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
                
        <mat-card-title *ngIf="!editable" class="button-icon-text">View centre</mat-card-title>
        <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create centre</mat-card-title>
        <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit centre</mat-card-title>        
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="centerEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap"fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row" >
                
                    
                        <input name="id" type="hidden" formControlName="id" />
                        <input name="version" type="hidden" formControlName="version" />
                        <input name="centreId" type="hidden" formControlName="centreId"/>
                        <mat-form-field>
                            <input name="name" matInput type="text"
                                   formControlName="name" placeholder="Name" />
                            <mat-error align="start" *ngIf="formErrors.name">
                                {{ formErrors.name }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field >
                            <input name="code" matInput type="text"
                                   formControlName="code" placeholder="Code" />
                            <mat-error align="start" *ngIf="formErrors.code">
                                {{ formErrors.code }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-select placeholder="Select Branch" formControlName="branchNameCode" [disabled]="isBranchCode">
                                <mat-option *ngFor="let branch of branches" [value]="branch.code">{{branch.branchNameCode}}</mat-option>           
                            </mat-select>
                            <mat-error align="start" *ngIf="formErrors.branchNameCode">{{ formErrors.branchNameCode }}</mat-error>
                          </mat-form-field>

                        <mat-form-field >
                            <input name="centerStatus" matInput type="text"
                                   formControlName="centerStatus" placeholder="Status">
                        </mat-form-field>
                          
                <mat-card-actions *ngIf="editable" class="pull-right" >
                    <button mat-raised-button color="primary" [disabled]="isBranchCode">Submit</button>
                    <button mat-raised-button (click)="goToCenterListPage()">Cancel</button>
                </mat-card-actions>
            </div>

        </form>
    </mat-card-content>
</mat-card>
