import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportService } from '../reports/report.service';
import { BaseChartDirective } from 'ng2-charts';
import { BmtReportModel } from './bmt-report.model';
import { Branch } from 'src/app/admin/branch/branch.model';

/**
 * This method is used to load the months
 * @author Anil P
 * @since v2.8.0
 */
@Component({
  selector: 'app-bar-chart-report',
  templateUrl: './bmt-productivity-report-bar-chart.component.html',
  styleUrls: ['./bmt-productivity-report.component.css'],
})
export class BmtProductivityReportBarChartComponent implements OnInit, OnDestroy {
  show = false;
  showProductivity = false;
  showActivityesCount = true;
  month: any;

  @Input() selectedBranches: Branch[];
  @Input() selectedStates: any[];
  @Input() bmtRoles: any[];
  @Input() bmtYear: any[];
  @Input() selectedBmtActivities: any[];
  @Input('bmtDashboardDataElement') bmtProductivityData: BmtReportModel[];
  @Input('bmtDashboardMonthSelectedElement') monthSelected: any[];
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  public barChartProductivityData = [];
  public barChartData = [];
  public lineChartData = [];
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public showProductivityReport = false;
  public bmtAllCountsArray: any[] = [];
  public barChartActivityOptions = {
    scaleShowVerticalLines: true,
    scaleShowHorizontalLines: true,
    maintainAspectRatio: false,
    events: ['click', 'mousemove', 'touchstart'],
    tooltips: { enabled: true },
    responsiveAnimationDuration: 5000,
    animation: {},
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: 0,
            suggestedMax: 50,
            stepSize: 10,
          },
        },
      ],
    },
  };

  constructor(private http: HttpClient, private _emp: ReportService) {}

  ngOnInit() {
    this.monthSelected.forEach((i) => {
      this.barChartLabels.push(i);
    });
    this.barChartProductivityData = this.bmtProductivityData;
    this.barChartData = this.bmtProductivityData;
    this.lineChartData = this.bmtProductivityData;

    this.barChartData.forEach((i) => {
      i.data = i.productivityCount;
      i.productivityCount.forEach((count) => {
        this.bmtAllCountsArray.push(count);
      });
    });
    let maxBmtCountYaxis: number = Math.max(...this.bmtAllCountsArray);
    const minBmtCountYaxis: number = Math.min(...this.bmtAllCountsArray);
    let stepCountYaxis: number = Math.round((maxBmtCountYaxis + minBmtCountYaxis) / 10);
    stepCountYaxis = stepCountYaxis < 1 ? 2 : stepCountYaxis;
    maxBmtCountYaxis = maxBmtCountYaxis < 2 ? 10 : maxBmtCountYaxis;
    const suggestedMax = maxBmtCountYaxis + stepCountYaxis;
    this.barChartActivityOptions.scales.yAxes = [
      {
        ticks: {
          beginAtZero: 0,
          suggestedMax: suggestedMax > 0 ? suggestedMax : 100,
          stepSize: stepCountYaxis > 0 ? stepCountYaxis : 10,
        },
      },
    ];
  }

  ngOnDestroy(): void {}
  /**
   * This method is called on the Toggle button change.
   * @author J Suhas barChartType
   * @since v2.8.2
   */
  onBmtReportTypeChange(value): void {
    this.barChartData = [];

    let maxBmtCountYaxis: number = Math.max(...this.bmtAllCountsArray);
    const minBmtCountYaxis: number = Math.min(...this.bmtAllCountsArray);
    maxBmtCountYaxis = maxBmtCountYaxis < 2 ? 10 : maxBmtCountYaxis;
    let stepCountYaxis: number = Math.round((maxBmtCountYaxis + minBmtCountYaxis) / 10);
    stepCountYaxis = stepCountYaxis < 1 ? 2 : stepCountYaxis;
    let suggestedMax = maxBmtCountYaxis + stepCountYaxis;

    this.barChartProductivityData.forEach((i) => {
      if (value.value === '1') {
        i.data = i.productivityCount.length > 0 ? i.productivityCount : [0];
      } else {
        i.data = i.productivity.length > 0 ? i.productivity : [0];
      }
      this.barChartData.push(i);
    });

    if (value.value === '2') {
      this.barChartData = this.barChartData.filter((i) => i.label !== 'PAN India');
      if (this.barChartData.length === 0) {
        this.barChartData.push({ label: '', data: [] });
      } // end if
      stepCountYaxis = 0;
      suggestedMax = 0;
      this.showProductivityReport = true;
    } else {
      this.showProductivityReport = false;
    }

    this.barChartActivityOptions.scales = {
      yAxes: [
        {
          ticks: {
            beginAtZero: 0,
            suggestedMax: suggestedMax > 0 ? suggestedMax : 100,
            stepSize: stepCountYaxis > 0 ? stepCountYaxis : 10,
          },
        },
      ],
    };
  }
  /**
   * This method is used to handle the report chart type.
   * @author J Suhas bhat
   * @since v2.8.2
   */
  onBmtReportChartTypeChange(value): void {
    if (value.value === '1') {
      this.barChartType = 'bar';
      this.chart.chartType = <any>this.barChartType;
    } else {
      this.barChartType = 'line';
      this.chart.chartType = <any>this.barChartType;
    }
  }

  /**
   * this method is used to get data after click on individual statename.
   */
  public chartClicked(element: any): void {
    let clickedBarMonthLabel = '';

    if (element.active.length > 0) {
      const chart = element.active[0]._chart;
      const activePoints = chart.getElementAtEvent(element.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const labelValue = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        clickedBarMonthLabel = labelValue;
      } // end if

      const points = [];
      const pointSelected = chart.tooltip._model.caretY;
      const legends = chart.legend.legendItems;

      for (let i = 0; i < element.active.length; ++i) {
        points.push(element.active[i]._model.y);
      }

      const position = points.indexOf(pointSelected);
      const label = legends[position].text;
      this._emp.bmtTablesSet(label, clickedBarMonthLabel);
      this.show = true;
    }
  }
}
