<table mat-table  [dataSource]="dataSource" class="mat-elevation-z8">


  <ng-container  matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef> User </th>
    <td mat-cell *matCellDef="let element"> {{element.user}} </td>
  </ng-container>

  <ng-container  matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> {{element.action}} </td>
  </ng-container>

  <ng-container  matColumnDef="remarks">
    <th mat-header-cell *matHeaderCellDef> Remarks </th>
    <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
  </ng-container>

  <ng-container matColumnDef="actionDate">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element.actionDate | date:'MMM d y h:mm a'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>