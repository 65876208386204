import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IgFormService} from '../../../../projects/ig-core/src/lib/form/form.service';
import {FormErrorModel} from '../../../../projects/ig-core/src/lib/form/form-error.model';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {MoratoriumService} from './moratorium.service';
import {Branch} from '../../admin/branch/branch.model';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'app-moratorium-consent',
    templateUrl: './moratorium-consent.html'
})

export class MoratoriumConsentComponent implements OnInit, OnDestroy {

    branchName: string;
    showOnlyMappedBranches = true;
    centreId: string;
    dropdownList: any[];
    stateDropdownList: any[];
    centreDropdownList: any[];
    selectedItems = [];
    dropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: false
    };
    stateDropdownSettings = {
        singleSelection: true,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: false,

    };
    csntreDropdownSettings = {
        singleSelection: true,
        idField: 'centreId',
        textField: 'centreId',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    selectedBranches: any[];
    branches: Branch[];
    states: any;
    selectedStates: any[];
    selectedCentre: any;
    branchCodes: any[];
    code: string;
    branchSelectIndicator = new FormControl();
    dataSource: any;
    stateList: string[];
    stateHint = false;
    branchHint = false;
    centreHint = false;
    public moratoriumConsentReportForm: FormGroup;
    @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;
    public formError: FormErrorModel;
    public formErrors = {
        stateName: '',
        name: '',
        selectedStates: '',
        selectedBranches: '',
        selectedCentres: '',
        code: '',
    };
    businessLineId: number;
    constructor(private moratoriumService: MoratoriumService,
                private igFormService: IgFormService, private formBuilder: FormBuilder,
                private snackBar: MatSnackBar) {
    }
    ngOnInit() {
        this.loadStates();
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
    }
    ngOnDestroy(): void {
    }

    onSelectState(state: any) {
        this.stateHint = false;
        this.selectedBranches = undefined;
        this.moratoriumConsentReportForm.get('name').reset();
        this.loadBranches();
    }
  
    onSelectAll(event){}

    onSelectBranch(branch: any) {
        this.branchHint = false;
        this.loadCentre(branch.id);
    }

    onSelectCentre(centre: any) {
        this.centreHint = false;
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        this.loadBranches();
    }

    onDeSelectState(state: any) {
        this.selectedBranches = [];
        this.loadBranches();
        this.moratoriumConsentReportForm.get('name').reset();
        this.moratoriumConsentReportForm.get('selectedCentres').reset();
        this.stateHint = true;
    }

    onDeSelectBranch(branch: any) {
        this.moratoriumConsentReportForm.get('selectedCentres').reset();
        this.branchHint = true;
        this.loadCentre('10000');
    }

    onDeSelectCentre(centre: any) {
        this.centreHint = true;
    }
    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.moratoriumConsentReportForm);
        if (this.moratoriumConsentReportForm.valid) {
            this.download();
        } else {
            this.formErrors = this.igFormService.validateForm(this.moratoriumConsentReportForm, this.formErrors, false);
            this.setFormErrorValue();
            return false;
        }
    }

    buildForm() {
        this.moratoriumConsentReportForm = this.formBuilder.group({
            stateName: [this.selectedStates, Validators.required],
            name: [this.branches, Validators.required],
            code: [this.code],
            selectedCentres: [this.selectedCentre , Validators.required]
        });
        this.moratoriumConsentReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.moratoriumConsentReportForm, this.formErrors, true);
        });
    }

    setFormErrorValue() {
        if (this.formErrors.stateName === 'This field is required') {
            this.stateHint = true;
        }
        if (this.formErrors.name === 'This field is required') {
            this.branchHint = true;
        }
        if (this.formErrors.selectedCentres === 'This field is required') {
            this.centreHint = true;
        }
    }

    loadBranches() {
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.moratoriumService.getAllBranchesForStates(this.businessLineId,this.stateList, this.showOnlyMappedBranches)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(

                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                    
                });

    }
    loadStates() {
        this.moratoriumService.getAllStatesForUser(this.businessLineId).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                    
                }
            );
    }

    loadCentre(branchId: any) {
        this.moratoriumService.getAllCentreForBranch(branchId).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.centreDropdownList = res.body;
                    
                });

    }

    download() {
        this.moratoriumConsentReportForm.get('name').value.forEach(i => {
            this.branchName = i.name;
        });
        this.moratoriumConsentReportForm.get('selectedCentres').value.forEach(i => {
            this.centreId = i.centreId;
        });
        this.moratoriumService.downloadConsentForm({
            branchName: this.branchName,
            centreId: this.centreId,
        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
            this.onError();
        }, () => {
            console.log('Completed file download.');
        });
    }
    onError() {
        this.snackBar.open(`Error Downloading Excel: (No customers found)`, 'Close', {
            duration: 3000,
        });
    }

    clearFilter() {
        this.stateSelect = undefined;
        this.selectedBranches = undefined;
        this.selectedStates = [];
        this.states = undefined;
        this.code = undefined;
        this.loadBranches();
    }

    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectBranch(item: any) {

    }
    onItemDeSelectAll(item: any) {
        this.selectedStates = [];
        this.loadBranches();

    }
}
