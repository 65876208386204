<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToTemplateListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="!editable" class="button-icon-text">View Template</mat-card-title>
        <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create Template</mat-card-title>
        <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit Template</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <form [formGroup]="activityConfigurationEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                class="two-per-row">

                <input name="id" type="hidden" formControlName="id" />
                <input name="version" type="hidden" formControlName="version" />

                <mat-form-field>
                    <input name="name" matInput type="text" formControlName="name" placeholder="Template Name" />
                    <mat-error align="start" *ngIf="formErrors.name">
                        {{ formErrors.name }}
                    </mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="code" matInput type="text" formControlName="code" placeholder="Template Code" />
                    <mat-error align="start" *ngIf="formErrors.code">
                        {{ formErrors.code }}
                    </mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <mat-select placeholder="Business Line" formControlName="businessLineId"
                        (selectionChange)='onOptionsSelected($event)'>
                        <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                            {{businessLine.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start"> {{ formErrors.businessLineId }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <mat-select placeholder="Role" name="roleCode" formControlName="roleCode">
                        <mat-option *ngFor="let role of roles" [value]="role">
                            {{role}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.roleCode">{{ formErrors.roleCode }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input matInput name="effectiveDate" formControlName="effectiveDate" [min]="minDate"
                        (focus)="tentativeFromDatePicker.open()" (click)="tentativeFromDatePicker.open()"
                        [matDatepicker]="tentativeFromDatePicker" placeholder="From Date" disabled/>
                    <mat-datepicker-toggle matSuffix [for]="tentativeFromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #tentativeFromDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.effectiveDate">{{ formErrors.effectiveDate }}</mat-error>
                </mat-form-field>
                &nbsp;
                <!-- <mat-form-field>
                    <input matInput name="toDate" formControlName="toDate"
                        [min]="activityConfigurationEditorForm.get('effectiveDate').value"
                        (focus)="tentativeToDatePicker.open()" (click)="tentativeToDatePicker.open()"
                        [matDatepicker]="tentativeToDatePicker" placeholder="To Date" />
                    <mat-datepicker-toggle matSuffix [for]="tentativeToDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #tentativeToDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.toDate">{{ formErrors.toDate }}</mat-error>
                </mat-form-field> -->

                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="primary">Submit</button>
                    <button mat-raised-button (click)="goToTemplateListPage()">Cancel</button>
                </mat-card-actions>
            </div>

            <div *ngIf="!editable" fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px"
                fxLayoutAlign="start">
                <mat-card-header class="page-title">
                    <mat-card-title class="button-icon-text">
                        Mapped
                    </mat-card-title>
                </mat-card-header>
                <div *ngIf="!editable" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start"
                    class="two-per-row" style="width: 100%; display: flex">
                    <mat-card-actions>
                        <div style="width: 240px;margin-right: 3%;float: left;" >
                            <ng-multiselect-dropdown [placeholder]="'List Of User'" [data]="userDropdownList"
                                [(ngModel)]="selectedUsers" [settings]="userDropdownSettings"
                                (onSelect)="onSelectUser($event)" (onSelectAll)="onSelectAllUser($event)"
                                (onDeSelect)="onItemDeSelectUser($event)"
                                (onDeSelectAll)="onItemDeSelectAllUser($event)" [ngModelOptions]="{standalone: true}"  disabled="true">
                            </ng-multiselect-dropdown>
                        </div>

                        <!-- <div style="width: 240px;margin-right: 3%;float: left;">
                            <ng-multiselect-dropdown [placeholder]="'List Of Role'" [data]="roleDropdownList"
                                [(ngModel)]="selectedRoles" [settings]="roleDropdownSettings"
                                (onSelect)="onSelectRole($event)" (onSelectAll)="onSelectAllRole($event)"
                                (onDeSelect)="onItemDeSelectRole($event)"
                                (onDeSelectAll)="onItemDeSelectAllRole($event)" [ngModelOptions]="{standalone: true}">
                            </ng-multiselect-dropdown>
                        </div> -->
                    </mat-card-actions>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                <mat-card-header class="page-title">
                    <mat-card-title class="button-icon-text">
                        Add Activity
                    </mat-card-title>
                </mat-card-header>
            </div>
            <div class="page-title-action">
                <mat-card-actions class="pull-left" *ngIf="editable">
                    <button mat-button mat-raised-button color="primary" matTooltip="Mandatory Activity"
                        (click)="onAddRow('')">
                        <mat-icon>add_circle</mat-icon>
                        Add Activity Daily
                    </button>
                </mat-card-actions>
                <div *ngIf="showError" style="color:red"> Add Activity Limit Is Over</div>
            </div>
            <br>

            <div formArrayName="templateTaskMappingDTOs" *ngFor="let field of fields,let i=index">
                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                    class="five-per-row" [formGroupName]="i">
                    <mat-form-field>
                        <mat-select placeholder="Activity Name" name="activity" formControlName="activity">
                            <mat-option *ngFor="let activity of activityList" [value]="activity.name">
                                {{activity.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.activity">{{ formErrors.activity }}</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>Select an option</mat-label>
                        <mat-select placeholder="Activity" name="moduleName" formControlName="moduleName"
                            (selectionChange)="checkValidation($event, i, 'D')">
                            <mat-option *ngFor="let activityn of activitys" [value]="activityn.name"
                                [disabled]="activityn.isDisable">
                                {{activityn.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.moduleName">{{ formErrors.moduleName }}</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <input name="count" matInput type="number" min="0" onkeypress="return event.charCode >= 48"
                            formControlName="count" placeholder="Daily Count" />
                        <mat-error align="start" *ngIf="formErrors.count">
                            {{ formErrors.activity }}
                        </mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <input name="month" matInput type="number" min="0" onkeypress="return event.charCode >= 48"
                            formControlName="month" placeholder="Month Count" />
                        <mat-error align="start" *ngIf="formErrors.month">
                            {{ formErrors.month }}
                        </mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-button-toggle-group *ngIf="editable">
                        <mat-button-toggle mat-mini-fab color="warn" (click)="removeActivity(i, 'D')" title="Delete">
                            <mat-icon>delete forever</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>

            <!-- monthly activity -->
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                <mat-card-header class="page-title">
                    <mat-card-title class="button-icon-text">
                        Add Activity
                    </mat-card-title>
                </mat-card-header>
            </div>
            <div class="page-title-action">
                <mat-card-actions class="pull-left" *ngIf="editable">
                    <button mat-button mat-raised-button color="primary" matTooltip="Mandatory Activity"
                        (click)="onAddMonthRow('')">
                        <mat-icon>add_circle</mat-icon>
                        Add Activity Monthly
                    </button>
                </mat-card-actions>
                <div *ngIf="showError" style="color:red"> Add Activity Limit Is Over</div>
            </div>
            <br>


            <div formArrayName="monthTemplateDTOs" *ngFor="let monthField of monthFields,let i=index">
                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                    class="five-per-row" [formGroupName]="i">
                    <mat-form-field>
                        <mat-select placeholder="Activity Name" name="activity" formControlName="activity">
                            <mat-option *ngFor="let activityMonth of activityMonthList" [value]="activityMonth.name">
                                {{activityMonth.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.activity">{{ formErrors.activity }}</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>Select an option</mat-label>
                        <mat-select placeholder="Activity" name="moduleName" formControlName="moduleName"
                            (selectionChange)="checkValidation($event, i, 'M')">
                            <mat-option *ngFor="let activityn of activitys" [value]="activityn.name"
                                [disabled]="activityn.isDisable">
                                {{activityn.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.moduleName">{{ formErrors.moduleName }}</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field style="width: 35rem;" (click)="multiDateSelect.open()">
                        <mat-chip-list formControlName="initiatedDate" #chipList aria-label="placeholder"
                            [selectable]="false">
                            <mat-chip *ngFor="let item of monthTemplateDTOs.at(i).get('initiatedDate').value" removable
                                (removed)="remove(item, i)">
                                {{ item | date : 'mediumDate' }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input [disabled]="!editable" #fromInput [matChipInputFor]="chipList"
                                placeholder="Select Multi Date*" style="color:white;" matInput name="effectiveDate"
                                [min]="activityConfigurationEditorForm.get('effectiveDate').value"
                                [max]="getLastDateOfMonth()" (focus)="multiDateSelect.open()"
                                [matDatepicker]="multiDateSelect" (dateInput)="dateChange($event, i)" />
                            <mat-datepicker (selectedChanged)="dateChange($event, i)" #multiDateSelect></mat-datepicker>
                        </mat-chip-list>
                        <mat-error>{{formErrors.initiatedDate}}</mat-error>
                    </mat-form-field>
                    <mat-button-toggle-group *ngIf="editable">
                        <mat-button-toggle mat-mini-fab color="warn" (click)="removeMonthly(i, 'M')" title="Delete">
                            <mat-icon>delete forever</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>