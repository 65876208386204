<mat-drawer-container class="full-height">
    <mat-drawer-content>
        <mat-card-header class="page-title">
            <div mat-card-avatar class="button-icon">
                <button mat-raised-button (click)="goToAuditTransactionPage()">
                    <mat-icon>keyboard_backspace</mat-icon>
                </button>
            </div>
            <mat-card-title class="button-icon-text">Branch Grade</mat-card-title>
        </mat-card-header>
        <mat-card class="margin-7 dull-background">
            <div>
                <div >BranchCode: {{branchGrade.branchCode}} BranchName: {{branchGrade.branchName}}
                    Audit Number: {{branchGrade.auditNo}}
                </div>
                <div>Possible Marks of Branch: {{branchGrade.branchTotalPossibleMarks}} Obtained Marks of Branch: {{branchGrade.branchTotalObtainedMarks}}</div>

                <div *ngFor="let centreScore of branchGrade.overallScores">
                    Centre: {{centreScore.centreCode}} Possible Marks: {{centreScore.totalPossibleMarks}} Obtained
                    Marks:{{centreScore.totalObtainedMarks}}
                    Percentage: {{centreScore.totalWeightagePercentage}}
                </div>

                <p>Overall Possible Marks of Checklist: {{branchGrade.overallChecklistPossibleMarks}}</p>
                <p>Overall Obtained Marks of Checklist: {{branchGrade.overallChecklistObtainedMarks}}</p>
                <p>Overall Weightage Percentage of Checklist: {{branchGrade.branchTotalWeightagePercentage}}</p>
                <p>Grade: {{branchGrade.currentGrade}}</p>
            </div>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>