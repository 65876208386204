import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { QuestionnaireConfigurationService } from './questionnaire-configuration.service';
import { QuestionnaireConfiguration } from './questionnaire-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class QuestionnaireConfigurationResolverService implements Resolve<QuestionnaireConfiguration> {
    constructor(private questionnaireConfigurationService: QuestionnaireConfigurationService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QuestionnaireConfiguration> | Observable<never> {
        return this.questionnaireConfigurationService.getQuestionnaireConfig(+route.paramMap.get('id'));
    }
}
