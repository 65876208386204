import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Log} from './log.model';
import {LogManagementService} from './log-management.service';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {LogManagementComponent} from './log-management.component';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'ig-change-log-level-bottom-sheet',
  templateUrl: 'change-log-level-bottom-sheet.html',
})
export class ChangeLogLevelBottomSheetComponent implements OnDestroy {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private bottomSheetRef: MatBottomSheetRef<LogManagementComponent>,
              private logManagementService: LogManagementService,
              private router: Router) {}

  logLevels: string[] = ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR', 'OFF'];

  changeLevel(name: string, level: string) {
    const log = new Log(name, level);
     this.logManagementService.changeLevel(log).pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
       this.logManagementService.dataUpdated();
       this.bottomSheetRef.dismiss();
     });
  }

  ngOnDestroy(): void {
  }
}
