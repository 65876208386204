import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BranchOrganisationMapping, BranchScorecardMapping, ModuleScorecardMapping, QuestionaireScorecard, QuestionaireScorecardMapping, ScoreSet, SubmoduleScorecardMapping } from './score-card.model';
import { Branch } from 'src/app/admin/branch/branch.model';
import { Question } from '../question/question.model';


@Injectable({
    providedIn: 'root'
})
export class ScoreCardService {
    downloadScorecardDetailReport(scorecardId: number) {
        throw new Error('Method not implemented.');
    }

    private API = 'api/score-card-templates';


    private API_MODULE = 'api/module-scorecard-mappings';


    private API_SUBMODULE = 'api/submodule-scorecard-mappings';

    private API_QUESTIONNAIRE = 'api/questionnaire-scorecard-mappings';

    private API_BRANCH = 'api/branch-scorecard-mappings';

    constructor(private http: HttpClient) { }

    findScoreSets(req: any): Observable<HttpResponse<ScoreSet[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.code) {
            params = params.set('code', req.code);
        }
        if (req.description) {
            params = params.set('description', req.description);
        }
        return this.http.get<ScoreSet[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveScoreSet(scoreSet: ScoreSet): Observable<HttpResponse<ScoreSet>> {
        const copy: ScoreSet = Object.assign({}, scoreSet);
        if (copy.id && copy.id != null) {
            return this.http.put<ScoreSet>(this.API, copy, { observe: 'response' });
        } else {
            return this.http.post<ScoreSet>(this.API, copy, { observe: 'response' });
        }
    }

    scorecardActive(id: number) {
        const requrl = 'api/score-card-templates/activate/';
        return this.http.put(requrl + id, null, { observe: 'response', responseType: 'text' as 'json' });
    }

    scorecardClone(id: number): Observable<any> {
        const reqURl = 'api/score-card-templates/clone/';
        return this.http.post<any>(reqURl + id, null, { observe: 'response', responseType: 'text' as 'json' });
    }

    getScoreSet(id: string) {
        return this.http.get<ScoreSet>(this.API + '/' + id);
    }

    deleteScoreSet(id: string) {
        return this.http.delete(this.API + '/' + id);
    }

    getAllScoreSets(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.API, { observe: 'response' });

    }

    deleteScoreCardData(id: string) {
        return this.http.delete(this.API + '/' + id);
    }


    getModuleScorecardMapping(id: number) {
        return this.http.get<ModuleScorecardMapping>(this.API_MODULE + '/' + id);
    }

    getModuleScorecardMappingById(id: string) {
        return this.http.get<ModuleScorecardMapping>(this.API_MODULE + '-by' + '/' + id);
    }

    deleteModuleScorecardMapping(id: number) {
        return this.http.delete(this.API_MODULE + '/' + id);
    }

    saveModuleScorecardMapping(moduleScorecardMappings: ModuleScorecardMapping[]): Observable<HttpResponse<ModuleScorecardMapping[]>> {
        const copy: ModuleScorecardMapping[] = Object.assign({}, moduleScorecardMappings);
        return this.http.put<ModuleScorecardMapping[]>(this.API_MODULE + '-list', moduleScorecardMappings, { observe: 'response' });
    }

    saveSubmoduleScorecardMapping(submoduleScorecardMappings: SubmoduleScorecardMapping[]): Observable<HttpResponse<SubmoduleScorecardMapping[]>> {
        const copy: SubmoduleScorecardMapping[] = Object.assign({}, submoduleScorecardMappings);
        return this.http.put<SubmoduleScorecardMapping[]>(this.API_SUBMODULE + '-list', submoduleScorecardMappings, { observe: 'response' });
    }

    getAllModuleScorecardMappings(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.API_MODULE, { observe: 'response' });

    }

    getSubmoduleScorecardMapping(id: number) {
        return this.http.get<SubmoduleScorecardMapping>(this.API_SUBMODULE + '/' + id);
    }

    getSubmoduleScorecardMappingById(id: string) {
        return this.http.get<SubmoduleScorecardMapping>(this.API_SUBMODULE + '-by' + '/' + id);
    }

    getQuestionnaireScorecardMappingById(id: string) {
        return this.http.get<QuestionaireScorecardMapping>(this.API_QUESTIONNAIRE + '-by' + '/' + id);
    }

    getBranchScorecardMappingById(id: string) {
        return this.http.get<SubmoduleScorecardMapping>(this.API_BRANCH + '-by' + '/' + id);
    }


    getQuestionScorecardMappingById(id: string) {
        return this.http.get<any>('api/getAll/questionsForScrprecardMapping/' + id);
    }


    deleteSubmoduleScorecardMapping(id: number) {
        return this.http.delete(this.API_SUBMODULE + '/' + id);
    }

    getAllSubmoduleScorecardMappings(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.API_SUBMODULE, { observe: 'response' });

    }

    getAllBranchesForStates(hierarchy: string, parentId: any[]): Observable<HttpResponse<BranchOrganisationMapping[]>> {
        let params = new HttpParams();
        let parentIds = parentId.toString();
        params = params.set('hierarchy', hierarchy);
        params = params.append('parentId', parentIds);
        return this.http.get<BranchOrganisationMapping[]>('api/organisation-mappings/hierarchy', { params: params, observe: 'response' });
    }

    getSubmoduleScorecardMappingListByIdAndModule(scoreCardId: any, module: any[]): Observable<HttpResponse<SubmoduleScorecardMapping[]>> {
        let params = new HttpParams();
        let modules = module.toString();
        params = params.set('scoreCardId', scoreCardId);
        params = params.set('module', modules);
        return this.http.get<SubmoduleScorecardMapping[]>('api/submodule-scorecard-mappings/module', { params: params, observe: 'response' });
    }

    getQuestionairesForSubmodule(submodule: any[]): Observable<HttpResponse<QuestionaireScorecard[]>> {
        let params = new HttpParams();
        let submodules = submodule.toString();
        params = params.set('submodule', submodules);
        return this.http.get<QuestionaireScorecard[]>('api/questionaires/scorecard/submodule', { params: params, observe: 'response' });
    }

    saveQuestionnaireScorecardMappingList(questionaireScorecardMapping: QuestionaireScorecardMapping[]): Observable<HttpResponse<QuestionaireScorecardMapping[]>> {
        const copy: QuestionaireScorecardMapping[] = Object.assign({}, questionaireScorecardMapping);
        return this.http.put<QuestionaireScorecardMapping[]>('api/questionnaire-scorecard-mappings-list', questionaireScorecardMapping, { observe: 'response' });
    }

    saveAndUpdateQuestionnaireScorecard(questionaireScorecardMapping: QuestionaireScorecardMapping): Observable<HttpResponse<QuestionaireScorecardMapping>> {
        const copy: QuestionaireScorecardMapping = Object.assign({}, questionaireScorecardMapping);
        return this.http.put<QuestionaireScorecardMapping>('api/questionnaire-scorecard-mappings-update', questionaireScorecardMapping, { observe: 'response' });
    }

    getQuestionaireScorecardMapping(id: string) {
        return this.http.get<SubmoduleScorecardMapping>('api/questionnaire-scorecard-mappings/' + id);
    }


    deleteQuestionaireScorecardMapping(id: number) {
        return this.http.delete('/questionnaire-scorecard-mappings/' + id);
    }

    getAllQuestionaireScorecardMappings(): Observable<HttpResponse<any>> {
        return this.http.get<any>('/questionnaire-scorecard-mappings/', { observe: 'response' });

    }

    saveBranchScorecardMapping(branchScorecardMapping: any): Observable<HttpResponse<BranchScorecardMapping>> {
        return this.http.put<BranchScorecardMapping>(this.API_BRANCH, branchScorecardMapping, { observe: 'response' });
    }

    deleteBySubmoduleScoreId(scorecardId: any, submoduleScoreId: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('scorecardId', scorecardId);
        params = params.append('submoduleScoreId', submoduleScoreId);
        return this.http.get<any>('api/delete-bysubmodule-scoreid', { params: params, observe: 'response' });
    }

}

