import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {MaterialLibModule} from '../../material-lib.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Route, RouterModule} from '@angular/router';
import {NonEditableResolveService} from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import {EditableResolveService} from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import {QuestionModule} from '../question/question.module';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ActivityRoleListComponent} from './activity-role-list/activity-role-list.component';
import {ActivityRoleEditComponent} from './activity-role-edit/activity-role-edit.component';
import {ActivityRoleResolverService} from './activity-role.resolve';
import {ActivityRoleDeleteDialogComponent} from './activity-role-list/activity-role-delete-dialog.component';
import {ActivityTemplateMappingComponent} from './activity-role-edit/activity-template-mapping.component';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { DatePipe } from '@angular/common';

export const activityRoleRoute: Route = {
    path: 'activity-role',
    canActivate: [AuthenticatedUserService],
    children: [
        {
            path: '', 
            canActivate: [AuthenticatedUserService],
            component: ActivityRoleListComponent,
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_LIST_ACTIVITYCONFIG'] }
        },
        {
            path: 'create', 
            canActivate: [AuthenticatedUserService],
            component: ActivityRoleEditComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_ACTIVITYCONFIG'] }
        },
        {
            path: 'edit/:id', 
            canActivate: [AuthenticatedUserService],
            component: ActivityRoleEditComponent,
            resolve: {
                activityRole: ActivityRoleResolverService,
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_ACTIVITYCONFIG'] }
        },
        {
            path: 'view/:id', 
            canActivate: [AuthenticatedUserService],
            component: ActivityRoleEditComponent,
            resolve: {
                activityRole: ActivityRoleResolverService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_ACTIVITYCONFIG'] }
        },
        {
            path: 'mapping-template', 
            canActivate: [AuthenticatedUserService],
            component: ActivityTemplateMappingComponent,
            resolve: {
                editable: EditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_ACTIVITYCONFIG'] }
        }
    ]
};

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        QuestionModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [ActivityRoleListComponent, ActivityRoleEditComponent, ActivityRoleDeleteDialogComponent,
        ActivityTemplateMappingComponent],
    entryComponents: [ActivityRoleDeleteDialogComponent],
    exports: [RouterModule, ReactiveFormsModule],
    providers:[DatePipe]
})
export class ActivityRoleModule {
}
