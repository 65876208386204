<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>settings_input_component</mat-icon>
    </div>
    <mat-card-title>
      Server Configuration Properties
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Configuration Properties
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="configurationDataSource">

            <!-- ID Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name</th>
              <td mat-cell *matCellDef="let row"> {{row.name | titlecase}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="level">
              <th mat-header-cell *matHeaderCellDef> Properties</th>
              <td mat-cell *matCellDef="let row">
                <ngx-json-viewer [json]="row.configProperties"></ngx-json-viewer>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Environment Properties
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ngx-json-viewer [json]="allConfiguration"></ngx-json-viewer>

      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>

