<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>explore</mat-icon>
        </div>
        <div mat-card-title>Activity Tracker Report</div>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
            class="five-per-row">
            <form [formGroup]="fetchEmployeesForm">
                <div style="display: flex; flex-wrap: wrap;">
                    <mat-form-field *ngIf="isRoleAdmin">
                        <mat-select placeholder="Business Line" formControlName="businessLineId"
                            (selectionChange)="changeBusinessLine($event)">
                            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                                {{businessLine.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error align="start"> {{ formErrors.businessLineId }}</mat-error>
                    </mat-form-field>
                    &nbsp;&nbsp;

                    <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                        <ng-multiselect-dropdown formControlName="selectedStates" [placeholder]="'states'"
                            [data]="allStates" [settings]="stateDropdownSettings" [(ngModel)]="selectedStates"
                            (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                            (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)" required>
                        </ng-multiselect-dropdown>
                        <mat-error align="start" *ngIf="formErrors.selectedStates && fetchEmployeesForm.controls['stateName']?.touched ">{{ formErrors.selectedStates }}</mat-error>
                    </div>

                    <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                        <ng-multiselect-dropdown formControlName="selBranches" [placeholder]="'branches'"
                            [data]="dropdownList" [settings]="branchDropdownSettings" [(ngModel)]="selectedBranches"
                            (onSelect)="onSelectBranch($event.code)" (onSelectAll)="onSelectAllBranch($event.code)"
                            (onDeSelect)="onItemDeSelectBranch($event.code)" required (onDeSelectAll)="onItemDeSelectBranch($event)" required="">
                        </ng-multiselect-dropdown>
                        <mat-error align="start" *ngIf="formErrors.selectedBranches && fetchEmployeesForm.controls['branchName']?.touched ">{{ formErrors.selectedBranches }}</mat-error>
                    </div>
                    <mat-form-field>
                        <mat-label>Role</mat-label>
                        <mat-select #roleSelect [(ngModel)]="selectedRoles"
                            (selectionChange)="selectionChanged(roleAllSelect, selectedRoles, roles)"
                            formControlName="selectedRoles" multiple>
                            <mat-checkbox #roleAllSelect style="padding-left:15px;"
                                (change)="onAllChanged($event.checked, roles, roleSelect, 'role')">
                                Select All
                            </mat-checkbox>
                            <mat-option *ngFor="let role of roles" [value]="role">
                                {{role}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="employeeFormError.selectedRoles">{{employeeFormError.selectedRoles}}</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="userStatus" formControlName="userStatus">
                            <mat-option *ngFor="let userStatus of userStatuses" [value]="userStatus">
                                {{userStatus}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="employeeFormError.userStatus">{{employeeFormError.userStatus}}</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="fetchEmployees()"
                        [disabled]="fetchEmployeesForm.invalid">
                        Fetch Employees
                    </button>
                    &nbsp;
                    <button type="button" style="margin-right: 10px" mat-raised-button color="primary"
                        (click)="clearFilter()">
                        Clear
                    </button>
                </div>
            </form>
        </div>
        <div style="margin-top: 15px;" fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="15px" fxLayoutAlign="start"
            class="four-per-row">
            <form [formGroup]="activityTrackerForm" noValidate>
                <mat-form-field>
                    <mat-label>Employees</mat-label>
                    <mat-select #employeeSelect [(ngModel)]="selectedUsers"
                        (selectionChange)="employeeSelectionChanged(employeeAllSelect, selectedUsers, users)"
                        formControlName="selectedUsers" multiple>
                        <mat-checkbox #employeeAllSelect style="padding-left:15px;"
                            (change)="onAllChanged($event.checked, users, employeeSelect, 'employee')">
                            Select All
                        </mat-checkbox>
                        <mat-option *ngFor="let user of users" [value]="user.login">
                            {{(user.firstName === null ? "" : user.firstName + " ")
                            + (user.lastName === null ? "" : user.lastName + " ")
                            + " | " + user.login}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="activityFormError.selectedUsers">{{activityFormError.selectedUsers}}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input matInput name="fromDate" [(ngModel)]="fromDate" formControlName="fromDate" [max]="toDate"
                        (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [matDatepicker]="fromDatePicker"
                        placeholder="From Date">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error *ngIf="activityFormError.fromDate">{{activityFormError.fromDate}}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input matInput name="toDate" [(ngModel)]="toDate" formControlName="toDate" [min]="fromDate"
                        (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [matDatepicker]="toDatePicker"
                        placeholder="To Date">
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                    <mat-error *ngIf="activityFormError.toDate">{{activityFormError.toDate}}</mat-error>
                </mat-form-field>
                &nbsp;
                <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                    [disabled]="activityTrackerForm.invalid">
                    <mat-icon>search</mat-icon>
                </button>
                &nbsp;
                <button type="button" mat-raised-button color="primary" style="margin-right: 10px"
                    [disabled]="activityTrackerForm.invalid" (click)="downloadActivityReport()">
                    <mat-icon>get_app</mat-icon>
                </button>
            </form>
        </div>
        <div class="mat-elevation-z8" style="margin-top: 20px">
            <table mat-table matSort matSortActive="state" matSortDirection="asc" matSortDisableClear
                [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="stateName">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">State</th>
                    <td mat-cell *matCellDef="let element" data-label="stateName" style="width: 50px;"> {{element.stateName}} </td>
                </ng-container>
                <ng-container matColumnDef="branchName">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">Branch</th>
                    <td mat-cell *matCellDef="let element" data-label="branchName" style="width: 50px;"> {{element.branchName}} </td>
                </ng-container>
                <ng-container matColumnDef="employeeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="employeeName" style="width: 50px;">Employee Name</th>
                    <td mat-cell *matCellDef="let element" data-label="employeeName" style="width: 50px;"> {{element.employeeName === null ?
                        '' : element.employeeName}} </td>
                </ng-container>

                <ng-container matColumnDef="employeeLogin">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">Employee ID</th>
                    <td mat-cell *matCellDef="let element" data-label="employeeLogin" style="width: 50px;"> {{element.employeeLogin}} </td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef style="width: 60px;">Role</th>
                    <td mat-cell *matCellDef="let element" data-label="role" style="width: 60px;"> {{element.role}} </td>
                </ng-container>

                <ng-container matColumnDef="bmvCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 40px;">Branch Visits</th>
                    <td mat-cell *matCellDef="let element" data-label="bmvCount" style="width: 40px;"> 
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'BMQ')">
                        {{(element.bmvCount === null || element.bmvCount === '') ? 0 : element.bmvCount}} 
                    </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cmmCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">Center Meetings</th>
                    <td mat-cell *matCellDef="let element" data-label="cmmCount" style="width: 50px;"> 
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'CMQ')">
                            {{(element.cmmCount === null || element.cmmCount === '') ? 0 : element.cmmCount}} 
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cgtCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">CGTs</th>
                    <td mat-cell *matCellDef="let element" data-label="cgtCount" style="width: 50px;"> 
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'CGTQ')">
                            {{(element.cgtCount === null || element.cgtCount === '') ? 0 : element.cgtCount}}
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="grtCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">GRTs</th>
                    <td mat-cell *matCellDef="let element" data-label="grtCount" style="width: 50px;"> 
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'GRTQ')">
                        {{(element.grtCount === null || element.grtCount === '') ? 0 : element.grtCount}}
                        </a>
                     </td>
                </ng-container>

                <ng-container matColumnDef="lucCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 30px;">Customer Visit</th>
                    <td mat-cell *matCellDef="let element" data-label="lucCount" style="width: 30px;">
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'LUQ')">
                            {{(element.lucCount === null || element.lucCount === '') ? 0 : element.lucCount}} 
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nonCoreActivityCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 70px;">NCAs</th>
                    <td mat-cell *matCellDef="let element" data-label="nonCoreActivityCount" style="width: 70px;">
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'NCAs')">
                            {{(element.nonCoreActivityCount === null || element.nonCoreActivityCount === '') ? 0 :
                            element.nonCoreActivityCount}} 
                        </a>
                        </td>
                </ng-container>

                <ng-container matColumnDef="ecqCount">
                    <th mat-header-cell *matHeaderCellDef style="width: 40px;">ECQ</th>
                    <td mat-cell *matCellDef="let element" data-label="ecqCount" style="width: 40px;"> 
                        <a href="javascript:void(0)" (click)="openCountDetails(element, 'ECQ')">
                        {{(element.ecqCount === null || element.ecqCount === '') ? 0 : element.ecqCount}} 
                    </a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>