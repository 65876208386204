import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { FetchBranchesForStates, months, years } from '../../constants/data.constants';
import { ReportService } from '../reports/report.service';
import { NameValueDto } from 'projects/ig-core/src/lib/interfaces/name-value-dto';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';

/**
 * This class is for the efficient report
 * @author J Suhas Bhat
 * @since v2.8.0
 */
@Component({
  selector: 'app-efficiency-report',
  templateUrl: './efficiency-report.component.html',
  styleUrls: ['./efficiency-report.component.css']
})
export class EfficiencyReportComponent implements OnInit, OnDestroy {

  stateDropdownList: any[];
  selectedStates: any[];
  selectedBranches: any[];
  stateList: string[];
  branches: Branch[];
  dropdownList: any[];
  states: any;
  type: any;
  isRoleAdmin:boolean;
  branchCodes: any[];
  selectedMonths: any[];
  selectedYear: string;
  selectedMonthsCode: Number[];
  allYears: NameValueDto[];
  monthDropdownList = [];
  selectedItems = [];
  businessLines: any[];
  selectedBusinessLine: any;
  monthDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    enableCheckAll: false,
    itemsShowLimit: 1
  };
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  stateDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  public branchEfficiencyReportForm: FormGroup;

  @ViewChild('stateSelect',{static:true}) stateSelect: MatSelect;
  @ViewChild('stateAllSelect',{static:true}) stateAllSelect: MatCheckbox;

  @ViewChild('branchSelect',{static:true}) branchSelect: MatSelect;
  @ViewChild('branchAllSelect',{static:true}) branchAllSelect: MatCheckbox;

  public formError: FormErrorModel;
  public formErrors = {
    type: '',
    selectedStates: '',
    selectedBranches: '',
    selectedMonths: '',
    selectedYear: '',
    stateName:'',
    name:'',
    monthSelection:'',
    businessLineId: ''
  };

  efficienyReportTypes = [{ code: 'BranchEfficiency', name: 'Branch Efficiency report' },
  { code: 'CenterEfficiency', name: 'Center Efficiency report' }];
  businessLine: any;
  loggedInUserRole: any;

  constructor(private questionaireService: QuestionaireService,
    private reportService: ReportService, private igFormService: IgFormService,
    private userService: UserService,private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {  this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
          this.loggedInUserRole = data.loggedInUser.body.role;
        });
       }

  ngOnInit(): void {
    this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
      this.businessLines = businessLines;
    });
    this.monthDropdownList = months
    this.selectedItems = []
    this.loadMonths();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.branchEfficiencyReportForm.controls['businessLineId'].disable();
    if (this.loggedInUserRole ==='ROLE_ADMIN') {
        this.isRoleAdmin = true;
        this.branchEfficiencyReportForm.controls['businessLineId'].enable();
    }else{  this.loadStates();
    }
   
  }
  ngOnDestroy(): void {
  }

  onSelectState(state: any) {
    this.selectedStates.push(state);
    this.loadBranches();
  }

  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.branchEfficiencyReportForm);
    if (this.branchEfficiencyReportForm.valid) {
      this.downloadbranchEfficiencyReport();
    } else {
      this.formErrors = this.igFormService.validateForm(this.branchEfficiencyReportForm, this.formErrors, false);
      return false;
    }
  }

  buildForm() {
    this.branchEfficiencyReportForm = this.formBuilder.group({
      type: [this.type, Validators.required],
      stateName: [this.selectedStates, Validators.required],
      name: [this.branches, Validators.required],
      monthSelection: [this.selectedMonths, Validators.required],
      selectedYear: [this.selectedYear, Validators.required],
      businessLineId: ['', Validators.required]
    });
    this.branchEfficiencyReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.branchEfficiencyReportForm, this.formErrors, true);
    });
  }

  loadBranches() {
    this.selectedBranches=null;
    console.log(this.selectedStates);
    this.stateList = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.stateList.push(this.selectedStates[i].name);
    }
    this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, FetchBranchesForStates.GET_MAPPED_ONLY)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<Branch[]>) => {
          this.branches = res.body;
          this.dropdownList = this.branches;
        });
  }

  loadStates() {
    console.log("here is business Line"+this.selectedBusinessLine);
    this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.states = res.body;
          this.stateDropdownList = this.states;
        }
      );
  }
  /**
   * This method is used to download the BMT efficiency report
   * @author J Suhas Bhat
   * @since v2.8.0
   */
  downloadbranchEfficiencyReport() {
    this.branchCodes = [];
    this.selectedMonthsCode = [];

    this.selectedBranches.forEach((value) => {
      this.branchCodes.push(value.code);
    }); // end loop

    this.selectedMonths.forEach((selectedMonth) => {
      this.selectedMonthsCode.push(Number.parseInt(selectedMonth.code, 10));
    }); // end loop

    this.reportService.downloadBmtEfficiencyReport({
      type: this.type.code,
      branchCodes: this.branchCodes,
      selectedMonths: this.selectedMonths,
      selectedYear: this.selectedYear,
      businessLineId: this.businessLine
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  onItemDeSelectBranch(item: any) {
  }
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();

  }
  /**
   * This method is used to load the months
   * @author J Suhas bhat
   * @since v2.8.0
   */
  loadMonths() {
    this.allYears = years;
  }
  /**
   * This method is called when the month is selected, and the corresponding validation is done.
   * By changing the variable maxMonthSelection we can manage the maximum months that can be selected.
   * @author J Suhas Bhat
   * @since v2.8.0
   */
  onMonthSelection(selectedMonth: any): void {
    const maxMonthSelection: Number = 4;

    if (this.selectedMonths.length === 1) {
      return;
    } else {
      let previousMonthSelected = false;
      let nextMonthSelected = false;
      this.selectedMonths.forEach(individualMonth => {
        if (Number.parseInt(individualMonth.code, 10) === (Number.parseInt(selectedMonth.code, 10) + 1)) {
          nextMonthSelected = true;
        } else if (Number.parseInt(individualMonth.code, 10) === (Number.parseInt(selectedMonth.code, 10) - 1)) {
          previousMonthSelected = true;
        }
      }); // end loop

      if ((!previousMonthSelected && !nextMonthSelected) || this.selectedMonths.length === maxMonthSelection) {
        this.selectedMonths = this.selectedMonths.filter(month => Number.parseInt(month.code, 10) !==
          Number.parseInt(selectedMonth.code, 10));
      }// end if
    }
  }

  /**
   * This method is to handle the deselection of the months and do the corresponding validations
   * @author J Suhas Bhat
   * @since v2.8.0
   */
  onMonthDeselection(deSelectedMonth: any): void {
    this.selectedMonths = this.selectedMonths.filter(month => Number.parseInt(month.code, 10) <= Number.parseInt(deSelectedMonth.code, 10));
  }

  clearFilter() {
    // this.type = '';
    // this.selectedBranches = [];
    // this.selectedStates = [];
    // this.selectedMonths = [];
    // this.selectedYear = '';
    // this.selectedBusinessLine = ''
    this.branchEfficiencyReportForm.reset();
    if(this.businessLine != undefined){
      this.stateDropdownList= [];
    }
    this.dropdownList= [];
  }
  changeBusinessLine(selectedBusniessLine: any){
    this.selectedStates=null;
    this.businessLine = selectedBusniessLine.value;
    this.loadStates();
  }
}
