<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon> face</mat-icon>
        </div>
        <mat-card-title class="button-icon-text">CRM File Upload</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <p>Please upload a xlsx file.
            <button mat-raised-button small color="accent" class="pull-right" (click)="downloadTemplate()">
                Download Template
            </button>
        </p>

        <form (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                 class="two-per-row">
                <input #fileUpload id="fileUpload" type="file" (change)="onFileSelection($event)"/>
                <mat-card-actions class="pull-right">
                    <button type="submit" mat-raised-button color="primary">Update</button>
                    <button mat-raised-button (click)="fileUpload.value = null">Clear</button>
                </mat-card-actions>
            </div>
        </form>

        <div style="color: red">{{message}}</div>
    </mat-card-content>
</mat-card>
