import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';

import {BranchSet} from '../branch-set.model';
import {BranchSetService} from '../branch-set.service';


export class BranchSetListDataSource extends  DataSource<BranchSet> {

  private branchSetSubject = new BehaviorSubject<BranchSet[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private branchSetService: BranchSetService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<BranchSet[]> {
    return this.branchSetSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.branchSetSubject.complete();
    this.totalCount$.complete();
  }

  loadBranchSets(sortDirection = 'asc', pageIndex = 0, pageSize = 3, name: String, code: String, businessLineId: number) {

    this.branchSetService.findBranchSets({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      name: name,
      code: code,
      businessLineId: businessLineId,
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.branchSetSubject.next(response['body']);
    });
  }

}
