import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuditTransactionService } from './audit-transaction-service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './audit-transaction-remarks-dialog.component.html',
})

export class AuditTransactionRemarksDialogComponent implements OnDestroy {

  // dialogRef needs MatDialogRef to map the dialog
  // auditTransactionService is needed to hit api mapping
  // @Inject is used to inject the data passed in the audit-transaction-workflow component's getRemarksDialog method to this dialog
  constructor(
    public dialogRef: MatDialogRef<AuditTransactionRemarksDialogComponent>,
    public auditTransactionService: AuditTransactionService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  // On clicking Cancel
  onNoClick() {
    this.dialogRef.close(this.data);
  }
  // On clicking Save
  onSubmit(data: any) {
    // Sending the object itself as when the input changes the value of the text box the element value property is changed
    this.auditTransactionService.updateTransactionRemarks(data.element)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        // After API hits the response can be processed to show a message or dialog can be closed
        this.dialogRef.close(response);
        this.snackBar.open(`Updated Remarks Successfully`, 'Close', {
          duration: 3000,
        });
      });
  }
  ngOnDestroy(): void { }
}

export interface DialogData {
  element: any;
}
