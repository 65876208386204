import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { BranchSetListComponent } from './branch-set-list/branch-set-list.component';
import { BranchSetEditorComponent } from './branch-set-edit/branch-set-editor.component';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { BranchSetResolverService } from './branch-set.resolve';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from '../../material-lib.module';
import { BranchSetDeleteDialogComponent } from './branch-set-list/branch-set-delete-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

export const branchSetRoute: Route = {
  path: 'branch-set',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: BranchSetListComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_BRANCHSET_LIST'] }
    },
    {
      path: 'create', 
      canActivate: [AuthenticatedUserService],
      component: BranchSetEditorComponent,
      resolve: {
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_CREATE_BRANCHSET'] }
    },
    {
      path: 'update/:id',
      canActivate: [AuthenticatedUserService],
      component: BranchSetEditorComponent,
      resolve: {
        branchSet: BranchSetResolverService,
        editable: EditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_EDIT_BRANCHSET'] }
    },
    {
      path: 'view/:id', 
      canActivate: [AuthenticatedUserService],
      component: BranchSetEditorComponent,
      resolve: {
        branchSet: BranchSetResolverService,
        editable: NonEditableResolveService
      },
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_BRANCHSET'] }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  declarations: [BranchSetListComponent, BranchSetEditorComponent, BranchSetDeleteDialogComponent],
  entryComponents: [BranchSetDeleteDialogComponent]
})
export class BranchSetModule { }
