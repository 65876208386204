import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {fileNameHeader} from '../../constants/data.constants';
import {MoratoriumReportParamModel} from './moratorium-report-param.model';
import {Branch} from '../../admin/branch/branch.model';

@Injectable({
    providedIn: 'root'
})

export class MoratoriumService {
    constructor(private http: HttpClient) { }

    downloadExcel(req?: any): Observable<any> {
        const params = new MoratoriumReportParamModel();
        params.branchCodes = req.branchCodes;
        params.consentStatus = req.consentStatus;
        params.stateName = req.stateName;
        return this.http.post('api/moratorium/download/report', params,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                fileName = 'Moratorium-Report.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    downloadConsentForm(req?: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('centreId', req.centreId);
        params = params.set('branchName', req.branchName);
        return this.http.post('api/moratorium/consent-form', params,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                fileName = 'Consent-Form.pdf';
            }
            out.filename = fileName;
            return out;
        }));
    }

    getAllBranchesForStates(businessLineId:number,stateName: any, showOnlyMappedBranches: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
            params = params.set('businessLineId', businessLineId);
            }
        params = params.set('stateName', stateName);
        params = params.set('showOnlyMappedBranches', showOnlyMappedBranches);
        return this.http.get<Branch[]>('api/branches/state-search', { params: params, observe: 'response'});
    }

    getAllStatesForUser(businessLineId:number): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
            params = params.set('businessLineId', businessLineId);
            }
        return this.http.get<any>('api/branches/branchsetStates', { params: params,observe: 'response' });
    }

    getAllCentreForBranch(branchId: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        params = params.set('branch_id', branchId);
        return this.http.get<Branch[]>('api/centres/branch-code', { params: params, observe: 'response'});
    }
}
