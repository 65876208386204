<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>help_outline</mat-icon>
        </div>
        <div mat-card-title>
            Questionnaire
        </div>
        <div class="page-title-action">
            <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
                <mat-icon>add_circle</mat-icon>
                Create Questionnaire
            </button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <div>
            <mat-form-field>
                <input matInput name="name" [(ngModel)]="name" placeholder="Name">
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-select name="code" [(ngModel)]="code" placeholder="Code">
                    <mat-option *ngFor="let questionnaireCode of questionnaireCodeList" [value]="questionnaireCode.code">{{questionnaireCode.code}}</mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-select name="status" [(ngModel)]="status" placeholder="Status">
                    <mat-option *ngFor="let questionnaireStatus of questionnaireStatusList" [value]="questionnaireStatus">{{questionnaireStatus}}</mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
            <button type="button" mat-raised-button color="primary" (click)="search()">
                <mat-icon>search</mat-icon>
            </button>
            &nbsp;
            <button type="button" mat-raised-button color="primary" (click)="clearFilter()">
                Clear
            </button>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="name" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef> Code </th>
                    <td mat-cell *matCellDef="let element" data-label="QuestionaireVersionName"> {{element.code}} </td>
                </ng-container>

                <ng-container matColumnDef="questionnaireVersionCode">
                    <th mat-header-cell *matHeaderCellDef> Version Code</th>
                    <td mat-cell *matCellDef="let element" data-label="QuestionaireVersionCode"> {{element.questionnaireVersionCode}} </td>
                </ng-container>

                <ng-container matColumnDef="questionnaireVersionName">
                    <th mat-header-cell *matHeaderCellDef> Version Name </th>
                    <td mat-cell *matCellDef="let element" data-label="QuestionaireVersionName"> {{element.questionnaireVersionName}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Name </th>
                    <td mat-cell *matCellDef="let element" data-label="Name"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </th>
                    <td mat-cell *matCellDef="let element" data-label="status"> {{element.status}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="View" [routerLink]="['view', element.id]">
                            <mat-icon>more</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Edit" [routerLink]="['edit', element.id]" [disabled]="element.status === 'ACTIVE'|| element.status === 'DEPRECATED'">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Delete" (click)="deleteDialog(element)" [disabled]="element.status === 'ACTIVE'">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Clone" (click)="clone(element.id)">
                            <mat-icon>file_copy</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Copy" (click)="copyDialog(element)">
                            <mat-icon>copy_all</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Activate" (click)="questionaireActive(element.id)" [disabled]="element.status === 'ACTIVE' || element.status === 'DEPRECATED'">
                            <mat-icon>done</mat-icon>
                        </button>

                        <button mat-icon-button matTooltip="Download" (click)="downloadQuestionsDetailReport(element.id)" [disabled]="element.code !== 'CCQ' && element.code !== 'BCQ' " >
                            <mat-icon>get_app</mat-icon>
                        </button>
                    </td>
                </ng-container>              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize" [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>