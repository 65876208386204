<div class="container" fxLayout="row" fxFlexFill fxLayoutAlign="center">
  <div fxFlex.gt-sm="50">
    <mat-card class="login-card ig-card-as-panel">

      <mat-card-header class="ig-background-primary">
        <mat-card-title>Sign In</mat-card-title>
        <mat-card-subtitle>With your registered username and password</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">

          <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field>
              <input name="usernameTxt" matInput type="text" formControlName="username" placeholder="Username"
                [attr.tabindex]="0" autocomplete="off" />
              <mat-error align="start" *ngIf="formErrors.username">{{ formErrors.username }}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input name="passwordTxt" matInput type="password" formControlName="password" placeholder="Password"
                [attr.tabindex]="0" autocomplete="off"/>
              <mat-error align="start" *ngIf="formErrors.password">{{ formErrors.password }}</mat-error>
            </mat-form-field>

            <mat-slide-toggle name="rememberMe" formControlName="rememberMe">Remember me</mat-slide-toggle>
            <a mat-button [routerLink]="" (click)="openDialog()" color="warn"
              style="text-transform: uppercase;padding-right: 0px" fxFlexAlign="end" fxFlex="1 2 auto"
              [attr.tabindex]="2">Forgot Password?</a>
            <button mat-raised-button color="primary" [attr.tabindex]="0">SIGN IN</button>
            <mat-error *ngIf="formError.error"> {{formError.error | json}}</mat-error>
          </div>
        </form>
      </mat-card-content>

    </mat-card>
  </div>
</div>