import {DataSource} from '@angular/cdk/table';
import {AuditTransaction} from './audit-transaction.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize, take} from 'rxjs/operators';
import {AuditTransactionService} from './audit-transaction-service';

export class AuditTransactionDataSource extends DataSource<AuditTransaction> {


    private auditTransactionSubject = new BehaviorSubject<AuditTransaction[]>([]);

    public totalCount$ = new BehaviorSubject<number>(0);

    constructor(private auditTransactionService: AuditTransactionService) { super(); }

    connect(collectionViewer: CollectionViewer): Observable<AuditTransaction[]> {
        return this.auditTransactionSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.auditTransactionSubject.complete();
        this.totalCount$.complete();
    }

    loadAudits(sortDirection = 'asc', pageIndex = 0, pageSize = 3, branchCode: string[], status: string) {

        this.auditTransactionService.auditTransactionData({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection,
            branchCode: branchCode,
            status: status,
        }).pipe(
            catchError(() => of([])),
            finalize(() => {})
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.auditTransactionSubject.next(response['body']);
        });
    }

loadAuditTransactionsOnWorkflow(sortDirection = 'asc', pageIndex = 0,
            pageSize = 3, branchCode: string[], tentativeFromDate: string, tentativeToDate: string, status: string) {
                //Splitting the values of branch name and code
                if(branchCode) {
                if (branchCode?.length > 0) {
                    branchCode = branchCode.map(data => {
                      const splitdata = data.split(" - ");
                      return splitdata[0];
                    });
                  }
                }
        this.auditTransactionService.getAuditTransactionsOnWorkflow({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection,
            branchCode: branchCode,
            tentativeFromDate: tentativeFromDate,
            tentativeToDate: tentativeToDate,
            status: status
        }).pipe(
            catchError(() => of([])),
            finalize(() => {})
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.auditTransactionSubject.next(response['body']);
        });
    }
}
