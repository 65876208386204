import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MenuItem} from '../menu-item';
import {Router} from '@angular/router';
import {IgLayoutService} from 'src/app/ig-layout/ig-layout.service';

@Component({
  selector: 'ig-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuItemComponent {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: MenuItem;
  @Input() depth: number;
  mobileQuery: MediaQueryList;

  constructor(public router: Router,
              private igLayoutService: IgLayoutService ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
    this.mobileQuery = this.igLayoutService.getMediaQueryForMobile();
  }

  onItemSelected(item: MenuItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      if (this.mobileQuery.matches) {
        this.igLayoutService.closeSideNav();
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

}
