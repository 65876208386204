import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Role} from './role.model';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    private API = 'api/roles';

    constructor(private http: HttpClient) {}

    findRoles(req: any): Observable<HttpResponse<Role[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        return this.http.get<Role[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveRole(role: Role): Observable<HttpResponse<Role>> {
        const copy: Role = Object.assign({}, role);
        if (copy.id && copy.id != null) {
            return this.http.put<Role>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<Role>(this.API, copy, {observe: 'response'});
        }
    }

    getRole(id: number) {
        return this.http.get<Role>(this.API + '/' + id);
    }

    deleteRole(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getAuthorities() {
      return this.http.get<any>('api/authorities');
    }

    getAllRoles(): Observable<HttpResponse<Role[]>> {
        return this.http.get<Role[]>(this.API + '/search', { observe: 'response' });
    }

    getSubordinateRoles(): Observable<HttpResponse<string[]>> {
        return this.http.get<string[]>('api/users/getSubordinateRoles', { observe: 'response' });
    }
}

