import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { Branch } from '../branch.model';
import { BranchService } from '../branch.service';

@Component({
    selector: 'app-branch-editor',
    templateUrl: './branch-editor.component.html',
    styleUrls: ['../branch.component.css']
})
export class BranchEditorComponent implements OnInit, OnDestroy {
    branch: Branch;
    businessLines: any[];
    editable = true;
    isNew: boolean;

    public branchEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        'id': '',
        'version': '',
        'name': '',
        'code': '',
        'businessLineId': '',
        'latitude': '',
        'longitude': '',
    };
    constructor(private branchService: BranchService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.branch === undefined) {
                    this.branch = {}
                } else {
                    this.branch = data.branch;
                }
                this.editable = data.editable;
            });
    }

    ngOnInit() {
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.branchEditorForm.disable();
        }
        this.branchService.getAllBusinessLines().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
    }
    ngOnDestroy(): void {
    }

    buildForm() {
        this.isNew = false;

        if (this.branch.id === undefined) {
            this.isNew = true;
        }
        this.branchEditorForm = this.formBuilder.group({
            'id': [this.branch.id],
            'version': [this.branch.version],
            'name': [this.branch.name, Validators.required],
            'code': [this.branch.code, Validators.required],
            'businessLineId': [this.branch.businessLineId, Validators.required],
            'createdBy': [this.branch.createdBy, []],
            'createdDate': [this.branch.createdDate, []],
            'sourceBranchId': [this.branch.sourceBranchId],
            'sourceBranchCode': [this.branch.sourceBranchCode],
            'stateId': [this.branch.stateId],
            'regionId': [this.branch.regionId],
            'regionName': [this.branch.regionName],
            'stateName': [this.branch.stateName],  
            'latitude': [{value:this.branch.latitude ? this.branch.latitude : '', disabled:this.branch.latitude}, Validators.required],
            'longitude': [{value:this.branch.longitude ? this.branch.longitude : '', disabled:this.branch.longitude}, Validators.required],
            'branchStatus': [{ value:this.branch.branchStatus? "ACTIVE" : "INACTIVE", disabled:"true"} ,Validators.required]
        }); 

        this.branchEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.branchEditorForm, this.formErrors, true);
        });
    }
    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.branchEditorForm);
        if (this.branchEditorForm.valid) {

            this.branchEditorForm.get('latitude')?.enable();
            this.branchEditorForm.get('longitude')?.enable();
            this.branchService.saveBranch(this.branchEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.branchEditorForm, this.formErrors, false);
            return false;
        }

    }

    private onSubmitSuccess(response) {
        let msg = '';
        if (this.branch.id) {
            msg = `Updated branch ${this.branch.id} successfully`;
        } else {
            msg = `Created branch successfully`;
        }
        this.snackBar.open(msg, 'Close');
        this.goToBranchListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.branchEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    }

    goToBranchListPage() {
        if (this.branch.id) {
            this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
    }

}
