import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import { EditableResolveService } from 'projects/ig-core/src/lib/resolves/editable-resolve.service';
import { MasterFilterUploadComponent } from './master-filter-Upload/master-filter-upload.component';

export const masterfilteruploadRoute: Route = {
    path: 'master-filter-upload',
    canActivate: [AuthenticatedUserService],
  children: [
    {path: '', component:MasterFilterUploadComponent,
      resolve: {
        editable: EditableResolveService
      }
    }]
};


@NgModule({
    imports: [
      BrowserModule,
      HttpClientModule,
      RouterModule
    ],

    declarations: [
    ],
    entryComponents: []
  })
  export class MasterFilterUploadModule { }
  