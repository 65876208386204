<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-title> Close Audit</div>
      </mat-card-header>
  <form [formGroup]="closeAuditForm">    
      <mat-form-field style="margin-left: 20px; margin-left: 1%;">
        <input matInput name="fromDateFilter" formControlName="commencedDate" [max]="finishedDate || currentDate"
        [(ngModel)]="commencedDate"
            [matDatepicker]="fromDatePicker" placeholder="Commenced on">
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
        <mat-error align="start" *ngIf="formErrors.commencedDate">{{ formErrors.commencedDate }}
        </mat-error>
     </mat-form-field>

    <mat-form-field style="margin-left: 20px;margin-left: 1%;">
        <input matInput name="toDateFilter" formControlName="finishedDate" [min]="commencedDate"
        [max]="currentDate" [(ngModel)]="finishedDate"
        [matDatepicker]="toDatePicker" placeholder="Finished on">
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
        <mat-error align="start" *ngIf="formErrors.finishedDate">{{ formErrors.finishedDate }}</mat-error>
    </mat-form-field>
      
    &nbsp;&nbsp;&nbsp;&nbsp;

    <div style="flex: 1">
        <mat-form-field appearance="outline" fxFlex="500px">
          <mat-label  
            style="color: black;font-weight: bold;font-size: medium;">Remarks</mat-label>
          <textarea row="5" formControlName="remarks" matInput type="text">
            </textarea>
        </mat-form-field>
      </div>

      <div style="display: flex; justify-content: flex-end; margin-left: 500px;">
        <button style="margin-left: 20px; margin-bottom: 10px; min-width: 100px;" mat-raised-button color="primary" (click)="submitAudit()">
         Submit
        </button>
        <button style="margin-left: 15px; margin-bottom: 10px; min-width: 100px;" mat-raised-button color="accent" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </form>
</mat-card>