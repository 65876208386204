import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AuditTransaction } from './audit-transaction.model';
import { AuditTransactionService } from './audit-transaction-service';

@Component({
    selector: 'app-audit-transaction-creation',
    templateUrl: './audit-transaction-editor.component.html'
})
export class AuditTransactionEditorComponent implements OnInit, OnDestroy {
    auditTransaction: AuditTransaction;
    editable: true;
    public auditTransactionEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        branchName: '',
        branchCode: '',
        auditNo: ''
    };
    constructor(private auditTransactionService: AuditTransactionService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService,
        private router: Router) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.auditTransaction === undefined) {
                    this.auditTransaction = {};
                } else {
                    this.auditTransaction = data.auditTransaction;
                }
                this.editable = data.editable;
            });
    }

    ngOnInit() {
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.auditTransactionEditorForm.disable();
        }
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.auditTransactionEditorForm = this.formBuilder.group({
            id: [this.auditTransaction.id],
            branchName: [this.auditTransaction.branchName, Validators.required],
            branchCode: [this.auditTransaction.branchCode, Validators.required],
            auditNo: [this.auditTransaction.auditNo, Validators.required],
        });
        this.auditTransactionEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.auditTransactionEditorForm, this.formErrors, true);
        });
    }
    goToAuditTransactionPage() {
        this.router.navigateByUrl('auditTransaction');
    }
}
