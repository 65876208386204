import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { allFileImportTypes } from '../../constants/data.constants';
import { Import } from '../import/import.model';
import { ReportService } from '../reports/report.service';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';

@Component({
  selector: 'app-job-file-upload',
  templateUrl: './job-file-upload.component.html',
  styleUrls: ['./job-file-upload.component.css']
}) 
export class JobFileUploadComponent implements OnInit, OnDestroy, AfterViewInit {
  file: File;
  message: string;
  fileImportTypesList:any[];
  code: any;
  jobDetails: Import = {};
  public fileImportForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = { code: ''};
  allFileImportTypesList: any[] = allFileImportTypes;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private snackBar: MatSnackBar, private reportService: ReportService,
    private sanitizer: DomSanitizer, private igFormService: IgFormService, private referenceCodeService:ReferenceCodeService
  ) {
  }

  @Input()
  multiple
  @Input()
  chooseLabel = 'Choose'
  @Input()
  uploadLabel = 'Upload'
  @Input()
  deleteButtonIcon = 'close'


  @ViewChild('fileUpload')
  fileUpload: ElementRef

  inputFileName: string

  @Input()
  files: File[] = [];

  ngOnInit() {
    this.loadFileJobs();
    this.buildForm();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit() {
  }

  buildForm() {
    this.fileImportForm = this.formBuilder.group({
      code: [this.code, Validators.required],
    });
    this.fileImportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.fileImportForm, this.formErrors, true);
    });
  }

  onClick(event) {

    if (this.fileUpload)
      this.fileUpload.nativeElement.click()
  }

  downloadTemplate() {
   
    const result = this.fileImportTypesList.filter((i) => {
      return i.name === this.fileImportForm.get("code").value;
    });
   const downloadLink = result[0].field1;
      const link = document.createElement('a');
      link.setAttribute('href',downloadLink);
      link.click();
      link.remove();
  }


  onFileSelection(event: any) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    console.log('event::::::', event)
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      if (this.validate(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
        if (!this.isMultiple()) {
          this.files = []
        }
        this.files.push(files[i]);
      }
    }
    this.file = event.target.files[0];
    event.target.value = null
    this.myInputVariable.nativeElement.value = '';
  }

  clear() {
    this.code = null
    this.files = []
  }

  removeSelectedFile(index) {
    this.files.splice(index, 1);
    this.file = undefined;
    this.code = undefined;
    this.jobDetails = {};
  }

  validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === f.size
        && f.type === f.type
      ) {
        return false
      }
    }
    return true
  }

  clearInputElement() {
    this.fileUpload.nativeElement.value = ''
  }

  isMultiple(): boolean {
    return this.multiple
  }

  @ViewChild('fileUploader', {static:false})
  myInputVariable: ElementRef;

  onSubmit(type: String) {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.fileImportForm);
    if (this.fileImportForm.valid) {
      if (type === "FILEUPLOAD") {
        this.fileUploadJobCreation()
      } else {
        this.fileUploadJobExcecution();
      }
    } else {
      this.formErrors = this.igFormService.validateForm(this.fileImportForm, this.formErrors, false);
      return false;
    }
  }

  onInput(q) {

  }

  fileUploadJobCreation() {
    const result = this.fileImportTypesList.filter((i) => {
      return i.name === this.fileImportForm.get("code").value;
    });
    if (this.file === undefined) {
      this.message = 'Please select a file';
    } else {
      this.jobDetails = {};
      
      this.reportService.uploadJobFile(result[0].field2, this.file).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => this.onSubmitSuccess(response),
          (response) => this.onSubmitError(response));
    }
  }

  fileUploadJobExcecution() {
    if (this.jobDetails.id === undefined) {
      this.message = 'Please create the jobs';
    } else {
      this.reportService.executeJobFile(this.jobDetails.id).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => {
          this.message = "File Processing started successfully \n\n"
            + "Please check in Import History For More Details";
          this.snackBar.open(`${this.message}`, 'Close', { duration: 3000 });
        });
    }
  }

  private onSubmitSuccess(response) {
    this.jobDetails = JSON.parse(response.body);
    this.message = " Successfully Job Created with the Following Details :\n"
      + "\n User can click Execute to Start the File Processing Job OR Schedular will Handle on Time"
      + "\n Job Name: " + this.jobDetails.jobName
      + "\n Job Id and Uploaded Date: " + this.jobDetails.id + " on " + this.jobDetails.uploadedDate
      + "\n Job Status : " + this.jobDetails.jobStatus + " for " + this.jobDetails.jobType;

    this.snackBar.open(`${this.message}`, 'Close', { duration: 3000 });
  }

  private onSubmitError(response) {
    this.snackBar.open(`Invalid file format. Please add the valid file with .csv format `, 'Close', {
      duration: 6000,
    });
    this.message = response.error.text;
  }


   //to load the all file upload
   loadFileJobs(){
    this.referenceCodeService.getModuleMastersCodes('File_Import_Type')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.fileImportTypesList = response;
      });
    }


}
