<mat-card style="height: 95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToActivityRoleListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title class="button-icon-text">Activity Mapping</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div>
            <mat-card-header class="page-title">
                <mat-card-title class="button-icon-text">
                    Select Template And Roles
                </mat-card-title>
            </mat-card-header>
        </div>

        <div>
            <div>
                <form [formGroup]="scorecardEditorForm" novalidate (ngSubmit)="onSubmit()">
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start">
                        <mat-form-field>
                            <mat-select placeholder="Business Line" formControlName="businessLineId"
                                (selectionChange)="changeBusinessLine($event)">
                                <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                                    {{businessLine.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error align="start"> {{ formErrors.businessLineId }}</mat-error>
                        </mat-form-field>
                        &nbsp;
                    <mat-form-field style="width: 180px">
                        <mat-select formControlName="role" [(ngModel)]="role" placeholder="Roles" (selectionChange)="onRoleSelection($event)">
                            <mat-option *ngFor="let role of roles"
                                     [value]="role">{{role.code | uppercase}}</mat-option>
                        </mat-select>
                        <mat-error align="start" *ngIf="formErrors.role" >{{formErrors.role }}</mat-error>
                    </mat-form-field>
                        
                        &nbsp;
                        <mat-card-actions class="pull-right">
                            <div style="width: 200px;margin-right: 3%;float: left;">
                                <ng-multiselect-dropdown formControlName="selectedTemplates"
                                    [placeholder]="'Select Template'" [data]="templateDropdownList"
                                    [(ngModel)]="selectedTemplates" [settings]="templateDropdownSettings"
                                    (onSelect)="onSelectTemplate($event)" (onSelectAll)="onSelectAllTemplate($event)"
                                    (onDeSelect)="onItemDeSelectTemplate($event)"
                                    (onDeSelectAll)="onItemDeSelectAllTemplate($event)" required>
                                </ng-multiselect-dropdown>
                                <mat-error align="start"
                                    *ngIf="formErrors.selectedTemplates && scorecardEditorForm.controls['selectedTemplates']?.touched">{{formErrors.selectedTemplates
                                    }}</mat-error>
                            </div>
                        </mat-card-actions>
                    </div>
                    &nbsp;
                    <div>
                        <br>
                        <mat-card-header class="page-title">
                            <mat-card-title class="button-icon-text">
                                Map Template To Areas
                            </mat-card-title>
                        </mat-card-header>
                    </div>
                    <mat-card-actions class="pull-right">

                        <div style="width: 180px;margin-right: 3%;float: left;">
                            <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                                [data]="stateDropdownList" [(ngModel)]="selectedStates"
                                [settings]="stateDropdownSettings" (onSelect)="onSelectState($event)"
                                (onSelectAll)="onSelectAllState($event)" (onDeSelect)="onItemDeSelects($event)"
                                (onDeSelectAll)="onItemDeSelectAll($event)" required>
                            </ng-multiselect-dropdown>
                            <mat-error align="start"
                                *ngIf="formErrors.stateName  && scorecardEditorForm.controls['stateName']?.touched">{{formErrors.stateName
                                }}</mat-error>
                        </div>

                        <div *ngIf="role!=undefined && role.flag==='BRANCH_LEVEL'">
                            <div style="width: 180px;margin-right: 3%;float: left;">
                                <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'"
                                    [data]="dropdownList" [(ngModel)]="selectedBranches" [settings]="dropdownSettings"
                                    (onSelect)="onSelectBranch($event)" (onSelectAll)="onSelectAllBranch($event)"
                                    (onDeSelect)="onItemDeSelectBranch($event)">
                                </ng-multiselect-dropdown>
                                <mat-error align="start" *ngIf="formErrors.name">{{formErrors.name }}</mat-error>
                            </div>
                        </div>

                        <div style="width: 180px;margin-right: 3%;float: left;">
                            <ng-multiselect-dropdown formControlName="user" [placeholder]="'users'"
                                [data]="userDropdownList" [(ngModel)]="selectedUser" [settings]="userDropdownSettings"
                                (onSelect)="onSelectUser($event)" (onSelectAll)="onSelectAllUser($event)"
                                (onDeSelect)="onDeSelectUser($event)">
                            </ng-multiselect-dropdown>
                            <mat-error align="start"
                                *ngIf="formErrors.user && scorecardEditorForm.controls['user']?.touched">{{formErrors.user
                                }}</mat-error>
                        </div>
                    </mat-card-actions>

                    <mat-card-actions *ngIf="editable" class="pull-right" style="margin-top: 5%; margin-bottom: 3%;">
                        <button mat-raised-button color="primary" (click)="clearFilter()">Clear</button>
                        <button mat-raised-button color="primary">Submit</button>
                        <button mat-raised-button (click)="goToActivityRoleListPage()">Cancel</button>
                    </mat-card-actions>
                </form>
            </div>
        </div>
    </mat-card-content>
</mat-card>