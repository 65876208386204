<mat-card class="margin-7 dull-background">
    <mat-card-header class="page-title">
        <mat-card-title>{{responseDetailObject.question.name}}</mat-card-title>
    </mat-card-header>
    <mat-dialog-content>
        <div>
            <span *ngFor="let entry of data.responseDetailObject.answer | keys">
                <div *ngIf="entry.key == 'answer'; else observation">
                    <label><b>{{entry.key}}:</b></label> {{entry.value}}
                </div>
                <ng-template #observation>
                    <div>
                        <label><b>{{entry.key}}:</b></label>
                        <div *ngFor="let observationEntryObject of entry.value index as i">
                            <span *ngFor="let observationEntry of observationEntryObject | keyvalue">
                                <div *ngIf="observationEntry.key != 'remark'">
                                    {{i+1}}) {{observationEntry.key}} : {{observationEntry.value[0]}} Instance and {{observationEntry.value[1]}} Deviations
                                </div>
                                <strong>{{observationEntry.value[1]}}</strong> Deviations
                                <span *ngIf="observationEntry.value[2]!=null">and Rectified
                                    <strong>{{observationEntry.value[2]}}</strong></span>
                            </span>

                        </div>
                    </div>
                </ng-template>
            </span>
        </div>
        <hr>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <h3>Change to</h3>
                <mat-select placeholder="Select Answer" style="width:100%" (selectionChange)="answerChange($event)"
                    formControlName="answer">
                    <mat-option *ngFor="let answer of answers" [value]="answer">
                        {{answer}}
                    </mat-option>
                </mat-select>
                <hr>
                <div formArrayName="observationConfig" [hidden]="hideObservations">
                    <div [formGroupName]="j"
                        *ngFor="let obControl of form.get('observationConfig')['controls'];  let j = index;">
                        <label><b>{{obControl.get("observation").value}}</b></label>
                        <br>
                        <mat-form-field>
                            <input matInput type="number" formControlName="instances" placeholder="Instances">
                        </mat-form-field>&nbsp;
                        <mat-form-field>
                            <input matInput type="number" formControlName="deviations" placeholder="Deviations">
                        </mat-form-field>&nbsp;
                        <mat-form-field>
                        <mat-select placeholder="IsRectified" (selectionChange)="answerChange($event)"
                            formControlName="isRectified">
                            <mat-option *ngFor="let isR of isRectified" [value]="isR">
                                {{isR}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
                <mat-dialog-actions>
                    <button mat-button mat-raised-button type="submit" color="primary">Save</button>
                    <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
                </mat-dialog-actions>
            </form>
            <div *ngIf="error!=''" style="color: red"> {{error}}</div>
        </div>
    </mat-dialog-content>
</mat-card>