import { Component, Inject, OnDestroy } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuditTransactionService } from './audit-transaction-service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './audit-transaction-reduce-score-grade-dialog.component.html',
})

export class AuditTransactionReduceScoreGradeDialogComponent implements OnDestroy {

    hasError = false;
    isreadOnly = true;
    errorMsg: any;
    // dialogRef needs MatDialogRef to map the dialog
    // auditTransactionService is needed to hit api mapping
    // @Inject is used to inject the data passed in the audit-transaction-workflow component's getRemarksDialog method to this dialog
    constructor(
        public dialogRef: MatDialogRef<AuditTransactionReduceScoreGradeDialogComponent>,
        public auditTransactionService: AuditTransactionService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.isreadOnly = data.element.readOnly;
    }

    // On clicking Cancel
    onNoClick() {
        this.dialogRef.close(this.data);
    }
    // On clicking Save
    onSubmit(data: any) {
        if (data.element.reducedScorePercentage > 100.00) {
            this.hasError = true;
            this.errorMsg = 'Reduced Percentage cannot be greater than';
            return;
        }
        if (data.element.reducedScorePercentage < 0) {
            this.hasError = true;
            this.errorMsg = 'Reduced Percentage cannot be less than 0!';
            return;
        }
        // Sending the object itself as when the input changes the value of the text box the element value property is changed
        this.auditTransactionService.updateTransactionRemarks(data.element)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => {
                // After API hits the response can be processed to show a message or dialog can be closed
                this.dialogRef.close(response);
                this.snackBar.open(`Updated Remarks Successfully`, 'Close', {
                    duration: 3000,
                });
            });
    }
    ngOnDestroy(): void { }
}

export interface DialogData {
    element: any;
}
