import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Question } from './question.model';
import { QuestionService } from './question.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionResolveService implements Resolve<Question> {
    constructor(private cs: QuestionService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Question> | Observable<never> {
        return this.cs.getQuestion(+route.paramMap.get('id'));
    }
}
