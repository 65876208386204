<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>donut_large</mat-icon>
        </div>
        <div mat-card-title>
            Branch
        </div>
        <div class="page-title-action">
            <button mat-button mat-raised-button color="accent" [routerLink]="'create'"  disabled="disabled">
                <mat-icon>add_circle</mat-icon>
                Create Branch
            </button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <form [formGroup]="branchListForm"  >
            <div>
                    <mat-form-field style="padding-right: 20px;">
                        <input matInput type="text" id="name" name="name" placeholder="Enter Name" formControlName="name"/>
                      </mat-form-field>
                    <mat-form-field style="padding-right: 20px;">
                      <input matInput type="text" id="code" name="code" placeholder="Enter Code" formControlName="code"/>
                    </mat-form-field>

                    <mat-form-field style="padding-right: 20px;">
                        <mat-select formControlName="businessLine"   placeholder="Business Line">
                          <mat-option  *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{businessLine.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="padding-right: 20px;" >
                        <mat-select formControlName="branchStatus" placeholder="Status" >
                            <mat-option *ngFor="let branchStatus of branchStatuses" [value]="branchStatus">
                                {{ branchStatus }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <button type="button" mat-raised-button color="primary" (click)="search()">
                      <mat-icon>search</mat-icon>
                    </button>
                    &nbsp;
                    <button style="margin-right: 5px" type="button" mat-raised-button color="primary" (click)="clearFilter()">
                      Clear
                    </button>
            </div>
        </form>

        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear
                   [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
                            <td mat-cell *matCellDef="let element" data-label="Name"> {{element.name}}</td>
                        </ng-container>
                    
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="code">Code</th>
                            <td mat-cell *matCellDef="let element" data-label="Code"> {{element.code}}</td>
                        </ng-container>

                        <ng-container matColumnDef="businessLine">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="businessLine">Business Line</th>
                            <td mat-cell *matCellDef="let element" data-label="Business Line"> {{element.businessLineDTO.name}}</td>
                        </ng-container>
                    
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
                            <td mat-cell *matCellDef="let element" data-label="Status">{{ element.branchStatus ? 'ACTIVE' : 'INACTIVE' }}</td>
                        </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-button-toggle-group #branchActionGroup="matButtonToggleGroup" class="table-row-action">
                            <mat-button-toggle [routerLink]="['view', element.id]" title="View More">
                                <mat-icon>more</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle [routerLink]="['update', element.id]" title="Edit" [disabled]="element.branchStatus !== true">
                                <mat-icon>edit</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle (click)="deleteDialog(element)" title="Delete">
                                <mat-icon>delete</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                           [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"  [pageIndex]="pagingAndSortParams.pageIndex"></mat-paginator>
                           
        </div>
    </mat-card-content>
</mat-card>
