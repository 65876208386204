import {Observable} from 'rxjs';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';

import {ConfigureService} from 'src/app/build-config/configure.service';
import {AuthTokenService} from '../authentication/auth-token.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigureService,
    private authTokenService: AuthTokenService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url)
      && !(this.configService.config.API_URL && request.url.startsWith(this.configService.config.API_URL)))) {
      return next.handle(request);
    }

    const token = this.authTokenService.getToken();
    if (!!token) {
      request = request.clone({
        setHeaders: {
          'x-auth-token': token
        }
      });
    }
    return next.handle(request);
  }

}
