import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Role} from '../role/role.model';
import {RoleService} from '../role/role.service';
import {ActivatedRoute, Data, Router} from '@angular/router';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { User } from './user.model';
import { UserService } from './user.service';

@Component({
    selector: 'app-user-edit-template',
    templateUrl: './user-edit-template.component.html',
    styleUrls: ['./user.component.css']
})
export class UserEditTemplateComponent implements OnInit, OnDestroy {
    userEditorForm: User;
    template: number;
    errorMessage: string;
    constructor(private userService: UserService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<UserEditTemplateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Data) {
                
}

    ngOnInit() {
      this.userEditorForm = this.data.userData;
      this.template = this.data.oldtemplate;
    }
    ngOnDestroy(): void {
    }

    goToUserListPage() {
      this.router.navigateByUrl('admin/user');
    }

    saveLatestTemplate() {
          this.userService.saveUser(this.userEditorForm).pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
    }


    private onSubmitSuccess(response) {
        this.snackBar.open(`Updated user ${this.userEditorForm.login} successfully`, 'Close', {
          duration: 3000,
        });
        this.dialogRef.close();
        this.goToUserListPage();
      }
    
      private onSubmitError(response) {
        this.errorMessage = response.error;
      }

    cancel(): void {
      this.userEditorForm.templeteHeaderId = this.template;
      this.userService.saveUser(this.userEditorForm).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => this.onSubmitSuccess(response),
        (response) => this.onSubmitError(response));    }
}
