import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Branch} from './branch.model';
import { BusinessLineDatamodel } from './business-line.model';

@Injectable({
    providedIn: 'root'
})
export class BranchService {

    private API = 'api/branches';

    constructor(private http: HttpClient) {}

    findBranchs(req: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        console.log(req)
        if (req.name) {
            params = params.set('name', req.name);
        }
        if (req.code) {
            params = params.set('code', req.code);
        }
        if (req.businessLineId) {
            params = params.set('businessLineId', req.businessLineId);
        }
        if(req.branchStatus){
            if(req.branchStatus == "ACTIVE"){
                params = params.set('branchStatus', 1);
            } else {
                params = params.set('branchStatus', 0);
            }
        }
        return this.http.get<Branch[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveBranch(branch: Branch): Observable<HttpResponse<Branch>> {
        const copy: Branch = Object.assign({}, branch);
        if (copy.id && copy.id != null) {
            return this.http.put<Branch>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<Branch>(this.API, copy, {observe: 'response'});
        }
    }

    getBranch(id: number) {
        return this.http.get<Branch>(this.API + '/' + id);
    }

    deleteBranch(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getAllBranches() {
      return this.http.get<Branch[]>(this.API + '/' + 'user');
    }
    getAllBranchesForUser(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/branches/user', { observe: 'response' });
    }

    getAllStates(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/branches/states', { observe: 'response' });
    }

    getAllBranchsetStates(businessLineId:number): Observable<HttpResponse<string[]>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
            params = params.set('businessLineId', businessLineId);
            }
        return this.http.get<any>('api/branches/branchsetStates/', {params: params, observe: 'response' });
    }

    getAllBusinessLines() {
        return this.http.get<BusinessLineDatamodel[]>('api/business-lines');
    }

    getAllActiveBranchesForUser(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/active/branches/user', { observe: 'response' });
    }
}

