import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IgFormService} from '../../../../projects/ig-core/src/lib/form/form.service';
import {FormErrorModel} from '../../../../projects/ig-core/src/lib/form/form-error.model';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {MoratoriumService} from './moratorium.service';
import {Branch} from '../../admin/branch/branch.model';
import {HttpResponse} from '@angular/common/http';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-moratorium-report',
    templateUrl: './moratorium-report.html'
})

export class MoratoriumReportComponent implements OnInit, OnDestroy {

    downLoadFormatList: any[];
    showOnlyMappedBranches = true;
    stateHint = false;
    branchHint = false;
    name: string;
    isRoleAdmin:boolean;
    showMatHint = false;
    dropdownList: any[];
    stateDropdownList: any[];
    selectedItems = [];
    dropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: false,

    };
    consentStatus: string;
    selectedBranches: any[];
    branches: Branch[];
    states: any;
    selectedStates: any[];
    branchCodes: any[];
    code: string;
    branchSelectIndicator = new FormControl();
    dataSource: any;
    stateList: string[];
    businessLines: any[];
    selectedBusinessLine: any;
    loggedInUserRole: any;
    businessLine: any;
    public moratoriumReportForm: FormGroup;
    @ViewChild('stateSelect',{static:false}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:false}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:false}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:false}) branchAllSelect: MatCheckbox;
    public formError: FormErrorModel;
    public formErrors = {
        stateName: '',
        name: '',
        selectedStates: '',
        selectedBranches: '',
        consentStatus: '',
        code: '',
        businessLineId:''
    };

    constructor(private igFormService: IgFormService, private formBuilder: FormBuilder,
        private userService: UserService,private activatedRoute: ActivatedRoute,
                private snackBar: MatSnackBar, private moratoriumService: MoratoriumService)
                 {this.activatedRoute.data
                    .pipe(takeUntil(componentDestroyed(this)))
                    .subscribe((data) => {
                        this.loggedInUserRole = data.loggedInUser.body.role;
                      });
    }

    ngOnInit() {
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
        this.optedMonthFilters();
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        
        this.moratoriumReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.moratoriumReportForm.controls['businessLineId'].enable();
        }else{  this.loadStates();
        }
    }

    ngOnDestroy(): void {
    }

    onSelectState(state: any) {
        this.stateHint = false;
        this.selectedBranches = undefined;
        this.moratoriumReportForm.get('name').reset();
        this.loadBranches();
    }
  
    onSelectAll(event){}

    onSelectAllState(state: any) {
        this.stateHint = false;
        this.selectedStates = state;
        this.loadBranches();
    }

    onSubmit() {
        this.branchCodes = [];
        if (this.selectedBranches !== undefined) {
            this.selectedBranches.forEach((value) => {
                this.branchCodes.push(value.code);
            });
        }
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.moratoriumReportForm);
        if (this.moratoriumReportForm.valid) {
            this.download();
        } else {
            this.formErrors = this.igFormService.validateForm(this.moratoriumReportForm, this.formErrors, false);
            this.setFormErrorValue();
            return false;
        }
    }

    setFormErrorValue() {
        if (this.formErrors.stateName === 'This field is required') {
            this.stateHint = true;
        }
        if (this.formErrors.name === 'This field is required') {
            this.branchHint = true;
        }
    }

    buildForm() {
        this.moratoriumReportForm = this.formBuilder.group({
            stateName: [this.selectedStates, Validators.required],
            name: [this.branches, Validators.required],
            consentStatus: [this.consentStatus, Validators.required],
            code: [this.code],
            businessLineId:['',Validators.required]
        });
        this.moratoriumReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.moratoriumReportForm, this.formErrors, true);
        });
    }

    loadBranches() {
        this.selectedBranches=null;
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.moratoriumService.getAllBranchesForStates(this.businessLine,this.stateList, this.showOnlyMappedBranches)
        .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                });
    }

    loadStates() {
        this.moratoriumService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                    
                }
            );
    }

    download() {
        this.moratoriumService.downloadExcel({
            consentStatus: this.moratoriumReportForm.get('consentStatus').value,
            branchCodes: this.branchCodes,
            stateName: this.stateList

        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
            this.onError();
        }, () => {
            console.log('Completed file download.');
        });
    }

    onError() {
        this.snackBar.open(`Error Downloading Excel`, 'Close', {
            duration: 3000,
        });
    }

    clearFilter() {
        // this.stateSelect = undefined;
        // this.selectedBranches = undefined;
        // this.selectedStates = [];
        // this.states = undefined;
        // this.consentStatus = undefined;
        // this.code = undefined;
        // this.selectedBusinessLine = ''
        this.moratoriumReportForm.reset();
        if(this.businessLine !== undefined){
            this.stateDropdownList = [];
          }
          this.dropdownList=[];
    }

    onItemDeSelectState(item: any) {
        this.moratoriumReportForm.get('name').reset();
        this.selectedBranches = [];
        this.loadBranches();
    }

    onSelectBranch(item: any) {
        this.branchHint = false;
    }

    onItemDeSelectBranch(item: any) {
        this.branchHint = true;
    }

    onItemDeSelectAll(item: any) {
        this.stateHint = true;
        this.moratoriumReportForm.get('name').reset();
        this.selectedStates = [];
        this.loadBranches();
    }

    optedMonthFilters() {
        this.downLoadFormatList = [
            {value: 'Consent Received', viewValue: 'Consent Received'},
            {value: 'Consent Not Received', viewValue: 'Consent Not Received'},
            {value: 'Show All', viewValue: 'Show All'}
        ];
    }
    changeBusinessLine(selectedBusniessLine: any){
        this.selectedStates=null;
        this.businessLine = selectedBusniessLine.value;
        this.loadStates();
      }
}
