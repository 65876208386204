import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import { AuditPlan } from './audit-plan.model';
import { AuditPlanService } from './audit-plan.service';

@Component({
    selector: 'app-audit-plan-delete-dialog',
    templateUrl: './audit-plan-delete-dialog.component.html',
})
export class AuditPlanDeleteDialogComponent implements OnInit, OnDestroy {
    auditPlan: AuditPlan;
    errorMessage: string;
    constructor(private auditPlanService: AuditPlanService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<AuditPlanDeleteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: AuditPlan) {
        this.auditPlan = data;
    }

    ngOnInit() {
    }
    ngOnDestroy(): void {
    }
    delete() {
        this.errorMessage = '';
        this.auditPlanService.deleteAuditPlan(this.auditPlan.id).pipe(takeUntil(componentDestroyed(this))).
        subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }
    onSaveSuccess(result) {
        this.snackBar.open(`Deleted AuditPlan successfully`, 'Close', {
            duration: 3000,
        });
        this.dialogRef.close();
    }
    onSaveError(response) {
        this.errorMessage = response.error;
    }
    cancel(): void {
        this.dialogRef.close();
    }
}
