import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import { ActivityTracker } from './activity-tracker.model';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';
import { NameValueDto } from 'projects/ig-core/src/lib/interfaces/name-value-dto';

@Component({
  selector: 'app-activity-tracker-report',
  templateUrl: './activity-traker-count-dialog.component.html'
})
export class ActivityTrakerCountDialogComponent implements OnInit, OnDestroy {
  activityTracker : ActivityTracker;
  type : String;
  errorMessage: string;
  typeCountList: NameValueDto[];
  fromDate: string;
  toDate: string;
  eachType: any;
  constructor(private referenceCodeService: ReferenceCodeService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ActivityTrakerCountDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { element : any, typeCount: any, dateFrom: string, dateTo: string}){
               this.activityTracker = data.element;
               this.type = data.typeCount;
               this.fromDate = data.dateFrom;
               this.toDate = data.dateTo;

              }
  ngOnInit() {
    if(this.type =='LUQ'){
      this.eachType =  'Customer Visit';
      }else {
        this.eachType =  this.type;
      }
    this.typeCount();
    this.typeCountList;
  }
  ngOnDestroy(): void {
  }

  typeCount() {
    this.referenceCodeService.getEachTasksCompletedCounts(this.activityTracker.employeeLogin, this.type, this.fromDate, this.toDate)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => this.onSaveSuccess(response));
  }

  onSaveSuccess(result) {
    this.typeCountList = result.body;
    }

  OK(): void {
    this.dialogRef.close();
  }
}
