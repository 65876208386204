import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AuditTransactionService } from './audit-transaction-service';
import { AuditTransaction } from './audit-transaction.model';
import { BranchGrade } from './branch-grade.model';
import { parse } from 'url';

@Component({
    selector: 'app-audit-transaction-show-scores',
    templateUrl: './audit-transaction-show-score.component.html',
})
export class AuditTransactionShowScoreComponent implements OnInit, OnDestroy {
    auditTransactionId: string;
    branchGrade: BranchGrade;

    constructor(private activatedRoute: ActivatedRoute,
        private auditTransactionService: AuditTransactionService,
        private router: Router) {
        this.auditTransactionId = activatedRoute.snapshot.paramMap.get('id');
    }

    ngOnDestroy(): void { }
    ngOnInit(): void {
            this.getBranchGrade();
    }
    getBranchGrade() {
        this.auditTransactionService.getScoresAndGrade(+this.auditTransactionId).pipe(takeUntil(componentDestroyed(this))).
            subscribe((response) => {
                this.branchGrade = response.body;
                console.log(this.branchGrade);
            });
    }

    goToAuditTransactionPage() {
        this.router.navigateByUrl('auditTransaction');
    }
}
