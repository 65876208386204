import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCode } from '../reference-code.model';
import { ReferenceCodeService } from '../reference-code.service';


@Component({
  selector: 'app-reference-code-delete-dialog',
  templateUrl: './reference-code-delete-dialog.component.html',
  styleUrls: ['../reference-code.component.css']
})
export class ReferenceCodeDeleteDialogComponent implements OnInit, OnDestroy {
  referenceCode: ReferenceCode;
  errorMessage: string;
  constructor(private referenceCodeService: ReferenceCodeService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialogRef: MatDialogRef<ReferenceCodeDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ReferenceCode) {
    this.referenceCode = data;
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }

  delete() {
    this.errorMessage = '';
    this.referenceCodeService.deleteReferenceCode(this.referenceCode.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
  }

  onSaveSuccess(result) {
    this.dialogRef.close('deleted');
  }

  onSaveError(response) {
    this.errorMessage = response;
  }

  cancel(): void {
    this.dialogRef.close('canceled');
  }
}
