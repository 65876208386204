import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BranchService } from '../branch.service';
import { BranchListDataSource } from './branch-list.data.source';
import { BranchDeleteDialogComponent } from './branch-delete-dialog.component';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BusinessLineDatamodel } from '../business-line.model';

@Component({
  selector: 'app-branch',
  templateUrl: 'branch-list.component.html',
  styleUrls: ['../branch.component.css']
})
export class BranchListComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: BranchListDataSource;
  listSource: ListSource;
  displayedColumns = ['name', 'code', 'businessLine', 'status', 'action'];
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  public branchListForm: FormGroup;
  businessLines: BusinessLineDatamodel[];
  branchStatus: string;
  branchStatuses: string[];

  constructor(
    private branchService: BranchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) { }
  ngOnInit() {
    this.branchListForm = this.buildForm();
    this.dataSource = new BranchListDataSource(this.branchService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this))).subscribe(listParams => {
      this.pagingAndSortParams = listParams.pagingAndSortParam;
      let sortColumn = this.pagingAndSortParams.sortColumn;
      if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
        sortColumn = 'id';
      }
      this.loadBranchs('');
    });
    this.branchService.getAllBusinessLines().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
      this.businessLines = businessLines;
    });
    this.loadStatuses();
  }

  buildForm() {
    return this.formBuilder.group({
      name: '',
      code: '',
      businessLine: 1,
      branchStatus: ''
    });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadBranchsPageWithParams());
  }

  loadBranchsPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  loadBranchs(sortColumn: String) {
    this.dataSource.loadBranchs(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
      this.pagingAndSortParams.pageIndex, 
      this.pagingAndSortParams.pageSize, 
      this.branchListForm.value['name'], 
      this.branchListForm.value['code'], 
      this.branchListForm.value['businessLine'], 
      this.branchListForm.value['branchStatus']);
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  deleteDialog(branchData: any) {
    const dialogRef = this.dialog.open(BranchDeleteDialogComponent, {
      data: branchData
    });
    dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
      if (result && result === 'deleted') {
        this.snackBar.open(`Deleted branch successfully`, 'Close');
        this.ngOnInit();
      }
    });
  }
  clearFilter() {
    this.branchListForm.get('name').setValue(undefined);
    this.branchListForm.get('code').setValue(undefined);
    this.branchListForm.get('businessLine').setValue(1);
    this.branchListForm.get('branchStatus').setValue(undefined);
    this.search()
  }

  search() {
    this.pagingAndSortParams.pageIndex = 0
    this.paginator.pageIndex = 0
    this.loadBranchs('id')
    this.loadBranchsPageWithParams()
    this.loadStatuses();
  }

  loadStatuses() {
    this.branchStatuses = ['ACTIVE', 'INACTIVE'];
  }

}
