<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>visibility</mat-icon>
        </div>
        <div mat-crad-title>Branch Visit Monitor</div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="branchVisitMonitorReportForm" novalidate (ngSubmit)="onSubmit()">

            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start">

                <!-- <mat-form-field style="margin-right: 1%;">
                    <mat-select [(ngModel)]="type" placeholder="Report Type" formControlName="type">
                        <mat-option *ngFor="let branchReportType of branchVisitReporttypes"
                            [value]="branchReportType">{{branchReportType.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.type">{{ formErrors.type }}</mat-error>
                </mat-form-field> -->

                <mat-form-field>
                    <mat-select formControlName="type" name="type" [(ngModel)]="type" placeholder="Report Type">
                      <mat-option *ngFor="let cihReports of branchVisitMonitorReportTypes" [value]="cihReports.code">{{cihReports.name}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.type">{{ formErrors.type }}</mat-error>
                  </mat-form-field>

                <div style="width: 180px;margin-right: 3%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'states'"
                        [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                        (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
                <div style="width: 180px;margin-left: 2%;margin-top: 4.6px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'branches'" [data]="dropdownList"
                        [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelectBranch($event)">
                    </ng-multiselect-dropdown>
                </div>

                <mat-form-field style="margin-left:5%;">
                    <input matInput name="fromDateFilter" formControlName="fromDateFilter" [max]="toDateFilter"
                        (focus)="fromDatePicker.open()" (click)="fromDatePicker.open()" [(ngModel)]="fromDateFilter"
                        [matDatepicker]="fromDatePicker" placeholder="FromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.fromDateFilter">{{ formErrors.fromDateFilter }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field style="margin-left: 20px;margin-left: 1%;">
                    <input matInput name="toDateFilter" formControlName="toDateFilter" [min]="fromDateFilter"
                        (focus)="toDatePicker.open()" (click)="toDatePicker.open()" [(ngModel)]="toDateFilter"
                        [matDatepicker]="toDatePicker" placeholder="ToDate">
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                    <mat-error align="start" *ngIf="formErrors.toDateFilter">{{ formErrors.toDateFilter }}</mat-error>
                </mat-form-field>

                <button type="submit" style="height: 40px;margin-right: 1%;" mat-raised-button color="primary">
                    <mat-icon>cloud_download</mat-icon> Download Report
                </button>

                <button type="button" style="height: 40px;" mat-raised-button color="primary"
                    (click)="clearFilter()">Clear</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>