import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Log} from './log.model';
import {LogManagementService} from './log-management.service';
import { MatBottomSheet} from '@angular/material/bottom-sheet';
import { MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {ChangeLogLevelBottomSheetComponent} from './change-log-level-bottom-sheet.component';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'ig-log-management',
  templateUrl: './log-management.component.html',
  styleUrls: ['./log-management.component.css']
})
export class LogManagementComponent implements OnInit, OnDestroy {

  displayedColumns: String[] = ['name', 'level', 'changeTo'];
  logDataSource: MatTableDataSource<Log>;
  noOfLoggers: number;


  constructor(
    private logManagementService: LogManagementService,
    private bottomSheet: MatBottomSheet
  ) {
    this.logDataSource = new MatTableDataSource<Log>();
  }

  ngOnInit() {
    this.loadList();
    this.logManagementService.whenDataUpdated().pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
      this.loadList();
    });
  }

  loadList() {
    this.logManagementService.findAll().pipe(takeUntil(componentDestroyed(this))).subscribe(response => this.onLogsFetched(response) );
  }

  onLogsFetched(response) {
    this.noOfLoggers = response.body.length;
    this.logDataSource = new MatTableDataSource<Log>(response.body);
  }

  applyFilter(filterValue: string) {
    this.logDataSource.filter = filterValue.trim().toLowerCase();

    if (this.logDataSource.paginator) {
      this.logDataSource.paginator.firstPage();
    }
  }

  changeLevelBottomSheet(row: Log) {
    this.bottomSheet.open(ChangeLogLevelBottomSheetComponent, {
      data: {log: row}
    });
  }

  ngOnDestroy(): void {
  }
}
