import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormErrorModel } from '../../../../../projects/ig-core/src/lib/form/form-error.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BranchOrganisationMapping, ScoreSet } from '../activity-role.model';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IgFormService } from '../../../../../projects/ig-core/src/lib/form/form.service';
import { ReferenceCodeService } from '../../../admin/reference-code/reference-code.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivityRoleService } from '../activity-role.service';
import { Role } from '../../../admin/role/role.model';
import { RoleService } from '../../../admin/role/role.service';
import { HttpResponse } from '@angular/common/http';
import { FetchBranchesForStates } from 'src/app/constants/data.constants';
import { Branch } from 'src/app/admin/branch/branch.model';
import { UserService } from 'src/app/admin/user/user.service';

@Component({
    selector: 'app-activity-mapping-template',
    templateUrl: './activity-template-mapping.html',
    styleUrls: ['./activity-role-edit.component.css']
})
export class ActivityTemplateMappingComponent implements OnInit, OnDestroy {


    scorecard: ScoreSet;
    editable: true;
    isPresent: boolean;
    isSave: boolean;
    code: string;
    hierarchy: string;
    parentId: any[];
    toDateFilter: string;
    fromDateFilter: string;
    dropdownList: any[];
    dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    stateDropdownList: any[];
    regionDropdownSettings = {};
    divisionDropdownSettings = {};
    areaDropdownSettings = {};
    zoneDropdownSettings = {};
    templateDropdownList: any[];
    selectedStates: any[];
    selectedBranches = [];
    zoneMultiId: string[] = [];
    zoneMultiIds: string[] = [];
    stateMultiId: string[] = [];
    regionMultiId: string[] = [];
    divisionMultiId: string[] = [];
    roles: Role[];
    selectedTemplates = [];
    templateDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        limitSelection: 1,
    };
    role: Role;
    templates: any;
    selectTempleteid: number;
    zoneOfBranchOrganisation: BranchOrganisationMapping[];
    stateOfBranchOrganisation: BranchOrganisationMapping[];
    regionOfBranchOrganisation: BranchOrganisationMapping[];
    divisionOfBranchOrganisation: BranchOrganisationMapping[];
    areaOfBranchOrganisation: BranchOrganisationMapping[];
    show: FormGroup;
    zones: BranchOrganisationMapping[];
    states: BranchOrganisationMapping[];
    regions: BranchOrganisationMapping[];
    divisions: BranchOrganisationMapping[];
    areas: BranchOrganisationMapping[];
    public scorecardEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        zones: '',
        states: '',
        regions: '',
        divisions: '',
        areas: '',
        selectedTemplates: '',
        role: '',
        user: '',
        stateName: '',
        businessLineId: ''
    };
    stateList: string[];
    branches: Branch[];
    users: any;
    userDropdownList: any[];
    branchIds: any[];
    selectedUser: any[];
    stateIds: any[];
    stateNames: any[];
    businessLines: any[];
    selectedBusinessLine: any;
    businessLine: number;
    userDropdownSettings = {
        singleSelection: false,
        idField: 'login',
        textField: 'firstName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };

    @ViewChild('stateSelect', { static: false }) stateSelect: MatSelect;
    @ViewChild('stateAllSelect', { static: false }) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect', { static: false }) branchSelect: MatSelect;
    @ViewChild('branchAllSelect', { static: false }) branchAllSelect: MatCheckbox;

    @ViewChild('userSelect', { static: false }) userSelect: MatSelect;
    @ViewChild('userAllSelect', { static: false }) userAllSelect: MatCheckbox;

    constructor(private scoreCardService: ActivityRoleService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService,
        private router: Router,
        private userService: UserService,
        private roleService: RoleService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.scorecard === undefined) {
                    this.isSave = true;
                    this.scorecard = {};

                } else {
                    this.isPresent = true;
                    this.scorecard = data.scorecard;
                }
                this.editable = data.editable;
            });
    }

    ngOnInit() {
        this.loadRoles();
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        this.loadStates();
        this.loadBusiness();
        this.loadTemplates(this.scorecardEditorForm.get('businessLineId').value, this.scorecardEditorForm.get('role').value);
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.scorecardEditorForm = this.formBuilder.group({
            stateName: [this.selectedStates, Validators.required],
            user: [this.selectedUser, Validators.required],
            role: [this.role, Validators.required],
            selectedTemplates: [],
            businessLineId: ['', Validators.required]
        });
        this.scorecardEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.scorecardEditorForm, this.formErrors, true);
        });

    }

    loadRoles() {
        this.roleService.getAllRoles().pipe(takeUntil(componentDestroyed(this))).subscribe(roles => {
            this.roles = roles.body;
        });
    }

    onSelectState(state: any) {
        this.selectedStates.push(state);
        if (this.role.flag === 'BRANCH_LEVEL') {
            this.loadBranches();
        } else {
            this.fetchEmployees();
        }
    }

    loadBusiness() {
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        if (this.role.flag === 'BRANCH_LEVEL') {
            this.loadBranches();
        } else {
            this.fetchEmployees();
        }
    }

    onSelectBranch(barnch: any) {
        this.selectedBranches.push(barnch);
        this.fetchEmployees();
    }

    onSelectAllBranch(barnch: any) {
        this.selectedBranches = barnch;
        this.fetchEmployees();
    }

    onSelectUser(user: any) {
        this.selectedUser;
        //this.fetchEmployees();
    }

    onSelectAllUser(user: any) {
        this.selectedUser = user;
        //this.fetchEmployees();
    }

    loadStates() {
        this.scoreCardService.getAllStatesForUser().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                }
            );
    }

    loadBranches() {
        console.log(this.selectedStates);
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.scoreCardService.getAllBranchesForStates(this.stateList, FetchBranchesForStates.GET_MAPPED_ONLY)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                });
    }

    onItemDeSelects(item: any) {
        this.selectedUser = null;
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectBranch(item: any) {
    }

    onItemDeSelectAll(item: any) {
        this.selectedUser = null;
        this.selectedStates = [];
        this.loadBranches();

    }

    onDeSelectUser(item: any) {

    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.scorecardEditorForm);
        if (this.scorecardEditorForm.valid) {
            this.saveMappings();
            
        } else {
            this.formErrors = this.igFormService.validateForm(this.scorecardEditorForm, this.formErrors, false);
            return false;
        }
    }

    saveMappings() {
        let areaList = [];
        if (this.areas !== undefined && this.areas.length > 0) {
            this.areas.forEach(element => {
                areaList.push(element.id);
            });
        }

        let userLoginList = [];
        if (this.selectedUser !== undefined && this.selectedUser.length > 0) {
            this.selectedUser.forEach(element => {
                userLoginList.push(element.login);
            });
        }

        if (this.selectedTemplates !== undefined && this.selectedTemplates.length > 0) {
            this.selectedTemplates.forEach(i => {
                this.selectTempleteid = i.id;
            });
        }
        this.scoreCardService.saveMapping({
            templateList: this.selectTempleteid,
            role: this.role.code,
            areaList: areaList,
            userLogin: userLoginList,
            branchId: this.branchIds,
            stateNames: this.stateNames,
        }).pipe(takeUntil(componentDestroyed(this))).subscribe((response) => this.onSubmitSuccess(response),
            (response) => this.onSubmitError(response));
    }

    private onSubmitSuccess(response) {
        this.snackBar.open(`Updated Template Mapping To Users Successfully`, 'Close', {
            duration: 3000,
        });
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.scorecardEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        } else if (errorModel && !errorModel.isValidationError) {
            this.formError.hasError = true;
            this.formError.error = errorModel.otherError;
        }
    }

    goToScorecardListPage() {
        this.router.navigateByUrl('scorecard');
    }

    loadTemplates(businessLineId: number, role: string) {
        this.templateDropdownList = []
        if (businessLineId !== 0 && role !== null) {
            this.scoreCardService.getAllTemplates(businessLineId, role).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => {
                    this.templates = response.body;
                    this.templateDropdownList = this.templates;
                },
                    (error) => {
                        let errorMessage = "No Template found for business line : " + this.businessLine + " and role : " + this.role.code;
                        if (error && error.error && error.error.detail) {
                            errorMessage = error.error.detail;
                        }
                        this.snackBar.open(errorMessage, 'close', {
                            duration: 10000,
                        });
                        console.error(errorMessage, error);
                    }
                );
        }
    }

    onSelectTemplate(event) {
    }

    onSelectAllTemplate(templates: any) {
        this.selectedTemplates = templates;
    }

    onItemDeSelectTemplate(item: any) {
    }

    onItemDeSelectAllTemplate(item: any) {
    }

    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    fetchEmployees() {
        this.branchIds = [];
        this.stateNames = [];
        if (this.role.flag === 'BRANCH_LEVEL') {
            this.selectedBranches.forEach((value) => {
                this.branchIds.push(value.id);
            });
        } else {
            this.selectedStates.forEach((value) => {
                this.stateNames.push(value.name);
            });
        }
        this.scoreCardService.getAllSubordinatesForBranchAndRole(
            this.role.code, this.branchIds, this.stateNames, this.businessLine)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.users = res.body;
                    this.userDropdownList = this.users;
                });
    }

    clearFilter() {
        // this.zones = undefined;
        // this.selectedBranches = undefined;
        //this.selectedStates = [];
        // this.states = undefined;
        // this.toDateFilter = undefined;
        // this.fromDateFilter = undefined;
        //this.code = undefined;
        //this.userDropdownList = undefined;
        // this.loadBranches();
        //this.selectedUser = undefined;
        //this.selectedTemplates = undefined;
        //this.role = undefined;
        // this.selectedBusinessLine= '';
        //this.businessLine = undefined;
        this.scorecardEditorForm.reset();
        if (this.businessLine != undefined) {
            this.templateDropdownList = [];
            this.userDropdownList = [];
        }
    }

    goToActivityRoleListPage() {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }

    changeBusinessLine(selectedBusinessLine: any) {
        this.selectedTemplates = null;
        this.role = null;
        this.selectedStates = null;
        this.selectedUser = null;
        this.businessLine = selectedBusinessLine.value;
        if (this.scorecardEditorForm.get("businessLineId").value && this.scorecardEditorForm.get("role").value) {
            this.loadTemplates(this.scorecardEditorForm.get("businessLineId").value, this.scorecardEditorForm.get("role").value);
        }
    }

    onRoleSelection(event) {
        this.selectedTemplates = null;
        if (this.scorecardEditorForm.get("businessLineId").value && this.scorecardEditorForm.get("role").value.code) {
            let role = this.scorecardEditorForm.get("role").value;
            this.loadTemplates(this.scorecardEditorForm.get("businessLineId").value, this.scorecardEditorForm.get("role").value.code);
        }
    }

}
