<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar class="button-icon">
      <button mat-raised-button (click)="goToAuditPlanPage()">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
    </div>
    <mat-card-title *ngIf="isSave" class="button-icon-text">Create Audit Plan</mat-card-title>
    <mat-card-title *ngIf="isPresent && editable" class="button-icon-text">Edit Audit Plan</mat-card-title>
    <mat-card-title *ngIf="isPresent && !editable" class="button-icon-text">View Audit Plan</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="auditPlanEditorForm" novalidate (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row">

        <mat-form-field>
          <input matInput name="tentativeFromDate" formControlName="tentativeFromDate" [max]="auditPlanEditorForm.get('tentativeToDate').value"
            (focus)="tentativeFromDatePicker.open()" (click)="tentativeFromDatePicker.open()" [matDatepicker]="tentativeFromDatePicker"
            placeholder="From Date">
          <mat-datepicker-toggle matSuffix [for]="tentativeFromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #tentativeFromDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.tentativeFromDate">{{ formErrors.tentativeFromDate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput name="tentativeToDate" formControlName="tentativeToDate" [min]="auditPlanEditorForm.get('tentativeFromDate').value"
            (focus)="tentativeToDatePicker.open()" (click)="tentativeToDatePicker.open()" [matDatepicker]="tentativeToDatePicker"
            placeholder="To Date">
          <mat-datepicker-toggle matSuffix [for]="tentativeToDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #tentativeToDatePicker></mat-datepicker>
          <mat-error align="start" *ngIf="formErrors.tentativeToDate">{{ formErrors.tentativeToDate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <input type="text" placeholder="Select Branch" matInput formControlName="branchNameCode" [matAutocomplete]="autoBranchCode">
          <mat-autocomplete #autoBranchCode="matAutocomplete">
            <mat-option *ngFor="let branch of filteredBranches | async" [value]="branch.branchNameCode">
              {{branch.branchNameCode}}
            </mat-option>
          </mat-autocomplete>
          <mat-error align="start" *ngIf="formErrors.branchNameCode">{{ formErrors.branchNameCode }}</mat-error>
        </mat-form-field>
       
        <mat-form-field>
  <input type="text" placeholder="Team Lead" matInput formControlName="teamLead" [matAutocomplete]="autoteamLead">
  <mat-autocomplete #autoteamLead="matAutocomplete">
    <mat-option *ngFor="let auditor of filteredTeamLead | async" [value]="auditor.login"
      [disabled]="(auditor.login === auditPlanEditorForm.get('assistant1').value) 
        || (auditor.login === auditPlanEditorForm.get('assistant2').value)
        || (auditor.login === auditPlanEditorForm.get('assistant3').value)">
      {{ auditor.login + ' - ' + auditor.firstName }}
    </mat-option>
  </mat-autocomplete>
  <mat-error align="start" *ngIf="formErrors.teamLead">{{ formErrors.teamLead }}</mat-error>
</mat-form-field>

<mat-form-field>
  <input type="text" placeholder="Assistant 1" matInput formControlName="assistant1" [matAutocomplete]="autoassistant1">
  <mat-autocomplete #autoassistant1="matAutocomplete">
    <mat-option *ngFor="let auditor of filteredAssistant1 | async" [value]="auditor.login" 
      [disabled]="(auditor.login === auditPlanEditorForm.get('teamLead').value)
        || (auditor.login === auditPlanEditorForm.get('assistant2').value)
        || (auditor.login === auditPlanEditorForm.get('assistant3').value)">
      {{ auditor.login + ' - ' + auditor.firstName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field>
  <input type="text" placeholder="Assistant 2" matInput formControlName="assistant2" [matAutocomplete]="autoassistant2">
  <mat-autocomplete #autoassistant2="matAutocomplete">
    <mat-option *ngFor="let auditor of filteredAssistant2 | async" [value]="auditor.login" 
      [disabled]="(auditor.login === auditPlanEditorForm.get('teamLead').value)
        || (auditor.login === auditPlanEditorForm.get('assistant1').value)
        || (auditor.login === auditPlanEditorForm.get('assistant3').value)">
      {{ auditor.login + ' - ' + auditor.firstName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field>
  <input type="text" placeholder="Assistant 3" matInput formControlName="assistant3" [matAutocomplete]="autoassistant3">
  <mat-autocomplete #autoassistant3="matAutocomplete">
    <mat-option *ngFor="let auditor of filteredAssistant3 | async" [value]="auditor.login" 
      [disabled]="(auditor.login === auditPlanEditorForm.get('teamLead').value)
        || (auditor.login === auditPlanEditorForm.get('assistant2').value)
        || (auditor.login === auditPlanEditorForm.get('assistant1').value)">
      {{ auditor.login + ' - ' + auditor.firstName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

        

        <mat-form-field>
          <input matInput name="auditForMonth" formControlName="auditForMonth"
            placeholder="Enter Month Number">
          <mat-error align="start" *ngIf="formErrors.auditForMonth">{{ formErrors.auditForMonth }}</mat-error>
        </mat-form-field>
        
        <mat-form-field>
          <input matInput name="auditForYear" formControlName="auditForYear"
          placeholder="Enter Year XXXX">
        <mat-error align="start" *ngIf="formErrors.auditForYear">{{ formErrors.auditForYear }}</mat-error>
        </mat-form-field>

        <mat-card-actions *ngIf="editable" class="pull-right">
          <button mat-raised-button color="primary">Submit</button>
          <button mat-raised-button (click)="goToAuditPlanPage()">Cancel</button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card-content>
</mat-card>