
import { ChangeDetectorRef,Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AuditTransactionService } from '../audit-transaction-service';
import { Subscription } from 'rxjs';



@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (typeof value === 'string') {
            value = JSON.parse(value);
        }
        const keys = [];
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                let changedKey = key;
                if (key === 'observationChoice') {
                    changedKey = 'Observation';
                }
                keys.push({ key: changedKey, value: value[key] });
            }
        }
        return keys;
    }
}

@Component({
    selector: 'app-audit-transaction-question',
    templateUrl: './audit-transaction-question.component.html',
    // styleUrls: ['./audit-transaction-question.component.css']
})
export class AuditTransactionQuestionComponent implements OnInit, OnDestroy {

    form: FormGroup;
    answers: any[] = ['Yes', 'No', 'NA'];
    isRectified: any[] = ['Yes', 'No'];
    observationChoiceArray: any[];
    responseDetailObject: any;
    hideObservations = true;
    error: any;
    rectified = 'No';



    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private auditTransactionService: AuditTransactionService,
        private snackBar: MatSnackBar,
        private changeDetector: ChangeDetectorRef,
        private dialogRef: MatDialogRef<AuditTransactionQuestionComponent>) {
        this.responseDetailObject = data.responseDetailObject;
        this.loadObserVationChoice();

    }

    loadObserVationChoice() {
        const obj = JSON.parse(this.data.responseDetailObject.question.configuration);
        if (obj !== null && obj !== undefined) {
            this.observationChoiceArray = obj['observationChoice'];
        }
    }

    ngOnInit() {
        const currentAnswer = JSON.parse(this.responseDetailObject.answer);
        this.hideObservations = true;
        if (currentAnswer["answer"] === 'No') {
            this.hideObservations = false;
        }
        this.form = this.formBuilder.group({
            answer: [currentAnswer["answer"], Validators.required],
            observationConfig: this.formBuilder.array(this.getObservationChanges(currentAnswer["observationConfig"]))
        });
    }

    getObservationChanges(observationConfig: any): any {
        const formArray = [];
        observationConfig = observationConfig ? observationConfig : [];
        this.observationChoiceArray.forEach(choise => {
            let result = observationConfig.find(a => a.hasOwnProperty(choise));
            result = result ? result[choise] : [];
            formArray.push(this.formBuilder.group({
                observation: [choise],
                instances: [result[0] ? result[0] : ''],
                deviations: [result[1] ? result[1] : ''],
                isRectified: [result[2] ? result[2] : '']
            }));
        });
        return formArray;
    }

    answerChange(event) {
        if (this.form.controls['answer'].value === 'No') {
            this.hideObservations = false;
        } else {
            this.hideObservations = true;
        }
    }

    submit() {
        this.error = '';
        const selectedAnswer = this.form.controls['answer'].value;
        if (selectedAnswer === null || selectedAnswer === '' || selectedAnswer === undefined) {
            this.error = 'select answer';
            return;
        }
        if (selectedAnswer && selectedAnswer === 'No') {
            const observationConfigAnswer = this.form.value.observationConfig;
            const observationConfigItems = [];
            observationConfigAnswer.every((element) => {
                const observation = element.observation;
                const instance = element.instances;
                const deviation = element.deviations;
                let isRectified = element.isRectified;
                if (isRectified === '') {
                    isRectified = this.rectified;
                }
                const observationConfigItem = {};
                observationConfigItem[observation] = [instance, deviation, isRectified];

                if (instance && !deviation && deviation === '') {
                    this.error = 'Please enter both instances and devitaions';
                    return false;
                }
                if (deviation && !instance && instance === '') {
                    this.error = 'Please enter both instances and devitaions';
                    return false;
                }

                if ((instance !== '' && instance <= 0) || (deviation !== '' && deviation <= 0)) {
                    this.error = 'Instances and deviations must be greater than zero';
                    return false;
                }
                if (deviation > instance) {
                    this.error = 'deviation should not be greater than instance';
                    return false;
                }

                if (this.error !== '') {
                    return false;
                }


                if (this.error === '') {
                    if ((instance !== null && instance !== '') && (deviation !== null && deviation !== '')) {
                        observationConfigItems.push(observationConfigItem);
                    }
                    return true;
                }
                return false;

            });

            if (observationConfigItems.length === 0 && this.error === '') {
              this.error = 'Instances and deviations should be there for atleast one of the observations';
            }
            if (this.error === '') {
                const answerObjResponse = { 'answer': selectedAnswer, 'observationConfig': observationConfigItems };
                this.udpdateResponseDetails(answerObjResponse);
            }
        } else if (this.error === '') {
            const answerObjResponse = { 'answer': selectedAnswer };
            this.udpdateResponseDetails(answerObjResponse);
        }
    }
    // udpdateResponseDetails(answerObj) {
    //     this.responseDetailObject.answer = JSON.stringify(answerObj);
    //     this.auditTransactionService.updateQuestionaireObservation(this.responseDetailObject)
    //         .pipe(takeUntil(componentDestroyed(this))).subscribe((response) => {
    //             this.dialogRef.close({ msg: 'Updated successfully', data: response });
    //         }, (errorResponse) => {
    //             this.dialogRef.close({ msg: errorResponse.error.title });
    //         });
    // }
             udpdateResponseDetails(answerObj) {
            const answer = JSON.stringify(answerObj);
            this.responseDetailObject.answer = answer;
            this.responseDetailObject.observationResponseDetailDTOs = answerObj.observationConfig;
            this.auditTransactionService.updateQuestionaireObservation(this.responseDetailObject)
               .pipe(takeUntil(componentDestroyed(this)))
               .subscribe(
                   (saveResponse) => this.onSaveSuccess(saveResponse),
                (errorResponse) => this.onSaveError(errorResponse)
               );
            //    this.auditTransactionService.notifyUpdateSuccess();
            
            }
              onSaveSuccess(saveResponse) {
                this.snackBar.open(`Updated successfully`, "Close", {
                    duration: 3000,
                  });
                  this.dialogRef.close('close');  
              }

                onSaveError(errorResponse){
                    var obj = JSON.parse(errorResponse.error);
                    this.snackBar.open(obj.title, "Close", {
                      duration: 5000,
                    });
                  }        

    ngOnDestroy(): void {
       
    }
}
