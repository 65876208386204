import { QuestionaireCopyComponent } from './questionnaire-copy.component';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ListSource } from 'projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';
import { QuestionService } from '../question/question.service';
import { QuestionaireDeleteDialogComponent } from './questionaire-delete-dialog.component';
import { QuestionaireDataSource } from './questionaire.data.source';
import { QuestionaireService } from './questionaire.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-questionaire',
    templateUrl: './questionaire.component.html'
})
export class QuestionaireComponent implements OnInit, AfterViewInit, OnDestroy {
    title: string;
    pagingAndSortParams: PagingAndSortParams;
    dataSource: QuestionaireDataSource;
    listSource: ListSource;
    questionaireId: number;
    errorMessage: string;
    refreshData: any;
    displayedColumns = ['code', 'questionnaireVersionCode', 'questionnaireVersionName', 'name', 'status', 'action'];
    name: String;
    code: String;
    status: String;
    required = false;
    questionnaireCodeList = [];
    questionnaireStatusList = ['DRAFT', 'ACTIVE', 'DEPRECATED'];
    constructor(private questionaireService: QuestionaireService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private referenceCodeService: ReferenceCodeService,
        private questionService: QuestionService) { }

      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;

    ngOnInit() {
        this.QuestionaireCode();
        this.dataSource = new QuestionaireDataSource(this.questionaireService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'name';
                }

               this.loadQuestionnaires(sortColumn);
            });

    }

    ngAfterViewInit(): void {
        this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
        .subscribe(() => this.loadQuestionnairesPage());
    }

    loadQuestionnaires(sortColumn: String) {
        this.dataSource.loadAudits(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.name, this.code, this.status);
    }

    loadQuestionnairesPage() {
        this.router.navigate(['questionaire'], this.getQueryParams());
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }
    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(questionaireData: any) {
        const dialogRef = this.dialog.open(QuestionaireDeleteDialogComponent, {
            data: questionaireData
        });
        dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe(result => {
            this.ngOnInit();
        });
    }
    clone(element: any) {
        this.questionaireId = element;
        this.questionaireService.questionaireClone(this.questionaireId).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }
    questionaireActive(element: any) {
        this.questionaireId = element;
        this.questionaireService.questionaireActivate(this.questionaireId).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }

    onSaveSuccess(result) {
        this.snackBar.open(result.body, 'Close', {
            duration: 3000,
        });
        this.ngOnInit();
    }

    onSaveError(response) {
        let errorObj = JSON.parse(response.error);
        this.snackBar.open(errorObj.title
        , 'Issue', {
            duration: 5000,
            horizontalPosition: 'center'
        });
    }

    edit(questionaireId: number) {
        this.router.navigateByUrl('questionaire/edit/' + questionaireId);
    }

    clearFilter() {
        this.code = undefined;
        this.status = undefined;
        this.name = undefined;
        this.search()
    }

    // loadRefcodes() {
    //     this.referenceCodeService.getQuestionaireCodes().pipe(takeUntil(componentDestroyed(this))).
    //     subscribe(response => this.questionnaireCodeList = response);
    // }

    QuestionaireCode() {
        //this.questionnaireCodesList = bmtReportTypes;

        this.questionaireService.getUniqueQuestionnaireCode().pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
            (res: HttpResponse<any>) => {
                this.questionnaireCodeList = res.body;    
            }
        );
    }

    downloadQuestionsDetailReport(element: any) {
        this.questionaireId = element;
        this.questionService.downloadQuestionsDetailReport(this.questionaireId).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
          console.log('start download:', res);
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.');
        });
      }


      search(){
        this.pagingAndSortParams.pageIndex = 0
        this.paginator.pageIndex = 0
        this.loadQuestionnaires('');
        this.loadQuestionnairesPage()
    }
    copyDialog(questionaireData: any) {
        const copyRef = this.dialog.open(QuestionaireCopyComponent, {
            data: questionaireData
        });
        copyRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe(result => {
            this.ngOnInit();
        });
    }
}
