export class PagingAndSortParams {

  public static PAGEINDEXKEY = 'page';
  public static PAGESIZEKEY = 'size';
  public static SORTCOLUMNKEY = 'sc';
  public static SORTDIRECTION = 'sd';

  constructor(
    public pageIndex = 0,
    public pageSize = 10,
    public sortColumn = '',
    public sortDirection = 'asc'
  ) { }
  public defaultPageSizeOptions() {
    return [1, 3, 5, 10];
  }
  public appDataDefaultPageSizeOptions() {
    return [10, 30, 50];
  }
}
