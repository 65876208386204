<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToBranchListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="!editable" class="button-icon-text">View Branch</mat-card-title>
        <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create Branch</mat-card-title>
        <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit Branch</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="branchEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap"fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row" >
                
                    
                        <input name="id" type="hidden" formControlName="id" />
                    
                
                    
                        <input name="version" type="hidden" formControlName="version" />
                    
                
                    
                        <mat-form-field>
                            <input name="name" matInput type="text"
                                   formControlName="name" placeholder="Name" />
                            <mat-error align="start" *ngIf="formErrors.name">
                                {{ formErrors.name }}
                            </mat-error>
                        </mat-form-field>
                    
                
                    
                        <mat-form-field>
                            <input name="code" matInput type="text"
                                   formControlName="code" placeholder="Code" />
                            <mat-error align="start" *ngIf="formErrors.code">
                                {{ formErrors.code }}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field>
                            <mat-select placeholder="Business Line" name="businessLineId" formControlName="businessLineId">
                              <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                                {{businessLine.name}}
                              </mat-option>
                            </mat-select>
                            <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                          </mat-form-field>
                    
                          <mat-form-field>
                            <input name="latitude" matInput type="number"
                                   formControlName="latitude" placeholder="Latitude" />
                            <mat-error align="start" *ngIf="formErrors.latitude">
                                {{ formErrors.latitude }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input name="longitude" matInput type="number"
                                   formControlName="longitude" placeholder="Longitude" />
                            <mat-error align="start" *ngIf="formErrors.longitude">
                                {{ formErrors.longitude }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field >
                            <input name="branchStatus" matInput type="text"
                                   formControlName="branchStatus" placeholder="Status">
                        </mat-form-field>

                <mat-card-actions *ngIf="editable" class="pull-right" >
                    <button mat-raised-button color="primary">Submit</button>
                    <button mat-raised-button (click)="goToBranchListPage()">Cancel</button>
                </mat-card-actions>
            </div>

        </form>
    </mat-card-content>
</mat-card>
