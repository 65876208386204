<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar style="text-align: center; padding: 10px 0px;">
            <mat-icon> insert_drive_file</mat-icon>
        </div>
        <mat-card-title class="button-icon-text">Job File Upload</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <p>Please select the Import type and download Sample template file if Required.</p>
        <form [formGroup]="fileImportForm" novalidate style="padding: 10px;" (ngSubmit)="onSubmit('FILESAMPLE')">
            <!-- <mat-form-field>
                <mat-select name="code" formControlName="code" [(ngModel)]="code" placeholder="Import Type">
                    <mat-option *ngFor="let allFileImportType of allFileImportTypesList"
                        [value]="allFileImportType.code">
                        {{allFileImportType.name}}</mat-option>
                </mat-select>
                <mat-error align="start" *ngIf="formErrors.code">{{ formErrors.code }}</mat-error>
            </mat-form-field>&nbsp;&nbsp; -->

            <mat-form-field>
                <mat-select placeholder="Import Type" name="code" formControlName="code">
                    <mat-option *ngFor="let fileImportType of fileImportTypesList" [value]="fileImportType.name" >
                        {{fileImportType.name}} 
                    </mat-option>
                </mat-select>
                <mat-error align="start"  *ngIf="formErrors.code">  {{ formErrors.code }}</mat-error>
            </mat-form-field> &nbsp;&nbsp;

            <button mat-raised-button color="accent" matTooltip="Download Templete" (click)="downloadTemplate()">
                Download Template
            </button>
        </form>
        <br>
        <form style="padding: 10px;">
            <div>
                <span>Please upload a file</span>&nbsp;&nbsp;
                <button mat-raised-button color="primary" style="border-radius: 50%" (click)="onClick($event)"
                    matTooltip="Choose File">
                    <mat-icon> attach_file </mat-icon>
                </button>
            </div>
            <br>
            <input #fileUpload id="fileUpload" type="file" (input)="onInput($event)" style="display:none;"
                (change)="onFileSelection($event)" [(ngModel)]="inputFileName" accept=".xlsx,.csv">
            <div *ngFor="let f of files">
                <span>{{f.name}}</span>
                <button mat-icon-button color="primary" [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
                    (click)="removeSelectedFile(index)">
                    <mat-icon>{{deleteButtonIcon}}</mat-icon>
                </button>
            </div>
            <mat-card-actions class="pull-right">
                <button mat-raised-button color="accent" (click)="onSubmit('FILEEXECUTE')"
                    [disabled]="!jobDetails.id">Execute</button>
                <button mat-raised-button color="primary" (click)="onSubmit('FILEUPLOAD')">Upload</button>
                <button mat-raised-button (click)="clear()">Clear</button>
            </mat-card-actions>
        </form>
        <div style="color: blue; font-weight: bold; white-space: pre-wrap;">{{message}}</div>
    </mat-card-content>


</mat-card>
