import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {takeUntil, delay} from 'rxjs/operators';
import {QuestionaireService} from '../../questionaire/questionaire.service';
import {AuditTransactionDataSource} from './audit-transaction.data.source';
import {AuditTransactionService} from './audit-transaction-service';
import {ListSource} from 'projects/ig-core/src/lib/list/list-source';
import {PagingAndSortParams} from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AuditTransactionRemarksDialogComponent} from './audit-transaction-remarks-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuditTransactionReduceScoreGradeDialogComponent} from './audit-transaction-reduce-score-grade-dialog.component';
import { statusList} from 'src/app/constants/data.constants';

@Component({
    selector: 'app-audit-transaction-workflow',
    templateUrl: './audit-transaction-workflow.component.html',
})
export class AuditTransactionWorkFlowComponent implements OnInit, OnDestroy, AfterViewInit {
    dataSource: AuditTransactionDataSource;
    listSource: ListSource;
    pagingAndSortParams: PagingAndSortParams;
    displayedColumns = ['auditNo', 'branchName', 'branchCode', 'planFromDate', 'planToDate', 'status', 'action'];
    loggedInUser: any;
    showScore: boolean;
    editScore: boolean;
    statusCodes: any = [];
    auditTransactions: any;
    branchDropdownList: any[];
    selectedBranches: any[] = [];
    dialogRef: MatDialogRef<AuditTransactionRemarksDialogComponent>;
    dialogRefForSore: MatDialogRef<AuditTransactionReduceScoreGradeDialogComponent>;
    public auditTransactionForm: FormGroup;
    allBranchesList: any[] = [];
    branchDropdownSettings = {
        singleSelection: false,
        enableCheckAll: false,
        idField: 'code',
        textField: 'name',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        limitSelection: 3
    };
    filteredBranchList: any[] = [];
    branchFilter: any;
    public statusLists = statusList;
    toDateFilter: string;
    fromDateFilter: string;
   

      @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:true}) sort: MatSort;
   

    constructor(private activatedRoute: ActivatedRoute, private router: Router,
                private questionaireService: QuestionaireService,
                private auditTransactionService: AuditTransactionService, public dialog: MatDialog,
                private formBuilder: FormBuilder) {
        this.dataSource = new AuditTransactionDataSource(this.auditTransactionService);
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                // this.auditTransactions = data.auditTransactions;
                this.loggedInUser = data.loggedInUser.body;
                this.statusCodes = data.statusCodes.body;
            });
    }

    onItemSelect(event:any){
        this.selectedBranches.push(event?.code)        
    }

    onDeselect(event){
        this.selectedBranches.splice(this.selectedBranches.findIndex(x => x == event.code),1)     
    }

    ngOnInit() {
        this.buildForm();
        this.showScore = false;
        this.editScore = false;
        if (this.loggedInUser.authorities.indexOf('ROLE_SHOW_SCORES') >= 0) {
            this.showScore = true;
        }
        if (this.loggedInUser.authorities.indexOf('ROLE_EDIT_SCORES') >= 0) {
            this.editScore = true;
        }
        this.loadBranches();
        this.dataSource = new AuditTransactionDataSource(this.auditTransactionService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'id';
                }
                this.loadAuditTransactionsOnWorkflow(sortColumn);
            });

        this.branchFilter.valueChanges.pipe(takeUntil(componentDestroyed(this)))
            .subscribe(() => {
                this.filterBranch();
            });
    }
    buildForm() {
        this.auditTransactionForm = this.formBuilder.group({
        branchFilter:"",
        tentativeFromDate:"",
        tentativeToDate:"",
        status:""
        });
    }

    filterBranch() {
        if (!this.branchDropdownList) {
            return;
        }
        // get the search keyword
        let search = this.branchFilter.value;
        if (search) {
            search = search.toLowerCase();
            const filterData = [];
            // this.loadBranches();
            this.branchDropdownList = this.allBranchesList;
            for (let i = 0; i < this.branchDropdownList.length; i++) {
                const branch = this.branchDropdownList[i];
                if (branch.name.toLowerCase().startsWith(search)) {
                    filterData.push(this.branchDropdownList[i]);
                }
            }
            this.branchDropdownList = filterData;
        } else {
            this.branchDropdownList = this.allBranchesList;
        }
    }

    ngAfterViewInit(): void {

        this.listSource.subscribePaginationAndSortChange().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(() => this.loadListParams());
    }

    loadListParams() {
        this.router.navigate([], this.getListParams());
    }

    getListParams() {
        return this.listSource.getQueryParamWithPaginationAndSortParams();
    }

    loadBranches() {
        this.questionaireService.getAllBranchesForUser().pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.branchDropdownList = res.body;
                    this.allBranchesList = res.body;
                    this.filteredBranchList = this.filterAndModifyBranches(
                        this.allBranchesList
                        );
                }
            );
    }

    onSubmit(sortColumn: String) {
        this.loadAuditTransactionsOnWorkflow(sortColumn);
    }

    loadAuditTransactionsOnWorkflow(sortColumn: String) {
        this.dataSource.loadAuditTransactionsOnWorkflow(
            sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex,
            this.pagingAndSortParams.pageSize,
            this.auditTransactionForm.controls['branchFilter'].value,
            this.auditTransactionForm.controls['tentativeFromDate'].value,
            this.auditTransactionForm.controls['tentativeToDate'].value,
            this.auditTransactionForm.controls['status'].value);

    }

    getCheckList(id: number, status: string) {
        this.router.navigateByUrl('auditTransaction/checklists/' + status + '/' + id);
    }

    showScores(id: number) {
        this.router.navigateByUrl('auditTransaction/scores/' + id);
    }

    getDetailedInfo(id: number, status: string) {
        this.router.navigateByUrl('auditTransaction/detailedInfo/' + status + '/' + id);
    }

    // Opens a Dialog Box asking for a remark as an Input, and sends the selected AuditTransaction data to the DialogComponent
    getRemarksDialog(element: any) {
        // Opens the Dialog registered as AuditTransactionRemarksDialog,
        // the selected AuditTransaction is send in the data variable as element
        this.dialogRef = this.dialog.open(AuditTransactionRemarksDialogComponent, {
            width: '500px',
            data: {element: element}
        });
        // Executes when the Dialog Box is Closed
        this.dialogRef.afterClosed()
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(result => {
                console.log('The dialog was closed');
                if (result) {
                    // update local variable
                    this.dataSource.loadAuditTransactionsOnWorkflow(this.pagingAndSortParams.sortDirection,
                        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.selectedBranches,
                        this.auditTransactionForm.controls['tentativeFromDate'].value,
                        this.auditTransactionForm.controls['tentativeToDate'].value,
                        this.auditTransactionForm.controls['status'].value);
                }
            });
    }

    getReducedScoreDialog(element: any) {
        // Opens the Dialog registered as AuditTransactionRemarksDialog,
        // the selected AuditTransaction is send in the data variable as element
        if (this.loggedInUser.role === 'HA_SUPERVISOR' || this.loggedInUser.role === 'HO_ASST') {
            element.readOnly = false;
        } else {
            element.readOnly = true;
        }

        if (element.status === 'WF_AUDIT_CLOSED') {
            element.readOnly = true;
        }
        this.dialogRefForSore = this.dialog.open(AuditTransactionReduceScoreGradeDialogComponent, {
            width: '500px',
            data: {element: element},
        });
        // Executes when the Dialog Box is Closed
        this.dialogRefForSore.afterClosed()
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(result => {
                console.log('The dialog was closed');
                if (result) {
                    // update local variable
                    this.dataSource.loadAuditTransactionsOnWorkflow(this.pagingAndSortParams.sortDirection,
                        this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.selectedBranches,
                        this.auditTransactionForm.controls['tentativeFromDate'].value,
                        this.auditTransactionForm.controls['tentativeToDate'].value,
                        this.auditTransactionForm.controls['status'].value);
                }
            });
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    clear(){
        this.auditTransactionForm.get("branchFilter").setValue("");
        this.auditTransactionForm.get("tentativeFromDate").setValue("");
        this.auditTransactionForm.get("tentativeToDate").setValue("");
        this.auditTransactionForm.get("status").setValue("");
        this.onSubmit("id");
    }

    // concatenating branch name code with name
     filterAndModifyBranches(branches: any[]): any[] {
      return branches.map((branch) => {
      const concatenatedData = `${branch.code} - ${branch.name}`;
      return concatenatedData;
      });
  }
}

