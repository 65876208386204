<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar style="text-align: center; padding: 10px 0px;">
            <mat-icon>insert_drive_file</mat-icon>
        </div>
        <mat-card-title class="button-icon-text">Master Filter Upload</mat-card-title>
    </mat-card-header>
        
    <mat-card-content>
        
        <form [formGroup]="masterfilterform">
            <div fxLayout="row wrap"fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="three-per-row" >
            <mat-form-field>
                <mat-select placeholder="Business Line"  formControlName="businessLineId" aria-required="true"
                (selectionChange)="changeBusinessLine($event)">
                     <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                          {{businessLine.name}} </mat-option>
                </mat-select>
                    <mat-error align="start" *ngIf="formErrors.businessLineId">  {{ formErrors.businessLineId }}</mat-error>
            </mat-form-field>
            &nbsp;&nbsp;

            <mat-form-field >
              <mat-select placeholder="Questionnaire Code"  formControlName="questionnaireCode" >
                <mat-option *ngFor="let questionnaireCode of questionnaires" [value]="questionnaireCode.code">
                  {{questionnaireCode.code}} 
                </mat-option>
              </mat-select>
              <mat-error align="start"  *ngIf="formErrors.questionnaireCode">  {{ formErrors.questionnaireCode }}</mat-error>
            </mat-form-field>
            &nbsp;&nbsp;

            <mat-form-field>
                <mat-select placeholder="Master Filter" formControlName="masterfilter" (selectionChange)="downloadFile($event)">
                    <mat-option *ngFor="let masterfilter of masterFiltersList" [value]="masterfilter">
                        {{masterfilter.name}} 
                    </mat-option>
                </mat-select>
                <mat-error align="start"  *ngIf="formErrors.masterfilter">  {{ formErrors.masterfilter }}</mat-error>
            </mat-form-field>

            <br>
           
                <div >
                    <span>Please upload a csv file</span>&nbsp;&nbsp;
                    <button mat-raised-button color="primary" style="border-radius: 50%" (click)="onClick($event)"
                        matTooltip="Choose File">
                        <mat-icon> attach_file </mat-icon>
                    </button>
                </div>
                <input #fileUpload id="fileUpload" type="file" (input)="onInput($event)" style="display:none;"
                (change)="onFileSelection($event)" [(ngModel)]="inputFileName" accept=".xlsx,.csv">
                <div *ngFor="let file of files">
                <span>{{file.name}}</span>
                <button mat-icon-button color="primary" [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
                    (click)="removeSelectedFile(index)">
                    <mat-icon>{{deleteButtonIcon}}</mat-icon>
                </button>

                <mat-card-actions class="pull-left">
                    <button mat-raised-button (click)="clearFilter()">Clear</button>
                    <button mat-raised-button color="primary" (click)="onSubmit('FILEUPLOAD')">Upload</button>
                    <button mat-raised-button color="accent" (click)="onSubmit('FILEEXECUTE')"
                    [disabled]="!jobDetails.id">Execute</button>
                    
                </mat-card-actions>  
             </div>
            </div>
        </form>
        <div style="color: blue; font-weight: bold; white-space: pre-wrap;">{{message}}</div>
    </mat-card-content>
</mat-card>
            

        