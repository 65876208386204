import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createRequestOption } from 'projects/ig-core/src/lib/utils/request-util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { ScoreRisk } from '../audit/audit-transaction/audit-transaction-detail/score-risk.model';
import { Questionaire } from './questionaire.model';
import { fileNameHeader, FetchBranchesForStates } from '../../constants/data.constants';
import { formatDate } from '@angular/common';
import { BranchVisitParams } from 'src/app/dashboard/branch-visit-monitor/branch-visit.model';
import { BmtReportParamsModel } from 'src/app/dashboard/bmtProductivityReport/bmt-report-params.model';
import { DownloadFormatType } from 'src/app/dashboard/reports/download-format';


@Injectable({
    providedIn: 'root'
})
export class QuestionaireService {
 

    private API = 'api/questionaires';
    private businessAPI = 'api/business-lines';

    constructor(private http: HttpClient) { }
    questionaireData(req: any): Observable<HttpResponse<Questionaire[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.name) {
            params = params.set('name', req.name);
        }
        if (req.code) {
            params = params.set('code', encodeURIComponent(req.code));
        }
        if (req.status) {
            params = params.set('status', req.status);
        }
        return this.http.get<Questionaire[]>(this.API, {
            params, observe: 'response'
        });
    }

    getQuestionaire(id: number) {
        return this.http.get<Questionaire>(this.API + '/' + id);
    }

    deleteQuestionaireData(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    saveQuestionaire(questionaire: Questionaire): Observable<HttpResponse<Questionaire>> {
        const copy: Questionaire = Object.assign({}, questionaire);
        if (copy.id && copy.id != null) {
            return this.http.put<Questionaire>(this.API, copy, { observe: 'response' });
        } else {
            return this.http.post<Questionaire>(this.API, copy, { observe: 'response' });
        }
    }

    questionaireActivate(id: number) {
        const requrl = 'api/questionaires/activate/';
        return this.http.put(requrl + id, null, { observe: 'response', responseType: 'text' as 'json' });
    }

    questionaireClone(id: number): Observable<any> {
        const reqURl = 'api/questionaires/clone/';
        return this.http.post<any>(reqURl + id, null, { observe: 'response', responseType: 'text' as 'json' });
    }

    getAllBranchesForUser(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/branches/user', { observe: 'response' });
    }

    downloadExcel(req?: any): Observable<any> {

        const param = new BmtReportParamsModel;

        if (req.type !== undefined) {
            param.type = req.type;
        }
        if (req.stateNames !== undefined) {
            param.stateNames = req.stateNames;
        }
        if(req.businessLineId !== undefined) {
            param.businessLineId = req.businessLineId;
        }
        
        if (req.branchCodes !== undefined) {
            param.branchCodes = req.branchCodes;
        }// end if
        if (req.fromDate !== undefined) {
            param.fromDate = new Date(formatDate(req.fromDate, 'yyyy-MM-dd', 'en-us'));
        }// end if
        if (req.toDate !== undefined) {
            param.toDate = new Date(formatDate(req.toDate, 'yyyy-MM-dd', 'en-us'));
        }// end if


        return this.http.post('api/questionnaire-configs/download-report', param,
            { observe: 'response', responseType: 'blob' }
        ).pipe(map((resp: HttpResponse<Blob>) => {
            const out = { data: null, filename: null };
            out.data = resp.body;
            let fileName: string = resp.headers.get(fileNameHeader);
            if (fileName == null) {
                let type: any;
                type = req.downloadFormat === DownloadFormatType.EXCEL ? '.xlsx' : '.pdf';
                fileName = 'QuestionaireReport' + '.xlsx';
            }
            out.filename = fileName;
            return out;
        }));
    }

    chnageQuestionnaireDetailRiskStatus(scoreRisk: ScoreRisk): Observable<Object> {
        return this.http.post('api/questionnaire-detail/update/riskStatus', scoreRisk);
    }

    getAllStatesForUser(businessLineId:number): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
        params = params.set('businessLineId', businessLineId);
        }
        return this.http.get<any>('api/branches/branchsetStates', { params: params,observe: 'response' });
    }

    getAllBranchesForStates(businessLineId:number ,stateName: any, showOnlyMappedBranches: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        if(businessLineId !== undefined){
            params = params.set('businessLineId', businessLineId);
            }
        params = params.set('stateName', stateName);
        params = params.set('showOnlyMappedBranches', showOnlyMappedBranches);
        return this.http.get<Branch[]>('api/branches/state-search', { params: params, observe: 'response'});
    }

    questionnaireCopy(refrenceQuestionnaireId: number, questionaireCode: string, questionaireName : string): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.set("refrenceQuestionnaireId", refrenceQuestionnaireId);
        params = params.set("questionaireCode", questionaireCode);
        params = params.set("questionaireName", questionaireName);
        return this.http.post('api/questionaires/copy', params, { observe: 'response', responseType: 'text'});
    }

    getUniqueQuestionnaireCode(): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/questionaires/uniqueCode', { observe: 'response' });
    }

    getBusinessLine() {
        return this.http.get<any>(this.businessAPI);
    }

    getAllQuestionnaireConfigsQuestionnaire(businessLineId: number): Observable<HttpResponse<any>>{
    let params = new HttpParams();
    if(businessLineId !== undefined){
        params = params.set("businessLineId", businessLineId);
    }
    return this.http.get<any>('api/questionnaire-configs/questionnaire', {params: params, observe: 'response' });
    }

    getAllActiveUniqueQuestionaires() {
         return this.http.get<any>('api/questionaire/active/uniqueCode');
    }


    uploadMasterFile( masterFilter: string, multipartFile: File): Observable<HttpResponse<any>>{
        const formData = new FormData();
        formData.set('masterFilter', masterFilter);
        formData.set('multipartFile', multipartFile);
        return this.http.post<string>('api/master-filter-upload', formData, { observe: 'response',responseType: 'xlsx/csv' as 'json' });
    }

    getAllActiveQuestionaires() {
        return this.http.get<any>('api/questionaire/active/all');
   }

   getAllActiveBranchesForStates(businessLineId:number ,stateName: any, showOnlyMappedBranches: any): Observable<HttpResponse<Branch[]>> {
    let params = new HttpParams();
    if(businessLineId !== undefined){
        params = params.set('businessLineId', businessLineId);
        }
    params = params.set('stateName', stateName);
    params = params.set('showOnlyMappedBranches', showOnlyMappedBranches);
    return this.http.get<Branch[]>('api/active/branches/state-search', { params: params, observe: 'response'});
}
}


