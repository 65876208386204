import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { MaterialLibModule } from '../../../material-lib.module';
import { AuditTransactionDeleteDialogComponent } from './audit-transaction-delete-dialog.component';
import { AddRemarkDialogComponent } from './audit-transaction-detail/add-remark-dialog.component';
// tslint:disable-next-line: max-line-length
import { AuditTransactionDetailInformationResponseComponent, KeysPipe } from './audit-transaction-detail/audit-transaction-detail-information-response.component';
import { AuditTransactionDetailComponent } from './audit-transaction-detail/audit-transaction-detail.component';
import { AuditTransactionQuestionComponent } from './audit-transaction-detail/audit-transaction-question.component';
import { CheckListDetailComponent } from './audit-transaction-detail/checklist-detail.component';
import { RiskConfirmationDialogComponent } from './audit-transaction-detail/risk-confirmation-dialog.component';
import { ViewRemarkDialogComponent } from './audit-transaction-detail/view-remark-dialog.component';
import { AuditTransactionEditorComponent } from './audit-transaction-editor.component';
import {
    
    AuditTransactionDetailAllResponseResolveService,
    AuditTransactionDetailResolveService,
    AuditTransactionResolveService, AuditTransactionWorkFlowResolveService,
    AuditWorkFlowConfigCodesResolveService, LoggedInUserResolveService
} from './audit-transaction-resolve.service';
import { AuditTransactionShowScoreComponent } from './audit-transaction-show-score.component';
import { AuditTransactionHistoryComponent } from './audit-transaction-workflow-history.component';
import { AuditTransactionWorkFlowComponent } from './audit-transaction-workflow.component';
import { AuditTransactionRemarksDialogComponent } from './audit-transaction-remarks-dialog.component';
import { AuditTransactionReduceScoreGradeDialogComponent } from './audit-transaction-reduce-score-grade-dialog.component';
import { AuthenticatedUserService } from 'projects/ig-core/src/lib/authentication/authenticated-user-service';
import {MatBadgeModule} from '@angular/material/badge';
import { EditRemarksDialogComponent } from './audit-transaction-detail/edit-remark-dialog.component';
import { FileDeleteDialogComponent } from './file-delete-dialog.component';
import { CloseAuditDialogComponent } from './audit-transaction-detail/close-audit-dialog.component';

export const auditTransactionRoute: Route = {
    path: 'auditTransaction',
    canActivate: [AuthenticatedUserService],
    children: [
        {
            path: '',
            canActivate: [AuthenticatedUserService],
            component: AuditTransactionWorkFlowComponent,
            resolve: {
                // auditTransactions: AuditTransactionWorkFlowResolveService,
                loggedInUser: LoggedInUserResolveService,
                statusCodes: AuditWorkFlowConfigCodesResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_VIEW_AUDIT_TRANSACTIONS'] }
        },
        {
            path: 'view/:id',
            canActivate: [AuthenticatedUserService],
            component: AuditTransactionEditorComponent,
            resolve: {
                auditTransaction: AuditTransactionResolveService,
                editable: NonEditableResolveService
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_AUDIT_TRANSACTIONS_HISTORY'] }
        },
        {
            path: 'checklists/:status/:id',
            canActivate: [AuthenticatedUserService],
            component: AuditTransactionDetailComponent,
            resolve: {
                // auditTransactionDetails: AuditTransactionDetailResolveService,
                loggedInUser: LoggedInUserResolveService,
            },
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_AUDIT_TRANSACTIONS_CHECKLIST'] }
        },
        {
            path: 'scores/:id',
            canActivate: [AuthenticatedUserService],
            component: AuditTransactionShowScoreComponent,
            data: { 'authorities': ['ROLE_ADMIN', 'ROLE_AUDIT_TRANSACTIONS_VIEW_SCORE'] }
        },
        {
            path: 'response/:id',
            component: CheckListDetailComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService,
            },
        },
        {
            path: 'edit-detailed-info/:status/:id',
            component: AuditTransactionDetailInformationResponseComponent,
            resolve: {
                loggedInUser: LoggedInUserResolveService,
                // auditTransactionDetail: AuditTransactionDetailResolveService,
                auditTransactionDetail: AuditTransactionDetailAllResponseResolveService
            },
        }
    ]

};
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatCardModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatBadgeModule
    ],
    declarations: [AuditTransactionEditorComponent,
        AuditTransactionDeleteDialogComponent, AuditTransactionHistoryComponent,
        AuditTransactionWorkFlowComponent, AuditTransactionDetailComponent,
        CheckListDetailComponent, RiskConfirmationDialogComponent, AuditTransactionShowScoreComponent,
        AddRemarkDialogComponent,
        AuditTransactionDetailInformationResponseComponent, AuditTransactionRemarksDialogComponent,
        AuditTransactionReduceScoreGradeDialogComponent,
        AuditTransactionQuestionComponent, KeysPipe, ViewRemarkDialogComponent, EditRemarksDialogComponent, FileDeleteDialogComponent,CloseAuditDialogComponent],
    entryComponents: [AuditTransactionDeleteDialogComponent, RiskConfirmationDialogComponent,
        AuditTransactionQuestionComponent, AddRemarkDialogComponent, ViewRemarkDialogComponent,
        AuditTransactionRemarksDialogComponent, AuditTransactionReduceScoreGradeDialogComponent, EditRemarksDialogComponent, FileDeleteDialogComponent, CloseAuditDialogComponent],
    exports: [AddRemarkDialogComponent]
})
export class AuditTransactionModule { }
