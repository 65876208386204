import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccountRoutingModule } from './user-account-routing.module';
import { LoginComponent } from './login/login.component';
import {MaterialLibModule} from '../material-lib.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LogoutComponent } from './logout/logout.component';
import {IgCoreModule} from 'projects/ig-core/src/lib/ig-core.module';
import {ForgotPasswordDialogComponent} from './login/forgot-password-dialog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResolveResetKey} from './user-account-routing.module';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserAccountRoutingModule,
    MaterialLibModule,
    FlexLayoutModule,
      IgCoreModule
  ],
  declarations: [LoginComponent, LogoutComponent, ForgotPasswordDialogComponent, ResetPasswordComponent, ProfileComponent],
  exports: [LoginComponent],
  entryComponents: [ForgotPasswordDialogComponent],
  providers: [ResolveResetKey]
})
export class UserAccountModule { }
