import { Component, OnInit } from '@angular/core';
import {ConfigureService} from '../build-config/configure.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title: string;
  constructor(private configService: ConfigureService) { }
serverUrl: string;
  ngOnInit() {
    this.title = 'Samasta-Mavrc WebApp';
    console.log(this.title);
    this.serverUrl = this.configService.config.API_URL;
  }

}
