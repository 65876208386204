<mat-card [style.width]="'300px'">
    <mat-card-header class="page-title">
      <mat-card-title>Delete Center</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      Are you sure, Do you want to delete the center - {{center?.name}} ?
      <mat-error ngclass="errorMessage && errorMessage != ''">{{errorMessage | json}}</mat-error>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="delete()">Delete</button>
      <button mat-raised-button color="accent" (click)="cancel()">No Thanks</button>
    </mat-card-actions>
  </mat-card>
  