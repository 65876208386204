<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar class="button-icon">
      <button mat-raised-button (click)="goToUserPage()">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
    </div>
    <mat-card-title class="button-icon-text">Upload User Branch Mapping</mat-card-title>
    
  </mat-card-header>
  <mat-card-content>
    <!-- <button mat-raised-button class="pull-right" (click)="downloadCurrentMappings()">
        Download Current Mappings
    </button> -->
    <p>Please upload a xlsx file to upload the user and branch mappings.
        <button mat-raised-button small color="accent" class="pull-right" (click)="downloadTemplate()">
            Download Template
        </button>
      OR
      &nbsp;
      <button mat-raised-button small color="accent" class="pull-right" (click)="downloadExcitingMapping()">
        Download Existing Mapping
      </button>
    </p>

    <form (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row">
        <input id="fileUpload" type="file" accept=".xlsx" (change)="onFileSelection($event)" />
        <mat-card-actions class="pull-right">
          <button type="submit" mat-raised-button color="primary">Submit</button>
          <button mat-raised-button (click)="goToUserPage()">Cancel</button>
        </mat-card-actions>
      </div>
    </form>
    <div style="color: red">{{message}}</div>
  </mat-card-content>
</mat-card>
