import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/admin/branch/branch.model';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { FetchBranchesForStates } from '../../constants/data.constants';
import { ReportService } from '../reports/report.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cross-functional-deviation',
  templateUrl: './cross-functional-deviation.component.html',
  styleUrls: ['./cross-functional-deviation.component.css']
})
export class CrossFunctionalDeviationComponent implements OnInit, OnDestroy {


  stateDropdownList: any[];
  selectedStates: any[];
  businessLines: any[];
    isRoleAdmin:boolean;
    loggedInUserRole: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  selectedBranches: any[];
  stateList: string[];
  branches: Branch[];
  dropdownList: any[];
  stateDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  states: any;
  type: any;
  branchCodes: any[];
  public crossFunctionalDeviationReportForm: FormGroup;

  @ViewChild('stateSelect', { static: false }) stateSelect: MatSelect;
  @ViewChild('stateAllSelect', { static: false }) stateAllSelect: MatCheckbox;

  @ViewChild('branchSelect', { static: false }) branchSelect: MatSelect;
  @ViewChild('branchAllSelect', { static: false }) branchAllSelect: MatCheckbox;

  public formError: FormErrorModel;
  public formErrors = {
    type: '',
    selectedStates: '',
    selectedBranches: '',
    stateName: '',
    name: '',
    businessLineId:''
  };

  public deviationReportTypes = [{ code: 'CrossFunctionalBranchdeviationreport', name: 'Cross Functional Branch Deviation report' },
  { code: 'CrossFunctionalCenterdeviationreport', name: 'Cross Functional Center Deviation report' }];
  businessLine: any;
  businessLineId: number;

  constructor(private questionaireService: QuestionaireService,
    private reportService: ReportService, private igFormService: IgFormService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
                 ) { this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUserRole = data.loggedInUser.body.role;
              });
    }

  ngOnInit() {
    this.buildForm();
    this.loadBusiness();
    this.crossFunctionalDeviationReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.crossFunctionalDeviationReportForm.controls['businessLineId'].enable();
        }else{  
            this.loadStates();
        }
    this.formError = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void {
  }

  onItemSelect(event) {
  }

  loadBusiness() {
    this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
      subscribe(businessLineDTO => this.businessLines = businessLineDTO);
  }

  onSelectAll(event) { }

  onSelectState(state: any) {
    this.selectedStates.push(state);
    this.loadBranches();
  }

  onSelectAllState(state: any) {
    this.selectedStates = state;
    this.loadBranches();
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.crossFunctionalDeviationReportForm);
    if (this.crossFunctionalDeviationReportForm.valid) {
      this.downloadbranchEfficiencyReport();
    } else {
      this.formErrors = this.igFormService.validateForm(this.crossFunctionalDeviationReportForm, this.formErrors, false);
      return false;
    }
  }



  onReportTypeSelection(): void {
    this.selectedStates = [];
    this.selectedBranches = [];
  }

  buildForm() {
    this.crossFunctionalDeviationReportForm = this.formBuilder.group({
      type: [this.type, Validators.required],
      stateName: [this.selectedStates, Validators.required],
      name: [this.branches, Validators.required],
      businessLineId: ['',Validators.required]

    });
    this.crossFunctionalDeviationReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.crossFunctionalDeviationReportForm, this.formErrors, true);
    });
  }

  loadBranches() {
    console.log(this.selectedStates);
    this.stateList = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.stateList.push(this.selectedStates[i].name);
    }
    this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, FetchBranchesForStates.GET_MAPPED_ONLY)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<Branch[]>) => {
          this.branches = res.body;
          this.dropdownList = this.branches;

        });
  }

  loadStates() {
    this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.states = res.body;
          this.stateDropdownList = this.states;

        }
      );
  }

  downloadbranchEfficiencyReport() {
    this.branchCodes = [];


    this.selectedBranches.forEach((value) => {
      this.branchCodes.push(value.code);
    });



    this.reportService.downloadCrossFunctionalDeviationReport({
      type: this.type.code,
      branchCodes: this.branchCodes,
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  onItemDeSelect(item: any) {
    this.selectedBranches = [];
    this.loadBranches();
  }

  onItemDeSelectBranch(item: any) {
  }
  onItemDeSelectAll(item: any) {
    this.selectedStates = [];
    this.loadBranches();

  }

  clearFilter() {
    this.type = '';
    this.selectedBranches = [];
    this.selectedStates = [];
    this.loadBranches();
  }

  changeBusinessLine(selectedBusniessLine: any){
    this.selectedStates=null;
    this.businessLine = selectedBusniessLine.value;
    this.loadStates();
    this.loadBranches();
}
}
