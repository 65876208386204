import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { ScoreSet } from './score-card.model';
import { ScoreCardService } from './score-card.service';
/* import { BranchSetService } from './branch-set.service';
import { BranchSet } from './branch-set.model';
 */
@Injectable({
    providedIn: 'root'
})
export class ScoreCardResolverService implements Resolve<ScoreSet> {
    constructor(private scoreCardService: ScoreCardService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScoreSet> | Observable<never> {
        return this.scoreCardService.getScoreSet(""+route.paramMap.get('id'));
    }
}
