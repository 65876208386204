

import { Component, OnInit ,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AutoPopulateTagService } from '../auto-populate-tag.service';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-auto-populate-tag-list',
  templateUrl: './auto-populate-tag-list.component.html',
  styleUrls: ['./auto-populate-tag-list.component.css']
})
export class AutoPopulateTagListComponent implements OnInit {

  tagListForm: FormGroup;
  displayedColumns: string[] = ['code', 'module', 'description', 'action'];
  dataSource = new MatTableDataSource<any>();
  pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  tags = [];
  modules = [];
  pagingAndSortParams = {
    pageSize: 10,
    defaultPageSizeOptions: () => [5, 10, 20],
  };

  constructor(
    private fb: FormBuilder,
    private autoPopulateTagService: AutoPopulateTagService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.tagListForm = this.fb.group({
      code: [''],
      autoPopulateModuleId: [''],
      query: ['']
    });

    this.loadTags();
    this.loadModules(); 

    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      const filterValues = JSON.parse(filter);
      const matchFilter = [];

      if (filterValues.code) {
        matchFilter.push(data.code.toLowerCase().includes(filterValues.code.toLowerCase()));
      }

      if (filterValues.autoPopulateModuleId) {
        matchFilter.push(data.autoPopulateModuleId === filterValues.autoPopulateModuleId);
      }

      return matchFilter.every(Boolean);
    };
  }

  loadTags(): void {
    this.autoPopulateTagService.getTags().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
    });
  }

  getModuleNameById(id: number): string {
    const module = this.modules.find(m => m.id === id);
    return module ? module.name : '';
  }

  search(): void {
    const filterValues = this.tagListForm.value;
    this.dataSource.filter = JSON.stringify(filterValues);
  }

  clearFilter(): void {
    this.tagListForm.reset();
    this.search();
  }

  loadModules(): void {
    this.autoPopulateTagService.getModules().subscribe(data => {
      this.modules = data;
    });
  }

  viewTag(id: string): void {
    console.log('View Tag ID:', id);
    this.router.navigate([`/view/${id}`]);
  }

  editTag(id: string): void {
    console.log('Edit Tag ID:', id);
    this.router.navigate([`/update/${id}`]);
  }


  deleteTag(element: any): void {
    // console.log('Deleting tag with ID:', element.id);
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      data: { message: 'Are you sure you want to delete this tag?' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // console.log('Confirmed delete');
        
        this.autoPopulateTagService.deleteTag(element.id).subscribe(
          () => {
            // console.log('Delete successful');
            this.dataSource.data = this.dataSource.data.filter(tag => tag.id !== element.id);

            this.snackBar.open('AutoPopulateTag deleted successfully.', 'Close', {
              duration: 3000,
            });

          },
          error => {
            console.error('Error deleting tag:', error);
          }
        );
      }
    });
  }
}
