<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon> swap_horiz </mat-icon>
        </div>
        <div mat-card-title>
            Reference Codes
        </div>
        <div class="page-title-action">
            <button mat-button mat-raised-button color="accent" [routerLink]="'create'">
                <mat-icon>add_circle</mat-icon>
                Create Reference Codes
            </button>
        </div>
    </mat-card-header>

    <mat-card-content>
        <div>
            <mat-form-field style="padding-right: 20px;">
                <input matInput type="text" id="classifierFilter" name="classifierFilter" placeholder="Enter Classifier" [(ngModel)]="classifierFilter"
                />
            </mat-form-field>

            <mat-form-field style="padding-right: 20px;">
                <input matInput type="text" id="nameFilter" name="nameFilter" placeholder="Enter Name" [(ngModel)]="nameFilter" />
            </mat-form-field>

            <mat-form-field style="padding-right: 20px;">
                <input matInput type="text" id="codeFilter" name="codeFilter" placeholder="Enter Code" [(ngModel)]="codeFilter" />
            </mat-form-field>

            <button mat-raised-button color="primary" (click)="search()">
                <mat-icon>search</mat-icon>
            </button>
            &nbsp;
            <button mat-raised-button color="primary" (click)="clearFilter()">
                Clear
            </button>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear
                   [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="classifier">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="classifier">Classifier</th>
                            <td mat-cell *matCellDef="let element" data-label="Classifier"> {{element.classifier}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
                            <td mat-cell *matCellDef="let element" data-label="Name"> {{element.name}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="code">Code</th>
                            <td mat-cell *matCellDef="let element" data-label="Code"> {{element.code}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="parentReferenceCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="parentReferenceCode">Parent Reference Code</th>
                            <td mat-cell *matCellDef="let element" data-label="Parent Reference Code"> {{element.parentReferenceCode}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
                            <td mat-cell *matCellDef="let element" data-label="Status"> {{element.status ? 'Active' : 'In-Active'}}</td>
                        </ng-container>
                      
                        <!--<ng-container matColumnDef="field1">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="field1">Field 1</th>
                            <td mat-cell *matCellDef="let element" data-label="Field 1"> {{element.field1}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="field2">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="field2">Field 2</th>
                            <td mat-cell *matCellDef="let element" data-label="Field 2"> {{element.field2}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="field3">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="field3">Field 3</th>
                            <td mat-cell *matCellDef="let element" data-label="Field 3"> {{element.field3}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="field4">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="field4">Field 4</th>
                            <td mat-cell *matCellDef="let element" data-label="Field 4"> {{element.field4}}</td>
                        </ng-container>
                      
                        <ng-container matColumnDef="field5">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="field5">Field 5</th>
                            <td mat-cell *matCellDef="let element" data-label="Field 5"> {{element.field5}}</td>
                        </ng-container>-->
                     
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-button-toggle-group #referenceCodeActionGroup="matButtonToggleGroup" class="table-row-action">
                            <mat-button-toggle [routerLink]="['view', element.id]" title="View More">
                                <mat-icon>more</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle [routerLink]="['update', element.id]" title="Edit">
                                <mat-icon>edit</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle (click)="deleteDialog(element)" title="Delete">
                                <mat-icon>delete</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize"
                           [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()" [pageIndex]="pagingAndSortParams.pageIndex"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
