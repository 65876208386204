export class ObservationResponseRemarks {
    constructor(
        public id?: number,
        public version?:number,
        public observationResponseDetailId?: number,
        public userLogin?: string,
        public remark?: string,
        public createdBy?: string,
        public createdDate?: any,
        public ObservationResponseFiles? : any
    ){}
}
    