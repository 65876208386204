import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { MaterialLibModule } from 'src/app/material-lib.module';
import { AuthenticatedUserService } from '../../../../projects/ig-core/src/lib/authentication/authenticated-user-service';
import { AppDataComponent } from './app-data.component';
import {UserDetailsComponent}from './user-details.component';


export const appDataRoute: Route = {
  path: 'app-data',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      canActivate: [AuthenticatedUserService],
      component: AppDataComponent,
      data: { 'authorities': ['ROLE_ADMIN', 'ROLE_SHOW_APPSDATA'] }
    },
  ]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [AppDataComponent, UserDetailsComponent],
  entryComponents: [],
  exports: [RouterModule]
})
export class AppDataModule { }
