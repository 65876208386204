import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { takeUntil, filter } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {MATemplateHeader, MATemplateTaskMapping} from '../activity-role.model';
import {ActivityRoleService} from '../activity-role.service';
import {HttpResponse} from '@angular/common/http';
import {NameValueDto} from '../../../../../projects/ig-core/src/lib/interfaces/name-value-dto';
import {activityTypes} from '../../../constants/data.constants';
import {DatePipe, formatDate} from '@angular/common';
import { MatInput } from '@angular/material/input';
import { QuestionaireService } from '../../questionaire/questionaire.service';
import { UserService } from 'src/app/admin/user/user.service';

@Component({
    selector: 'app-activity-role-edit',
    templateUrl: './activity-role-edit.component.html',
    styleUrls: ['./activity-role-edit.component.css']
})
export class ActivityRoleEditComponent implements OnInit, OnDestroy {

    activityHeader: MATemplateHeader;
    editable = true;
    showError = false;
    isNew: boolean;
    fields: any;
    activitys: any[];
    monthFields:any;
    nameValueDTOs: NameValueDto[];
    activityList: any[];
    activityMonthList: any[];
    userDropdownList: any[];
    roleDropdownList: any[];
    templates: any;
    users: any;
    roles: any;
    selectedUsers = [];
    plannedDates = [];
    selectedRoles = [];
    activityData=[];
    existingVal: any[];
    disabledItem ='';
    selectIndex = 0;
    moduleName=[];
    selectedBranches: any[];
    userDropdownSettings = {};
    roleDropdownSettings = {};
    roleList = [];
    maTemplateTaskMapping: MATemplateTaskMapping[];
    public activityConfigurationEditorForm: FormGroup;
    templateTaskMappingDTOs: FormArray;
    monthTemplateDTOs: FormArray;
    public formError: FormErrorModel;
    minDate = new Date();
    monthlyActivityDates:Array<any> = []
    @ViewChildren('fromInput', {
        read: MatInput
      }) fromInput: QueryList<MatInput>;
    public formErrors= {
        'id': '',
        'version': '',
        'name': '',
        'code': '',
        'effectiveDate': '',
        'activity': '',
        'description': '',
        'count': '',
        'moduleName': '',
        'moduleCode': '',
        'selectedUsers': '',
        'selectedRoles' : '',
        'roleCode':'',
        // 'toDate': '',
        'month': '',
        'plannedDate': '',
        'type':'',
        'initiatedDate':'',
        'businessLineId':''
    };
    count: any;
    month: any;
    businessLines: any[];
    selectedBusinessLine: number;
  
    constructor(
        private questionaireService: QuestionaireService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService,
        private scoreCardService: ActivityRoleService,
        private activityRoleService: ActivityRoleService,
        private userService: UserService,
        private datePipe:DatePipe) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.activityRole === undefined) {
                    this.activityHeader = {};
                    this.activityHeader.templateTaskMappingDTOs = [];
                    this.activityHeader.monthTemplateDTOs = [];
                } else {
                    this.activityHeader = data.activityRole;
                    if(this.activityHeader.templateTaskMappingDTOs.length > 0) {
                        this.activityHeader.monthTemplateDTOs = [];
                        this.activityHeader.monthTemplateDTOs = this.activityHeader.templateTaskMappingDTOs.filter(activity => activity.type === 'MONTHLY');
                        this.activityHeader.templateTaskMappingDTOs = this.activityHeader.templateTaskMappingDTOs.filter(activity => activity.type === null || activity.type === 'DAILY');
                    } else {
                        this.activityHeader.monthTemplateDTOs = [];
                    }

                }
                this.editable = data.editable;
            });
    }

   ngOnInit() {
        this.ActivityTypes();
        this.buildForm();
        this.loadUsers();
        this.loadRole();
        if(this.activityHeader.id){
            this.loadRefrenceCode(this.activityConfigurationEditorForm.get('businessLineId').value);
        }
        this.loadBusinessLine();
        // this.loadRoles();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.activityConfigurationEditorForm.disable();
        }
    }

    dateChange(event, i){ 
        this.monthlyActivityDates = this.monthTemplateDTOs.at(i).get("initiatedDate").value
        let temp = this.datePipe.transform(event.value,'yyyy-MM-dd', 'en-us').toString()     
        if(!this.monthlyActivityDates.find(el=> el == temp)){
            this.monthlyActivityDates.push(temp)
            this.monthTemplateDTOs.at(i).get("initiatedDate").setValue(this.monthlyActivityDates)
        }    
    }

    remove(date,i){
        this.monthlyActivityDates = this.monthTemplateDTOs.at(i).get("initiatedDate").value
        this.monthlyActivityDates.splice(this.monthlyActivityDates.findIndex(el=> el === date),1)
        this.monthTemplateDTOs.at(i).get("initiatedDate").setValue(this.monthlyActivityDates)
        this.fromInput.forEach(el=>el.value = '')
    }

    ngOnDestroy(): void {
    }



    loadRefrenceCode(selectedBusinessLine: number) {
        this.activitys = [];
        this.questionaireService.getAllQuestionnaireConfigsQuestionnaire(selectedBusinessLine).pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
            (res: HttpResponse<any>) => {
                this.nameValueDTOs= res.body;
                this.nameValueDTOs.forEach(i => {
                    i.isDisable = false;
                    this.activitys.push(i);
                }); 
                this.activityHeader.templateTaskMappingDTOs.forEach ((val, index) => {
                    this.checkValidation({'value': val.moduleName}, index,'D');
                })
                this.activityHeader.monthTemplateDTOs.forEach ((val, index) => {
                    this.checkValidation({'value': val.moduleName}, index,'M');
                })   
            }
            , (res: HttpResponse<any>) => this.activityLoadError(res.body));
        //old code dont delete me for algo purpose    
        /* this.referenceCodeService.getActivityForMA('SUBMODULE_MASTER')
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.nameValueDTOs = data;
                this.nameValueDTOs.forEach(i => {
                    i.isDisable = false;
                    this.activitys.push(i);
                });
                this.activityHeader.templateTaskMappingDTOs.forEach ((val, index) => {
                    this.checkValidation({'value': val.moduleName}, index,'D');
                })
                this.activityHeader.monthTemplateDTOs.forEach ((val, index) => {
                    this.checkValidation({'value': val.moduleName}, index,'M');
                })
            }, (res: HttpResponse<any>) => this.activityLoadError(res.body)); */
    }
    private activityLoadError(response) {
        this.snackBar.open('Failed to get roles, Please try again later [' + response + ']', 'Close', {
            duration: 3000,
        });
    }


    buildForm() {
        this.isNew = false;

        if (this.activityHeader.id === undefined) {
            this.isNew = true;
        }
        this.activityConfigurationEditorForm = this.formBuilder.group({
            id: [this.activityHeader.id],
            version: [this.activityHeader.version],
            name: [this.activityHeader.name, Validators.required],
            code: [this.activityHeader.code, Validators.required],
            effectiveDate: [this.activityHeader.effectiveDate ? this.activityHeader.effectiveDate : new Date(), Validators.required], 
            roleCode: [this.activityHeader.roleCode, Validators.required],
            // toDate: [this.activityHeader.toDate],
            businessLineId: [this.activityHeader.businessLineId ,[Validators.required]],
            createdBy: [this.activityHeader.createdBy, []],
            createdDate: [this.activityHeader.createdDate, []],
            templateTaskMappingDTOs: this.formBuilder.array([]),
            monthTemplateDTOs:this.formBuilder.array([]),
        });

        this.activityConfigurationEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.activityConfigurationEditorForm, this.formErrors, true);
        });

        this.templateTaskMappingDTOs = this.activityConfigurationEditorForm.get('templateTaskMappingDTOs') as FormArray;
        if (this.activityHeader.templateTaskMappingDTOs.length > 0) {
            for (let i = 0; i < this.activityHeader.templateTaskMappingDTOs.length; i++) {
                this.templateTaskMappingDTOs.push(this.getMandatoryActivity(this.activityHeader.templateTaskMappingDTOs[i]));
            }
        } else {
            this.templateTaskMappingDTOs.push(this.addMandatoryActivity(''));
        }
        this.fields = this.templateTaskMappingDTOs.controls;
        console.log(this.fields);
        
        this.monthTemplateDTOs = this.activityConfigurationEditorForm.get('monthTemplateDTOs') as FormArray;
        if (this.activityHeader.monthTemplateDTOs.length > 0) {
            for (let i = 0; i < this.activityHeader.monthTemplateDTOs.length; i++) {
                this.monthTemplateDTOs.push(this.getMonthMandatoryActivity(this.activityHeader.monthTemplateDTOs[i]));
            }
        } else {
            this.monthTemplateDTOs.push(this.addMonthMandatoryActivity(''));
        }
        this.monthFields = this.monthTemplateDTOs.controls;
        console.log(this.monthFields);
        
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.activityConfigurationEditorForm);
        if (this.activityConfigurationEditorForm.valid) {
            this.activityConfigurationEditorForm.value.templateTaskMappingDTOs.forEach(i => {
                i.type = 'DAILY'
                this.activitys.forEach(j => {
                    if (j.name === i.moduleName) {
                        i.moduleCode = j.code;
                        i.businessCodeId = j.businessCode;
                    }

                    if (i.activity.toUpperCase() === 'MANDATORY ACTIVITY') {
                        i.isMandatory = true;
                    } else {
                        i.isMandatory = false;
                    }
                });
            });
            this.activityConfigurationEditorForm.value.monthTemplateDTOs.forEach(i => {
                i.type = 'MONTHLY'
                this.activitys.forEach(j => {
                    if (j.name === i.moduleName) {
                        i.moduleCode = j.code;
                        i.businessCodeId = j.businessCode;
                    }
                    if (i.activity.toUpperCase() === 'MANDATORY ACTIVITY') {
                        i.isMandatory = true;
                    } else {
                        i.isMandatory = false;
                    }
                });
            });

            this.activityConfigurationEditorForm.value.monthTemplateDTOs.forEach(i => {
                this.activityConfigurationEditorForm.value.templateTaskMappingDTOs.push(i);
            });

            this.activityConfigurationEditorForm.value.effectiveDate =
                formatDate(this.activityConfigurationEditorForm.value.effectiveDate, 'yyyy-MM-dd', 'en-us');
            if (this.activityConfigurationEditorForm.value.toDate) {
                this.activityConfigurationEditorForm.value.toDate =
                    formatDate(this.activityConfigurationEditorForm.value.toDate, 'yyyy-MM-dd', 'en-us');
            }

            // this.roleList = this.activityConfigurationEditorForm.value.roleCodes;
            // this.activityConfigurationEditorForm.controls['roleCodes'].setValue(this.roleList);
            this.activityRoleService.saveActivityHeader(this.activityConfigurationEditorForm.value)
                .pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.activityConfigurationEditorForm, this.formErrors, false);
            return false;
        }

    }

    private onSubmitSuccess(response) {
        let msg = '';
        if (this.activityHeader.id) {
            msg = `Updated tempalte name: ${this.activityHeader.name} successfully`;
        } else {
            msg = `Created Template successfully`;
        }
        this.snackBar.open(msg, 'Close');
        this.goToTemplateListPage();
    }

    private onSubmitError(response) {
        if(response.error.detail == "The given id must not be null!; nested exception is java.lang.IllegalArgumentException: The given id must not be null!"){
            this.snackBar.open('Please Add the Activities.', 'Close', {
                duration: 2000,
              });
        }else{
        const errorModel = this.igFormService.handleServerError(this.activityConfigurationEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    }
    }

    private roleLoadError(response) {
        this.snackBar.open('Failed to get roles, Please try again later [' + response + ']', 'Close', {
          duration: 10,
        });
      }

    goToTemplateListPage() {
        if (this.activityHeader.id) {
            this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        }
    }

    loadUsers() {
        this.scoreCardService.getUsresAndRoles(this.activityHeader.id).pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => {
                this.users = response["userLoginIds"];
                this.userDropdownList = this.users;
                this.selectedUsers = this.users;

                this.userDropdownSettings = {
                    singleSelection: false,
                    idField: 'id',
                    textField: 'userLoginIds',
                    selectAllTest: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 2,
                    allowSearchFilter: true,
                };
            });
    }

    loadRoles() {
        this.scoreCardService.getUsresAndRoles(this.activityHeader.id).pipe(takeUntil(componentDestroyed(this)))
            .subscribe((response) => {
                this.roles = response["role"];
                this.roleDropdownList = this.roles;
                this.selectedRoles = this.roles;   
                
                this.roleDropdownSettings = {
                    singleSelection: false,
                    idField: 'id',
                    textField: 'role',
                    selectAllTest: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 2,
                    allowSearchFilter: true,
                };
            });
    }

    loadBusinessLine() {
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
    }

    loadRole() {
        this.userService.getAllRoles()
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((data) => {
            this.roles = data;
          }, (res: HttpResponse<any>) => this.roleLoadError(res.body));
      }

    onSelectRole(event){
    }

    onSelectAllRole(roles: any){
        this.selectedRoles = roles;
    }

    onItemDeSelectRole(item: any){
    }

    onItemDeSelectAllRole(item: any){
    }

    onSelectUser(event) {
    }

    onSelectAllUser(users: any) {
        this.selectedUsers = users;
    }

    onItemDeSelectUser(item: any) {
    }

    onItemDeSelectAllUser(item: any) {
    }
   
    onAddRow(data: any) {
        this.templateTaskMappingDTOs = this.activityConfigurationEditorForm.get('templateTaskMappingDTOs') as FormArray;
        if (this.activitys.length === this.templateTaskMappingDTOs.length) {
            this.showError = true;
        } else {
            this.showError = false;
            this.templateTaskMappingDTOs.push(this.addMandatoryActivity(''));
            this.fields = this.templateTaskMappingDTOs.controls;
        }
    }

   //month
   onAddMonthRow(data: any) {
    this.monthTemplateDTOs = this.activityConfigurationEditorForm.get('monthTemplateDTOs') as FormArray;
    if (this.activitys.length === this.monthTemplateDTOs.length) {
        this.showError = true;
    } else {
        this.showError = false;
        this.monthTemplateDTOs.push(this.addMonthMandatoryActivity(''));
        this.monthFields = this.monthTemplateDTOs.controls;
    }
  }
    private addMandatoryActivity(data: any): FormGroup {
        return this.formBuilder.group({
            id: [],
            version: [],
            activity: ['', Validators.required],
            month: ['', Validators.required],
            moduleName: ['', Validators.required],
            moduleCode: [''],
            description: [''],
            count: ['', Validators.required],
            type: [''],
            businessCodeId: [''],
           
        });
    }

    private getMandatoryActivity(data: any): FormGroup {
        return this.formBuilder.group({
            id: [data.id],
            version: [data.version],
            activity: [data.activity, Validators.required],
            month: [data.month, Validators.required],
            moduleName: [data.moduleName, Validators.required],
            moduleCode: [''],
            description: [''],
            type: [''],
            count: [data.count, Validators.required],
            businessCodeId: [''],

        });
    }

    private addMonthMandatoryActivity(data: any): FormGroup {
        return this.formBuilder.group({
            id: [],
            version: [],
            activity: ['',  Validators.required],
            moduleCode: [''],
            count: [''],
            moduleName: ['', Validators.required],
            type:[''],
            description: [''],
            // plannedDate:[[], Validators.required] ,
            initiatedDate:[[], Validators.required],
            businessCodeId: [''],
        });
    }
    private getMonthMandatoryActivity(data: any): FormGroup {
        return this.formBuilder.group({
            id: [data.id],
            version: [data.version],
            activity: [data.activity, Validators.required],
            moduleCode: [''],
            moduleName: [data.moduleName, Validators.required],
            description: [''],
            type:[''],
            count: [''],
            // plannedDate:[data.plannedDate, Validators.required],
            initiatedDate:[data.initiatedDate, Validators.required],
            businessCodeId: [''],
           
        });
    }

    getLastDateOfMonth(){
        var date = new Date(this.activityConfigurationEditorForm.get('effectiveDate').value), y = date.getFullYear(), m = date.getMonth();
        return new Date(y, m + 1, 0);
    }

    private removeActivity(i: number, activity: string) {
        this.templateTaskMappingDTOs = this.activityConfigurationEditorForm.get('templateTaskMappingDTOs') as FormArray;
        if (this.templateTaskMappingDTOs.at(i).get("id").value != null) {
            this.activityRoleService.deleteTaskMapping(this.templateTaskMappingDTOs.at(i).get("id").value)
                .pipe(takeUntil(componentDestroyed(this)))
                .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
        }
        this.templateTaskMappingDTOs.removeAt(i);
        this.fields = this.templateTaskMappingDTOs.controls;
        if (this.activitys.length === this.templateTaskMappingDTOs.length) {
            this.showError = true;
        } else {
            this.showError = false;
        }
        const existingVal = this.activityData.find(x => (x.id === i) && (x.activity === activity));
        this.activitys.forEach(x => { 
            if (existingVal && (x.name === existingVal.value)) {
                x.isDisable = false;
                this.activityData.forEach((item, y) => {
                    if (item.id === i && item.activity === activity) {
                        this.activityData.splice(y, 1);
                    }
                })
                this.activityData.forEach((item, y) => {
                    if (item.activity === activity) {
                        item.id = item.id > i ? item.id - 1: item.id;
                    }
                })
            }
        });
       
    }

  //monthly
    private removeMonthly(i: number, activity: string) {
        this.monthTemplateDTOs = this.activityConfigurationEditorForm.get('monthTemplateDTOs') as FormArray;
        if (this.monthTemplateDTOs.at(i).get("id").value != null) {
            this.activityRoleService.deleteTaskMapping(this.monthTemplateDTOs.at(i).get("id").value)
                .pipe(takeUntil(componentDestroyed(this)))
                .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
        }
        this.monthTemplateDTOs.removeAt(i);
        this.monthFields = this.monthTemplateDTOs.controls;
        if (this.activitys.length === this.monthTemplateDTOs.length) {
            this.showError = true;
        } else {
            this.showError = false;
        }
        const existingVal = this.activityData.find(x => (x.id === i) && (x.activity === activity));
        this.activitys.forEach(x => { 
            if (existingVal && (x.name === existingVal.value)) {
                x.isDisable = false;
                this.activityData.forEach((item, y) => {
                    if (item.id === i && item.activity === activity) {
                        this.activityData.splice(y, 1);
                    }
                })
                this.activityData.forEach((item, y) => {
                    if (item.activity === activity) {
                        item.id = item.id > i ? item.id - 1: item.id;
                    }
                })
            }
        });
    }
  
    checkValidation(element: any, index: number, activity: string) {
        const existingVal = this.activityData.find(x => (x.id === index) && (x.activity === activity));
        this.activitys.forEach(x => { 
            if (existingVal && (x.name === existingVal.value)) {
                x.isDisable = false;
                this.activityData.forEach((item, y) => {
                    if (item.id === index && item.activity === activity) {
                        this.activityData.splice(y, 1);
                    }
                })
            }
            if (x.name === element.value) {
                x.isDisable = true;
            }
        });
        this.activityData.push({'id': index, 'value': element.value, 'activity': activity});   
    }

    ActivityTypes() {
        this.activityMonthList= [];
        this.activityList = activityTypes;
        this.activityMonthList.push(activityTypes[0]);
    }

    onSaveSuccess(result) {
    }

    onSaveError(response) {
    }
    onOptionsSelected(selectedBusniessLIne: any){
        this.loadRefrenceCode(selectedBusniessLIne.value);
    }


}


