<!-- <form [formGroup]="form" (ngSubmit)="submit(form)">
    <h1 mat-dialog-title align="center" >Are you sure you want to {{config.buttonTitle}}?</h1>
   <br>
    <mat-dialog-content *ngIf="config.isCommentable">
            
        <mat-form-field >
            <input matInput  type="textarea" formControlName="remark"  placeholder="Enter remarks">
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button type="submit" color="primary">OK</button>
        <button mat-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</form> -->

<mat-card>
    <mat-card-header>
        <mat-card-title align="center"> Are you sure you want to {{config.buttonTitle}}?</mat-card-title>
    </mat-card-header>
    <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="submit(form)">
                <mat-form-field *ngIf="config.isCommentable" style="width:30vw;">
                    <textarea matInput  type="textarea" formControlName="remark"  placeholder="Enter remarks" rows="2"></textarea>
                    <mat-error align="start" *ngIf="formErrors.remark">{{formErrors.remark}}</mat-error>
                </mat-form-field>
                    <mat-card-actions align="center">
                        <button mat-button mat-raised-button type="submit" color="primary">OK</button>
                        <button mat-button mat-raised-button type="button" mat-dialog-close>Cancel</button>
                    </mat-card-actions>
                </form>
    </mat-card-content>
</mat-card>
