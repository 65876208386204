import {Component, OnDestroy, OnInit} from '@angular/core';
import {HealthService} from './health.service';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'ig-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent implements OnInit, OnDestroy {
  healthData: any;
  updatingHealth: boolean;

  constructor(
    private healthService: HealthService
  ) {

  }

  ngOnInit() {
    this.refresh();
  }

  baseName(name: string) {
    return this.healthService.getBaseName(name);
  }

  getBadgeClass(statusState) {
    if (statusState === 'UP') {
      return 'badge-success';
    } else {
      return 'badge-danger';
    }
  }

  refresh() {
    this.updatingHealth = true;

    this.healthService.checkHealth().pipe(takeUntil(componentDestroyed(this))).subscribe(health => {
      this.healthData = this.healthService.transformHealthData(health);
      this.updatingHealth = false;
      console.log(this.healthData);
    }, error => {
      if (error.status === 503) {
        this.healthData = this.healthService.transformHealthData(error.error);
        this.updatingHealth = false;
      }
    });
  }

  showHealth(health: any) {
   /* const modalRef  = this.modalService.open(IgHealthModalComponent);
    modalRef.componentInstance.currentHealth = health;
    modalRef.result.then(result => {
      // Left blank intentionally, nothing to do here
    }, reason => {
      // Left blank intentionally, nothing to do here
    });*/
  }

  subSystemName(name: string) {
    return this.healthService.getSubSystemName(name);
  }

  readableValue(value: number, healthName: string) {
    if (healthName === 'diskSpace') {
      // Should display storage space in an human readable unit
      const val = value / 1073741824;
      if (val > 1) { // Value
        return val.toFixed(2) + ' GB';
      } else {
        return (value / 1048576).toFixed(2) + ' MB';
      }
    }

    if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value.toString();
    }
  }

  ngOnDestroy(): void {
  }

}
