<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>waves</mat-icon>
    </div>
    <mat-card-title>
      Logs Management
    </mat-card-title>
    <mat-card-subtitle>
      There are {{ noOfLoggers }} loggers.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="logDataSource">

        <!-- ID Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef> Level </th>
          <td mat-cell *matCellDef="let row">{{row.level}}
          </td>
        </ng-container>

        <ng-container matColumnDef="changeTo">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="changeLevelBottomSheet(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
