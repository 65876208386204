<mat-toolbar color="primary" class="ig-layout-toolbar">
  <!--
  <div fxFlexAlign="end">

  </div>-->
  <div fxLayout="row"  fxFlexFill="">
    <div fxFlex="30%" fxFlexAlign="center">
      <button mat-icon-button (click)="toggleSideNav()"><mat-icon>menu</mat-icon></button>
      <h1 class="ig-layout-app-name" fxFlex="" style="padding-top: 3px">Samasta MAVRC</h1>
    </div>
    <div fxFlex="50%" fxFlexAlign="center"></div>
    <div fxFlex="" fxFlexAlign="center" >
      <button mat-button [matMenuTriggerFor]="menu" *ngIf="loggedInUser" fxFlex="" style="text-align: right">
        <span fxShow fxHide.xs="true" fxHide.lg="false">Welcome {{loggedInUser.firstName}}</span><mat-icon>more_vert</mat-icon>
    </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item routerLink="profile">
          <mat-icon>account_circle</mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item (click)="changePassword()">
          <mat-icon>help_outline</mat-icon>
          <span>Change Password</span>
        </button>
        <button mat-menu-item routerLink="signout">
          <mat-icon>logout</mat-icon>
          <span>Sign Out</span>
        </button>
      </mat-menu></div>
  </div>
</mat-toolbar>
