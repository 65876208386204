import {DataSource} from '@angular/cdk/table';
import {ReportService} from '../reports/report.service';
import {BmtDataModel} from './bmt-report-useractivity.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {months} from 'src/app/constants/data.constants';

export class ProductivityCountDataSource extends DataSource<BmtDataModel> {

    private productivitySubject = new BehaviorSubject<BmtDataModel[]>([]);

    public totalCount$ = new BehaviorSubject<number>(0);
    parmData: any;
    stateName: String;
    branchCodes: String[];
    activities: String[];
    roles: String[];
    selectedMonth: any;
    year: any;
    activity: any;
    newMonth: any;

    constructor(private  reportService: ReportService) {
        super();
        this.parmData = this.reportService.bmtTableGet();
        this.year = this.parmData.ProductivityData['year'];
        this.stateName = this.parmData.ProductivityStateData['stateName'];
        this.selectedMonth = this.parmData.ProductivityStateData['month'];
        this.branchCodes = this.parmData.ProductivityData['branchCode'];
        this.activities = this.parmData.ProductivityData['activity'];
        this.roles = this.parmData.ProductivityData['role'];
        const selectedMonthObject: any = months.filter(month => month.name === this.selectedMonth);
        selectedMonthObject.forEach(element => {
            this.newMonth = element.code;
        });
    }

    connect(collectionViewer: CollectionViewer): Observable<BmtDataModel[]> {
        return this.productivitySubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.productivitySubject.complete();
        this.totalCount$.complete();
    }

    loadProductivityActiveCountdata(sortDirection = 'asc', pageIndex = 0, pageSize = 10) {

        this.reportService.findProductivityActiveCountData({
            page: pageIndex,
            size: pageSize,
            sort: sortDirection,
            stateName: this.stateName,
            branchCodes: this.branchCodes,
            roles: this.roles,
            months: this.newMonth,
            year: this.year,
            activities: this.activities

        }).pipe(
            catchError(() => of([])),
            finalize(() => {
            })
        ).pipe(take(1)).subscribe((response) => {
            this.totalCount$.next(response['headers'].get('X-Total-Count'));
            this.productivitySubject.next(response['body']);
        });
    }

}
