<div class="ig-layout-container" [class.ig-layout-is-mobile]="mobileQuery.matches" fxLayout="column" fxFlexFill="">
  <ig-header-component></ig-header-component>

  <mat-sidenav-container class="ig-layout-sidenav-container">
    <mat-sidenav #sNav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56" [opened]="!mobileQuery.matches && isAuthenticated">
      <ig-multi-level-menu [menus]="menuItems"></ig-multi-level-menu>
        <app-footer></app-footer>
    </mat-sidenav>

    <mat-sidenav-content class="ig-layout-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
