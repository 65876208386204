import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { PagingAndSortParams } from "projects/ig-core/src/lib/list/paging-and-sort-params";
import { AuditPlanDataSource } from "./audit-plan.data.source";
import { ListSource } from "projects/ig-core/src/lib/list/list-source";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { AuditPlanService } from "./audit-plan.service";
import { QuestionaireDeleteDialogComponent } from "../../questionaire/questionaire-delete-dialog.component";
import { AuditPlanDeleteDialogComponent } from "./audit-plan-delete-dialog.component";
import { Branch } from "src/app/admin/branch/branch.model";
import { NameValueDto } from "projects/ig-core/src/lib/interfaces/name-value-dto";
import { BranchService } from "src/app/admin/branch/branch.service";
import { months, years } from "src/app/constants/data.constants";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { User } from "src/app/admin/user/user.model";
import { HttpResponse } from "@angular/common/http";
import { UserService } from "src/app/admin/user/user.service";

@Component({
  selector: "app-audit-plan",
  templateUrl: "./audit-plan.component.html",
})
export class AuditPlanComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: AuditPlanDataSource;
  listSource: ListSource;
  code: string;
  branchCode: string;
  branches: Branch[];
  allMonths: NameValueDto[];
  allYears: NameValueDto[];
  branchCodes: string = '';
  codes: string[];
  name: string = '';
  public auditPlanForm: FormGroup;
  auditors: User[];
  dropdownList: any[] = [];
  selectedBranches: any[];
  dropdownSettings = {
    singleSelection: false,
    idField: "code",
    textField: "nameCode",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  auditdropdownSettings = {
    singleSelection: true,
    idField: "login",
    textField: "firstName",
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };


  displayedColumns = [
    "branchName",
    "teamLead",
    "assistant1",
    "tentativeFromDate",
    "tentativeToDate",
    "action",
  ];
  filteredBranchList: any[] = [];
  filteredTeamLeadList: any[] = [];

  constructor(
    private auditPlanService: AuditPlanService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private branchService: BranchService,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.buildForm();
    this.pageLoad();
  }

  buildForm() {
    this.auditPlanForm = this.formBuilder.group({
      branchName: "",
      teamLead: "",
      assistant1: "",
      auditMonth: "",
      auditYear: "",
      teamLogin:"",
      assistantLogin:"",
    });
  }

  pageLoad(){
    this.dataSource = new AuditPlanDataSource(this.auditPlanService);
    this.listSource = new ListSource(
      this.activatedRoute,
      this.paginator,
      this.sort
    );
    this.listSource
      .getListParams()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === "" || sortColumn === undefined) {
          sortColumn = "id";
        }
        this.loadAuditPlan(sortColumn);
        this.loadMonthsAndYears();
        this.loadAuditors();
        this.loadBranches();
      });
  }

  loadMonthsAndYears() {
    this.allMonths = months;
    this.allYears = years;
  }

  ngAfterViewInit(): void {
    this.listSource
      .subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadAuditPlanPage());
  }

  loadAuditPlanPage() {
    this.router.navigate(["auditPlan"], this.getQueryParams());
  }
  loadAuditPlan(sortColumn: String) {

    this.selectedBranches = [];
    if(this.auditPlanForm.value.branchName !== ''){
      this.auditPlanForm.value.branchName.forEach(value => {
        this.selectedBranches.push(value.code) ;
    });
    }

    this.dataSource.loadAuditPlan(
      sortColumn + "," + this.pagingAndSortParams.sortDirection,
      this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize,
      this.auditPlanForm.value.teamLogin,
      this.auditPlanForm.value.assistantLogin,
      this.auditPlanForm.value.auditMonth,
      this.auditPlanForm.value.auditYear,
      this.selectedBranches
    );
  }
  getQueryParams() {
    const queryParams =
      this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  deleteDialog(auditPlan: any) {
    const dialogRef = this.dialog.open(AuditPlanDeleteDialogComponent, {
      data: auditPlan,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.clearFilter();
        this.pageLoad();
      });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  clearFilter() {
    this.auditPlanForm.get("branchName").setValue("");
    this.auditPlanForm.get("teamLead").setValue("");
    this.auditPlanForm.get("assistant1").setValue("");
    this.auditPlanForm.get("teamLogin").setValue("");
    this.auditPlanForm.get("assistantLogin").setValue("");
    this.auditPlanForm.get("auditMonth").setValue(undefined);
    this.auditPlanForm.get("auditYear").setValue(undefined);
    this.search()
  }


  downloadAuditPlan() {
    this.selectedBranches = [];
    if(this.auditPlanForm.value.branchName !== ''){
      this.auditPlanForm.value.branchName.forEach(value => {
        this.selectedBranches.push(value.code) ;
    });
    }
      
    this.auditPlanService
      .downloadAuditPlanReport({
        branchCodes:  this.selectedBranches,
        teamLead: this.auditPlanForm.value.teamLogin,
        assistant1: this.auditPlanForm.value.assistantLogin,
        auditForMonth: this.auditPlanForm.value.auditMonth,
        auditForYear: this.auditPlanForm.value.auditYear,
      })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res) => {
          console.log("start download:", res);
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          console.log("download error:", JSON.stringify(error));
        },
        () => {
          console.log("Completed file download.");
        }
      );
  }

  search() {
    if(this.auditPlanForm.value.teamLead !== ""){
    const teamLeadLogin = this.auditPlanForm.value.teamLead[0].login;
    this.auditPlanForm.get("teamLogin").setValue(teamLeadLogin);
    }

    if(this.auditPlanForm.value.assistant1 !== ""){
    const assistant1Login = this.auditPlanForm.value.assistant1[0].login;
    this.auditPlanForm.get("assistantLogin").setValue(assistant1Login);
    }

    this.pagingAndSortParams.pageIndex = 0;
    this.paginator.pageIndex = 0;
    this.loadAuditPlan("");
    this.loadAuditPlanPage();
  }
  

  loadAuditors() {
    this.userService
      .getUserByRole({ role: "AUDITOR" })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((res: HttpResponse<User[]>) => {
        this.auditors = res.body;
      });
  }

  onItemSelect(event) {
    console.log(event);
  }

  onItemDeSelectBranch(item: any) {
    this.selectedBranches = [];
    this.auditPlanForm.patchValue({
      branchCodes: "",
      branchName: "",
    });
  }

  onItemDeSelectTeamLead(item: any) {
    this.auditPlanForm.patchValue({
      teamLead: "",
      teamLogin : "",
    });
  }

  onItemDeSelectAssistance1(item: any) {
    this.auditPlanForm.patchValue({
      assistant1: "",
      assistantLogin: "",
    });
  }

  onSelectAll(event) {
    console.log(event);
  }

  loadBranches() {
    this.branchService
      .getAllActiveBranchesForUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((res: HttpResponse<Branch[]>) => {
        this.branches = res.body;
        this.branches.forEach(value => {
          value.nameCode = `${value.code} - ${value.name}` ;
      });
        this.filteredBranchList = this.branches;
      });
  }
   // concatenating branch name code with name
  filterAndModifyBranches(branches: any[]): any[] {
    return branches.map((branch) => {
      const concatenatedData = `${branch.code} - ${branch.name}`;
      return concatenatedData;
    });
  }
}
