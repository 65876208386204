import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AsyncSubject, Observable} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {ServerConfigurationModel} from './server-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ServerConfigurationService {

  constructor(private http: HttpClient) {
  }

  getConfigurations(): Observable<ServerConfigurationModel[]> {
    const returnAsync = new AsyncSubject<ServerConfigurationModel[]>();
    this.http.get('management/configprops').pipe(take(1)).subscribe(response => {
      const configurations = this.getConfigPropertiesObjects(response);
      returnAsync.next(this.formatToServerConfigurationModelList(configurations));
      returnAsync.complete();
    }, response => {
      returnAsync.next(response);
      returnAsync.complete();
    });
    return returnAsync.asObservable();
  }

  private keys(dict): Array<string> {
    return (dict === undefined) ? [] : Object.keys(dict);
  }

  private formatToServerConfigurationModelList(configProperties): ServerConfigurationModel[] {
    const retServerConfiguration = [];
    const configPropertiesKeys = this.keys(configProperties);
    for (const configPropKey of configPropertiesKeys) {
      retServerConfiguration.push(new ServerConfigurationModel(this.formatConfPropKeyName(configPropKey),
        configProperties[configPropKey]));
    }
    // return retServerConfiguration;
    return retServerConfiguration.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  }

  private formatConfPropKeyName(keyName) {
    if (keyName.indexOf('.') >= 0) {
      const keySplitAry = keyName.split('.');
      return keySplitAry[keySplitAry.length - 1];
    } else {
      return keyName;
    }
  }

  private getConfigPropertiesObjects(res: Object) {
    // This code is for Spring Boot 2
    if (res['contexts'] !== undefined) {
      for (const key in res['contexts']) {
        // If the key is not bootstrap, it will be the ApplicationContext Id
        // For default app, it is baseName
        // For microservice, it is baseName-1
        if (!key.startsWith('bootstrap')) {
          return res['contexts'][key]['beans'];
        }
      }
    }
    // by default, use the default ApplicationContext Id
    return res['contexts']['ignition5base']['beans'];
  }

  getEnv(): Observable<any> {
    return this.http.get('management/env');
  }
}
