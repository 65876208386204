<mat-card>
  <mat-card-header class="page-title">
    <div mat-card-avatar>
      <mat-icon>event_note</mat-icon>
    </div>
    <div mat-card-title>
      AuditPlan
    </div>
    <div class="page-title-action">
      <mat-card-actions class="pull-right">
          <button mat-raised-button color="accent" [routerLink]="'create'">
              <mat-icon>add_circle</mat-icon>
              Create Plan
            </button>
            <button mat-button mat-raised-button color="primary" [routerLink]="'upload'">
          <mat-icon>cloud_upload</mat-icon>
          Upload Plans
        </button>
      </mat-card-actions>
    </div>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="auditPlanForm" >
     <div>
      <div style="width: 210px;margin-right: 3%;float: left;margin-top:8px;">
        <ng-multiselect-dropdown formControlName="branchName" [placeholder]="'branches'" [data]="filteredBranchList"
          [settings]="dropdownSettings" (onSelect)="onItemSelect($event.code)"
            (onSelectAll)="onSelectAll($event.code)" (onDeSelect)="onItemDeSelectBranch($event.code)" required>
        </ng-multiselect-dropdown>
     </div>
      &nbsp;
      <div style="width: 210px;margin-right: 3%;float: left;margin-top:8px;">
        <ng-multiselect-dropdown formControlName="teamLead" placeholder="Team Lead" [data]="auditors"
          [settings]="auditdropdownSettings" (onSelect)="onItemSelect($event.login)"
           (onDeSelect)="onItemDeSelectTeamLead($event.login)" required>
        </ng-multiselect-dropdown>
     </div>
      &nbsp;
      <div style="width: 210px;margin-right: 3%;float: left;margin-top:8px;">
        <ng-multiselect-dropdown formControlName="assistant1" placeholder="Assistant 1" [data]="auditors"
          [settings]="auditdropdownSettings" (onSelect)="onItemSelect($event.login)"
          (onDeSelect)="onItemDeSelectAssistance1($event.login)" required>
        </ng-multiselect-dropdown>
     </div>
      <mat-form-field style="width: 120px; margin-right: 8px;">
          <mat-select name="auditMonth" formControlName="auditMonth" placeholder="Months">
              <mat-option *ngFor="let month of allMonths" [value]="month.code">{{month.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field style="width: 120px;">
          <mat-select name="auditYear" formControlName="auditYear" placeholder="Years">
              <mat-option *ngFor="let year of allYears" [value]="year.code">{{year.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      &nbsp;
      <button type="button" style="margin-right: 1px" mat-raised-button color="primary"  (click)="search()">
        <mat-icon>search</mat-icon>
      </button>
      &nbsp;
      <button type="button" style="margin-right:1px" mat-raised-button color="primary" (click)="downloadAuditPlan()">
        <mat-icon>get_app</mat-icon>Download Plans
      </button>
      &nbsp;
      <button type="button" style="margin-right:1px" mat-raised-button color="primary" (click)="clearFilter()">Clear</button>
    </div> 
    </form>
    <div class="mat-elevation-z8">
      <table mat-table matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="branchName">
          <th mat-header-cell *matHeaderCellDef> Branch Name </th>
          <td mat-cell *matCellDef="let element" data-label="branchName"> {{element.branchName ? element.branchName: '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="teamLead">
          <th mat-header-cell *matHeaderCellDef> Team Lead </th>
          <td mat-cell *matCellDef="let element" data-label="teamLead"> {{element.teamLeadName ? element.teamLeadName: '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="assistant1">
          <th mat-header-cell *matHeaderCellDef> Assistant 1 </th>
          <td mat-cell *matCellDef="let element" data-label="assistant1"> {{element.assistant1Name ? element.assistant1Name: '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="tentativeFromDate">
          <th mat-header-cell *matHeaderCellDef> From Date </th>
          <td mat-cell *matCellDef="let element" data-label="tentativeFromDate"> {{element.tentativeFromDate | date: 'mediumDate' }} </td>
        </ng-container>

        <ng-container matColumnDef="tentativeToDate">
          <th mat-header-cell *matHeaderCellDef> To Date </th>
          <td mat-cell *matCellDef="let element" data-label="tentativeToDate"> {{element.tentativeToDate | date: 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="View" [routerLink]="['view', element.id]">
              <mat-icon>more</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Edit" [routerLink]="['edit', element.id]">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" (click)="deleteDialog(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="pagingAndSortParams.pageSize" [pageSizeOptions]="pagingAndSortParams.defaultPageSizeOptions()"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
