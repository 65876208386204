import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    /*Adding icons from http://materialdesignicons.com/ and
    for social media font and color https://www.sitepoint.com/fonts-colors-used-facebook-twitter-google/*/
    this.addSVGIcon('facebook');
    this.addSVGIcon('google-plus');
    this.addSVGIcon('twitter');
  }

  addSVGIcon(fileName: string, assetPath?: string) {
      if (!assetPath || assetPath === undefined) {
        assetPath = '../assets/additional-icons/';
      }
    this.matIconRegistry.addSvgIcon(fileName + '-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(assetPath + fileName + '.svg'));
  }
}
