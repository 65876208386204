import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReferenceCode } from './reference-code.model';
import { NameValueDto } from 'projects/ig-core/src/lib/interfaces/name-value-dto';
import { SCORECARD_MASTER } from 'src/app/constants/data.constants';

@Injectable({
    providedIn: 'root'
})
export class ReferenceCodeService {

    private API = 'api/reference-codes';
    constructor(private http: HttpClient) {}

    findReferenceCodes(req: any): Observable<HttpResponse<ReferenceCode[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (req.classifierFilter) {
            params = params.set('classifier', req.classifierFilter);
        }
        if (req.nameFilter) {
            params = params.set('name', req.nameFilter);
        }
        if (req.codeFilter) {
            params = params.set('code', req.codeFilter);
        }
        return this.http.get<ReferenceCode[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveReferenceCode(referenceCode: ReferenceCode): Observable<HttpResponse<ReferenceCode>> {
        const copy: ReferenceCode = Object.assign({}, referenceCode);
        if (copy.id && copy.id != null) {
            return this.http.put<ReferenceCode>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<ReferenceCode>(this.API, copy, {observe: 'response'});
        }
    }

    getReferenceCode(id: number) {
        return this.http.get<ReferenceCode>(this.API + '/' + id);
    }

    deleteReferenceCode(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getClassifiers() {
      return this.http.get<NameValueDto>('api/_refs/reference-codes/classifiers');
    }

    getParentCodes(classifier: string) {
      return this.http.get<NameValueDto[]>('api/_refs/reference-codes/parentcodes/' + classifier + '?status=1');
    }

    getModuleMastersCodes(classifier: string) {
        return this.http.get<ReferenceCode[]>('api/_refs/reference-codes/scorecard/' + classifier + '?status=1');
      }

    getBusnisseCodes(classifier: string) {
        return this.http.get<ReferenceCode[]>('api/_refs/reference-codes/scorecard/' + classifier);
    }  
    getCategoryWeightageMapping() {
        return this.http.get<ReferenceCode[]>(this.API + '/all/category-weightage-mapping');
    }

    getQuestionaireCodes() {
        return this.http.get<NameValueDto[]>('api/_refs/reference-codes/codes/'+ SCORECARD_MASTER.SUBMODULE_MASTER);
    }

    getScoreCardCodes(classifier:string, status:number) {
        let params = new HttpParams();
        params = params.set('status', status.toString());
        return this.http.get<any[]>('api/_refs/reference-codes/scorecard/'+classifier,{params, observe: 'response'});
    }

    getActivity(classifier: string) {
        return this.http.get<NameValueDto[]>('api/_refs/reference-codes/codes/' + classifier );
    }

    getActivityForMA(classifier: string) {
        return this.http.get<NameValueDto[]>('api/_refs/reference-codes/codes-ma/' + classifier );
    }
    getUniqueReferenceCodes() {
        return this.http.get<ReferenceCode[]>(this.API + '/questionnaire/unique-code');
    }

    getLatestCategoryWeightageMapping() {
        return this.http.get<ReferenceCode[]>(this.API + '/latest/all/category-weightage-mapping');
    }

    getEachTasksCompletedCounts(userLogin : any, countType:any, fromDate: string, toDate: string){
        let params = new HttpParams();
        if(userLogin !== undefined){
            params = params.set('userLogin', userLogin);
        }
        if(countType !== undefined){
            params = params.set('countType', countType);
        }
        if(fromDate !== undefined){
            params = params.set('fromDate', fromDate);
        }
        if(toDate !== undefined){
            params = params.set('toDate', toDate);
        }
        return this.http.get<NameValueDto[]>('api/dashboard/activity-tracker/each/count',{params, observe: 'response'} );
    }
}

    