<div class="health-container">
<mat-accordion style="margin-top: 10px;">
  <mat-expansion-panel *ngFor="let health of healthData">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{baseName(health.name) | titlecase}} {{ subSystemName(health.name)}}
      </mat-panel-title>
      <mat-panel-description>
        {{health.status}}
        <button mat-icon-button *ngIf="health.status === 'UP'" color="primary">
        <mat-icon >thumb_up</mat-icon>
      </button>
        <button mat-icon-button *ngIf="health.status !== 'UP'" color="warn">
        <mat-icon>thumb_down</mat-icon>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="health.details">
      <mat-list>
        <mat-list-item *ngFor="let detail of health.details.details | keyvalue">
          <h3 matLine> {{detail.key | titlecase }} </h3>
          <p matLine>
            <strong> {{ readableValue(detail.value, health.name)}} </strong>
          </p>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>

  </mat-expansion-panel>
</mat-accordion>
</div>
