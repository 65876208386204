import { Injectable, NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, Router, RouterModule, Routes } from '@angular/router';
import { LoggedInUserService } from 'projects/ig-core/src/lib/authentication/logged-in-user.service';
import { LoginService } from 'projects/ig-core/src/lib/authentication/login.service';
import { NonEditableResolveService } from 'projects/ig-core/src/lib/resolves/non-editable-resolve.service';
import { catchError, map } from 'rxjs/operators';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@Injectable()
export class ResolveResetKey implements Resolve<any> {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    if (route.queryParamMap.get('key')) {
      return this.loginService
        .checkResetKey(route.queryParamMap.get('key'))
        .pipe(
          map(result => {
            if (!result) {
              console.log('no data found');
            } else {
              return result.body;
            }
          }),
          catchError((err, caught) => {
            this.snackBar.open('Sorry!!Link has been expired', 'Close');
            return this.router.navigate(['signin']);
          })
        );
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProfileResolverService implements Resolve<any> {
  key: string;
  constructor(
    private loggedInUserService: LoggedInUserService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  resolve() {
    return this.loggedInUserService.get().pipe(
      map(result => {
        return result.body;
      }),
      catchError((err, caught) => {
        this.snackBar.open('Profile details not found', 'Close');
        return this.router.navigate(['signin']);
      })
    );
  }
}

const routes: Routes = [
  {path: 'signin', component: LoginComponent},
  {path: 'signout', component: LogoutComponent},
  {
    path: 'reset/finish',
    component: ResetPasswordComponent,
    resolve: {
      user: ResolveResetKey
    }
  },
  { path: 'profile', component: ProfileComponent,
    resolve: {
      user: ProfileResolverService,
      editable: NonEditableResolveService
    }
  }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAccountRoutingModule { }
