import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ScoreCardService } from '../score-card.service';
import { ScoreSet } from '../score-card.model';

@Component({
    selector: 'app-score-card-delete-dialog',
    templateUrl: './score-card-delete-dialog.component.html'
})
export class ScorecardDeleteDialogComponent implements OnInit, OnDestroy {
    scoreSet:ScoreSet;

    errorMessage: string;
    constructor(
        private scoreCardService: ScoreCardService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ScorecardDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ScoreSet) {
        this.scoreSet = data;
    }

    ngOnInit() {
    }
    ngOnDestroy(): void {
    }
    delete() {
        this.errorMessage = '';
        this.scoreCardService.deleteScoreCardData(this.scoreSet.id).pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
    }
    onSaveSuccess(result) {
        this.snackBar.open(`Deleted questionaire '${this.scoreSet.code}'
        of version '${this.scoreSet.code}' successfully`, 'Close', {
            duration: 3000,
        });
        this.dialogRef.close();
    }
    onSaveError(response) {
        this.errorMessage = response.error;
    }
    cancel(): void {
        this.dialogRef.close();
    }
}
