import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {QuestionnaireConfiguration} from './questionnaire-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class QuestionnaireConfigurationService {

  private API = 'api/questionnaire-configs';

  constructor(private http: HttpClient) {}

    findQuestionnaireConfigs(req: any): Observable<HttpResponse<QuestionnaireConfiguration[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        console.log(req)
        if (req.questionnaireCode) {
            params = params.set('questionnaireCode', req.questionnaireCode);
        }
        if (req.businessLineId) {
            params = params.set('businessLineId', req.businessLineId);
        }
        return this.http.get<QuestionnaireConfiguration[]>(this.API, {
            params, observe: 'response'
        });
    }

    getQuestionnaireConfig(id: number) {
        return this.http.get<QuestionnaireConfiguration>(this.API + '/' + id);
    }

    deleteQuestionnaireConfig(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    saveQuestionnaireConfiguration(questionnaireConfiguration: QuestionnaireConfiguration): Observable<HttpResponse<QuestionnaireConfiguration>> {
    const copy: QuestionnaireConfiguration = Object.assign({}, questionnaireConfiguration);
    if (copy.id && copy.id != null) {
        return this.http.put<QuestionnaireConfiguration>(this.API, copy, {observe: 'response'});
    } else {
        return this.http.post<QuestionnaireConfiguration>(this.API, copy, {observe: 'response'});
    }
   }
 
   getgetAllTaskTemplateMastersByMode(modeOfImplementation : string): Observable<HttpResponse<any>>{
    let params = new HttpParams();
    params = params.set("modeOfImplementation", modeOfImplementation);
    return this.http.get<any>('api/task-template-masters/mode/implementation', {params: params, observe: 'response' });
    }
}