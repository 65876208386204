  <mat-card [style.width]="'500px'">
    <mat-card-header class="page-title">
        <mat-card-title>{{eachType}} Breakdown : User : {{activityTracker.employeeName}}  ({{activityTracker.employeeLogin}}) </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngFor = "let typeCount of typeCountList" >
        {{typeCount.type}}       :        {{typeCount.code}}
       </mat-card-content>
</mat-card>


