import {Component, OnDestroy, OnInit} from '@angular/core';
import {RiskObservationService} from './risk-oveservation.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpResponse} from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import { AuditPlan } from 'src/app/entities/audit/audit-plan/audit-plan.model';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-risk-observation',
  templateUrl: './risk-observation.component.html'
})

export class RiskObservationComponent implements OnInit, OnDestroy {
    auditPlan: any[];
    selectedBranches: any[];
    branches: any;
    branchCodes: any[];
    status: any;
    statusLists:  string[] = [];
    branchSelectIndicator = new FormControl();
    dataSource: any;
    value: any[];
    data: any[];
    isPresent: boolean;
    public riskObservationForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        name: '',
        status: '',
    };
    constructor(private riskObservationService: RiskObservationService,
                private igFormService: IgFormService,
                private formBuilder: FormBuilder, private snackBar: MatSnackBar, ) {
    }
    ngOnInit() {
        this.loadBranches();
        this.buildForm();
        this.statusLists.push('NO_RISK');
        this.statusLists.push('LOW_RISK');
        this.statusLists.push('MEDIUM_RISK');
        this.statusLists.push('HIGH_RISK');
        this.formError = new FormErrorModel(false, '');

    }
    ngOnDestroy(): void {
    }

    loadBranches() {
      this.riskObservationService.getAllBranchesForUser().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
          (res: HttpResponse<any>) => { this.onBranchesLoadedSuccess(res.body); });
  }
  private onBranchesLoadedSuccess(data) {
    this.branches = data;
}

    onSubmit() {
        this.selectedBranches.forEach((value) => {
            this.branchCodes.push(value.code);
        });
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.riskObservationForm);
        if (this.riskObservationForm.valid) {

        } else {
            this.formErrors = this.igFormService.validateForm(this.riskObservationForm, this.formErrors, false);
            return false;
        }
    }

    buildForm() {
        this.riskObservationForm = this.formBuilder.group({
            name: [this.branches, Validators.required],
            status: [this.status, Validators.required],
        });
        this.riskObservationForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.riskObservationForm, this.formErrors, true);
        });
    }
    onError() {
        this.snackBar.open(`Error Downloading Excle`, 'Close', {
            duration: 3000,
        });
    }
    loadCheckListUnderRisk(req?: any) {
        this.branchCodes = [];
        this.selectedBranches.forEach((value) => {
            this.branchCodes.push(value.code);
        });
        this.riskObservationService.findRiskObservation({
            branchCode: this.branchCodes,
            status: this.status
        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
                if (res !== undefined) {
                    this.isPresent = true;
                    this.auditPlan = res.body;
                }

        });

    }

}
