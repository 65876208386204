<mat-card style="height: 95%; overflow-y: hidden;">
    <mat-card-header class="page-title">
        <div mat-card-avatar>
            <mat-icon>file_copy</mat-icon>
        </div>
        <div mat-card-title>
            Moratorium Consent Form
        </div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="moratoriumConsentReportForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">

                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="stateName" [placeholder]="'States'"
                                             [data]="stateDropdownList" [(ngModel)]="selectedStates" [settings]="stateDropdownSettings"
                                             (onSelect)="onSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                                             (onDeSelect)="onDeSelectState($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <mat-hint *ngIf="stateHint" style="font-size: 11px;color: #f44336;">This field is required</mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;

                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="name" [placeholder]="'Branches'" [data]="dropdownList"
                                             [(ngModel)]="selectedBranches" [settings]="dropdownSettings" (onSelect)="onSelectBranch($event)"
                                             (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeSelectBranch($event)">
                    </ng-multiselect-dropdown>
                    <mat-hint *ngIf="branchHint" style="font-size: 11px;color: #f44336;">This field is required</mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;

                <div style="width: 180px;margin-right: 3%;float: left;margin-top: 8px;">
                    <ng-multiselect-dropdown formControlName="selectedCentres" [placeholder]="'Centres'" [data]="centreDropdownList"
                                             [(ngModel)]="selectedCentre" [settings]="csntreDropdownSettings" (onSelect)="onSelectCentre($event)"
                                             (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeSelectCentre($event)">
                    </ng-multiselect-dropdown>
                    <mat-hint *ngIf="centreHint" style="font-size: 11px;color: #f44336;">This field is required</mat-hint>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;


                <button type="submit" mat-raised-button color="primary">
                    <mat-icon>cloud_download</mat-icon> Download Report
                </button>
                &nbsp;
                <!--<button type="button" style="height: 40px;" mat-raised-button color="primary"
                        (click)="clearFilter()">Clear</button>-->
            </div>
        </form>
    </mat-card-content>
</mat-card>