import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {IgErrorConstants} from './ig-error.constants';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class IgErrorLogService {

  snackBarActiveTime = 50000;
  constructor(private snackBar: MatSnackBar) {  }
  logError(error: any) {
    /*Returns a date converted to a string using Universal Coordinated Time (UTC).*/
    const date = new Date().toUTCString();

  /*  if (error instanceof HttpErrorResponse) {
      /*The response body may contain clues as to what went wrong,
      console.error(date, IgErrorConstants.httpError, error, 'Status code:',
        (<HttpErrorResponse>error).status);
      this.snackBar.open(`${IgErrorConstants.httpError} : ${error.message}`, 'Close', {
        duration: this.snackBarActiveTime,
      });
    } else if (error instanceof TypeError) {
      console.error(date, IgErrorConstants.typeError, error);
      this.snackBar.open(`${IgErrorConstants.typeError} - ${error.message}`, 'Close', {
        duration: this.snackBarActiveTime,
      });
    } */
    /* if (error instanceof Error) {
      console.error(date, IgErrorConstants.generalError, error);
      this.snackBar.open(`${IgErrorConstants.generalError} - ${error.message}`, 'Close', {
        duration: this.snackBarActiveTime,
      });
    } else {
      console.error(date, IgErrorConstants.somethingHappened, error);
      this.snackBar.open(`${IgErrorConstants.somethingHappened} - ${error.message}`, 'Close', {
        duration: this.snackBarActiveTime,
      });
    } */
  }
}
