import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID, AfterViewInit, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { ReportService } from '../reports/report.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { DownloadFormatType } from '../reports/download-format';
import { Router } from '@angular/router';
import { SYNC_NAME_MASTER } from 'src/app/constants/data.constants';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';


export enum RequestAnalysis {
  Open = "open",
  Success = "success",
  Processing = "processing",
  Failed = "failed"
}

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.css']
})
export class ScoreCardComponent implements OnInit, OnDestroy, AfterViewInit {
  [x: string]: any;
  @ViewChild('myDialog') myDialog: TemplateRef<any>;

  @ViewChild('tableDataPaginator1') set tableDataPaginator1(value: MatPaginator) {
    if (value) this.tableDataSets.paginator = value;
  };
  @ViewChild('tableDataPaginator2') set tableDataPaginator2(value: MatPaginator) {
    if (value) this.tableTaskDataSets.paginator = value;
  };
  @ViewChild('tableDataPaginator3') set tableDataPaginator3(value: MatPaginator) {
    if (value) this.departmentLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator4') set tableDataPaginator4(value: MatPaginator) {
    if (value) this.submoduleLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator5') set tableDataPaginator5(value: MatPaginator) {
    if (value) this.tasksubmoduleLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator6') set tableDataPaginator6(value: MatPaginator) {
    if (value) this.stateLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator7') set tableDataPaginator7(value: MatPaginator) {
    if (value) this.branchLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator8') set tableDataPaginator8(value: MatPaginator) {
    if (value) this.centreLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator9') set tableDataPaginator9(value: MatPaginator) {
    if (value) this.roleLevelData.paginator = value;
  };
  @ViewChild('tableDataPaginator10') set tableDataPaginator10(value: MatPaginator) {
    if (value) this.allTaskVisitNumber.paginator = value;
  };
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  message: RequestAnalysis = "open" as RequestAnalysis;
  myInputVariable: ElementRef;
  selectedMain: any[];
  branchCode: string[];
  mainDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'code',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };
  branches: any;
  selectedBranch: any[];
  branchdropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllTest: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  fromDateFilter: string;
  toDateFilter: string;
  fromDate: string;
  toDate: string;

  displayedColumns = ['reportType', 'reportCount', 'obtainedMarks'];
  displayedAllTaskColumns = ['reportType', 'reportModule', 'reportSubModule', 'reportCount'];
  displayedTTabular = ['reportModule', 'reportSubModule', 'submittedOn', 'branchName', 'stateName', 'obtainedMarks', 'action'];
  displayedQTabular = ['branchCode', 'stateName', 'branchName', 'reportType', 'obtainedMarks', 'action'];
  displayedCentreColumns = ['reportType', 'branchName', 'reportCount', 'reportModule', 'reportSubModule','stateName'];
  displayedBranchColumns = ['reportModule', 'reportType', 'reportCount'];
  scoreCardTemplates: any[];
  syncSchedularLog: any = {};

  public scoreCardForm: FormGroup;
  @ViewChild('mainSelect') mainSelect: MatSelect;
  @ViewChild('mainAllSelect') mainAllSelect: MatCheckbox;

  public formError: FormErrorModel;
  public formErrors = {
    fromDateFilter: '',
    toDateFilter: '',
    selectedBranch: '',
    mainModule: ''
  };

  public barChartPlugins = [pluginDataLabels];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',       
        font: {
          weight: 'bold'
        },
      },
      legend: {
        position: 'right'
      },
    }
  };
  
  public barChartType: ChartType = 'bar';
  public chartLabelsets: string[] = [];
  public chartDatasets: Array<any> = [];
  public branchLabelsets: string[] = [];
  public branchDatasets: Array<any> = [];
  public branchBackgroundColor: any[] = [];

  public tableDataSets: MatTableDataSource<any> = new MatTableDataSource();
  public tableTaskDataSets: MatTableDataSource<any> = new MatTableDataSource();
  public departmentLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public submoduleLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public tasksubmoduleLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public stateLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public branchLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public centreLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public roleLevelData: MatTableDataSource<any> = new MatTableDataSource();
  public roleNameCountData: MatTableDataSource<any> = new MatTableDataSource();
  public allTaskVisitNumber: MatTableDataSource<any> = new MatTableDataSource();
  public branchVisitNumberData: MatTableDataSource<any> = new MatTableDataSource();
  public centreVisitNumberData: MatTableDataSource<any> = new MatTableDataSource();
  public branchAssessmentNumberData: MatTableDataSource<any> = new MatTableDataSource();
  public allTaskVisitNumberData: any = [];

  highestData: any = [];
  lowestData: any = [];
  departmentLevel: any = [];
  totalBranchsScoreData: any = {};
  isTabularMain: boolean = true;
  popupData: any = {};
  showMe: boolean = false;
  isAudit:boolean= true;
  comparisionGraphData:any={};

  constructor(
    private snackBar: MatSnackBar,
    private reportService: ReportService,
    private igFormService: IgFormService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string) {
  }

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.loadScoreCardTemplates();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    /* this.tableDataSets.paginator = this.tableDataPaginator1;
    this.tableTaskDataSets.paginator = this.tableDataPaginator2;
    this.departmentLevelData.paginator = this.tableDataPaginator3;
    this.submoduleLevelData.paginator = this.tableDataPaginator4;
    this.tasksubmoduleLevelData.paginator = this.tableDataPaginator5;
    this.stateLevelData.paginator = this.tableDataPaginator6;
    this.branchLevelData.paginator = this.tableDataPaginator7;
    this.centreLevelData.paginator = this.tableDataPaginator8;
    this.roleLevelData.paginator = this.tableDataPaginator9;
    this.allTaskVisitNumber.paginator = this.tableDataPaginator10; */
  }

  ngOnDestroy(): void {
  }

  buildForm() {
    this.scoreCardForm = this.formBuilder.group({
      mainModule: [this.selectedMain, Validators.required],
      branchSelect: [this.selectedBranch],
      fromDateFilter: [this.fromDateFilter, Validators.required],
      toDateFilter: [this.toDateFilter, Validators.required]
    });
    this.scoreCardForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
      this.formErrors = this.igFormService.validateForm(this.scoreCardForm, this.formErrors, true);
    });
  }

  public graphChange(event) {
    switch (Number(event.value)) {
      case 1: this.generateTheData("TASK"); break;
      case 2: this.generateTheData("YEAR"); break;
      case 3: this.generateTheData("MONTH"); break;
      case 4: this.generateTheData("WEEK"); break;
      case 5: this.generateTheData("DAYS"); break;
      default: break;
    }
  }

  
  public branchGraphsTabChange(event) {
    switch (Number(event.value)) {
      case 0: this.showMe=false;
             this.generateComparisonReportData("BRANCH");
              break;
      case 1:  this.showMe=true;
                this.isAudit=true;
            this.generateComparisonReportData("DIVISION");
             break;
      case 2: this.showMe=false;
             this.generateComparisonReportData("STATE");
              break;
      default: break;
    }
  }

  onDivisionAuditChange(isSelected: boolean){
    if(isSelected){
      this.setChart( this.comparisionGraphData["graphDataSets"],  this.comparisionGraphData["graphDataLabels"], "YES");
      this.randomize();
    }else {
      this.setChart( this.comparisionGraphData["WITHOUT_AUDIT"]["graphDataSets"],  this.comparisionGraphData["WITHOUT_AUDIT"]["graphDataLabels"], "YES");
      this.randomize();
    }
  }

  // events
  public chartClicked({ }: { event?: any, active?: {}[] }): void {
    //console.log(event, active);
  }

  public chartHovered({ }: { event?: any, active?: {}[] }): void {
    //console.log(event, active);
  }

  public randomize(): void {
    this.barChartType = this.barChartType === 'bar' ? 'line' : 'bar';
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.tableDataSets.filter = filterValue;
  }

  public generateTheReport(): void {
    this.selectedBranch.map(k => k["code"] = this.branches.find(item => item.id === k.id)["code"]);
    this.generateStatisticData("ALL");
    this.generateTheData("TASK");
    this.generateTableDate("ALL");
    this.generateContributerdata("ALL");
    this.generateBasicActivityCountdata("ALL");
    this.generateComparisonReportData("BRANCH");
  }

  loadScoreCardTemplates() {
    this.reportService.getScorecardTemplates().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.scoreCardTemplates = res.body;
        }
      );
  }

  calculatedScore(buttonType): void {
    if (!this.scoreCardForm.valid) return
    if (buttonType === 'YES') {
      if (confirm("Are you sure to generate Scores?, It may take more minutes!!")) {
        this.generateTheScores(buttonType);
      }
    } else {
      this.generateTheScores(buttonType);
    }
  }

  

  generateTheScores(buttonType) {
    this.message = "processing" as RequestAnalysis;
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.calculatedScore(
      scoreCardCode, buttonType,
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response: HttpResponse<any>) => {
        this.branches = JSON.parse(response.body);
        this.getLatestSyncTransaction();
      });
  }

  private setChart(graphDataSets: any, graphDataLabels: any, isBranch: string) {
    if (isBranch === "NO") {
      this.chartLabelsets = null;
      this.chartDatasets = null;
      this.chartLabelsets = graphDataLabels;
      this.chartDatasets = graphDataSets;
      setTimeout(() => {
        this.chartLabelsets = graphDataLabels;
      }, 50);
      setTimeout(() => {
        this.chartDatasets = graphDataSets;
        this.randomize();
      }, 100);
    } else {
      this.branchBackgroundColor = [];
      var tempColor = [];
      graphDataLabels.forEach((item, index) => {
        item === this.selectedBranch[0]["name"] ?
          tempColor.push('dodgerblue') : tempColor.push('hotpink');
        graphDataLabels[index] = item + "(" + graphDataSets[0]["data"][index] + ")";
      });
      this.branchBackgroundColor.push({ backgroundColor: tempColor });

      this.branchLabelsets = null;
      this.branchDatasets = null;
      this.branchLabelsets = graphDataLabels;
      this.branchDatasets = graphDataSets;
      setTimeout(() => {
        this.branchLabelsets = graphDataLabels;
      }, 50);
      setTimeout(() => {
        this.branchDatasets = graphDataSets;
        this.randomize();
      }, 100);
    }
  }

  public setPopupDialogData(data) {
    this.popupData = data;
    this.dialog.open(this.myDialog, { width: '70%', height: 'auto' });
    this.dialog.afterAllClosed
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(_result => console.log("closed"));
  }

  public setTotalBranchsScoreData(key, value) {
    this.totalBranchsScoreData[key] = value;
  }

  public generateTheData(reportType: string): void {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.generateReportData(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCode,
      reportType,
      scoreCardCode,
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        response = JSON.parse(response.body);
        this.setChart(response["graphDataSets"], response["graphDataLabels"], "NO");
        this.randomize();
      });
  }

  public generateStatisticData(reportType: string): void {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.generateStatisticData(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCode,
      reportType,
      scoreCardCode,
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        response = JSON.parse(response.body);
        this.departmentLevelData.data = response[0];
        this.submoduleLevelData.data = response[1];
        this.tasksubmoduleLevelData.data = response[2];
        this.stateLevelData.data = response[3];
        this.branchLevelData.data = response[4];
        this.centreLevelData.data = response[5];
        this.roleLevelData.data = response[6];
        this.roleNameCountData.data = response[8];
        this.branchVisitNumberData.data = response[9];
        this.centreVisitNumberData.data = response[10];
        this.branchAssessmentNumberData.data = response[11];
        if (Object.keys(response[7]).length > 0) {
          this.setTotalBranchsScoreData("branchCode", response[7][0]["branchCode"]);
          this.setTotalBranchsScoreData("branchName", response[7][0]["branchName"]);
          this.setTotalBranchsScoreData("stateName", response[7][0]["stateName"]);
          this.setTotalBranchsScoreData("obtainedMarks", response[7][0]["obtainedMarks"]);
          this.setTotalBranchsScoreData("reportCount", response[7][0]["reportCount"]);
          this.setTotalBranchsScoreData("reportType", response[7][0]["reportType"]);
          this.setTotalBranchsScoreData("userCount", response[7][0]["reportSubModule"]);
        }

        this.ngAfterViewInit();
      });
  }

  public generateContributerdata(reportType: string): void {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.generateContributerdata(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCode,
      reportType,
      scoreCardCode,
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        response = JSON.parse(response.body);
        this.highestData = response[0];
        this.lowestData = response[1];
      });
  }

  public generateTableDate(reportType: string): void {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.generateTableDate(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCode,
      reportType,
      scoreCardCode,
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.tableDataSets.data = JSON.parse(response.body);
      });
  }

  public getTaskTableDate(data: any): void {
    this.isTabularMain = !this.isTabularMain;

    this.tableTaskDataSets.data = [];
    if (!this.isTabularMain) {
      let branchCode = [];
      this.selectedBranch.map(k => branchCode.push(k["code"]));
      let scoreCardCode = this.selectedMain[0]["id"];
      this.reportService.getTaskTableDate(
        data.reportSubModule,
        scoreCardCode,
        data.questionnaireResponseId).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => {
          this.tableTaskDataSets.data = JSON.parse(response.body);
        });
    }
  }

  public generateBasicActivityCountdata(reportType: string): void {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.generateBasicActivityCountdata(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCode,
      reportType,
      scoreCardCode,
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.allTaskVisitNumber = new MatTableDataSource(JSON.parse(response.body));
        this.allTaskVisitNumberData = this.allTaskVisitNumber.data;
        var taskCount = 0;
        this.allTaskVisitNumberData.map(eachTask => taskCount += Number(eachTask.reportCount));
        this.setTotalBranchsScoreData("visitCount", taskCount);
        this.ngAfterViewInit();
      });
  }

  public generateComparisonReportData(reportType: string): void {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.generateReportData(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      branchCode,
      reportType,
      scoreCardCode,
    ).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        response = JSON.parse(response.body);
        this.comparisionGraphData=response;
        this.setChart( this.comparisionGraphData["graphDataSets"],  this.comparisionGraphData["graphDataLabels"], "YES");
        this.randomize();
      });
  }

  
  downloadScorecardReport(downloadFormat: any) {
    this.selectedBranch.map(k => k["code"] = this.branches.find(item => item.id === k.id)["code"]);
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    let scoreCardCode = this.selectedMain[0]["id"];
    let downloadFormatType = downloadFormat === 'PDF' ? DownloadFormatType.PDF : DownloadFormatType.EXCEL;
    this.reportService.downloadScorecardReport(
      this.fromDate = formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      this.toDate = formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
      scoreCardCode,
      branchCode,
      downloadFormatType
    ).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      console.log('start download:', res);
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      console.log('download error:', JSON.stringify(error));
      this.onError();
    }, () => {
      console.log('Completed file download.');
    });
  }

  downloadAllCommonDetailedReport(downloadType: any) {
    let branchCode = [];
    this.selectedBranch.map(k => branchCode.push(k["code"]));
    this.reportService.downloadAllCommonReport({
      type: downloadType,
      branchCodes: branchCode,
      fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  onError() {
    this.snackBar.open(`Error Downloading Excel`, 'Close', {
      duration: 3000,
    });
  }

  goToReportDownloadPage() {
    this.router.navigateByUrl('/dashboard/crossfunctionaldeviation');
  }

  getLatestSyncTransaction() {
    if (!this.scoreCardForm.valid) return;
    this.message = "open" as RequestAnalysis;
    this.syncSchedularLog = {}
    this.reportService.getLatestSyncTransaction(SYNC_NAME_MASTER.SCORE_CARD_ANALYSIS).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((res: HttpResponse<any>) => {
        this.syncSchedularLog = JSON.parse(res.body);
        if (this.syncSchedularLog["status"] === "INITIALIZED") {
          this.message = "processing" as RequestAnalysis;
        } else if (this.syncSchedularLog["status"] === "PROCESSED") {
          this.message = "success" as RequestAnalysis;
        } else if (this.syncSchedularLog["status"] === "PROCESSED_WITH_ERRORS") {
          this.message = "failed" as RequestAnalysis;
        } else {
          this.message = "open" as RequestAnalysis;
        }
      });
  }

  downloadAllScoreCardReport() {
    let scoreCardCode = this.selectedMain[0]["id"];
    this.reportService.downloadScoreCardBranchscoringReport({
      scoreCardCode,
      fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
      toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us'),
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }


}
