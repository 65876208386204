import {formatDate} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild, LOCALE_ID, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSelect } from '@angular/material/select';
import {MatCheckbox } from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {FormErrorModel} from 'projects/ig-core/src/lib/form/form-error.model';
import {IgFormService} from 'projects/ig-core/src/lib/form/form.service';
import {takeUntil} from 'rxjs/operators';
import {Branch} from 'src/app/admin/branch/branch.model';
import {hardBucketReporttypes} from 'src/app/constants/data.constants';
import {QuestionaireService} from 'src/app/entities/questionaire/questionaire.service';
import {ReportService} from '../reports/report.service';
import { UserService } from 'src/app/admin/user/user.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-hard-bucket-collection',
    templateUrl: './hard-bucket-collection.component.html',
    styleUrls: ['./hard-bucket-collection.component.css']
})
export class HardBucketCollectionComponent implements OnInit, OnDestroy {
  
    stateDropdownList: any[];
    selectedStates: any[];
    stateDropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllTest: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    selectedBranches: any[];
    stateList: string[];
    branches: Branch[];
    dropdownList: any[];
    dropdownSettings = {
        singleSelection: false,
        idField: 'code',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };
    isRoleAdmin:boolean;
    states: any;
    fromDateFilter: string;
    toDateFilter: string;
    type: string;
    codes: any[];
    fromdate1: string;
    todate1: string;
    hardBucketReportList: any[];
    businessLines: any[];
    selectedBusinessLine: any;
    businessLine: any;
    loggedInUserRole: any;
    public hardBucketReportForm: FormGroup;

    @ViewChild('stateSelect',{static:true}) stateSelect: MatSelect;
    @ViewChild('stateAllSelect',{static:true}) stateAllSelect: MatCheckbox;

    @ViewChild('branchSelect',{static:true}) branchSelect: MatSelect;
    @ViewChild('branchAllSelect',{static:true}) branchAllSelect: MatCheckbox;

    public formError: FormErrorModel;
    public formErrors = {
        type: '',
        selectedStates: '',
        selectedBranches: '',
        fromDateFilter: '',
        toDateFilter: '',
        stateName:'',
        name:'',
        businessLineId:''
    };
  
    constructor(private questionaireService: QuestionaireService,
                private reportService: ReportService, private igFormService: IgFormService,
                private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,
                private snackBar: MatSnackBar,private userService: UserService,
                @Inject(LOCALE_ID) private locale: string) {
                    this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                this.loggedInUserRole = data.loggedInUser.body.role;
              });

    }


    ngOnInit(): void {
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(businessLines => {
            this.businessLines = businessLines;
        });
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        this.HardBucketReport();
        this.hardBucketReportForm.controls['businessLineId'].disable();
        if (this.loggedInUserRole ==='ROLE_ADMIN') {
            this.isRoleAdmin = true;
            this.hardBucketReportForm.controls['businessLineId'].enable();
        }else{  this.loadStates();
        }
    }

    onItemSelect(event){
    }
  
    onSelectAll(event){}

    ngOnDestroy(): void {
    }

    HardBucketReport() {
        this.hardBucketReportList = hardBucketReporttypes;
    }

    onSelectState(state: any) {
        this.selectedStates.push(state);
        this.loadBranches();
    }

    onSelectAllState(state: any) {
        this.selectedStates = state;
        this.loadBranches();
    }


    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.hardBucketReportForm);
        if (this.hardBucketReportForm.valid) {
            this.downloadCihReport();
        } else {
            this.formErrors = this.igFormService.validateForm(this.hardBucketReportForm, this.formErrors, false);
            return false;
        }
    }

    buildForm() {
        this.hardBucketReportForm = this.formBuilder.group({
            type: [this.type, Validators.required],
            stateName: [this.selectedStates, Validators.required],
            name: [this.branches, Validators.required],
            fromDateFilter: [this.fromDateFilter, Validators.required],
            toDateFilter: [this.toDateFilter, Validators.required],
            businessLineId:['',Validators.required]
        });
        this.hardBucketReportForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.hardBucketReportForm, this.formErrors, true);
        });
    }

    loadBranches() {
        this.selectedBranches=null;
        console.log(this.selectedStates);
        this.stateList = [];
        for (let i = 0; i < this.selectedStates.length; i++) {
            this.stateList.push(this.selectedStates[i].name);
        }
        this.questionaireService.getAllBranchesForStates(this.businessLine,this.stateList, true).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<Branch[]>) => {
                    this.branches = res.body;
                    this.dropdownList = this.branches;
                });
    }

    loadStates() {
        console.log(this.selectedBusinessLine);
        this.questionaireService.getAllStatesForUser(this.businessLine).pipe(takeUntil(componentDestroyed(this)))
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.states = res.body;
                    this.stateDropdownList = this.states;
                }
            );
    }

    downloadCihReport() {
        this.codes = [];
        this.selectedBranches.forEach((value) => {
            this.codes.push(value.code);
        });
        this.reportService.downloadHardBucketReport({
            type: this.type,
            branchCodes: this.codes,
            fromDate: formatDate(this.fromDateFilter, 'yyyy-MM-dd', 'en-us'),
            toDate: formatDate(this.toDateFilter, 'yyyy-MM-dd', 'en-us')
        })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(res => {
                console.log('start download:', res);
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => {
                console.log('download error:', JSON.stringify(error));
                this.onError();
            }, () => {
                console.log('Completed file download.');
            });
    }

    onError() {
        this.fromdate1 = formatDate(this.fromDateFilter, 'dd-MM-yyyy', this.locale);
        this.todate1 = formatDate(this.toDateFilter, 'dd-MM-yyyy', this.locale);
        this.snackBar.open(` Data Not found selected From date: ` + this.fromdate1 + ` To Date: ` + this.todate1 + ``, 'Close', {
            duration: 5000
        });
    }

    onItemDeSelect(item: any) {
        this.selectedBranches = [];
        this.loadBranches();
    }

    onItemDeSelectBranch(item: any) {
    }

    onItemDeSelectAll(item: any) {
        this.selectedStates = [];
        this.loadBranches();
    }

    clearFilter() {
        // this.type = '';
        // this.selectedBranches = [];
        // this.selectedStates = [];
        // this.fromDateFilter = '';
        // this.toDateFilter = '';
        // this.selectedBusinessLine = ''
        this.hardBucketReportForm.reset();
        if(this.businessLine !== undefined){
            this.stateDropdownList = [];
          }
          this.dropdownList=[];
    }

    changeBusinessLine(selectedBusniessLine: any){
        this.selectedStates=null;
        this.businessLine = selectedBusniessLine.value;
        this.loadStates();
      }

}
